import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import config from "./config";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { getUA } from "react-device-detect";

// Tracking
import * as Sentry from "@sentry/browser";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: "properties",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
        ],
    },
});

const trackPageView = (location) => {
    setTimeout(() => {
        // Google Analytics
        if (window.gtag) {
            window.gtag("config", "UA-74257248-1", {
                page_path: location.pathname,
            });
        }

        // Facebook Pixel
        ReactPixel.pageView();
    }, 1000);
};

const history = createBrowserHistory();

if (process.env.NODE_ENV === "production" && !getUA.includes("Prerender")) {
    // Facebook Pixel
    ReactPixel.init("290509301352672");

    trackPageView(history.location);
    history.listen(trackPageView);

    // Hotjar
    hotjar.initialize(1477019);

    // Sentry
    Sentry.init({
        dsn: "https://865d32609ee7459a823d6f4db0ffdb94@sentry.io/1794891",
    });

    // LinkedIn
    LinkedInTag.init("2315980");
}

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
