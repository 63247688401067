import React, { Component } from "react";

import PropTypes from "prop-types";

import { FaBed, FaBath, FaCar, FaBuilding } from "react-icons/fa";

class FaComponent extends Component {
    static propTypes = {
        tag: PropTypes.string,
    };

    components = {
        bed: FaBed,
        bath: FaBath,
        car: FaCar,
        building: FaBuilding,
    };

    render() {
        const TagName = this.components[this.props.tag];
        return <TagName />;
    }
}
export default FaComponent;
