import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { GoogleMapProvider } from "@googlemap-react/core";
import MapPanel from "../components/map/MapPanel";
import Place from "../components/map/Place";
import ListingPanel from "../components/ListingPanel";
import { FaHome } from "react-icons/fa";

ComparableProperties.propTypes = {
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    cardType: PropTypes.string.isRequired,
    address: PropTypes.string,
    numBed: PropTypes.number,
    numBath: PropTypes.number,
    numCar: PropTypes.number,
    dwellingType: PropTypes.string,
    showHome: PropTypes.bool,
    months: PropTypes.number,
    addressSlug: PropTypes.string,
    filterStreet: PropTypes.bool,
    limitOverride: PropTypes.number,
    withinBoundary: PropTypes.string,
    hideShowMore: PropTypes.bool,
};

export default function ComparableProperties(props) {
    const [latitude, setLatitude] = useState(props.latitude);
    const [longitude, setLongitude] = useState(props.longitude);

    useEffect(() => {
        if (props.latitude !== latitude || props.longitude !== longitude) {
            setLatitude(props.latitude);
            setLongitude(props.longitude);
        }
    }, [props.latitude, props.longitude, latitude, longitude]);

    return (
        <GoogleMapProvider>
            {latitude && longitude && (
                <Fragment>
                    <MapPanel
                        location={{
                            lat: latitude,
                            lng: longitude,
                        }}
                        height={"40vh"}
                        zoom={16}
                    />
                    <ListingPanel
                        latitude={latitude}
                        longitude={longitude}
                        cardType={props.cardType}
                        numBed={props.numBed}
                        numBath={props.numBath}
                        numCar={props.numCar}
                        dwellingType={props.dwellingType}
                        months={props.months}
                        addressSlug={props.addressSlug}
                        filterStreet={props.filterStreet}
                        limitOverride={props.limitOverride}
                        withinBoundary={props.withinBoundary}
                        hideShowMore={props.hideShowMore}
                    />

                    {props.showHome && (
                        <Place
                            key={props.address}
                            location={{
                                lat: latitude,
                                lng: longitude,
                            }}
                            icon={<FaHome />}
                            height="30px"
                            width="30px"
                            zindex={110}
                            fitBounds={true}
                        />
                    )}
                </Fragment>
            )}
        </GoogleMapProvider>
    );
    // }
}
