import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Carousel, { Modal, ModalGateway } from "react-images";

const navButtonStyles = (base) => ({
    ...base,
    backgroundColor: "white",
    boxShadow: "0 1px 6px rgba(0, 0, 0, 0.18)",
    color: "#666666",

    "&:hover, &:active": {
        backgroundColor: "white",
        color: "#666666",
        opacity: 1,
    },
    "&:active": {
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.14)",
        transform: "scale(0.96)",
    },
});

class PropertyImagePanel extends Component {
    static propTypes = {
        imageBucketUrl: PropTypes.string,
        images: PropTypes.array.isRequired,
        address: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            images: this.props.imageBucketUrl
                ? this.convertImagesToGalleryFormat(this.props.images)
                : this.props.images,
            lightboxIsOpen: false,
            selectedIndex: undefined,
        };
    }

    toggleLightbox = (selectedIndex) => {
        this.setState({
            lightboxIsOpen: !this.state.lightboxIsOpen,
            selectedIndex: selectedIndex,
        });
    };

    convertImagesToGalleryFormat(images) {
        let jsonObj = [];

        for (let i = 0; i < images.length; i++) {
            jsonObj.push({
                source:
                    this.props.imageBucketUrl +
                    "/" +
                    images[i].substr(0, images[i].lastIndexOf(".")) +
                    "-1920.jpg",
                thumbnail:
                    this.props.imageBucketUrl +
                    "/" +
                    images[i].substr(0, images[i].lastIndexOf(".")) +
                    "-800.jpg",
            });
        }

        return jsonObj;
    }

    updateImages() {
        if (this.props.imageBucketUrl) {
            this.setState({
                images: this.convertImagesToGalleryFormat(this.props.images),
            });
        } else {
            this.setState({
                images: this.props.images,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.images &&
            prevProps.images &&
            this.props.images[0] !== prevProps.images[0]
        ) {
            this.updateImages();
        }
    }

    render() {
        return (
            <>
                {this.state.images && (
                    <Fragment>
                        <Row className="mt-4">
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    className="bg-dark text-white"
                                    border="light"
                                    onClick={() => this.toggleLightbox(0)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Card.Img
                                        src={this.state.images[0].thumbnail}
                                        alt={this.props.address}
                                    />
                                </Card>
                            </Col>
                            <Col
                                md={4}
                                lg={4}
                                xl={4}
                                className="d-none d-md-flex flex-column flex-wrap align-content-between"
                            >
                                {this.state.images[1] && (
                                    <Card
                                        className="bg-dark text-white"
                                        border="light"
                                        onClick={() => this.toggleLightbox(1)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Card.Img
                                            src={this.state.images[1].thumbnail}
                                            alt={
                                                "Image #2 for " +
                                                this.props.address
                                            }
                                        />
                                    </Card>
                                )}
                                {this.state.images[2] && (
                                    <Card
                                        className="bg-dark text-white align-bottom mt-auto"
                                        border="light"
                                        onClick={() => this.toggleLightbox(2)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Card.Img
                                            src={this.state.images[2].thumbnail}
                                            alt={
                                                "Image #3 for " +
                                                this.props.address
                                            }
                                            style={{ cursor: "pointer" }}
                                        />
                                        {this.state.images.length > 3 && (
                                            <Card.ImgOverlay
                                                className="d-flex align-items-center"
                                                style={{
                                                    backgroundColor:
                                                        "rgb(0,0,0,0.5)",
                                                }}
                                            >
                                                <Card.Body className="h1 text-center">
                                                    +{" "}
                                                    {this.state.images.length -
                                                        3}
                                                </Card.Body>
                                            </Card.ImgOverlay>
                                        )}
                                    </Card>
                                )}
                            </Col>
                        </Row>
                        <ModalGateway>
                            {this.state.lightboxIsOpen ? (
                                <Modal onClose={this.toggleLightbox}>
                                    <Carousel
                                        currentIndex={this.state.selectedIndex}
                                        frameProps={{ autoSize: "height" }}
                                        views={this.state.images}
                                        styles={{
                                            container: (base) => ({
                                                ...base,
                                                height: "100vh",
                                            }),
                                            view: (base) => ({
                                                ...base,
                                                alignItems: "center",
                                                display: "flex ",
                                                height: "calc(100vh - 54px)",
                                                justifyContent: "center",

                                                "& > img": {
                                                    maxHeight:
                                                        "calc(100vh - 94px)",
                                                },
                                            }),
                                            navigationPrev: navButtonStyles,
                                            navigationNext: navButtonStyles,
                                            footerCaption: (base) => ({
                                                ...base,
                                                fontSize: 40,
                                            }),
                                            footerCount: (base) => ({
                                                ...base,
                                                fontSize: 22,
                                            }),
                                        }}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </Fragment>
                )}
            </>
        );
    }
}

export const convertImages = (images) => {
    let converted_images = [];

    for (let i = 0; i < images.length; i++) {
        converted_images.push({
            source: images[i],
            thumbnail: images[i],
        });
    }

    return converted_images;
};

export default PropertyImagePanel;
