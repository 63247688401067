import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import isEmpty from "lodash.isempty";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";

import { convertUnderscoreToDash, titleCase } from "../Helpers";
import AddressTextBox from "../components/AddressTextBox";
import LASchoolCard from "../components/LASchoolCard";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import NavDropdown from "react-bootstrap/NavDropdown";

import { UserContext } from "../App";

import "./Home.css";

SchoolsHome.propTypes = {
    history: PropTypes.object,
};

export default function SchoolsHome(props) {
    const [state, dispatch] = React.useContext(UserContext);

    const [la2List, setLa2List] = useState([]);
    const [la2SortBy, setLa2SortBy] = useState([
        "number_of_schools",
        "average_icsea_value",
    ]);
    const [la2OrderBy, setLa2OrderBy] = useState(["desc", "desc"]);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: "/" } },
        };
        props.history.push(location);
    }

    useEffect(() => {
        async function getLa2List() {
            const la2Json =
                process.env.REACT_APP_DATA_URL + "/schools/sydney/la2.json";

            fetch(la2Json)
                .then((response) => response.json())
                .then((response) => {
                    setLa2List(orderBy(response, la2SortBy, la2OrderBy));
                });
        }

        getLa2List();
    }, [la2SortBy, la2OrderBy]);

    function handleSort(sort_key) {
        setLa2SortBy(sort_key.split("-")[0]);
        setLa2OrderBy(sort_key.split("-")[1]);
    }

    return (
        <Fragment>
            <Helmet>
                <style type="text/css">{`
                        .navbar.header {
                            display: none;
                        }

                    `}</style>
                <title>{"NSW School Catchment Areas - Liveable"}</title>
                <meta
                    property="og:title"
                    content={"NSW School Catchment Areas - Liveable"}
                />
                <meta
                    name="description"
                    content="List of Public Schools and their Catchment Areas in the Greater Sydney Region"
                />
                <meta
                    property="og:description"
                    content="List of Public Schools and their Catchment Areas in the Greater Sydney Region"
                />
                <link rel="canonical" href="https://liveable.co/schools" />
                <meta property="og:url" content="https://liveable.co/schools" />
                <meta
                    property="og:image"
                    content="https://liveable.co/images/liveable_schools_og.jpg"
                />
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co",
                        name: "Liveable - Property Research Simplified",
                        description:
                            "Get the full picture of a neighbourhood and its surroundings. Liveable helps take the guesswork out of finding an ideal place to live",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic
                    type="webPage"
                    jsonldtype="WebPage"
                    schema={{
                        name: "School Research Simplified - Liveable",
                        description:
                            "List of Public Schools and their Catchment Areas in the Greater Sydney Region",
                        url: "https://liveable.co/schools",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    <GenericCollection type="itemListElement">
                        <Generic
                            jsonldtype="ListItem"
                            schema={{
                                position: 1,
                                name: "School Catchment Areas",
                                item: "https://liveable.co/schools",
                            }}
                        />
                    </GenericCollection>
                </Generic>
            </JSONLD>
            <Container fluid className="header-bg-area-splash">
                <Container>
                    <Navbar
                        className="justify-content-between pt-3"
                        variant="dark"
                        expand="sm"
                    >
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src="https://liveable.co/images/liveable_logo_top.png"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Fragment>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>

                                <Nav className="px-0">
                                    {state.isAuthenticated ? (
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                dispatch({
                                                    type: "LOGOUT",
                                                })
                                            }
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="warning"
                                                onClick={goToLogin}
                                            >
                                                Login
                                            </Button>
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    </Navbar>
                    <Row
                        id="header-cta"
                        style={{ paddingTop: "11vh", paddingBottom: "10vh" }}
                    >
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <h1 className="header-text">
                                NSW School Catchment Areas
                            </h1>
                            <h4 className="header-text">
                                Explore school catchment areas for properties
                                and neighbourhoods
                            </h4>
                            <div className="mt-4">
                                <AddressTextBox type="schools" />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                Neighbourhoods
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col xs={0} sm={2} md={4} lg={4} xl={4} />
                        <Col className="text-center">
                            <NavDropdown
                                title="Sort By"
                                id="sort-nav-dropdown"
                                onSelect={(e) => handleSort(e)}
                                flip="false"
                            >
                                <NavDropdown.Item eventKey="number_of_schools-asc">
                                    Number of Schools: Low-High
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="number_of_schools-desc">
                                    Number of Schools: High-Low
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey="average_latest_year_enrolment-asc">
                                    Average Students per School: Low-High
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="average_latest_year_enrolment-desc">
                                    Average Students per School: High-Low
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey="average_icsea_value-asc">
                                    Average ICSEA Value: Low-High
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="average_icsea_value-desc">
                                    Average ICSEA Value: High-Low
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Col>{" "}
                        <Col xs={0} sm={2} md={4} lg={4} xl={4} />
                    </Row>
                    <Container fluid>
                        <Row>
                            {!isEmpty(la2List) &&
                                la2List.map((neighbourhood) => (
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        className="pt-3"
                                        key={convertUnderscoreToDash(
                                            neighbourhood.la2
                                        )}
                                    >
                                        <LASchoolCard
                                            base_url={
                                                process.env
                                                    .REACT_APP_SCHOOLS_URL +
                                                "/sydney"
                                            }
                                            la3_id={convertUnderscoreToDash(
                                                neighbourhood.la3
                                            )}
                                            la2_id={convertUnderscoreToDash(
                                                neighbourhood.la2
                                            )}
                                            name={titleCase(
                                                neighbourhood.la2,
                                                "_"
                                            )}
                                            data={[
                                                {
                                                    label: "Schools",
                                                    data:
                                                        neighbourhood.number_of_schools,
                                                },
                                                {
                                                    label: "Avg Students",
                                                    data: Math.round(
                                                        neighbourhood.average_latest_year_enrolment,
                                                        2
                                                    ),
                                                },
                                                {
                                                    label: "Avg ICSEA",
                                                    data: Math.round(
                                                        neighbourhood.average_icsea_value,
                                                        2
                                                    ),
                                                },
                                            ]}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Container>
                </Container>
            </Container>
        </Fragment>
    );
}
