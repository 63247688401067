import React, { Component } from "react";
import PropTypes from "prop-types";
import GroupedCensusChart from "./charts/GroupedCensusChart";

/**
 * todo: make the charts fit within the window
 * https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 */
export default class Census extends Component {
    static propTypes = {
        la1_id: PropTypes.string,
        la2_id: PropTypes.string.isRequired,
        la3_id: PropTypes.string.isRequired,
        la4_id: PropTypes.string.isRequired,
        showSummaryOnly: PropTypes.bool,
    };

    render() {
        return (
            <GroupedCensusChart
                la1_id={this.props.la1_id}
                la2_id={this.props.la2_id}
                la3_id={this.props.la3_id}
                la4_id={this.props.la4_id}
                showSummaryOnly={this.props.showSummaryOnly}
            />
        );
    }
}
