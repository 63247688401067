import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import orderBy from "lodash/orderBy";

import { convertUnderscoreToDash, titleCase } from "../Helpers";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

AreaLA4.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            la4_id: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function AreaLA4(props) {
    const [name] = useState(titleCase(props.match.params.la4_id));
    const [la3List, setLa3List] = useState([]);
    // const [la2List, setLa2List] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        getNeighbourhoods("la3");

        async function getNeighbourhoods(la) {
            const la3Json =
                process.env.REACT_APP_DATA_URL +
                "/metadata" +
                "/" +
                props.match.params.la4_id +
                "/" +
                la +
                "-home.json";

            fetch(la3Json)
                .then((response) => response.json())
                .then((response) => {
                    if (la === "la3") {
                        setLa3List(orderBy(response, "name", "asc"));
                    } else {
                        // setLa2List(orderBy(response, "name", "asc"));
                    }
                })
                .catch(() => {
                    props.history.push({
                        pathname: "/404",
                    });
                });
        }
        // getNeighbourhoods("la2");
    }, [props.history, props.match.params.la4_id]);

    return (
        <Fragment>
            {name && (
                <Helmet>
                    <title>{name + " Property Research - Liveable"}</title>
                    <meta
                        property="og:title"
                        content={name + " Property Research - Liveable"}
                    />
                    <meta
                        name="description"
                        content={
                            "Property Research for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            "Property Research for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id
                        }
                    />
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id
                        }
                    />
                </Helmet>
            )}
            {name && (
                <>
                    <JSONLD>
                        <Generic
                            type="webPage"
                            jsonldtype="WebPage"
                            schema={{
                                name: name + " Property Research",
                                description:
                                    "Property Research for " +
                                    name +
                                    ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics.",
                                url:
                                    "https://liveable.co" +
                                    props.location.pathname,
                            }}
                        />
                    </JSONLD>
                    <JSONLD>
                        <Generic
                            type="breadcrumbList"
                            jsonldtype="BreadcrumbList"
                        >
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Area",
                                        item: "https://liveable.co/area",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: titleCase(
                                            props.match.params.la4_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id,
                                    }}
                                />
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                </>
            )}
            <Container className="pb-5 pt-4">
                <section id="area">
                    <Row>
                        <Col>
                            <h1>
                                Local Government Areas in{" "}
                                {titleCase(props.match.params.la4_id, "-")}
                            </h1>
                        </Col>
                    </Row>
                </section>
                <section id="neighbourhoods" className="mt-3">
                    <Row>
                        {la3List.map((la3) => (
                            <Col
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                                className="pt-3 text-center carousel-card"
                                key={"La3_" + la3.id}
                            >
                                <Card bg="light" key={"La3_Card_" + la3.id}>
                                    <Card.Body>
                                        {la3.name_id && (
                                            <a
                                                href={
                                                    "/area/" +
                                                    props.match.params.la4_id +
                                                    "/" +
                                                    convertUnderscoreToDash(
                                                        la3.name_id
                                                    )
                                                }
                                            >
                                                <Card.Title>
                                                    {la3.name}
                                                </Card.Title>
                                            </a>
                                        )}
                                        <Card.Subtitle className="mt-3 text-muted">
                                            Area:{" "}
                                            {Math.round(la3.area / 1000) / 100}{" "}
                                            km<sup>2</sup>
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </section>
            </Container>
        </Fragment>
    );
}
