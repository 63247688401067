import React, { Component, createRef } from "react";
import PropTypes from "prop-types";

import * as d3 from "d3";

export default class GroupedBarGraph extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        totals: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
        fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        label_margin: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {};
    }

    componentDidMount() {
        this.drawChart();
    }

    UNSAFE_componentWillUpdate() {
        this.drawChart();
    }

    wrap(text, width) {
        text.each(function() {
            let text = d3.select(this);

            if (!text.text().includes("-")) {
                let words = text
                        .text()
                        .split(/\s+/)
                        .reverse(),
                    word,
                    line = [],
                    // lineNumber = 0,
                    lineHeight = 1.0, // ems
                    y = text.attr("y"),
                    dy = 0;
                if (words.length > 1) {
                    let tspan = text
                        .text(null)
                        .append("tspan")
                        // .attr("x", -5)
                        .attr("x", text.attr("x"))
                        .attr("y", y)
                        .attr("dy", dy + "em");

                    while ((word = words.pop())) {
                        line.push(word);
                        tspan.text(line.join(" "));

                        if (
                            tspan.node().getComputedTextLength() -
                                text.attr("x") >
                            width
                        ) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text
                                .append("tspan")
                                .attr("x", text.attr("x"))
                                .attr("y", y)
                                .attr(
                                    "dy",
                                    // ++lineNumber * lineHeight + dy + "em"
                                    lineHeight + "em"
                                )
                                .text(word);
                        }
                    }
                }
            }
        });
    }

    drawChart() {
        let { width, totals, data, fill, label_margin } = this.props;
        const num_groups = totals.length;
        const target_bar_height = 20;

        // Make left margin dynamic based on label widths
        const longest_type = data.reduce(function(a, b) {
            return a.type.length > b.type.length ? a : b;
        });
        // Set the dimensions and margins of the graph
        const longest_label = label_margin
            ? label_margin
            : (longest_type.type.length * 6) / 3;
        const min_left = 70;

        const margin = {
            top: 10,
            right: 50,
            // right: 10,
            // bottom: 20,
            bottom: 10,
            left: longest_label > min_left ? longest_label : min_left, // left is based on length of labels
        };

        width = this.ref.current
            ? this.ref.current.parentNode.offsetWidth * 0.9
            : width;

        // auto adjust height/width of the chart based on item count and window size
        let svg_width = width;
        let chart_width = svg_width - margin.left - margin.right;
        const svg_height =
            num_groups * target_bar_height * data.length +
            margin.top +
            margin.bottom;
        const chart_height = svg_height - margin.top - margin.bottom;
        let x = d3.scaleLinear().range([0, chart_width]);
        let y = d3.scaleBand().range([chart_height, 0]);

        // Calculate the max percentage to determine the length of the longest bar
        let percentages = [];
        data.forEach((d) => {
            d.values.forEach((v, i) => percentages.push(v / totals[i]));
        });
        x.domain([0, d3.max(percentages)]);

        y.domain(
            data.map(function(d) {
                return d.type;
            })
        )
            .padding(0.1)
            .paddingOuter(0.75);
        // console.log(
        //     "render chart",
        //     window.innerWidth,
        //     this.props.width,
        //     this.ref,
        //     this.ref.current,
        //     this.ref.current.offsetWidth,
        //     this.ref.current.parentNode,
        //     this.ref.current.parentNode.offsetWidth,
        //     d3.select(this.ref.current)
        // );

        //avoid re-rendering over a previous svg
        d3.select(this.ref.current)
            .select("g")
            .remove();

        let svg = d3
            .select(this.ref.current)
            // .attr("width", width) // fixed size
            // .attr("height", height) // fixed size
            .attr("width", svg_width) // adjusted size
            .attr("height", svg_height) // adjusted size
            .append("g")
            .attr(
                "transform",
                "translate(" + margin.left + "," + margin.top + ")" // make room for the labels
            );

        const bar_height = y.bandwidth() / num_groups;

        // create a group for your grouped bars
        const g = svg
            .selectAll(".bar")
            .data(data)
            .enter()
            .append("g");

        g.append("rect")
            .attr("x", (d) => {
                return x(d.type);
            })
            .attr("height", bar_height)
            .attr("y", (d) => {
                return y(d.type);
            })
            .attr("width", (d) => {
                return x(d.values[0] / totals[0]);
            })
            .attr("fill", Array.isArray(fill) ? fill[0] : fill);

        g.append("rect")
            .attr("x", (d) => {
                return x(d.type);
            })
            .attr("height", bar_height)
            .attr("y", (d) => {
                return y(d.type) + bar_height;
            })
            .attr("width", (d) => {
                return x(d.values[1] / totals[1]); //todo: harcoded index
            })
            .attr("fill", Array.isArray(fill) ? fill[1] : fill);

        g.append("rect")
            .attr("x", (d) => {
                return x(d.type);
            })
            .attr("height", bar_height)
            .attr("y", (d) => {
                return y(d.type) + 2 * bar_height;
            })
            .attr("width", (d) => {
                return x(d.values[2] / totals[2]); //todo: harcoded index
            })
            .attr("fill", Array.isArray(fill) ? fill[2] : fill);

        // add the Y-axis
        svg.append("g")
            .attr("class", "y axis")
            .call(d3.axisLeft(y))

            .selectAll(".tick text")
            .style("text-anchor", "end")

            .call(this.wrap, margin.left)
            .call((g) => g.select(".domain").remove()); // remove the axis line;

        // Dummy component to calculate where to place label on bar
        let textWidthArray = [...Array(num_groups)].map(() => Array(0));
        for (let i = 0; i < textWidthArray.length; i++) {
            svg.append("g")
                .selectAll(".dummyText")
                .data(data)
                .enter()
                .append("text")
                .attr("font-family", "sans-serif")
                // .attr("font-size", "6px")
                .attr("font-size", "0.6em")
                // .attr("font-size", label_font_size)
                .text(function(d) {
                    return (100 * (d.values[i] / totals[i])).toFixed(1) + "%";
                })
                .each(function() {
                    const thisWidth = this.getComputedTextLength();
                    textWidthArray[i].push(thisWidth);
                    this.remove(); // remove them just after displaying them
                });
        }

        // Add text label on bars
        // const xOutsidePadding = 22;
        // const xOutsidePadding = 26;
        const xOutsidePadding = 0;
        const xInsidePadding = -2;
        // const yPadding = 4;
        // const yPadding = 0;

        // create a group for the text on bars
        const text = svg
            .selectAll(".bartext")
            .data(data)
            .enter()
            .append("g");

        for (let i = 0; i < textWidthArray.length; i++) {
            text.append("text")
                .attr("x", (d, j) => {
                    return x(d.values[i] / totals[i]) <
                        textWidthArray[i][j] - xInsidePadding
                        ? x(d.values[i] / totals[i]) + xOutsidePadding // place text outside bar
                        : // : x(d.values[i] / totals[i]) + xInsidePadding; // place text inside bar
                          x(d.values[i] / totals[i]) - textWidthArray[i][j]; // place text inside bar
                })
                .attr("dy", ".7em") // align to middle of bar
                .attr("y", (d) => {
                    return y(d.type) + (i * y.bandwidth()) / num_groups;
                })
                .attr("text-align", "center")
                .attr("dominant-baseline", "central")
                .attr("font-family", "sans-serif")
                .attr("font-size", "0.6em")
                .attr("fill", (d, j) => {
                    return x(d.values[i] / totals[i]) <
                        textWidthArray[i][j] - xInsidePadding
                        ? "black" // text outside bar
                        : "white"; // text inside bar
                })
                .text((d) => {
                    // Don't display value if its 0
                    return d.values[i] === 0
                        ? ""
                        : (100 * (d.values[i] / totals[i])).toFixed(1) + "%";
                });
        }
    }

    render() {
        return <svg ref={this.ref} />;
    }
}
