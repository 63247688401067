import React from "react";

export const PropertiesContext = React.createContext(null);

export function reducer(state, action) {
    return {
        properties: action.properties,
        lower_quantile: action.lower_quantile,
        median: action.median,
        upper_quantile: action.upper_quantile,
        count: action.count,
    };
}

export const initialState = {
    properties: [],
    median: null,
    lower_quantile: null,
    upper_quantile: null,
};
