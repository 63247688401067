import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";

import { convertUnderscoreToDash } from "../Helpers";
import AddressTextBox from "../components/AddressTextBox";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { UserContext } from "../App";

import "./Home.css";

AreaHome.propTypes = {
    history: PropTypes.object,
};

export default function AreaHome(props) {
    const [state, dispatch] = React.useContext(UserContext);

    const DEFAULT_LIMIT = 32; // cards to show per pagination

    const [blogPosts, setBlogPosts] = useState([]);
    const [la2List, setLa2List] = useState([]);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [maxLimit, setMaxLimit] = useState(500);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: "/" } },
        };
        props.history.push(location);
    }

    async function getBlogPosts() {
        const numberOfPosts = 8;
        const blogJson =
            "https://blog.liveable.co/wp-json/wp/v2/posts?per_page=" +
            numberOfPosts;

        fetch(blogJson)
            .then((response) => response.json())
            .then((response) => {
                setBlogPosts(response);
            });
    }

    async function getNeighbourhoods() {
        const la2Json =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/sydney" +
            "/la2-home.json";

        fetch(la2Json)
            .then((response) => response.json())
            .then((response) => {
                setLa2List(orderBy(response, "popn", "desc"));
                setMaxLimit(response.length);
            });
    }

    useEffect(() => {
        getNeighbourhoods();
        getBlogPosts();
    }, []);

    function handleClickButtonShowMore() {
        setLimit(limit + DEFAULT_LIMIT);
    }

    return (
        <Fragment>
            <Helmet>
                <style type="text/css">{`
                        .navbar.header {
                            display: none;
                        }

                    `}</style>
                <link rel="canonical" href="https://liveable.co/area" />
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co",
                        name: "Liveable - Property Research Simplified",
                        description:
                            "Get the full picture of a neighbourhood and its surroundings. Liveable helps take the guesswork out of finding an ideal place to live",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic
                    type="webPage"
                    jsonldtype="WebPage"
                    schema={{
                        name: "Neighbourhood Research Simplified - Liveable",
                        description:
                            "Get the full picture of a neighbourhood and its surroundings. Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics.",
                        url: "https://liveable.co/area",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    <GenericCollection type="itemListElement">
                        <Generic
                            jsonldtype="ListItem"
                            schema={{
                                position: 1,
                                name: "Area",
                                item: "https://liveable.co/area",
                            }}
                        />
                    </GenericCollection>
                </Generic>
            </JSONLD>
            <Container fluid className="header-bg-area-splash">
                <Container>
                    <Navbar
                        className="justify-content-between pt-3"
                        variant="dark"
                        expand="sm"
                    >
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src="https://liveable.co/images/liveable_logo_top.png"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Fragment>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>

                                <Nav className="px-0">
                                    {state.isAuthenticated ? (
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                dispatch({
                                                    type: "LOGOUT",
                                                })
                                            }
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="warning"
                                                onClick={goToLogin}
                                            >
                                                Login
                                            </Button>
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    </Navbar>
                    <Row
                        id="header-cta"
                        style={{ paddingTop: "11vh", paddingBottom: "10vh" }}
                    >
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <h1 className="header-text">
                                Neighbourhood Research Simplified
                            </h1>
                            <h4 className="header-text">
                                Get the full picture of a neighbourhood
                            </h4>
                            <div className="mt-4">
                                <AddressTextBox />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 background-grey liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                The Pulse
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row className="carousel-wrapper">
                        {blogPosts.map((post) => (
                            <Col
                                xs={10}
                                sm={10}
                                md={4}
                                lg={4}
                                xl={4}
                                className="pt-3 text-center carousel-card"
                                key={"BlogPost_" + post.id}
                            >
                                <Card
                                    bg="light"
                                    key={"BlogPost_Card_" + post.id}
                                >
                                    {post.jetpack_featured_media_url && (
                                        <a href={post.link}>
                                            <Card.Img
                                                variant="top"
                                                src={
                                                    post.jetpack_featured_media_url
                                                }
                                            />
                                        </a>
                                    )}
                                    <Card.Body>
                                        <a href={post.link}>
                                            <Card.Title
                                                dangerouslySetInnerHTML={{
                                                    __html: post.title.rendered,
                                                }}
                                            ></Card.Title>
                                        </a>
                                        <Card.Text
                                            className="mt-3 mb-2 text-muted"
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered.includes(
                                                    "&hellip;"
                                                )
                                                    ? post.excerpt.rendered.substring(
                                                          0,
                                                          post.excerpt.rendered.indexOf(
                                                              "&hellip;"
                                                          ) + "&hellip;".length
                                                      )
                                                    : post.excerpt.rendered,
                                            }}
                                        ></Card.Text>
                                        <a href={post.link}>
                                            <Button
                                                id="blog-read-more-link"
                                                variant="link"
                                                className="mt-2"
                                            >
                                                Read more
                                            </Button>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                Neighbourhoods
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        {la2List.slice(50, 50 + limit).map((la2) => (
                            <Col
                                xs={6}
                                sm={6}
                                md={3}
                                lg={3}
                                xl={3}
                                className="pt-3 text-center carousel-card"
                                key={"La2_" + la2.id}
                            >
                                <Card bg="light" key={"La2_Card_" + la2.id}>
                                    <Card.Body>
                                        {/* TODO: Sydney as LA4 hardcoded. Need to fix */}
                                        {la2.name_id && (
                                            <a
                                                href={
                                                    "/area/sydney/" +
                                                    convertUnderscoreToDash(
                                                        la2.parent_name_id
                                                    ) +
                                                    "/" +
                                                    convertUnderscoreToDash(
                                                        la2.name_id
                                                    )
                                                }
                                            >
                                                <Card.Title>
                                                    {la2.name}
                                                </Card.Title>
                                            </a>
                                        )}
                                        <Card.Subtitle className="mt-3 mb-2 text-muted">
                                            {la2.postal.join(", ")}
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {la2List.length > 0 && limit < maxLimit && (
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button
                                    id="home-neighbourhoods-show-more"
                                    variant="outline-primary"
                                    onClick={() => handleClickButtonShowMore()}
                                >
                                    Show More
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Container>
        </Fragment>
    );
}
