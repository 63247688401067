import React, { useState, useEffect, Fragment } from "react";
// import { Redirect } from "react-router";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { API } from "aws-amplify";
import {
    slugify,
    convertDashToPlus,
    addressStripCountry,
    // whatsMyLimit,
    titleCase,
    convertUnderscoreToDash,
    convertDashToUnderscore,
    moneyFormatter,
    // getSlugCount,
    storeSlug,
    convertNameToId,
    trackView,
} from "../Helpers";
import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import { getUA } from "react-device-detect";
import { Link } from "react-router-dom";
import ScrollUp from "react-scroll-up";
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";

// import { GoogleMapProvider } from "@googlemap-react/core";
// import MapPanel from "../components/map/MapPanel";
// import Place from "../components/map/Place";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import HeaderNav from "../components/navbar/HeaderNav";
import PropertyInfo from "../components/PropertyInfo";
import ComparableProperties from "../components/ComparableProperties";
import Amenities from "../components/Amenities";
// import Transit from "../components/Transit";
import Schools from "../components/Schools";
import Census from "../components/Census";
import PropertyImagePanel from "../components/PropertyImagePanel";
import CallToActionFooter from "../components/navbar/CallToActionFooter";
// import Paywall from "../components/Paywall";
import MortgageRepaymentCalculator from "../components/MortgageRepaymentCalculator";
import PropertyHistory from "../components/PropertyHistory";

import { UserContext } from "../App";

import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";

import AdSense from "react-adsense";

PropertyProfile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            addressSlug: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function PropertyProfile(props) {
    const [state] = React.useContext(UserContext);

    const [addressSlug, setAddressSlug] = useState(undefined);
    const [lotNumber, setLotNumber] = useState(undefined);
    const [address, setAddress] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [numBed, setNumBed] = useState(undefined);
    const [numBath, setNumBath] = useState(undefined);
    const [numCar, setNumCar] = useState(undefined);
    const [area, setArea] = useState(undefined);
    const [areaType, setAreaType] = useState(undefined);
    const [dwellingType, setDwellingType] = useState(undefined);
    const [images, setImages] = useState([]);
    const [soldData, setSoldData] = useState(undefined);
    const [listingSaleData, setListingSaleData] = useState(undefined);
    const [listingRentData, setListingRentData] = useState(undefined);
    // const [types, setTypes] = useState(undefined);
    const [la1_id, setLa1_id] = useState(undefined);
    const [la2_id, setLa2_id] = useState(undefined);
    const [la2_name, setLa2_name] = useState(undefined);
    const [la3_id, setLa3_id] = useState(undefined);
    const [la3_name, setLa3_name] = useState(undefined);
    const [la4_id, setLa4_id] = useState(undefined);

    // const [scrollPositionY, setScrollPositionY] = useState(0);

    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [description, setDescription] = useState(undefined);

    // const [showPaywall, setShowPaywall] = useState(false);

    const [loading, setLoading] = useState(false);
    // const [sampleReport, setSampleReport] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setAddressSlug(undefined);
        setAddress(undefined);
        setNumBed(undefined);
        setNumBath(undefined);
        setNumCar(undefined);
        setArea(undefined);
        setAreaType(undefined);
        setDwellingType(undefined);
        setImages(undefined);
        setSoldData(undefined);
        setListingSaleData(undefined);
        setListingRentData(undefined);
        setDescription(undefined);
        setLoading(true);

        /**
         * Get la1, la2 from lat/lng and update the state
         *
         */
        async function getLiveableArea(lat, lng) {
            // if (
            //     lat !== latitude ||
            //     lng !== longitude ||
            //     typeof la1 === "undefined"
            // ) {
            let liveable_url =
                process.env.REACT_APP_LIVEABLE_DATA_API_URL +
                "/v1/la/sydney/?lat=" +
                lat +
                "&lng=" +
                lng;

            return fetch(liveable_url)
                .then((response) => response.json())
                .then((data) => {
                    setLa1_id(data.la1);
                    setLa2_id(data.la2);
                    setLa3_id(data.la3);
                    setLa4_id(data.la4);
                    setLa2_name(data.la2_name);
                    setLa3_name(data.la3_name);
                });
            // }
        }

        try {
            API.get(
                "properties",
                `/address/${props.match.params.addressSlug}`
            ).then((property) => {
                if (property.count > 0 && property.result[0].addressSlug) {
                    // if there are multiple properties with the same address slug, we take the first one
                    storeSlug(property.result[0].addressSlug);

                    setAddressSlug(property.result[0].addressSlug);
                    setLotNumber(property.result[0].lotNumber);
                    setAddress(
                        addressStripCountry(property.result[0].formattedAddress)
                    );
                    setLatitude(property.result[0].lat);
                    setLongitude(property.result[0].lng);
                    setNumBed(property.result[0].numBed);
                    setNumBath(property.result[0].numBath);
                    setNumCar(property.result[0].numCar);
                    setArea(property.result[0].area);
                    setAreaType(property.result[0].areaType);
                    setDwellingType(property.result[0].dwellingType);
                    setImages(property.result[0].images);
                    setSoldData(property.result[0].sold);
                    setListingSaleData(property.result[0].listingSale);
                    setListingRentData(property.result[0].listingRent);
                    setLa1_id(property.result[0].la1);
                    setLa2_id(property.result[0].la2);
                    setLa3_id(property.result[0].la3);
                    setLa4_id(property.result[0].la4);
                    if (property.result[0].la2) {
                        setLa2_name(titleCase(property.result[0].la2, "_"));
                    }

                    if (property.result[0].la3) {
                        setLa3_name(titleCase(property.result[0].la3, "_"));
                    }

                    if (
                        !property.result[0].la1 ||
                        !property.result[0].la2 ||
                        !property.result[0].la3 ||
                        !property.result[0].la4
                    ) {
                        getLiveableArea(
                            property.result[0].lat,
                            property.result[0].lng
                        );
                    }
                    setLoading(false);
                    // TODO: Work out what to do with multiple properties having the same addressSlug
                    //} else if (property.count > 1) {
                } else {
                    // retrieve the lat/lng from the address slug
                    if (getUA.includes("Prerender")) {
                        let here_geocode_url =
                            "https://geocoder.api.here.com/6.2/geocode.json?app_id=" +
                            process.env.REACT_APP_ID_HERE +
                            "&app_code=" +
                            process.env.REACT_APP_CODE_HERE +
                            "&searchtext=" +
                            convertDashToPlus(props.match.params.addressSlug);

                        fetch(here_geocode_url)
                            .then((response) => response.json())
                            .then((data) => {
                                const view = data.Response.View;
                                if (
                                    view.length > 0 &&
                                    view[0].Result.length > 0
                                ) {
                                    const location = view[0].Result[0].Location;

                                    setLatitude(
                                        location.DisplayPosition.Latitude
                                    );
                                    setLongitude(
                                        location.DisplayPosition.Longitude
                                    );
                                    setAddressSlug(
                                        slugify(location.Address.Label)
                                    );
                                    setLotNumber(0);
                                    setAddress(
                                        addressStripCountry(
                                            location.Address.Label
                                        )
                                    );

                                    getLiveableArea(
                                        location.DisplayPosition.Latitude,
                                        location.DisplayPosition.Longitude
                                    );
                                }
                                setLoading(false);
                            });
                    } else {
                        geocodeByAddress(
                            convertDashToPlus(props.match.params.addressSlug)
                        ).then((results) => {
                            storeSlug(results[0].addressSlug);

                            setAddressSlug(
                                slugify(results[0].formatted_address)
                            );
                            setLotNumber(0);
                            setAddress(
                                addressStripCountry(
                                    results[0].formatted_address
                                )
                            );

                            // console.log(results);
                            // redirect to profile page if address is not an area
                            if (
                                !(
                                    results[0].types.includes("locality") ||
                                    results[0].types.includes("postal_code")
                                )
                            ) {
                                const pathname =
                                    process.env.REACT_APP_PROFILE_URL +
                                    "/" +
                                    slugify(results[0].formatted_address);
                                props.history.push({
                                    pathname: pathname,
                                });
                            }

                            getLatLng(results[0]).then(({ lat, lng }) => {
                                setLatitude(lat);
                                setLongitude(lng);
                                getLiveableArea(lat, lng);

                                // Redirect to area page
                                if (
                                    results[0].types &&
                                    (results[0].types.includes("locality") ||
                                        results[0].types.includes(
                                            "postal_code"
                                        ))
                                ) {
                                    const pathname =
                                        process.env.REACT_APP_AREA_URL +
                                        "/" +
                                        props.match.params.addressSlug;

                                    props.history.push({
                                        pathname: pathname,
                                        state: {
                                            latitude: lat,
                                            longitude: lng,
                                        },
                                    });
                                }
                            });

                            setLoading(false);
                        });
                    }
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        // FOR SAMPLE REPORTS
        // let sampleUrl = "https://data.liveable.co/sample-property.json";

        // fetch(sampleUrl)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         if (
        //             data.sampleProperties.includes(
        //                 props.match.params.addressSlug
        //             )
        //         ) {
        //             setSampleReport(true);
        //         } else {
        //             setSampleReport(false);
        //         }
        //     });
    }, [props.history, props.match.params.addressSlug]);

    useEffect(() => {
        const sold_description =
            address +
            " has " +
            listingState.count +
            " similar properties (" +
            (numBed === undefined || isNaN(numBed) ? "-" : numBed) +
            " bed, " +
            (numBath === undefined || isNaN(numBath) ? "-" : numBath) +
            " bath, " +
            (numCar === undefined || isNaN(numCar) ? "-" : numCar) +
            " car) " +
            "with a median property price of " +
            moneyFormatter.format(listingState.median) +
            " that were sold recently. " +
            "The price range for the comparable " +
            "nearby properties is " +
            moneyFormatter.format(listingState.lower_quantile) +
            " to " +
            moneyFormatter.format(listingState.upper_quantile);

        setDescription(sold_description);
    }, [listingState, address, numBed, numBath, numCar]);

    // track user view
    useEffect(() => {
        trackView(
            state.user,
            props.history.location.pathname,
            "property-profile"
        );
    }, [state.user, props.history.location]);

    /**
     * Get the Area this property belongs to, if the address slug is a locality or postal code
     * then redirect to the Area page
     *
     */
    // function getArea() {
    //     // console.log("getArea()", this.props);
    //     // console.log(
    //     //     "Update domain listing here if from domain link",
    //     //     this.state.addressSlug,
    //     //     this.props.match.params.addressSlug,
    //     //     this.state.dontConvertImages
    //     // );
    //     geocodeByAddress(convertDashToPlus(props.match.params.addressSlug))
    //         .then((results) => {
    //             setAddressSlug(slugify(results[0].formatted_address));
    //             setLotNumber(0);
    //             setAddress(addressStripCountry(results[0].formatted_address));
    //             setTypes(results[0].types);

    //             if (
    //                 slugify(results[0].formatted_address) !==
    //                 props.match.params.addressSlug
    //             ) {
    //                 // Push domain property details as well
    //                 // console.log(
    //                 //     "======================================================"
    //                 // );
    //                 // console.log(
    //                 //     "props.history.push",
    //                 //     slugify(results[0].formatted_address),
    //                 //     this.props.history
    //                 // );

    //                 // console.log(this.props.history.state);

    //                 props.history.push({
    //                     pathname:
    //                         process.env.REACT_APP_PROFILE_URL +
    //                         "/" +
    //                         slugify(results[0].formatted_address),

    //                     state: props.history.state,
    //                 });
    //             }

    //             return getLatLng(results[0]);
    //         })
    //         .then(({ lat, lng }) => {
    //             setLatitude(lat);
    //             setLongitude(lng);
    //             getLiveableArea(lat, lng);

    //             // Redirect to area page
    //             if (
    //                 types &&
    //                 (types.includes("locality") ||
    //                     types.includes("postal_code"))
    //             ) {
    //                 const pathname =
    //                     process.env.REACT_APP_AREA_URL +
    //                     "/" +
    //                     props.match.params.addressSlug;

    //                 props.history.push({
    //                     pathname: pathname,
    //                     state: {
    //                         latitude: latitude,
    //                         longitude: longitude,
    //                     },
    //                 });
    //             }
    //         });
    // }

    // async function getProperty() {
    //     let property = {};
    //     // Redirect from PropertyLink, lookup property details from history instead for faster loading
    //     if (
    //         props.history &&
    //         props.history.location &&
    //         props.history.location.state
    //     ) {
    //         property["count"] = 1;
    //         let result = [];
    //         let sold_property = {};

    //         if (props.history.location.state.addressSlug) {
    //             sold_property["addressSlug"] =
    //                 props.history.location.state.addressSlug;

    //             sold_property["lotNumber"] =
    //                 props.history.location.state.lotNumber;

    //             sold_property["area"] = props.history.location.state.area;
    //             sold_property["areaType"] =
    //                 props.history.location.state.areaType;
    //         }

    //         // remove the 2nd number in building address eg 18/30-44 Garden Street becomes 18/40 Garden Street
    //         const fixedAddress = props.history.location.state.address.replace(
    //             /[-]\d+/,
    //             ""
    //         );
    //         sold_property["formattedAddress"] = fixedAddress;

    //         // temporarily update the displayed address until we get the proper address back from Google geocodeByAddress
    //         setAddress(fixedAddress);

    //         sold_property["lat"] = props.history.location.state.latitude;
    //         sold_property["lng"] = props.history.location.state.longitude;
    //         sold_property["numBed"] = props.history.location.state.numBed;
    //         sold_property["numBath"] = props.history.location.state.numBath;
    //         sold_property["numCar"] = props.history.location.state.numCar;
    //         sold_property["lastPrice"] = props.history.location.state.price;
    //         sold_property["lastSettlementDate"] =
    //             props.history.location.state.lastSettlementDate;

    //         sold_property["dwellingType"] =
    //             props.history.location.state.dwellingType;

    //         if (
    //             props.history.location.state.images &&
    //             props.history.location.state.images.length &&
    //             props.history.location.state.images[0].includes("domain")
    //         ) {
    //             sold_property["images"] = convertImages(
    //                 props.history.location.state.images
    //             );
    //             setDontConvertImages(true);
    //         } else {
    //             sold_property["images"] = props.history.location.state.images;
    //         }

    //         result.push(sold_property);

    //         property["result"] = result;
    //     } else {
    //         // gets called twice - dont call again if state has been updated with addressSlug
    //         if (addressSlug !== props.match.params.addressSlug) {
    //             //TODO: update this.state.addressSlug?
    //             await API.get(
    //                 "properties",
    //                 `/address/${props.match.params.addressSlug}`
    //             ).then((result) => {
    //                 property = result;
    //             });
    //         }
    //     }

    //     return property;
    // }

    function handleChangeNumBed(bed) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numBed: bed,
        });
    }

    function handleChangeNumBath(bath) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numBath: bath,
        });
    }

    function handleChangeNumCar(car) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numCar: car,
        });
    }

    function savePropertyDetails(propertyDetails) {
        return API.put("properties", `/address/${addressSlug}`, {
            body: propertyDetails,
        });
    }

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    return (
        <Fragment>
            {address && (
                <Helmet>
                    <title>
                        {"Property Report: " + address + " - Liveable"}
                    </title>
                    <meta
                        property="og:title"
                        content={"Property Report: " + address + " - Liveable"}
                    />
                    <meta
                        name="description"
                        content={
                            "Property Profile Report for " +
                            address +
                            ". Find out all about the property and its surroundings. Recent Comparable Sales. Demographics. Nearby Amenities. Local Schools. Public Transport options. Neighbourhood demographics."
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            "Property Profile Report for " +
                            address +
                            ". Find out all about the property and its surroundings. Recent Comparable Sales. Demographics. Nearby Amenities. Local Schools. Public Transport options. Neighbourhood demographics."
                        }
                    />
                    {images && images[0] && (
                        <meta
                            property="og:image"
                            content={
                                process.env.REACT_APP_IMAGE_BUCKET_URL +
                                "/" +
                                images[0].substr(
                                    0,
                                    images[0].lastIndexOf(".")
                                ) +
                                "-800.jpg"
                            }
                        />
                    )}
                    {addressSlug && (
                        <meta
                            property="og:url"
                            content={
                                "https://liveable.co/property/" + addressSlug
                            }
                        />
                    )}
                    {addressSlug && (
                        <link
                            rel="canonical"
                            href={"https://liveable.co/property/" + addressSlug}
                        />
                    )}
                    {/* Since paywalled, tell Google not to have a Cached view for the page on searches */}
                    <meta name="robots" content="noarchive" />

                    {/* Zendesk widget */}
                    {!getUA.includes("Prerender") &&
                        process.env.NODE_ENV === "production" && (
                            <script
                                id="ze-snippet"
                                src="https://static.zdassets.com/ekr/snippet.js?key=09814143-b86a-422d-a3bb-f9c58904826d"
                            ></script>
                        )}

                    {/* <script src="https://unohomeloans.com.au/loan-score-widget/uno-loanScore-widget.min.js"></script> */}
                </Helmet>
            )}

            {address && (
                <JSONLD>
                    <Generic
                        type="webPage"
                        jsonldtype="WebPage"
                        schema={{
                            name: "Property Report: " + address,
                            description:
                                "Property Profile Report for " +
                                address +
                                ". Find out all about the property and its surroundings. Recent Comparable Sales. Demographics. Nearby Amenities. Local Schools. Public Transport options. Neighbourhood demographics.",
                            url:
                                "https://liveable.co" + props.location.pathname,
                            isAccessibleForFree: "False",
                        }}
                    >
                        <GenericCollection type="hasPart">
                            <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-sale",
                                }}
                            />
                            <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-rent",
                                }}
                            />
                            <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-amenities",
                                }}
                            />
                            <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-schools",
                                }}
                            />
                            {/* <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-transit",
                                }}
                            /> */}
                            <Generic
                                jsonldtype="WebPageElement"
                                schema={{
                                    isAccessibleForFree: "False",
                                    cssSelector: ".paywall-insights",
                                }}
                            />
                        </GenericCollection>
                    </Generic>
                </JSONLD>
            )}
            {address && la4_id && la3_name && la2_name && (
                <JSONLD>
                    <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                        <GenericCollection type="itemListElement">
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 1,
                                    name: "Liveable",
                                    item: "https://liveable.co",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 2,
                                    name: titleCase(la4_id),
                                    item:
                                        "https://liveable.co/area/" +
                                        convertUnderscoreToDash(la4_id),
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 3,
                                    name: la3_name,
                                    item:
                                        "https://liveable.co/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name),
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 4,
                                    name: la2_name,
                                    item:
                                        "https://liveable.co/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name) +
                                        "/" +
                                        convertNameToId(la2_name),
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 5,
                                    name: address,
                                    item:
                                        "https://liveable.co/property/" +
                                        props.match.params.addressSlug,
                                }}
                            />
                        </GenericCollection>
                    </Generic>
                </JSONLD>
            )}

            <HeaderNav
                headings={[
                    {
                        label: "Property",
                        link: "#property",
                    },
                    {
                        label: "Sold Properties",
                        link: "#sold",
                    },
                    {
                        label: "Listings for Sale",
                        link: "#sale",
                    },
                    {
                        label: "Listings for Rent",
                        link: "#rent",
                    },
                    {
                        label: "Amenities",
                        link: "#amenities",
                    },
                    {
                        label: "Schools",
                        link: "#schools",
                    },
                    // {
                    //     label: "Transit Options",
                    //     link: "#transit",
                    // },
                    {
                        label: "Insights",
                        link: "#insights",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pb-5 pt-4">
                {/* Breadcrumb */}
                {la2_id && la2_name && la3_id && la3_name && la4_id && (
                    <section id="breadcrumb" className="text-muted">
                        <Row>
                            <Col>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id)
                                    }
                                >
                                    {titleCase(la4_id, "_")}
                                </Link>
                                <span className="px-2">&gt;</span>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name)
                                    }
                                >
                                    {la3_name}
                                </Link>
                                <span className="px-2">&gt;</span>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name) +
                                        "/" +
                                        convertNameToId(la2_name)
                                    }
                                >
                                    {titleCase(la2_id, "_")}
                                </Link>
                            </Col>
                        </Row>
                    </section>
                )}
                {/* Property Info */}
                <section id="property" className="pt-2">
                    <Row>
                        <Col>
                            <PropertyInfo
                                addressSlug={addressSlug}
                                address={address}
                                numBed={numBed}
                                numBath={numBath}
                                numCar={numCar}
                                area={area}
                                areaType={areaType}
                                dwellingType={dwellingType}
                                lat={latitude}
                                lng={longitude}
                                onChangeNumBed={handleChangeNumBed}
                                onChangeNumBath={handleChangeNumBath}
                                onChangeNumCar={handleChangeNumCar}
                                median={listingState.median}
                                lowerQuantile={listingState.lower_quantile}
                                upperQuantile={listingState.upper_quantile}
                                count={listingState.count}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                    {/* Property Images */}
                    {!getUA.includes("Prerender") &&
                        !isEmpty(images) &&
                        images.length > 1 && (
                            <PropertyImagePanel
                                imageBucketUrl={
                                    process.env.REACT_APP_IMAGE_BUCKET_URL
                                }
                                images={images}
                                address={address}
                            />
                        )}

                    {/* Google Map */}
                    <Row className="mt-4 mb-4">
                        <Col>
                            {/* <GoogleMapProvider>
                                {latitude && longitude && (
                                    <MapPanel
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        height={"30vh"}
                                        zoom={15}
                                        enableZoom={true}
                                    />
                                )}
                                {latitude && longitude && (
                                    <Place
                                        key={address}
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        text={address}
                                        icon={<FaHome />}
                                        height="30px"
                                        width="30px"
                                    />
                                )}
                                <TransitLayer />
                            </GoogleMapProvider> */}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Property Sale and Listing History */}
                <section id="history" className="pb-3">
                    <PropertyHistory
                        soldData={soldData}
                        listingSaleData={listingSaleData}
                        showListingSaleData={
                            listingSaleData && state.userPlan === "Pro"
                        }
                        listingRentData={listingRentData}
                        showListingRentData={
                            listingRentData && state.userPlan === "Pro"
                        }
                        loading={loading}
                    />
                    {state.userPlan !== "Pro" &&
                        (listingSaleData || listingRentData) && (
                            <Row className="mt-2">
                                <Col>
                                    <Alert
                                        variant="primary"
                                        className="mt-3 py-5 text-center"
                                    >
                                        <p>
                                            Upgrade to view listing history and
                                            any changes made to the listing
                                            (including price and auction date)
                                            during an agency campaign.
                                        </p>
                                        <p>
                                            Knowing the price movements during
                                            the life of the listing&#39;s
                                            campaign can help with price
                                            negotiations when making an offer.
                                        </p>
                                        <Button
                                            className="mt-3 px-4 py-2"
                                            variant="warning"
                                            id="buy-property-history"
                                            onClick={() => goToPage("pricing")}
                                        >
                                            Upgrade
                                        </Button>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                </section>
                <hr />
                {/* Google Ads */}
                {state.userPlan !== "Pro" && (
                    <>
                        <section id="ads" className="pt-4">
                            <Row>
                                <Col>
                                    <AdSense.Google
                                        client="ca-pub-4439000183008596"
                                        slot="3312277323"
                                        style={{
                                            display: "block",
                                            textAlign: "center",
                                        }}
                                        layout="in-article"
                                        format="fluid"
                                    />
                                </Col>
                            </Row>
                        </section>
                        <hr />
                    </>
                )}
                {/* Sold Properties */}
                <section id="sold" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Comparable Sold Properties</h2>
                        </Col>
                    </Row>
                    {!loading &&
                        description &&
                        listingState.count &&
                        listingState.median && (
                            <Row className="mt-2 mb-4">
                                <Col>{description}</Col>
                            </Row>
                        )}

                    <Row className="mt-3">
                        <Col>
                            {latitude && longitude && (
                                <PropertiesContext.Provider
                                    value={{ listingState, dispatch }}
                                >
                                    <ComparableProperties
                                        addressSlug={addressSlug}
                                        cardType="sold"
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                        numBed={numBed}
                                        numBath={numBath}
                                        numCar={numCar}
                                        dwellingType={dwellingType}
                                        showHome={true}
                                        limitOverride={
                                            state.userPlan === "Pro" ? 12 : 6
                                        }
                                        hideShowMore={
                                            state.userPlan === "Pro"
                                                ? false
                                                : true
                                        }
                                    ></ComparableProperties>
                                </PropertiesContext.Provider>
                            )}
                        </Col>
                    </Row>

                    {state.userPlan !== "Pro" && (
                        <Row className="mt-2">
                            <Col>
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <p>
                                        Upgrade to view all{" "}
                                        <strong>{listingState.count}</strong>{" "}
                                        comparable properties that were recently
                                        sold.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        id="buy-sold"
                                        onClick={() => goToPage("pricing")}
                                    >
                                        Upgrade
                                    </Button>
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </section>
                <hr />
                {/* Sales Listings */}
                <section id="sale" className="pt-4 paywall-sale">
                    <Row>
                        <Col>
                            <h2>Comparable Sales Listings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Listings for similar properties for sale:{" "}
                            {numBed === undefined ? "-" : numBed} bed,{" "}
                            {numBath === undefined ? "-" : numBath} bath and{" "}
                            {numCar === undefined ? "-" : numCar} car.
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        addressSlug={addressSlug}
                                        cardType="sale"
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                        numBed={numBed}
                                        numBath={numBath}
                                        numCar={numCar}
                                        dwellingType={dwellingType}
                                        showHome={true}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <p>
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Sales Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Sales Listings allows
                                        you to benchmark what Sellers of similar
                                        properties are asking for.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        id="buy-sales"
                                        onClick={() => goToPage("pricing")}
                                    >
                                        Upgrade
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                <section id="compare-home-loans" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Mortgage Repayments Calculator</h2>
                        </Col>
                    </Row>
                    <MortgageRepaymentCalculator
                        propertyPrice={listingState.median}
                    />
                </section>
                <hr />
                {/* Uno Loan Score widget */}
                {/* <section id="home-loans-score" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Check your Existing Home Loan Score</h2>
                            <p>
                                uno is Australia&#39;s first Active Home Loan
                                Manager. If you already have a home loan, uno
                                can help you find a great deal and stay on a
                                great deal the whole time you have a loan.
                            </p>
                        </Col>
                    </Row>
                    <div
                        id="uno-loanScore-widget"
                        apikey="RF4V^#x$yf2v3U"
                        referrer="liveable"
                        referrerlabel="Liveable"
                    />
                </section>
                <hr /> */}
                {/* Rental Listings */}
                <section id="rent" className="pt-4 paywall-rent">
                    <Row>
                        <Col>
                            <h2>Comparable Rental Listings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Listings for similar properties for rent:{" "}
                            {numBed === undefined ? "-" : numBed} bed,{" "}
                            {numBath === undefined ? "-" : numBath} bath and{" "}
                            {numCar === undefined ? "-" : numCar} car.
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        addressSlug={addressSlug}
                                        cardType="rent"
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                        numBed={numBed}
                                        numBath={numBath}
                                        numCar={numCar}
                                        dwellingType={dwellingType}
                                        showHome={true}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <p>
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Rental Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Rental Listings is
                                        handy for Investors. It allows an
                                        Investor to benchmark what the potential
                                        yield is for the property, and how much
                                        supply there is for similar properties
                                        on the market.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        id="buy-rental"
                                        onClick={() => goToPage("pricing")}
                                    >
                                        Upgrade
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Amenities  - Remove for Prerender */}
                {!getUA.includes("Prerender") && (
                    <>
                        <section
                            id="amenities"
                            className="pt-4 paywall-amenities"
                        >
                            <Row>
                                <Col>
                                    <h2>Amenities</h2>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    {state.userPlan === "Pro" ? (
                                        latitude &&
                                        longitude && (
                                            <Amenities
                                                address={address}
                                                latitude={latitude}
                                                longitude={longitude}
                                                showDistance={true}
                                            />
                                        )
                                    ) : (
                                        <Alert
                                            variant="primary"
                                            className="mt-3 py-5 text-center"
                                        >
                                            <p>
                                                Upgrade to view cafes,
                                                restaurants, bar and
                                                supermarkets in the vicinity of{" "}
                                                {address
                                                    ? address
                                                    : "the property"}
                                                .
                                            </p>
                                            <Button
                                                className="mt-3 px-4 py-2"
                                                variant="warning"
                                                id="buy-amenities"
                                                onClick={() =>
                                                    goToPage("pricing")
                                                }
                                            >
                                                Upgrade
                                            </Button>
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        </section>
                        <hr />
                    </>
                )}
                {/* Schools  */}
                {longitude && latitude && la2_id && (
                    <>
                        <section id="schools" className="pt-4 paywall-schools">
                            <Row>
                                <Col>
                                    <h2>Local Schools</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    List of Schools and their Catchment Areas
                                    for {address}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Schools
                                        latitude={latitude}
                                        longitude={longitude}
                                        la2_id={convertDashToUnderscore(la2_id)}
                                        showHome={true}
                                    />
                                </Col>
                            </Row>
                        </section>
                        <hr />
                    </>
                )}
                {/* Transit Options*/}
                {/* {!getUA.includes("Prerender") && (
                    <>
                        <section id="transit" className="pt-4 paywall-transit">
                            <Row>
                                <Col>
                                    <h2>Transit Options</h2>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    {state.userPlan === "Pro" ? (
                                        latitude &&
                                        longitude && (
                                            <GoogleMapProvider>
                                                <Transit
                                                    addressSlug={addressSlug}
                                                    latitude={latitude}
                                                    longitude={longitude}
                                                    showHome={true}
                                                    showDistance={true}
                                                />
                                            </GoogleMapProvider>
                                        )
                                    ) : (
                                        <Alert
                                            variant="primary"
                                            className="mt-3 py-5 text-center"
                                        >
                                            <p>
                                                Upgrade to view the public
                                                transport options that are in
                                                the vicinity of{" "}
                                                {address
                                                    ? address
                                                    : "the property"}
                                                .
                                            </p>
                                            <Button
                                                className="mt-3 px-4 py-2"
                                                variant="warning"
                                                id="buy-transit"
                                                onClick={() =>
                                                    goToPage("pricing")
                                                }
                                            >
                                                Upgrade
                                            </Button>
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        </section>
                        <hr />
                    </>
                )} */}
                {/* Neighbourhood Insights */}
                {la1_id && la2_id && la3_id && la4_id && (
                    <section id="insights" className="pt-4 paywall-insights">
                        <Row>
                            <Col>
                                <h2>Neighbourhood Insights</h2>
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col>
                                <Census
                                    la1_id={la1_id}
                                    la2_id={la2_id}
                                    la3_id={la3_id}
                                    la4_id={la4_id}
                                    showSummaryOnly={state.userPlan !== "Pro"}
                                />
                            </Col>
                        </Row>
                        {state.userPlan !== "Pro" && (
                            <Row className="mt-2">
                                <Col>
                                    <Alert
                                        variant="primary"
                                        className="mt-3 py-5 text-center"
                                    >
                                        <p>
                                            Upgrade to obtain a further
                                            breakdown and demographics of the
                                            people living in this neighbourhood
                                        </p>
                                        <Button
                                            className="mt-3 px-4 py-2"
                                            variant="warning"
                                            id="buy-insights"
                                            onClick={() => goToPage("pricing")}
                                        >
                                            Upgrade
                                        </Button>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </section>
                )}
            </Container>

            {/* Paywall stuff */}
            {/* If user is not authenticated and hits limit, show Paywall to signup */}
            {/* {!getUA.includes("Prerender") &&
                // This gives the user a view up till Comparable Sold Properties
                showPaywall &&
                getSlugCount() > whatsMyLimit(state.isAuthenticated) &&
                !state.isAuthenticated && (
                    <Paywall backdrop="static" show={true} />
                )} */}

            {/* If user is authenticated but is on the Free plan and hits limit, redirect to Pricing page */}
            {/* {!getUA.includes("Prerender") &&
                getSlugCount() > whatsMyLimit(state.isAuthenticated) &&
                state.isAuthenticated &&
                state.userPlan === "Free" && (
                    <Redirect
                        to={{
                            pathname: "/pricing",
                            state: {
                                from: { pathname: props.location.pathname },
                                message:
                                    "You have reached your Free plan limit",
                            },
                        }}
                    />
                )} */}

            {!getUA.includes("Prerender") && state.userPlan !== "Pro" && (
                <CallToActionFooter />
            )}
        </Fragment>
    );
}
