import React from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { IconContext } from "react-icons";

AgencyDetails.propTypes = {
    name: PropTypes.string,
    agencyLogo: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
};

export default function AgencyDetails(props) {
    return (
        <Row className="d-flex align-items-center">
            <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className="text-center text-md-left "
            >
                <Row>
                    <Col>
                        <h1>{props.name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>{props.address}</Col>
                </Row>

                {props.phone && (
                    <Row>
                        <Col>Ph: {props.phone}</Col>
                    </Row>
                )}

                {props.website && (
                    <Row>
                        <Col>
                            Web:{" "}
                            <a
                                href={props.website + "?utm_source=liveable.co"}
                                rel="nofollow"
                            >
                                {props.website}
                            </a>
                        </Col>
                    </Row>
                )}

                {/* {props.facebook && (
                    <Row>
                        <Col>
                            Social:{" "}
                            <IconContext.Provider
                                value={{ style: { verticalAlign: "middle" } }}
                            >
                                <a href={props.facebook} rel="nofollow">
                                    <FaFacebookSquare />
                                </a>
                            </IconContext.Provider>
                        </Col>
                    </Row>
                )} */}
            </Col>
            <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="text-center text-md-right py-3"
            >
                {props.agencyLogo && (
                    <img
                        src={
                            process.env.REACT_APP_IMAGE_BUCKET_URL +
                            "/" +
                            props.agencyLogo
                        }
                        alt={props.name}
                        max-width={"200px"}
                    />
                )}
            </Col>
        </Row>
    );
}
