import React, { Component, Fragment } from "react";
import _ from "lodash";
import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";
import * as d3 from "d3";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CensusArea from "../../components/CensusArea";

import GroupedBarGraph from "./GroupedBarGraph";
import OverlappingColumnChart from "./OverlappingColumnChart";
import PieChart from "./PieChart";
import Legend from "./Legend";

import QuickStats from "../census/QuickStats";

import Loading from "../Loading";

//let census_base_url = process.env.REACT_APP_LIVEABLE_DATA_API_URL + "/v1/json/la1_boundary/liveable/census/sydney/?";

/**
 * Input params:
 * la1 ID: 1133318
 * la2 ID: sydney
 *
 *
 */

//https://color.adobe.com/create
const dual_fill_colours = ["#165582", "#F2A844"];
const triad_fill_colours = ["#165582", "#F2A844", "#98ABC7"];

export default class GroupedCensusChart extends Component {
    static propTypes = {
        la1_id: PropTypes.string,
        la2_id: PropTypes.string.isRequired,
        la3_id: PropTypes.string.isRequired,
        la4_id: PropTypes.string.isRequired,
        showSummaryOnly: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    /**
     * async required to wait for all promises to return with responses
     */
    async componentDidMount() {
        try {
            // fetch city census json
            let city_census_url =
                process.env.REACT_APP_DATA_URL +
                "/census/" +
                this.props.la4_id +
                ".json";

            // fetch la1 census json
            let la1_census_url = null;
            if (this.props.la1_id) {
                la1_census_url =
                    process.env.REACT_APP_DATA_URL +
                    "/census/" +
                    this.props.la4_id +
                    "/" +
                    this.props.la1_id +
                    ".json";
            }

            // fetch la2 census json
            let la2_census_url =
                process.env.REACT_APP_DATA_URL +
                "/census/" +
                this.props.la4_id +
                "/" +
                this.props.la2_id +
                ".json";

            // la2_census_url = "/data/census/sydney/ashfield.json"; // TODO remove after testing
            // la1_census_url = "/data/census/sydney/redfern.json"; // TODO remove after testing
            let [primary, secondary, tertiary] = await Promise.all([
                //return empty is la1 is not supplied
                la1_census_url
                    ? fetch(la1_census_url)
                          .then((response1) => response1.json())
                          .then((data) =>
                              d3
                                  .nest()
                                  .key((d) => {
                                      return d.liveable_type;
                                  })
                                  .map(data)
                          )
                    : Promise.resolve(d3.map()),
                fetch(la2_census_url)
                    .then((response2) => response2.json())
                    .then((data) =>
                        d3
                            .nest()
                            .key((d) => {
                                return d.liveable_type;
                            })
                            .map(data)
                    ),
                fetch(city_census_url).then((response3) =>
                    response3.json().then((data) =>
                        d3
                            .nest()
                            .key((d) => {
                                return d.liveable_type;
                            })
                            .map(data)
                    )
                ),
            ]);

            // console.log(la1_census_url, la2_census_url, city_census_url);

            // default to la2 vs la4 if no data found for la1
            if (primary.size() === 0) {
                la1_census_url = null;
            }

            this.setState({
                data: la1_census_url
                    ? [primary, secondary, tertiary]
                    : [secondary, tertiary],
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        fetch(process.env.REACT_APP_CENSUS_FILTER_URL)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    census_filters: data,
                    loaded: true,
                });
            });
    }

    formatType(type, census_filter) {
        census_filter.formatting.forEach((f) => {
            type = type.toString().replace(f.replace, f.with);
        });

        type = type.replace(/_/g, " ");
        type = type.replace(/^ /g, "");

        return type;
    }

    /**
     * Get the census data based on the filter
     *
     * @param {*} nested_data
     * @param {*} filter
     */
    getData(nested_data, filter) {
        let new_data = [];

        for (const type of filter.liveable_types.values()) {
            const liveable_type = nested_data.get(type);

            // if using census_type, dont add to list
            if (filter.use_census_type) {
                if (liveable_type) {
                    new_data.push({
                        type: this.formatType(
                            liveable_type[0].census_type,
                            filter
                        ),
                        value: liveable_type ? liveable_type[0].value : 0,
                    });
                }
            } else {
                new_data.push({
                    type: this.formatType(type, filter),
                    value: liveable_type ? liveable_type[0].value : 0,
                });
            }
        }

        if (filter.sort) {
            new_data = new_data.sort((a, b) => a.value - b.value);
        }

        if (filter.max_types && new_data.length > filter.max_types) {
            new_data = new_data.sort((a, b) => a.value - b.value);

            new_data = new_data.slice(
                new_data.length - filter.max_types,
                new_data.length
            );
        }

        return new_data;
    }

    /**
     *
     * @param {*} dataSets - array of nested census data
     * @param {*} filter   - census filter
     */
    getMergedData(dataSets, filter) {
        let merged_data = [];
        const primary_index = 0; //dataSets.length > 0?  dataSets.length - 1: 0;

        for (const type of filter.liveable_types.values()) {
            if (filter.use_census_type) {
                //only add value if the type exists int he primary data set
                if (dataSets[primary_index].get(type)) {
                    // this.getValues(dataSets, type);
                    merged_data.push({
                        type: this.formatType(
                            dataSets[primary_index].get(type)[0].census_type,
                            filter
                        ),
                        value: dataSets[0].get(type)
                            ? dataSets[0].get(type)[0].value
                            : 0, //todo: test first data set
                        values: dataSets.map((d) =>
                            d.get(type) ? d.get(type)[0].value : 0
                        ), // return zero if the type is not found
                        liveable_type: this.formatType(type, filter),
                    });
                }
            } else {
                merged_data.push({
                    type: this.formatType(type, filter),
                    value: dataSets[0].get(type)
                        ? dataSets[0].get(type)[0].value
                        : 0, //todo: test first data set
                    values: dataSets.map((d) =>
                        d.get(type) ? d.get(type)[0].value : 0
                    ), // return zero if the type is not found
                });
            }
        }

        // sort the first data set, since its the primary
        if (filter.sort) {
            merged_data = merged_data.sort((a, b) => a.values[0] - b.values[0]);
        }

        // only return the top results
        if (filter.max_types && merged_data.length > filter.max_types) {
            merged_data = merged_data.sort((a, b) => a.value - b.value);

            merged_data = merged_data.slice(
                merged_data.length - filter.max_types,
                merged_data.length
            );
        }

        return merged_data;
    }

    getGroupedData(nested_data, filter) {
        let new_data = [];

        for (const [
            i,
            primary_type,
        ] of filter.primary_liveable_types.entries()) {
            // if primary_type does not exist in nested-data the type is null!
            const primary_liveable_type = nested_data.get(primary_type);
            const secondary_type = filter.secondary_liveable_types[i];
            const secondary_liveable_type = nested_data.get(secondary_type);

            new_data.push({
                type: this.formatType(primary_type, filter),
                value: primary_liveable_type
                    ? nested_data.get(primary_type)[0].value
                    : 0,
                secondary: secondary_liveable_type
                    ? secondary_liveable_type[0].value
                    : 0,
            });
        }

        return new_data;
    }

    getValues(dataSets, type) {
        const values = dataSets.map((d) => {
            return d.get(type) ? d.get(type)[0].value : 0;
        });

        return values;
    }

    /**
     * Get array of totals matching the total_type
     * if filter.total_type is not supplied, then calculate the total from the data
     * @param {*} nested_data
     * @param {*} filter
     */
    getTotals(nested_data, filter) {
        const total_type = filter.total_type;

        let totals = [];
        if (total_type) {
            totals = nested_data.map((e) => {
                const total = e.get(total_type);

                if (total) {
                    return total[0].value;
                } else {
                    return 0;
                }
            });
        } else {
            totals = nested_data.map((e) => {
                return this.getTotal(e, filter);
            });
        }

        return totals;
    }

    getTotal(data, filter) {
        let total = 0;
        for (const type of filter.liveable_types) {
            total += data.get(type) ? data.get(type)[0].value : 0;
        }
        return total;
    }

    /**
     * Show two charts per row by default, for large charts show 1 per row
     * @param {
     * } filter
     */
    showChartCol(filter) {
        const chart_type = filter.chart_type;
        let data = [];

        let show_chart = true;

        if (chart_type === "bar") {
            data = this.getMergedData(this.state.data, filter);

            if (data.length === 0) {
                show_chart = false;
            }
        } else if (chart_type === "grouped_column") {
            data = this.getGroupedData(this.state.data[0], filter);
            if (this.groupedDataHasNoValues(data)) {
                show_chart = false;
            }
        } else if (chart_type === "pie") {
            data = this.getData(this.state.data[0], filter);

            if (data.length === 0) {
                show_chart = false;
            }
        } else {
            data = this.getMergedData(this.state.data, filter);
            if (data.length === 0) {
                show_chart = false;
            }
        }

        if (show_chart) {
            switch (filter.large_chart) {
                case true:
                    return (
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={6}
                            className="mt-2 mb-2"
                            key={"card_" + filter.title}
                        >
                            <h5>{filter.title}</h5>
                            {this.renderChart(filter, data)}
                        </Col>
                    );

                default:
                    return (
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="mt-2 mb-2"
                            key={"card_" + filter.title}
                        >
                            <h5>{filter.title}</h5>
                            {this.renderChart(filter, data)}
                        </Col>
                    );
            }
        }
    }

    groupedDataHasNoValues(grouped_data) {
        const filtered_data = grouped_data.filter((d) => {
            return d.value !== 0 || d.secondary !== 0;
        });

        return filtered_data.length === 0;
    }

    renderChart(filter, data) {
        switch (filter.chart_type) {
            case "bar": {
                if (data.length > 0) {
                    return (
                        <div>
                            <GroupedBarGraph
                                key={filter.title}
                                data={data}
                                width={
                                    filter.width ? 500 : window.innerWidth * 0.9
                                }
                                fill={
                                    this.props.la1_id
                                        ? triad_fill_colours
                                        : dual_fill_colours
                                }
                                totals={this.getTotals(this.state.data, filter)}
                                title={filter.title}
                                label_margin={filter.label_margin}
                            />
                            <div>
                                <Legend
                                    width={300}
                                    height={50}
                                    data={
                                        this.state.data.length === 3
                                            ? [
                                                  this.props.la1_id,
                                                  _.startCase(
                                                      this.props.la2_id
                                                  ),
                                                  "Greater Sydney",
                                              ]
                                            : [
                                                  _.startCase(
                                                      this.props.la2_id
                                                  ),
                                                  "Greater Sydney",
                                              ]
                                    }
                                    fill={
                                        this.state.data.length === 3
                                            ? triad_fill_colours
                                            : dual_fill_colours
                                    }
                                />
                            </div>
                        </div>
                    );
                } else {
                    return;
                }
            }
            case "grouped_column": {
                if (this.groupedDataHasNoValues(data)) {
                    return;
                } else {
                    return (
                        <OverlappingColumnChart
                            key={filter.title}
                            data={data}
                            // width={filter.width ? filter.width : 640}
                            width={
                                filter.width
                                    ? filter.width
                                    : window.innerWidth * 0.9
                            }
                            height={filter.height ? filter.height : 750}
                            title={filter.title}
                        />
                    );
                }
            }
            case "pie": {
                const radius = window.innerWidth <= 768 ? 140 : 200;
                const width =
                    window.innerWidth <= 768
                        ? 300
                        : filter.width
                        ? filter.width
                        : 600;
                return (
                    <PieChart
                        key={filter.title}
                        // data={this.getData(this.state.data[0], filter)}
                        data={data}
                        // width={filter.width ? filter.width : 640}
                        width={width}
                        innerRadius={0}
                        outerRadius={
                            filter.outerRadius ? filter.outerRadius : radius
                        }
                        title={filter.title}
                        topElems={filter.top_elements}
                    />
                );
            }
            default:
                return (
                    <div>
                        <GroupedBarGraph
                            key={filter.title}
                            data={data}
                            width={filter.width ? filter.width : 760}
                            fill={
                                this.props.la1_id
                                    ? triad_fill_colours
                                    : dual_fill_colours
                            }
                            totals={this.getTotals(this.state.data, filter)}
                            title={filter.title}
                        />
                        <Legend
                            width={300}
                            height={50}
                            data={
                                this.props.la1_id
                                    ? [
                                          this.props.la1_id,
                                          _.startCase(this.props.la2_id),
                                          "Greater Sydney",
                                      ]
                                    : [
                                          _.startCase(this.props.la2_id),
                                          "Greater Sydney",
                                      ]
                            }
                            fill={
                                this.props.la1_id
                                    ? triad_fill_colours
                                    : dual_fill_colours
                            }
                        />
                    </div>
                );
        }
    }

    getLiveableType(liveable_type) {
        const type_value = this.state.data[0].get(liveable_type);

        if (type_value) {
            return this.state.data[0].get(liveable_type)[0].value;
        } else {
            return 0;
        }
    }

    render() {
        if (this.state.loaded) {
            return this.state.data ? (
                <>
                    {this.props.la1_id && (
                        <Row>
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="mt-2 mb-2"
                            >
                                <QuickStats
                                    la1_id={this.props.la1_id}
                                    population={this.getLiveableType(
                                        "census_people_total_persons"
                                    )}
                                    median_age_of_persons={this.getLiveableType(
                                        "census_median_and_averages_median_age_of_persons"
                                    )}
                                    families={this.getLiveableType(
                                        "census_family_composition_total_families"
                                    )}
                                    dwellings={this.getLiveableType(
                                        "census_dwelling_structure_total_private_dwellings_dwellings"
                                    )}
                                    average_household_size={this.getLiveableType(
                                        "census_median_and_averages_average_household_size"
                                    )}
                                    average_number_of_persons_per_bedroom={this.getLiveableType(
                                        "census_median_and_averages_average_number_of_persons_per_bedroom"
                                    )}
                                    median_total_family_income_weekly={this.getLiveableType(
                                        "census_median_and_averages_median_total_family_income_weekly"
                                    )}
                                    median_total_household_income_weekly={this.getLiveableType(
                                        "census_median_and_averages_median_total_household_income_weekly"
                                    )}
                                    median_total_personal_income_weekly={this.getLiveableType(
                                        "census_median_and_averages_median_total_personal_income_weekly"
                                    )}
                                    median_mortgage_repayment_monthly={this.getLiveableType(
                                        "census_median_and_averages_median_mortgage_repayment_monthly"
                                    )}
                                    median_rent_weekly={this.getLiveableType(
                                        "census_median_and_averages_median_rent_weekly"
                                    )}
                                    // census_family_composition_total_families
                                    //census_dwelling_structure_total_private_dwellings_dwellings
                                />
                            </Col>
                            {!this.props.showSummaryOnly && (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="mt-2 mb-2"
                                >
                                    <CensusArea
                                        la1_id={this.props.la1_id}
                                        la2_id={this.props.la2_id}
                                        la3_id={this.props.la3_id}
                                        la4_id={this.props.la4_id}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}

                    {!this.props.showSummaryOnly &&
                        !isEmpty(this.state.census_filters) &&
                        this.state.census_filters.map((category) => (
                            <Fragment key={category.category}>
                                <Row>
                                    <Col>
                                        <h3>{category.category}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {!isEmpty(category.sub_categories) &&
                                        category.sub_categories.map((filter) =>
                                            // filter.title === "Languages" &&
                                            // this.showChartCol(filter)
                                            this.showChartCol(filter)
                                        )}
                                </Row>
                            </Fragment>
                        ))}
                </>
            ) : (
                <div />
            );
        }

        return (
            <Loading
                loading={!this.state.loaded}
                dataset={this.state.data ? this.state.data : []}
                datasetName="Demographics"
            />
        );
    }
}
