import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import AddressTextBox from "../AddressTextBox";
// import { FaUserAlt } from "react-icons/fa";

import { UserContext } from "../../App";

Header.propTypes = {
    site_title: PropTypes.string,
    search_bar: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

function Header(props) {
    const [state, dispatch] = React.useContext(UserContext);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    function goToAccount() {
        let cbPortal = window.Chargebee.getInstance().createChargebeePortal();
        cbPortal.open();
    }

    return (
        <Navbar
            className="justify-content-between m-0 header"
            style={{ backgroundColor: "#315990" }}
            variant="dark"
            expand="sm"
        >
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <Image
                            id="header-logo"
                            src="https://liveable.co/images/liveable_logo_top.png"
                            className="d-inline-block align-top"
                        />
                    </Link>
                </Navbar.Brand>
                <Fragment>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Col className="px-0">
                            <Navbar.Text className="mr-auto align-top">
                                {props.site_title}
                            </Navbar.Text>
                        </Col>
                        <Col className="px-1 address-textbox">
                            {props.search_bar && <AddressTextBox />}
                        </Col>

                        <Nav className="px-0 mx-1">
                            {state.isAuthenticated ? (
                                <>
                                    {state.chargebeeUser && (
                                        <Button
                                            id="button-account"
                                            variant="warning"
                                            onClick={goToAccount}
                                            className="m-1"
                                        >
                                            Account
                                        </Button>
                                    )}
                                    <Button
                                        id="button-logout"
                                        variant="warning"
                                        onClick={() =>
                                            dispatch({
                                                type: "LOGOUT",
                                            })
                                        }
                                        className="m-1"
                                    >
                                        Logout
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        id="button-login"
                                        variant="warning"
                                        onClick={goToLogin}
                                    >
                                        Login
                                    </Button>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Fragment>
            </Container>
        </Navbar>
    );
}

export default withRouter(Header);
