import { distance } from "../../Helpers";
import { getUA } from "react-device-detect";

export const liveableNearbySearch = (location, radius, type) => {
    return new Promise((resolve, reject) => {
        let places = [];

        const data_url = getUA.includes("Prerender")
            ? process.env.REACT_APP_LIVEABLE_DATA_API_URL_LONG_TIMEOUT
            : process.env.REACT_APP_LIVEABLE_DATA_API_URL;

        const url =
            data_url +
            "/v1/json/marker/liveable/place_all/sydney/?liveable_type=" +
            type +
            "&lng=" +
            location.lng +
            "&lat=" +
            location.lat +
            "&radius=" +
            radius;
        try {
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    data.forEach((i) => {
                        i.distance = distance(
                            location.lat,
                            location.lng,
                            i.lat,
                            i.lng
                        );

                        places.push(i);
                    });

                    resolve(places);
                });
        } catch (e) {
            reject(e);
        }
    });
};
