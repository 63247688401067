import React, { useState } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import LoaderButton from "../forms/LoaderButton";
// import FacebookButton from "./FacebookButton";

import { useFormFields } from "../../Helpers";
import { UserContext } from "../../App";

Login.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    formType: PropTypes.string,
    pageType: PropTypes.string,
};

export function Login(props) {
    // eslint-disable-next-line
    const [state, dispatch] = React.useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        firstName: "",
    });
    const [formType, handleFormTypeChange] = useState(
        props.formType ? props.formType : "login"
    );
    const { from, property } = props.location.state || {
        from: { pathname: "/" },
        property: { pathname: "/" },
    };

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleLoginSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await Auth.signIn(fields.email, fields.password).then(function(
                user
            ) {
                dispatch({
                    type: "LOGIN",
                    payload: user,
                });
                if (props.pageType !== "modal") {
                    props.history.push(from);
                }
            });
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    async function handleSignUpSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.signUp({
                username: fields.email,
                password: fields.password,
                attributes: { given_name: fields.firstName },
            });
            setIsLoading(false);

            await Auth.signIn(fields.email, fields.password).then(function(
                user
            ) {
                dispatch({
                    type: "LOGIN",
                    payload: user,
                });

                if (
                    props.location.state &&
                    props.location.state.subscribe &&
                    props.location.state.subscribe === true
                ) {
                    window.Chargebee.getInstance().openCheckout({
                        hostedPage: () => {
                            let data = {
                                userId: user.username,
                                firstName: user.attributes.given_name,
                                email: user.attributes.email,
                                redirectUrl: "https://liveable.co" + property,
                                planId: props.location.state.planId,
                            };
                            return axios
                                .post(
                                    process.env.REACT_APP_CHARGEBEE_API_URL +
                                        "/generate_checkout_new_url",
                                    data
                                )
                                .then((response) => response.data);
                        },
                        success() {
                            dispatch({
                                type: "SET_USER_PLAN",
                                payload: "Pro",
                            });
                            props.history.push(property);
                        },
                    });
                } else {
                    axios
                        .post(
                            process.env.REACT_APP_CHARGEBEE_API_URL +
                                "/create_new_user",
                            {
                                userId: user.username,
                                firstName: user.attributes.given_name,
                                email: user.attributes.email,
                            }
                        )
                        .then(dispatch({ type: "LOGIN_CHARGEBEE" }));
                }
            });

            if (props.pageType !== "modal") {
                props.history.push(from);
            }
        } catch (e) {
            e.message && alert(e.message);
            setIsLoading(false);
        }
    }

    function handleNavClickLogin(e) {
        e.preventDefault();
        handleFormTypeChange("login");
    }

    function handleNavClickSignUp(e) {
        e.preventDefault();
        handleFormTypeChange("signup");
    }

    function renderSignUpForm() {
        return (
            <form onSubmit={handleSignUpSubmit}>
                <FormGroup controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <FormControl
                        autoFocus
                        type="email"
                        placeholder="eg. alexa@gmail.com"
                        value={fields.email}
                        onChange={handleFieldChange}
                        required
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <Form.Label>Password</Form.Label>
                    <FormControl
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                        required
                    />
                </FormGroup>
                <FormGroup controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <FormControl
                        type="text"
                        placeholder="eg. Alexa"
                        onChange={handleFieldChange}
                        value={fields.firstName}
                        required
                    />
                </FormGroup>
                <LoaderButton
                    block
                    type="submit"
                    isLoading={isLoading}
                    className="mt-4 btn-warning"
                    disabled={!validateForm()}
                >
                    Sign Up
                </LoaderButton>
            </form>
        );
    }

    function renderLoginForm() {
        return (
            <Form onSubmit={handleLoginSubmit}>
                <FormGroup controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <FormControl
                        autoFocus
                        type="email"
                        placeholder="eg. alexa@gmail.com"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="password">
                    <Form.Label>Password</Form.Label>
                    <FormControl
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <Link to="/login/reset">Forgot password?</Link>

                <LoaderButton
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                    className="mt-3 btn-warning"
                >
                    Login
                </LoaderButton>
            </Form>
        );
    }

    // function handleFbLogin() {
    //     dispatch({
    //         type: "LOGIN",
    //         payload: null,
    //     });

    //     if (props.pageType !== "modal") {
    //         props.history.push(from);
    //     }
    // }

    return (
        <Container className="mb-4">
            <Row className="my-3">
                <Col>
                    <Nav
                        className="justify-content-center flex-sm-row"
                        fill
                        variant="pills"
                        defaultActiveKey={formType}
                    >
                        <Nav.Item>
                            <Nav.Link
                                onClick={(e) => handleNavClickLogin(e)}
                                eventKey="login"
                            >
                                Login
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={(e) => handleNavClickSignUp(e)}
                                eventKey="signup"
                            >
                                Sign Up
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            {formType === "signup" ? renderSignUpForm() : renderLoginForm()}
            {/* <hr />
            <FacebookButton onLogin={handleFbLogin} /> */}
            <Row className="mt-2 text-center">
                <Col>
                    <small>
                        By using Liveable, you are agreeing to our{" "}
                        <a href="/terms" target="_blank">
                            terms
                        </a>
                        .
                    </small>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(Login);
