import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { moneyFormatter, convertDateStringToDate } from "../Helpers";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";

PropertyHistory.propTypes = {
    soldData: PropTypes.array,
    listingSaleData: PropTypes.array,
    showListingSaleData: PropTypes.bool,
    listingRentData: PropTypes.array,
    showListingRentData: PropTypes.bool,
    loading: PropTypes.bool,
};

export default function PropertyHistory(props) {
    let today = new Date();

    function dateDiffInWeeks(lastSeenDate, firstSeenDate) {
        return Math.ceil(
            (lastSeenDate - firstSeenDate) / (1000 * 60 * 60 * 24 * 7)
        );
    }

    function convertDateToMonthYear(date) {
        return date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "short",
        });
    }

    function getDateRange(lastSeenDate, firstSeenDate) {
        let myDate = today;

        if (dateDiffInWeeks(today, lastSeenDate) <= 1) {
            myDate = today;
        } else {
            myDate = lastSeenDate;
        }

        if (
            convertDateToMonthYear(myDate) ===
            convertDateToMonthYear(firstSeenDate)
        ) {
            return [convertDateToMonthYear(myDate)];
        } else {
            return [
                convertDateToMonthYear(firstSeenDate),
                " - ",
                myDate === today ? "Present" : convertDateToMonthYear(myDate),
            ];
        }
    }

    return (
        <>
            <Row className="pt-4 mb-2">
                <Col>
                    <h2>Property History</h2>
                </Col>
            </Row>
            {!props.soldData &&
                !props.listingSaleData &&
                !props.listingRentData &&
                !props.loading && (
                    <Row className="pt-3 text-center">
                        <Col>
                            <Alert variant="danger">
                                No data recorded for this property
                            </Alert>
                        </Col>
                    </Row>
                )}
            {props.soldData && (
                <>
                    <Row className="mt-4 mb-3">
                        <Col>
                            <h3>Sales History</h3>
                        </Col>
                    </Row>
                    <Table responsive className="mt-3 sold-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Date</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.soldData.map((item) => (
                                <tr key={"sold_" + item.contractDate}>
                                    <td>
                                        {convertDateStringToDate(
                                            item.contractDate.toString()
                                        ).toLocaleDateString(undefined, {
                                            year: "numeric",
                                            month: "short",
                                        })}
                                    </td>
                                    <td>
                                        {moneyFormatter.format(
                                            item.purchasePrice
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {/* {props.soldData.map((item) => (
                        <Row className="mt-2" key={"sold_" + item.contractDate}>
                            <Col xs={4} sm={4} md={4} lg={3} xl={3}>
                                <h5>
                                    <Badge
                                        pill
                                        className="border border-warning p-3"
                                    >
                                        {convertDateStringToDate(
                                            item.contractDate.toString()
                                        ).toLocaleDateString(undefined, {
                                            year: "numeric",
                                            month: "short",
                                        })}
                                    </Badge>
                                </h5>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={3} xl={3}>
                                <h5>
                                    <Badge className="border border-warning py-3 px-4">
                                        SOLD
                                        <span className="px-3 text-muted">
                                            |
                                        </span>
                                        {moneyFormatter.format(
                                            item.purchasePrice
                                        )}
                                    </Badge>
                                </h5>
                            </Col>
                        </Row>
                    ))} */}
                </>
            )}
            {props.listingSaleData && (
                <>
                    <Row className="mt-4 mb-3">
                        <Col>
                            <h3>Listing History (Sale)</h3>
                        </Col>
                    </Row>

                    <Table responsive className="mt-3 listing-sale-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Date</th>
                                <th>Agency</th>
                                <th className="text-center">Weeks on Market</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.listingSaleData.map((item) => (
                                <Fragment key={"listingSale_" + item.listingId}>
                                    <tr className="table-light">
                                        <td>
                                            {getDateRange(
                                                convertDateStringToDate(
                                                    item.lastSeenDate
                                                ),
                                                convertDateStringToDate(
                                                    item.firstSeenDate
                                                )
                                            ).map((date) => date)}
                                        </td>
                                        <td>{item.agencyName}</td>
                                        <td className="text-center">
                                            {dateDiffInWeeks(
                                                today,
                                                convertDateStringToDate(
                                                    item.lastSeenDate
                                                )
                                            ) <= 2
                                                ? dateDiffInWeeks(
                                                      today,
                                                      convertDateStringToDate(
                                                          item.firstSeenDate
                                                      )
                                                  )
                                                : dateDiffInWeeks(
                                                      convertDateStringToDate(
                                                          item.lastSeenDate
                                                      ),
                                                      convertDateStringToDate(
                                                          item.firstSeenDate
                                                      )
                                                  )}
                                        </td>
                                    </tr>
                                    {props.showListingSaleData &&
                                        item.listingDetails
                                            .filter(
                                                (detail) =>
                                                    detail.listingStatus !==
                                                    "delisted"
                                            )
                                            .map((detail) => (
                                                <tr
                                                    key={
                                                        "listingSale_" +
                                                        detail.listingId +
                                                        "_" +
                                                        detail.firstSeenDate
                                                    }
                                                    className="text-muted font-italic"
                                                >
                                                    <td>
                                                        <small>
                                                            <span className="pl-1"></span>
                                                            {getDateRange(
                                                                convertDateStringToDate(
                                                                    detail.lastSeenDate
                                                                ),
                                                                convertDateStringToDate(
                                                                    detail.firstSeenDate
                                                                )
                                                            ).map(
                                                                (date) => date
                                                            )}
                                                        </small>
                                                    </td>
                                                    <td colSpan="2">
                                                        <small>
                                                            {detail.rawPrice}
                                                            {detail.listingStatus ===
                                                                "sold" && (
                                                                <div>
                                                                    (Marked as
                                                                    Sold by
                                                                    Agent)
                                                                </div>
                                                            )}
                                                            {detail.auctionDate && (
                                                                <div>
                                                                    (Auction:{" "}
                                                                    {convertDateStringToDate(
                                                                        detail.auctionDate
                                                                    ).toLocaleDateString(
                                                                        undefined,
                                                                        {
                                                                            year:
                                                                                "numeric",
                                                                            month:
                                                                                "short",
                                                                            day:
                                                                                "numeric",
                                                                        }
                                                                    )}
                                                                    )
                                                                </div>
                                                            )}
                                                        </small>
                                                    </td>
                                                </tr>
                                            ))}
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
            {props.listingRentData && (
                <>
                    <Row className="mt-4 mb-3">
                        <Col>
                            <h3>Listing History (Rent)</h3>
                        </Col>
                    </Row>

                    <Table responsive className="mt-3 listing-rent-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Date</th>
                                <th>Agency</th>
                                <th className="text-center">Weeks on Market</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.listingRentData.map((item) => (
                                <Fragment key={"listingRent_" + item.listingId}>
                                    <tr className="table-light">
                                        <td>
                                            {getDateRange(
                                                convertDateStringToDate(
                                                    item.lastSeenDate
                                                ),
                                                convertDateStringToDate(
                                                    item.firstSeenDate
                                                )
                                            ).map((date) => date)}
                                        </td>
                                        <td>{item.agencyName}</td>
                                        <td className="text-center">
                                            {dateDiffInWeeks(
                                                today,
                                                convertDateStringToDate(
                                                    item.lastSeenDate
                                                )
                                            ) <= 2
                                                ? dateDiffInWeeks(
                                                      today,
                                                      convertDateStringToDate(
                                                          item.firstSeenDate
                                                      )
                                                  )
                                                : dateDiffInWeeks(
                                                      convertDateStringToDate(
                                                          item.lastSeenDate
                                                      ),
                                                      convertDateStringToDate(
                                                          item.firstSeenDate
                                                      )
                                                  )}
                                        </td>
                                    </tr>
                                    {props.showListingRentData &&
                                        item.listingDetails
                                            .filter(
                                                (detail) =>
                                                    detail.listingStatus !==
                                                    "delisted"
                                            )
                                            .map((detail) => (
                                                <tr
                                                    key={
                                                        "listingRent_" +
                                                        detail.listingId +
                                                        "_" +
                                                        detail.firstSeenDate
                                                    }
                                                    className="text-muted font-italic"
                                                >
                                                    <td>
                                                        <small>
                                                            <span className="pl-1"></span>
                                                            {getDateRange(
                                                                convertDateStringToDate(
                                                                    detail.lastSeenDate
                                                                ),
                                                                convertDateStringToDate(
                                                                    detail.firstSeenDate
                                                                )
                                                            ).map(
                                                                (date) => date
                                                            )}
                                                        </small>
                                                    </td>
                                                    <td colSpan="2">
                                                        <small>
                                                            {detail.rawPrice}
                                                            {detail.listingStatus ===
                                                                "leased" && (
                                                                <div>
                                                                    (Marked as
                                                                    Leased by
                                                                    Agent)
                                                                </div>
                                                            )}
                                                        </small>
                                                    </td>
                                                </tr>
                                            ))}
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </>
    );
}
