import React, { Component } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { convertDashToPlus, convertUnderscoreToDash } from "../Helpers";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const override = css`
    display: absolute;
    margin: 0 auto;
    border-color: red;
`;

class RedirectSchoolsAreaPage extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                la3_id: PropTypes.string,
                la2_id: PropTypes.string,
                addressSlug: PropTypes.string,
            }),
        }),
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            la1_id: undefined,
            la2_id: undefined,
            la3_id: undefined,
            la4_id: undefined,
            loaded: false,
        };
    }

    waitForGlobal(key, callback) {
        if (window[key]) {
            callback();
        } else {
            setTimeout(() => {
                this.waitForGlobal(key, callback);
            }, 25);
        }
    }

    componentDidMount() {
        if (this.props.match.params.la3_id && this.props.match.params.la2_id) {
            this.setState({
                la2_id: this.props.match.params.la2_id,
                la3_id: this.props.match.params.la3_id,
                la4_id: "sydney",
            });
        } else if (this.props.match.params.addressSlug) {
            if (
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.lat &&
                this.props.history.location.state.lng
            ) {
                this.setState({
                    lat: this.props.history.location.state.lat,
                    lng: this.props.history.location.state.lng,
                });
            } else {
                this.waitForGlobal("google", () => {
                    this.setState({ google: window.google });

                    this.getArea();
                });
            }
        }
    }

    getArea() {
        const { lat, lng } = this.state;
        if (lat && lng) {
            this.getLiveableArea(lat, lng);
        } else if (this.props.match.params.addressSlug) {
            geocodeByAddress(
                convertDashToPlus(this.props.match.params.addressSlug)
            )
                .then((results) => {
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    this.setState({
                        latitude: lat,
                        longitude: lng,
                    });

                    this.getLiveableArea(lat, lng);
                });
        }
    }

    /**
     * Get la1, la2 from lat/lng and update the state
     *
     * TODO: make city dynamic
     *
     */
    getLiveableArea(lat, lng) {
        let liveable_url =
            process.env.REACT_APP_LIVEABLE_DATA_API_URL +
            "/v1/la/sydney/?lat=" +
            lat +
            "&lng=" +
            lng;

        return fetch(liveable_url)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    la1_id: data.la1,
                    la2_id: data.la2,
                    la3_id: data.la3,
                    la4_id: data.la4,
                    loaded: true,
                });
            });
    }

    render() {
        const { la2_id, la3_id, la4_id, loaded } = this.state;

        return (
            <Container className="py-5 my-5 text-center">
                <Row>
                    <Col>
                        {!loaded ? (
                            <BeatLoader
                                css={override}
                                sizeUnit={"px"}
                                size={30}
                                color={"#315990"}
                                loading={true}
                            />
                        ) : la2_id && la3_id && la4_id ? (
                            <>
                                <Helmet>
                                    <meta
                                        name="prerender-status-code"
                                        content="301"
                                    />
                                    <meta
                                        name="prerender-header"
                                        content={
                                            "Location: https://liveable.co/schools/sydney/" +
                                            convertUnderscoreToDash(la3_id) +
                                            "/" +
                                            convertUnderscoreToDash(la2_id)
                                        }
                                    />
                                </Helmet>

                                <Redirect
                                    to={
                                        "/schools/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertUnderscoreToDash(la3_id) +
                                        "/" +
                                        convertUnderscoreToDash(la2_id)
                                    }
                                />
                            </>
                        ) : (
                            this.props.history.push({
                                pathname: "/404",
                                state: {
                                    message:
                                        "The neighbourhood you're searching for is outside our coverage area",
                                },
                            })
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default RedirectSchoolsAreaPage;
