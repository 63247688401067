import React, { useEffect, useState, Fragment } from "react";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";

import {
    convertUnderscoreToDash,
    convertDashToSlug,
    titleCase,
    trackView,
} from "../Helpers";
import Schools from "../components/Schools";
import AreaCard from "../components/AreaCard";
import ComparableProperties from "../components/ComparableProperties";
import MortgageRepaymentCalculator from "../components/MortgageRepaymentCalculator";
import AddressTextBox from "../components/AddressTextBox";
import Loading from "../components/Loading";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HeaderNav from "../components/navbar/HeaderNav";
import ScrollUp from "react-scroll-up";
import { FaMapMarkedAlt, FaRegArrowAltCircleUp } from "react-icons/fa";

import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";
import { UserContext } from "../App";

import "./Home.css";

import AdSense from "react-adsense";

SchoolsLA2.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            la4_id: PropTypes.string,
            la3_id: PropTypes.string,
            la2_id: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function SchoolsLA2(props) {
    const [state] = React.useContext(UserContext);
    const [neighbourhood, setNeighbourhood] = useState(undefined);
    const [schools, setSchools] = useState(undefined);
    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);

        let la2_url =
            "https://3mmgs190l6.execute-api.ap-southeast-2.amazonaws.com/dev/v1/lan_by_x/sydney/?la=la2&name_id=" +
            convertDashToSlug(props.match.params.la2_id);

        fetch(la2_url)
            .then((response) => response.json())
            .then((la2_data) => {
                setNeighbourhood(la2_data);
                setLoading(false);
            });
    }, [props.match.params.la2_id]);

    // track user view
    useEffect(() => {
        trackView(state.user, props.history.location.pathname, "school-area");
    }, [state.user, props.history.location]);

    return (
        <Fragment>
            <Helmet>
                <title>
                    {neighbourhood
                        ? neighbourhood.name +
                          " School Catchment Area - Liveable"
                        : props.match.params.la2_id &&
                          `${titleCase(props.match.params.la2_id, "_")}` +
                              ` School Catchment Area - Liveable`}
                </title>
                {neighbourhood
                    ? neighbourhood.name + " School Catchment Area - Liveable"
                    : props.match.params.la2_id &&
                      `${titleCase(props.match.params.la2_id, "_")}` +
                          ` School Catchment Area - Liveable`}
                {neighbourhood && schools && (
                    <meta
                        name="description"
                        content={
                            "Public Schools in " +
                            neighbourhood.name +
                            "." +
                            schools.map((school) => " " + school.school_name)
                        }
                    />
                )}
                {neighbourhood && schools && (
                    <meta
                        property="og:description"
                        content={
                            "Public Schools in " +
                            neighbourhood.name +
                            "." +
                            schools.map((school) => " " + school.school_name)
                        }
                    />
                )}
                <link
                    rel="canonical"
                    href={
                        "https://liveable.co/schools/" +
                        props.match.params.la4_id +
                        "/" +
                        props.match.params.la3_id +
                        "/" +
                        props.match.params.la2_id
                    }
                />
                <meta
                    property="og:url"
                    content={
                        "https://liveable.co/schools/" +
                        props.match.params.la4_id +
                        "/" +
                        props.match.params.la3_id +
                        "/" +
                        props.match.params.la2_id
                    }
                />
                <meta
                    property="og:image"
                    content="https://liveable.co/images/liveable_schools_og.jpg"
                />
            </Helmet>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    {props.match.params.la4_id &&
                        props.match.params.la3_id &&
                        props.match.params.la2_id && (
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Liveable",
                                        item: "https://liveable.co",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: "Schools",
                                        item: "https://liveable.co/schools",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 3,
                                        name: titleCase(
                                            props.match.params.la4_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id,
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 4,
                                        name: titleCase(
                                            props.match.params.la3_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id +
                                            "/" +
                                            props.match.params.la3_id,
                                    }}
                                />
                                {neighbourhood && neighbourhood.name && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 5,
                                            name: neighbourhood.name,
                                        }}
                                    />
                                )}
                            </GenericCollection>
                        )}
                </Generic>
            </JSONLD>
            <HeaderNav
                headings={[
                    {
                        label: "Schools",
                        link: "#schools",
                    },
                    {
                        label: "Recently Sold Properties",
                        link: "#sold",
                    },
                    {
                        label: "Nearby Neighbourhoods",
                        link: "#nearby",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pb-5 pt-4">
                {/* Breadcrumb */}
                <section id="breadcrumb" className="text-muted">
                    <Row>
                        <Col>
                            <Link to={"/schools/" + props.match.params.la4_id}>
                                {titleCase(props.match.params.la4_id, "-")}
                            </Link>
                            <span className="px-2">&gt;</span>
                            {neighbourhood &&
                                neighbourhood.parent_name_id &&
                                neighbourhood.parent_name && (
                                    <Link
                                        to={
                                            "/schools/" +
                                            props.match.params.la4_id +
                                            "/" +
                                            props.match.params.la3_id
                                        }
                                    >
                                        {neighbourhood.parent_name}
                                    </Link>
                                )}
                        </Col>
                    </Row>
                </section>
                <section id="area">
                    <Row>
                        <Col xs={12} sm={8}>
                            <h1>
                                {neighbourhood && (
                                    <a
                                        href={
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id +
                                            "/" +
                                            props.match.params.la3_id +
                                            "/" +
                                            props.match.params.la2_id
                                        }
                                    >
                                        {neighbourhood.name}
                                    </a>
                                )}
                            </h1>
                            {neighbourhood && neighbourhood.postal && (
                                <h2 style={{ fontSize: "1.3rem" }}>
                                    Postcode: {neighbourhood.postal.join(", ")}
                                </h2>
                            )}
                        </Col>
                        <Col xs={12} sm={4} className="py-1 text-sm-center">
                            <a
                                href={
                                    "https://liveable.co/area/" +
                                    props.match.params.la4_id +
                                    "/" +
                                    props.match.params.la3_id +
                                    "/" +
                                    props.match.params.la2_id
                                }
                            >
                                <span className="d-none d-sm-block">
                                    <FaMapMarkedAlt
                                        style={{ fontSize: "2rem" }}
                                    />
                                </span>

                                <h3 style={{ fontSize: "1.2rem" }}>
                                    View detailed profile of the area
                                </h3>
                            </a>
                        </Col>
                    </Row>
                </section>
                <Loading
                    loading={loading}
                    dataset={neighbourhood}
                    datasetName="Schools"
                    className="pt-4"
                />
                {/* Schools  */}
                <section id="schools" className="pt-4">
                    <Row className="mt-2">
                        <Col>
                            {neighbourhood && neighbourhood.location && (
                                <Schools
                                    latitude={neighbourhood.location["lat"]}
                                    longitude={neighbourhood.location["lng"]}
                                    la2_id={neighbourhood.name_id}
                                    showHome={false}
                                    areaOnly={true}
                                    enableSorting={true}
                                    schoolsCallback={(data) => setSchools(data)}
                                    hideMiniMap={true}
                                />
                            )}
                        </Col>
                    </Row>
                </section>
                <section
                    id="address-search"
                    className="mt-5 mb-3 py-5 background-grey"
                >
                    <Row className="text-center">
                        <Col>
                            <h2>Search School Catchments by Address</h2>
                        </Col>
                    </Row>
                    <Row className="py-3 text-center">
                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="mt-2">
                                <AddressTextBox />
                            </div>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                    </Row>
                </section>
                {/* Google ads */}
                <section className="py-5">
                    <Row>
                        <Col>
                            <AdSense.Google
                                client="ca-pub-4439000183008596"
                                slot="1358090840"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                                layout="in-article"
                                format="fluid"
                            />
                        </Col>
                    </Row>
                </section>
                {/* Sold Properties */}
                <section id="sold" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Recently Sold Properties</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Properties that were recently sold in{" "}
                            {neighbourhood &&
                                neighbourhood.name &&
                                "in " + neighbourhood.name}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {neighbourhood &&
                                neighbourhood.location["lat"] &&
                                neighbourhood.location["lng"] && (
                                    <PropertiesContext.Provider
                                        value={{ listingState, dispatch }}
                                    >
                                        <ComparableProperties
                                            cardType="sold"
                                            latitude={
                                                neighbourhood.location["lat"]
                                            }
                                            longitude={
                                                neighbourhood.location["lng"]
                                            }
                                            addressSlug={neighbourhood.name_id}
                                            address={neighbourhood.name}
                                            showHome={false}
                                            limitOverride={6}
                                        ></ComparableProperties>
                                    </PropertiesContext.Provider>
                                )}
                        </Col>
                    </Row>
                </section>
                <hr />
                <section id="compare-home-loans" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Mortgage Repayments Calculator</h2>
                        </Col>
                    </Row>
                    <MortgageRepaymentCalculator
                        propertyPrice={listingState.median}
                    />
                </section>
                <hr />

                {/* Nearby Neighbourhoods */}
                <section id="nearby" className="pt-4 pb-2">
                    <Row>
                        <Col>
                            <h2>Nearby Neighbourhoods</h2>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {neighbourhood &&
                            !isEmpty(neighbourhood.neighbours) &&
                            neighbourhood.neighbours.map((neighbour) => (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={3}
                                    key={neighbour.name_id}
                                    style={{ marginTop: "1em" }}
                                >
                                    <AreaCard
                                        base_url={
                                            "/area/sydney/" +
                                            convertUnderscoreToDash(
                                                neighbour.parent_name_id
                                            ) +
                                            "/"
                                        }
                                        name_id={convertUnderscoreToDash(
                                            neighbour.name_id
                                        )}
                                        name={neighbour.name}
                                        postal={neighbour.postal}
                                    />
                                </Col>
                            ))}
                    </Row>
                </section>
            </Container>
        </Fragment>
    );
}
