/* eslint-disable react/prop-types */
import React, { Component } from "react";

import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import PropTypes from "prop-types";

const Action = styled.div`
    display: flex;
    margin-top: 10px;
    // flex-direction: column;
    justify-content: space-between;
}
`;

const Menu = styled.div`
    margin: 5%;
}
`;

const property_types = {
    House: "house",
    Apartment: "apartment",
    Townhouse: "townhouse",
    Other: "other",
};

export default class PropertyTypeDropdownForm extends Component {
    static propTypes = {
        types: PropTypes.array,
        // id: PropTypes.string,
        // children: PropTypes.array,
        // handleChange: PropTypes.func,
        // handleClear: PropTypes.func,
        handleTypes: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            types: this.props.types,
        };

        Object.values(property_types).forEach(
            (v) =>
                (this.state[v] = this.state.types
                    ? this.state.types.includes(v)
                    : false)
        );

        this.updateValues = this.updateValues.bind(this);
        this.reset = this.reset.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        // Object.values(property_types).map((val) => {
        //     this.setState({
        //         [val]: false,
        //     });
        // });
    }

    handleInputChange(event) {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    updateValues() {
        const selected = Object.values(property_types).filter(
            (v) => this.state[v]
        );
        this.props.handleTypes(selected);
    }

    reset() {
        Object.values(property_types).forEach((val) => {
            this.setState({
                [val]: false,
            });
        });

        this.props.handleTypes([]);
    }

    toggleDropdown = (e) => {
        let elem =
            e.target.parentNode.parentNode.parentNode.parentNode.classList;
        elem.contains("show") ? elem.remove("show") : elem.add("show");

        this.updateValues();
    };

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("nextProps", nextProps, nextProps["types"]);
        // console.log("nextState", nextState);
        // console.log("this.state", this.state);

        if (nextProps.types !== nextState.types) {
            this.setState({ types: nextProps.types });

            Object.values(property_types).forEach((v) => {
                this.setState((s) => {
                    return {
                        [v]: s.types ? s.types.includes(v) : false,
                    };
                });
            });
        }

        return true;
    }

    render() {
        // console.log(this.state);
        const selected = Object.values(property_types).filter(
            (v) => this.state[v]
        );

        let selectedString =
            " (" +
            (Array.isArray(selected) && selected.length ? " ... " : " - ") +
            ") ";

        return (
            <Dropdown onToggle={this.toggle}>
                <Dropdown.Toggle variant="light">
                    {this.props.children}
                    {selectedString}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Menu>
                        <form>
                            {Object.keys(property_types).map((key) => (
                                <div key={key}>
                                    <label>
                                        <input
                                            name={property_types[key]}
                                            type="checkbox"
                                            checked={
                                                this.state[property_types[key]]

                                                // key in this.state.types
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                        {key}
                                    </label>
                                </div>
                            ))}

                            <Action>
                                <Button
                                    variant="outline-dark"
                                    size="sm"
                                    onMouseDown={() => this.reset()}
                                    onClick={this.reset}
                                >
                                    Clear
                                </Button>

                                <Button
                                    variant="dark"
                                    size="sm"
                                    onClick={this.toggleDropdown}
                                >
                                    OK
                                </Button>
                            </Action>
                        </form>
                    </Menu>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
