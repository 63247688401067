import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import { GoogleMapProvider } from "@googlemap-react/core";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";

import {
    convertDashToUnderscore,
    titleCase,
    getBlogPost,
    moneyFormatter,
    convertNameToId,
    trackView,
    // slugify,
} from "../Helpers";
import MapPanel from "../components/map/MapPanel";
import Geometry from "../components/map/Geometry";
import ComparableProperties from "../components/ComparableProperties";
import Amenities from "../components/Amenities";
import Schools from "../components/Schools";
import Transit from "../components/Transit";
import Census from "../components/Census";
// import NeighbouringArea from "../components/NeighbouringArea";
import PropertyImagePanel from "../components/PropertyImagePanel";
// import Carousel from "react-bootstrap/Carousel";
import MortgageRepaymentCalculator from "../components/MortgageRepaymentCalculator";
import AgentCard from "../components/agent/AgentCard";

import HeaderNav from "../components/navbar/HeaderNav";
import ScrollUp from "react-scroll-up";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

// import ReactHtmlParser from "react-html-parser"; //https://www.npmjs.com/package/react-html-parser
import parse from "html-react-parser"; //https://www.npmjs.com/package/html-react-parser
// import parser from "fast-html-parser"; //https://www.npmjs.com/package/fast-html-parser

import DOMParser from "react-native-html-parser"; //https://www.npmjs.com/package/react-native-html-parser
import XMLSerializer from "react-native-html-parser"; //https://www.npmjs.com/package/react-native-html-parser

//https://stackoverflow.com/questions/31569225/how-to-display-previous-and-next-images-with-a-bootstrap-carousel?noredirect=1&lq=1

import { UserContext } from "../App";
import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";

import AdSense from "react-adsense";

AreaProfile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            la4_id: PropTypes.string,
            la3_id: PropTypes.string,
            la2_id: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

const post_types = [
    "love",
    "introduction",
    "conclusion",
    "amenities",
    "shopping",
    "entertainment",
    "eats",
    "fun",
    "transport",
    "insights",
    "schools",
];

export default function AreaProfile(props) {
    const [state] = React.useContext(UserContext);

    const [id, setId] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [postal, setPostal] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [parentName, setParentName] = useState(
        titleCase(props.match.params.la2_id)
    );
    // const [population, setPopulation] = useState(undefined);
    const [geometry, setGeometry] = useState(undefined);
    // const [neighbours, setNeighbours] = useState(undefined);
    const [featuredImgSrc, setFeaturedImgSrc] = useState(undefined);
    const [images, setImages] = useState([]);
    const [blogPost, setBlogPost] = useState(undefined);

    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [description, setDescription] = useState(undefined);
    const [topAgents, setTopAgents] = useState(undefined);

    useEffect(() => {
        window.scrollTo(0, 0);

        const la2GeoJson =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/" +
            props.match.params.la4_id +
            "/" +
            convertDashToUnderscore(props.match.params.la3_id) +
            "/" +
            convertDashToUnderscore(props.match.params.la2_id) +
            ".json";

        fetch(la2GeoJson)
            .then((response) => response.json())
            .then((response) => {
                setId(response.properties.name_id);
                setName(response.properties.name);
                setPostal(response.properties.postal);
                setLatitude(response.properties.location.lat);
                setLongitude(response.properties.location.lng);
                setParentName(response.properties.parent_name);
                // setPopulation(response.properties.popn);
                setGeometry(response.geometry);
            });

        async function getPost() {
            const blogWpPost = getBlogPost(
                props.match.params.la4_id,
                props.match.params.la2_id
            );

            blogWpPost.then((r) => {
                if (r && r[0] && r[0].content && r[0].content.rendered) {
                    const content = r[0].content.rendered;

                    const parser = new DOMParser.DOMParser();
                    const dom = parser.parseFromString(content, "text/html");
                    const s = new XMLSerializer.XMLSerializer();

                    // Get image urls before they are removed below
                    const figure = dom.querySelect("figure");
                    let images = [];

                    let featured_img_src;
                    if (r[0].jetpack_featured_media_url) {
                        featured_img_src = r[0].jetpack_featured_media_url.split(
                            "?"
                        )[0];
                        images.push({
                            source: featured_img_src,
                            thumbnail: featured_img_src,
                        });
                    }

                    setFeaturedImgSrc(featured_img_src);

                    figure.forEach((img) => {
                        const image = img.querySelect("img")[0];
                        const src = image.getAttribute("src").split("?")[0];
                        const alt = image.getAttribute("alt").split("?")[0];

                        // remove the featured image if its duplicated in the article
                        if (src === featured_img_src) {
                            images.shift();
                        }

                        images.push({
                            source: src,
                            thumbnail: src,
                            caption: alt,
                        });
                    });

                    if (images.length) {
                        setImages(images);
                    }

                    let post = blogWpPost;
                    post_types.forEach((type) => {
                        const element = dom.querySelect("#" + type);
                        if (Array.isArray(element) && element.length) {
                            const element_first = element[0];
                            if (element_first) {
                                // Remove images
                                const figure_array = element_first.querySelect(
                                    "figure"
                                );

                                figure_array.forEach((e) => {
                                    e.parentNode.removeChild(e);
                                });

                                blogWpPost[type] = s
                                    .serializeToString(element_first)
                                    .replace(/h4>/g, "h5>")
                                    .replace(/h3>/g, "h4>")
                                    .replace(/h2>/g, "h3>");
                            }
                        }
                    });

                    if (post) {
                        setBlogPost(post);
                    }
                }
            });
        }

        async function getTopAgents() {
            const topAgentsUrl =
                process.env.REACT_APP_LIVEABLE_AGENT_API_URL +
                "/get_top_agent_in/" +
                props.match.params.la3_id +
                "/" +
                props.match.params.la2_id;

            fetch(topAgentsUrl)
                .then((response) => response.json())
                .then((response) => {
                    setTopAgents(response.result);
                });
        }
        // getNeighbourhood();
        getPost();
        getTopAgents();
    }, [
        props.match.params.la2_id,
        props.match.params.la3_id,
        props.match.params.la4_id,
    ]);

    useEffect(() => {
        const sold_description =
            name +
            " has " +
            listingState.count +
            " properties that were sold recently with a median property price of " +
            moneyFormatter.format(listingState.median) +
            ". The price range for the area is " +
            moneyFormatter.format(listingState.lower_quantile) +
            " to " +
            moneyFormatter.format(listingState.upper_quantile);

        setDescription(sold_description);
    }, [listingState, name]);

    // track user view
    useEffect(() => {
        trackView(state.user, props.history.location.pathname, "area-profile");
    }, [state.user, props.history.location]);

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    // showImageCarousel(images) {
    //     let items = [];

    //     images.forEach((image, index) => {
    //         if (
    //             index <= this.state.selectedIndex + 1 &&
    //             index >= this.state.selectedIndex - 1
    //         ) {
    //             items.push(
    //                 <Carousel.Item key={index}>
    //                     <img
    //                         className="d-block w-100"
    //                         src={image.source}
    //                         // onError={(e) => {
    //                         //     if (e.target.src !== image_source_large) {
    //                         //         e.target.onerror = null;
    //                         //         e.target.src = image_source_large;
    //                         //     }
    //                         // }}
    //                         alt={image.caption}
    //                     />
    //                 </Carousel.Item>
    //             );
    //         } else {
    //             // lazy load images
    //             items.push(
    //                 <Carousel.Item key={index}>
    //                     <img
    //                         className="d-block w-100"
    //                         src={image.source}
    //                         // onError={(e) => {
    //                         //     if (e.target.src !== image_source_large) {
    //                         //         e.target.onerror = null;
    //                         //         e.target.src = image_source_large;
    //                         //     }
    //                         // }}
    //                         alt={image.caption}
    //                     />
    //                 </Carousel.Item>
    //             );
    //         }
    //     });

    //     return (
    //         <Carousel interval={2000} onSelect={(i) => this.onSelect(i)}>
    //             {items}
    //         </Carousel>
    //     );
    // }

    // onSelect(selectedIndex) {
    //     this.setState({ selectedIndex: selectedIndex });
    // }

    // async function getNeighbourhood() {
    //     let la2_url =
    //         process.env.REACT_APP_LIVEABLE_DATA_API_URL +
    //         "/v1/lan_by_x/" +
    //         props.match.params.la4_id +
    //         "/?la=la2&name_id=" +
    //         convertDashToUnderscore(props.match.params.la2_id);
    //
    //     fetch(la2_url)
    //         .then((response) => response.json())
    //         .then((la2_data) => setNeighbours(la2_data.neighbours));
    // }

    return (
        <Fragment>
            {name && (
                <Helmet>
                    <title>{name + " Property Research - Liveable"}</title>
                    <meta
                        property="og:title"
                        content={"Area Profile: " + name + " - Liveable"}
                    />
                    <meta
                        name="description"
                        content={
                            "Area Profile for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            "Area Profile for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    {featuredImgSrc && (
                        <meta property="og:image" content={featuredImgSrc} />
                    )}
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id +
                            "/" +
                            props.match.params.la3_id +
                            "/" +
                            props.match.params.la2_id
                        }
                    />
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id +
                            "/" +
                            props.match.params.la3_id +
                            "/" +
                            props.match.params.la2_id
                        }
                    />

                    <script src="https://unohomeloans.com.au/loan-score-widget/uno-loanScore-widget.min.js"></script>
                </Helmet>
            )}
            {name && (
                <>
                    <JSONLD>
                        <Generic
                            type="webPage"
                            jsonldtype="WebPage"
                            schema={{
                                name: "Area Profile: " + name,
                                description:
                                    "Area Profile for " +
                                    name +
                                    ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics.",
                                url:
                                    "https://liveable.co" +
                                    props.location.pathname,
                            }}
                        />
                    </JSONLD>
                    <JSONLD>
                        <Generic
                            type="breadcrumbList"
                            jsonldtype="BreadcrumbList"
                        >
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Liveable",
                                        item: "https://liveable.co",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: "Area",
                                        item: "https://liveable.co/area",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 3,
                                        name: titleCase(
                                            props.match.params.la4_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id,
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 4,
                                        name: titleCase(
                                            props.match.params.la3_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id +
                                            "/" +
                                            props.match.params.la3_id,
                                    }}
                                />
                                {name && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 5,
                                            name: name,
                                        }}
                                    />
                                )}
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                </>
            )}
            <HeaderNav
                headings={[
                    {
                        label: "Area",
                        link: "#area",
                    },
                    {
                        label: "Sold Properties",
                        link: "#sold",
                    },
                    {
                        label: "Listings for Sale",
                        link: "#sale",
                    },
                    {
                        label: "Top Agents",
                        link: "#agents",
                    },
                    {
                        label: "Listings for Rent",
                        link: "#rent",
                    },
                    {
                        label: "Amenities",
                        link: "#amenities",
                    },
                    {
                        label: "Schools",
                        link: "#schools",
                    },
                    {
                        label: "Transit Options",
                        link: "#transit",
                    },
                    {
                        label: "Insights",
                        link: "#insights",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pb-5 pt-4">
                {/* Breadcrumb */}
                <section id="breadcrumb" className="text-muted">
                    <Row>
                        <Col>
                            <Link to={"/area/" + props.match.params.la4_id}>
                                {titleCase(props.match.params.la4_id, "-")}
                            </Link>
                            <span className="px-2">&gt;</span>
                            <Link
                                to={
                                    "/area/" +
                                    props.match.params.la4_id +
                                    "/" +
                                    convertNameToId(parentName)
                                }
                            >
                                {parentName}
                            </Link>
                        </Col>
                    </Row>
                </section>
                <section id="area">
                    <Row>
                        <Col>
                            <h1>{name}</h1>
                            {postal && "Postcode: " + postal.join(", ")}
                        </Col>
                    </Row>
                    {/* Property Images */}
                    {!isEmpty(images) && images.length > 1 && (
                        <Row className="mb-4">
                            <Col>
                                <PropertyImagePanel images={images} />
                            </Col>
                        </Row>
                    )}

                    {blogPost && blogPost["love"] && (
                        <Row>
                            <Col>
                                <h2>What we love{name && " about " + name}:</h2>
                                {parse(blogPost["love"])}
                            </Col>
                        </Row>
                    )}

                    {blogPost && blogPost["introduction"] && (
                        <Row>
                            <Col>{parse(blogPost["introduction"])}</Col>
                        </Row>
                    )}

                    {blogPost && blogPost["conclusion"] && (
                        <Row>
                            <Col>{parse(blogPost["conclusion"])}</Col>
                        </Row>
                    )}
                    {/* Google Map */}
                    <Row className="mt-4 mb-4">
                        <Col>
                            <GoogleMapProvider>
                                {latitude && longitude && (
                                    <MapPanel
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        height={"40vh"}
                                        zoom={13}
                                        enableZoom={true}
                                    />
                                )}
                                {geometry && (
                                    <Geometry
                                        id={id}
                                        name={name}
                                        geometry={geometry}
                                        fill={"#315990"}
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                    />
                                )}
                            </GoogleMapProvider>
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Sold Properties */}
                <section id="sold" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Sold Properties</h2>
                        </Col>
                    </Row>
                    {name &&
                        description &&
                        listingState.count &&
                        listingState.median && (
                            <Row className="mt-2 mb-4">
                                <Col>{description}</Col>
                            </Row>
                        )}
                    <Row className="mt-3">
                        <Col>
                            {latitude && longitude && (
                                <PropertiesContext.Provider
                                    value={{ listingState, dispatch }}
                                >
                                    <ComparableProperties
                                        cardType="sold"
                                        latitude={latitude}
                                        longitude={longitude}
                                        addressSlug={id}
                                        address={name}
                                        showHome={false}
                                    ></ComparableProperties>
                                </PropertiesContext.Provider>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Sales Listings */}
                <section id="sale" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Sales Listings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Properties listed for Sale that are currently on the
                            market {name && "in " + name}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {state.isAuthenticated &&
                            state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        cardType="sale"
                                        latitude={latitude}
                                        longitude={longitude}
                                        addressSlug={id}
                                        address={name}
                                        showHome={false}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <Alert.Heading>
                                        {!state.isAuthenticated
                                            ? "Login"
                                            : "Subscription"}{" "}
                                        required to view Comparable Sales
                                        Listings
                                    </Alert.Heading>
                                    <p className="mt-5">
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Sales Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Sales Listings allows
                                        you to benchmark what Sellers of similar
                                        properties are asking for.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        id="login-sales"
                                        onClick={() =>
                                            state.isAuthenticated
                                                ? goToPage("pricing")
                                                : goToPage("login")
                                        }
                                    >
                                        {state.isAuthenticated
                                            ? "Subscribe"
                                            : "Login"}
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Top Agents */}
                {topAgents && (
                    <section id="agents" className="pt-4">
                        <Row>
                            <Col>
                                <h2>Top Agents {name && "in " + name}</h2>
                            </Col>
                        </Row>
                        <Row>
                            {topAgents.slice(0, 6).map((agent) => (
                                <Col
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                    xl={2}
                                    key={agent.agentId}
                                    className="py-2"
                                >
                                    <AgentCard
                                        agentId={agent.agentId}
                                        // agentSlug={slugify(agent.agentName)}
                                        name={agent.agentName}
                                        photo={agent.agentImage}
                                        agencyLogo={agent.agencyLogo}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </section>
                )}
                <hr />
                <section id="compare-home-loans" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Mortgage Repayments Calculator</h2>
                        </Col>
                    </Row>
                    <MortgageRepaymentCalculator
                        propertyPrice={listingState.median}
                    />
                </section>
                <hr />
                <section id="home-loans-score" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Check your Existing Home Loan Score</h2>
                            <p>
                                uno is Australia&#39;s first Active Home Loan
                                Manager. If you already have a home loan, uno
                                can help you find a great deal and stay on a
                                great deal the whole time you have a loan.
                            </p>
                        </Col>
                    </Row>
                    {/* Uno Loan Score widget */}
                    <div
                        id="uno-loanScore-widget"
                        apikey="RF4V^#x$yf2v3U"
                        referrer="liveable"
                        referrerlabel="Liveable"
                    />
                </section>
                <hr />
                {/* Google Ads */}
                <section id="ads" className="pt-4">
                    <Row>
                        <Col>
                            <AdSense.Google
                                client="ca-pub-4439000183008596"
                                slot="8490234770"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                                layout="in-article"
                                format="fluid"
                            />
                        </Col>
                    </Row>
                </section>
                {/* Rental Listings */}
                <section id="rent" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Rental Listings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Properties listed for Rent that are currently on the
                            market {name && "in " + name}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {state.isAuthenticated &&
                            state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        cardType="rent"
                                        latitude={latitude}
                                        longitude={longitude}
                                        addressSlug={id}
                                        address={name}
                                        showHome={false}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <Alert.Heading>
                                        {!state.isAuthenticated
                                            ? "Login"
                                            : "Subscription"}{" "}
                                        required to view Comparable Rental
                                        Listings
                                    </Alert.Heading>
                                    <p className="mt-5">
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Rental Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Rental Listings is
                                        handy for Investors. It allows an
                                        Investor to benchmark what the potential
                                        yield is for the property, and how much
                                        supply there is for similar properties
                                        on the market.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        id="login-rental"
                                        onClick={() =>
                                            state.isAuthenticated
                                                ? goToPage("pricing")
                                                : goToPage("login")
                                        }
                                    >
                                        {state.isAuthenticated
                                            ? "Subscribe"
                                            : "Login"}
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Amenities  */}
                <section id="amenities" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Amenities</h2>
                        </Col>
                    </Row>
                    {blogPost && blogPost["eats"] && (
                        <Row>
                            <Col>{parse(blogPost["eats"])}</Col>
                        </Row>
                    )}

                    {blogPost && blogPost["amenities"] && (
                        <Row>
                            <Col>{parse(blogPost["amenities"])}</Col>
                        </Row>
                    )}

                    {blogPost && blogPost["entertainment"] && (
                        <Row>
                            <Col>{parse(blogPost["entertainment"])}</Col>
                        </Row>
                    )}

                    {blogPost && blogPost["fun"] && (
                        <Row>
                            <Col>{parse(blogPost["fun"])}</Col>
                        </Row>
                    )}

                    {blogPost && blogPost["shopping"] && (
                        <Row>
                            <Col>{parse(blogPost["shopping"])}</Col>
                        </Row>
                    )}
                    <Row className="mt-2">
                        <Col>
                            {longitude && latitude && (
                                <Amenities
                                    latitude={latitude}
                                    longitude={longitude}
                                    showDistance={false}
                                />
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Schools  */}
                <section id="schools" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Local Schools</h2>
                        </Col>
                    </Row>
                    {blogPost && blogPost["schools"] && (
                        <Row>
                            <Col>{parse(blogPost["schools"])}</Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            List of Schools and their Catchment Areas{" "}
                            {name && "for " + name}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {longitude && latitude && id && (
                                <Schools
                                    latitude={latitude}
                                    longitude={longitude}
                                    la2_id={id}
                                    showHome={false}
                                    areaOnly={true}
                                    enableSorting={true}
                                    hideMiniMap={true}
                                />
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Transit Options*/}
                <section id="transit" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Transit Options</h2>
                        </Col>
                    </Row>
                    {blogPost && blogPost["transport"] && (
                        <Row>
                            <Col>{parse(blogPost["transport"])}</Col>
                        </Row>
                    )}
                    <Row className="mt-2">
                        <Col>
                            {latitude && longitude && (
                                <GoogleMapProvider>
                                    <Transit
                                        latitude={latitude}
                                        longitude={longitude}
                                        showHome={false}
                                        showDistance={false}
                                    />
                                </GoogleMapProvider>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Neighbourhood Insights */}
                <section id="insights" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Neighbourhood Insights</h2>
                        </Col>
                    </Row>
                    {blogPost && blogPost["insights"] && (
                        <Row>
                            <Col>{parse(blogPost["insights"])}</Col>
                        </Row>
                    )}
                    <Row className="mt-2">
                        <Col>
                            {id && (
                                <Census
                                    la2_id={id}
                                    la3_id={props.match.params.la3_id}
                                    la4_id={props.match.params.la4_id}
                                />
                            )}
                        </Col>
                    </Row>
                </section>
                {/* <hr /> */}
                {/* Neighbouring Areas */}
                {/* <Row className="mt-4">
                        <Col>
                            <h2>Nearby Areas</h2>
                        </Col>
                    </Row> */}
                {/* <Row className="mt-2">
                        {!isEmpty(neighbours) &&
                            neighbours.map((neighbour) => (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    key={neighbour.name_id}
                                    style={{ marginTop: "1em" }}
                                >
                                    <NeighbouringArea
                                        name_id={neighbour.name_id}
                                        name={neighbour.name}
                                        postal={neighbour.postal}
                                    />
                                </Col>
                            ))}
                    </Row> */}
            </Container>
        </Fragment>
    );
}
