import React, { useContext } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { OverlayView, GoogleMapContext } from "@googlemap-react/core";
import { useEffect } from "react";

const defaultZindex = 100;
/**
 * Circular marker
 *
 */
const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(props) => (props.width ? props.width : "20px")};
    height: ${(props) => (props.height ? props.height : "20px")};
    background-color: ${(props) => (props.color ? props.color : "#2c7fb8")};
    border: 2px solid #fff;
    border-radius: ${(props) => (props.radius ? props.radius : "100%")};
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
    z-index: ${(props) => (props.zindex ? props.zindex : defaultZindex)};
`;

/**
 * Text accompanying the marker
 *
 */
const Text = styled.div`
    position: absolute;
    top: 16px;
    left: -50px;
    border: 2px solid #fff;
    background-color: white;
    text-align: center;
    width: 100px;
    z-index: ${(props) =>
        props.zindex ? props.zindex + 1 : defaultZindex + 1};
`;

/**
 * (Optional) Icon inside the marker
 *
 */
const Icon = styled.div`
    position: absolute;
    verticalalign: "middle";
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 2 : defaultZindex + 2};
`;

/**
 * Index value for marker
 *
 */
const Index = styled.div`
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 3 : defaultZindex + 3};
    white-space: nowrap;
`;

export default function Place(props) {
    const { state } = useContext(GoogleMapContext);

    useEffect(() => {
        // // fit the marker inside the map
        if (state.map && props.fitBounds) {
            const map = state.map;

            let bounds = map.getBounds();
            if (bounds && !bounds.contains(props.location)) {
                bounds.extend(props.location);
                map.fitBounds(bounds);
            }
        }
    }, [state.map, props.fitBounds, props.location]);

    return (
        <OverlayView position={props.location}>
            <Marker
                {...(props.onClick ? { onClick: props.onClick } : {})}
                {...(props.onHover ? { onHover: props.onHover } : {})}
                color={props.color}
                zindex={props.zindex}
                height={props.height}
                width={props.width}
            />
            {props.icon && <Icon zindex={props.zindex}>{props.icon}</Icon>}
            {props.index && <Index zindex={props.zindex}>{props.index}</Index>}
            {props.text && <Text zindex={props.zindex}>{props.text}</Text>}
        </OverlayView>
    );
}

Place.defaultProps = {
    onClick: null,
    // onClick: handleClick(),
    // onHover: handleClick(),
};

Place.propTypes = {
    location: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    color: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.object,
    index: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    radius: PropTypes.string,
    fitBounds: PropTypes.bool,
    zindex: PropTypes.number,
};
