/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import PropTypes from "prop-types";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";

import { SliderRail, Handle, Track, Tick } from "../Slider";

const MenuItem = styled.div`
    margin: 5%;
}
`;

const Action = styled.div`
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
`;

const sliderStyle = {
    // Give the slider some width
    position: "relative",
    width: "100%",
};

const domain = [0, 5];
const MIN = 0;
const MAX = 1;

DropdownForm.propTypes = {
    values: PropTypes.object,
    id: PropTypes.string,
    children: PropTypes.array,
    domain: PropTypes.array,
    handleChange: PropTypes.func,
    handleClear: PropTypes.func,
    handleSliderValues: PropTypes.func.isRequired,
};

export default function DropdownForm(props) {
    const [reset, setReset] = useState(false);
    const [min, setMin] = useState(props.values ? props.values.min : null);
    const [max, setMax] = useState(props.values ? props.values.max : null);
    const [selected, setSelected] = useState("");
    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        updateSelected();

        function updateSelected() {
            let selected_str = ""; // string in button to show which values have been selected

            let max_str = max;

            if (max === props.domain[MAX]) {
                max_str = max + "+";
            }

            if (min === null) {
                selected_str = " ( - ) ";
            } else if (min === max) {
                selected_str = " (  " + min + "  ) ";
            } else {
                selected_str = " (" + min + "-" + max_str + ") ";
            }

            setSelected(selected_str);
        }
    }, [min, max, props.domain]);

    useEffect(() => {
        setMin(props.values ? props.values.min : null);
        setMax(props.values ? props.values.max : null);
    }, [props.values]);

    function updateValues() {
        props.handleSliderValues([min, max], props.id);
    }

    function resetFilter() {
        setMin(null);
        setMax(null);
        setReset(true);

        //update the parent state
        props.handleSliderValues([null, null], props.id);
    }

    function onChange(values) {
        if (reset) {
            setMin(null);
            setMax(null);

            setReset(false);
        } else if (values[MIN] === 0 && values[MAX] === 0 && !toggled) {
            //TODO: toggled checks if user wants to update to 0 count
            setMin(values[MIN]);
            setMax(values[MAX]);
        } else if (values[MIN] === 0 && values[MAX] === 0) {
            //Dont do anything since the values will reset to 0 by default when the dropdown is toggled
        } else if (values[MIN] !== min || values[MAX] !== max) {
            setMin(values[MIN]);
            setMax(values[MAX]);
        } else {
            setMin(values[MIN]);
            setMax(values[MAX]);
        }

        setToggled(false);
    }

    function toggleDropdown(e) {
        let elem = e.target.parentNode.parentNode.parentNode.classList;
        elem.contains("show") ? elem.remove("show") : elem.add("show");

        updateValues();
    }
    function toggle() {
        setToggled(true);
    }

    return (
        <Dropdown onToggle={toggle}>
            <Dropdown.Toggle variant="light" id={props.id}>
                {props.children}
                {selected}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <MenuItem>
                    <Slider
                        mode={1}
                        step={1}
                        domain={props.domain ? props.domain : domain}
                        rootStyle={sliderStyle}
                        values={[min, max]}
                        onChange={onChange}
                    >
                        <Rail>
                            {({ getRailProps }) => (
                                <SliderRail getRailProps={getRailProps} />
                            )}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map((handle) => (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            domain={domain}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                        <Ticks count={domain[1]}>
                            {({ ticks }) => (
                                <div className="slider-ticks">
                                    {ticks.map((tick) => (
                                        <Tick
                                            key={tick.id}
                                            tick={tick}
                                            count={ticks.length}
                                        />
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    </Slider>
                </MenuItem>
                <MenuItem>
                    <Action>
                        <Button
                            variant="outline-dark"
                            size="sm"
                            // onMouseDown={() => reset()}
                            onClick={resetFilter}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="dark"
                            size="sm"
                            // onMouseDown={() => updateValues()}
                            onClick={toggleDropdown}
                        >
                            OK
                        </Button>
                    </Action>
                </MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    );
}
