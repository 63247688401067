import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

import Table from "react-bootstrap/Table";

/**
 *  LA1 Quick Stats
 *
 * TODO: LA2 stats
 *
 */

const Amount = styled.div`
    ::before {
        content: "$";
    }

    display: inline-block;
    vertical-align: bottom;
    float: none;
`;

export default class QuickStats extends Component {
    static propTypes = {
        la1_id: PropTypes.string.isRequired,
        population: PropTypes.number,
        median_age_of_persons: PropTypes.number,
        families: PropTypes.number,
        dwellings: PropTypes.number,
        average_household_size: PropTypes.number,
        average_number_of_persons_per_bedroom: PropTypes.number,
        median_total_family_income_weekly: PropTypes.number,
        median_total_household_income_weekly: PropTypes.number,
        median_total_personal_income_weekly: PropTypes.number,
        median_mortgage_repayment_monthly: PropTypes.number,
        median_rent_weekly: PropTypes.number,
    };

    // componentDidMount() {
    //     const la2GeoJson =
    //         process.env.REACT_APP_DATA_URL +
    //         "/metadata" +
    //         "/sydney" +
    //         "/" +
    //         convertDashToUnderscore(this.props.match.params.la3_id) +
    //         "/" +
    //         convertDashToUnderscore(this.props.match.params.la2_id) +
    //         ".json";

    //     fetch(la2GeoJson)
    //         .then((response) => response.json())
    //         .then((response) => {
    //             this.setState({
    //                 id: response.properties.name_id,
    //                 name: response.properties.name,
    //                 postal: response.properties.postal,
    //                 latitude: response.properties.location.lat,
    //                 longitude: response.properties.location.lng,
    //                 population: response.properties.popn,
    //                 geometry: response.geometry,
    //             });
    //         });

    // }

    render() {
        return (
            <>
                <span>
                    This property belongs to the Statistical Area Level 1 code
                    of {this.props.la1_id}
                </span>
                <Table striped bordered hover className="mt-3">
                    <tbody>
                        <tr>
                            <th>Population</th>
                            <td>{this.props.population}</td>
                        </tr>
                        <tr>
                            <th>Median age</th>
                            <td>{this.props.median_age_of_persons}</td>
                        </tr>
                        <tr>
                            <th>Average household size</th>
                            <td>{this.props.average_household_size}</td>
                        </tr>
                        <tr>
                            <th>Families</th>
                            <td>{this.props.families}</td>
                        </tr>
                        <tr>
                            <th>Dwellings</th>
                            <td>{this.props.dwellings}</td>
                        </tr>
                        <tr>
                            <th>Average number of persons per bedroom</th>
                            <td>
                                {
                                    this.props
                                        .average_number_of_persons_per_bedroom
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Median mortgage repayment ($/monthly)</th>
                            <td>
                                <Amount>
                                    {
                                        this.props
                                            .median_mortgage_repayment_monthly
                                    }
                                </Amount>
                            </td>
                        </tr>
                        <tr>
                            <th>Median rent ($/weekly)</th>
                            <td>
                                <Amount>{this.props.median_rent_weekly}</Amount>
                            </td>
                        </tr>
                        <tr>
                            <th>Median total family income ($/weekly)</th>
                            <td>
                                <Amount>
                                    {
                                        this.props
                                            .median_total_family_income_weekly
                                    }
                                </Amount>
                            </td>
                        </tr>
                        <tr>
                            <th>Median total household income ($/weekly)</th>
                            <td>
                                <Amount>
                                    {
                                        this.props
                                            .median_total_household_income_weekly
                                    }
                                </Amount>
                            </td>
                        </tr>
                        <tr>
                            <th>Median total personal income ($/weekly)</th>
                            <td>
                                <Amount>
                                    {
                                        this.props
                                            .median_total_personal_income_weekly
                                    }
                                </Amount>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Row className="mb-3"></Row>
            </>
        );
    }
}
