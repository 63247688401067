import React, { Fragment } from "react";
import PropTypes from "prop-types";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
    position: "absolute",
    width: "100%",
    height: 42,
    transform: "translate(0%, -50%)",
    borderRadius: 7,
    cursor: "pointer",
    // border: '1px solid white',
};

const railInnerStyle = {
    position: "absolute",
    width: "100%",
    height: 14,
    transform: "translate(0%, -50%)",
    borderRadius: 7,
    pointerEvents: "none",
    // backgroundColor: "rgb(155,155,155)",
    backgroundColor: "#6aadd3", //Navy Blue #004369, light blue #8B9CB6
};

export function SliderRail({ getRailProps }) {
    return (
        <Fragment>
            <div style={railOuterStyle} {...getRailProps()} />
            <div style={railInnerStyle} />
        </Fragment>
    );
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    getHandleProps,
}) {
    return (
        <Fragment>
            <div
                style={{
                    left: `${percent}%`,
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    WebkitTapHighlightColor: "rgba(0,0,0,0)",
                    zIndex: 5,
                    width: 28,
                    height: 42,
                    cursor: "pointer",
                    backgroundColor: "none",
                }}
                {...getHandleProps(id)}
            />
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2,
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
                    backgroundColor: disabled ? "#666" : "#ffc400",
                }}
            >
                <div
                    style={{
                        fontFamily: "Roboto",
                        fontSize: 12,
                        color: "white",
                        transform: "translate(35%, 20%)", // align to the middle of the Handle
                    }}
                >
                    {percent === 100 ? value + "+" : value}
                </div>
            </div>
        </Fragment>
    );
}

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Handle.defaultProps = {
    disabled: false,
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
    return (
        <div
            style={{
                position: "absolute",
                transform: "translate(0%, -50%)",
                height: 14,
                zIndex: 1,
                backgroundColor: disabled ? "#999" : "#315990", // teal: #0292B7 // blue #546C91 // liveable blue 315990
                borderRadius: 7,
                cursor: "pointer",
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    );
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Track.defaultProps = {
    disabled: false,
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    marginTop: 14,
                    width: 1,
                    height: 5,
                    backgroundColor: "rgb(200,200,200)",
                    left: `${tick.percent}%`,
                }}
            />
            <div
                style={{
                    position: "absolute",
                    marginTop: 22,
                    fontSize: 10,
                    textAlign: "center",
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    );
}

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
};

Tick.defaultProps = {
    format: (d) => d,
};

// export function Handle({ handle: { id, value, percent }, getHandleProps }) {
//     return (
//         <div
//             style={{
//                 left: `${percent}%`,
//                 position: "absolute",
//                 marginLeft: -15,
//                 // marginTop: 15,
//                 marginTop: 5,
//                 zIndex: 200,
//                 width: 28,
//                 height: 28,
//                 textAlign: "center",
//                 cursor: "pointer",
//                 borderRadius: "50%",
//                 backgroundColor: "#ffc107", //"#DB1F48", //1C4670 //#DB1F48
//                 color: "white",
//                 border: "2px solid #fff",
//             }}
//             {...getHandleProps(id)}
//         >
//             <div
//                 style={{
//                     fontFamily: "Roboto",
//                     fontSize: 10,
//                     color: "white",
//                     transform: "translate(0%, 40%)", // align to the middle of the Handle
//                 }}
//             >
//                 {percent === 100 ? value + "+" : value}
//             </div>
//         </div>
//     );
// }

// function Track({ source, target, getTrackProps }) {
//     return (
//         <div
//             style={{
//                 position: "absolute",
//                 height: 10,
//                 zIndex: 100,
//                 // marginTop: 25,
//                 backgroundColor: "#315990", // teal: #0292B7 // blue #546C91 // liveable blue 315990
//                 borderRadius: 5,
//                 cursor: "pointer",
//                 left: `${source.percent}%`,
//                 width: `${target.percent - source.percent}%`,
//             }}
//             {
//                 ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
//             }
//         />
//     );
// }

// function Tick({ tick, count }) {
//     return (
//         <div>
//             <div
//                 style={{
//                     position: "absolute",
//                     // marginTop: 42,
//                     marginLeft: -0.5,
//                     width: 1,
//                     height: 8,
//                     backgroundColor: "silver",
//                     left: `${tick.percent}%`,
//                 }}
//             />
//             <div
//                 style={{
//                     position: "absolute",
//                     // marginTop: 50,
//                     marginTop: 8,
//                     fontSize: 12,
//                     textAlign: "center",
//                     marginLeft: `${-(100 / count) / 2}%`,
//                     width: `${100 / count}%`,
//                     left: `${tick.percent}%`,
//                 }}
//             >
//                 {tick.percent === 100 ? tick.value + "+" : tick.value}
//             </div>
//         </div>
//     );
// }
