import React, { useContext } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { OverlayView, GoogleMapContext } from "@googlemap-react/core";

const defaultZindex = 100;
/**
 * Text accompanying the marker
 *
 */
const Text = styled.div`
    position: absolute;
    color: white;
    text-align: center;
    transform: translate(-50%, 0);
    font-weight: bold;
    font-size: 1.5em;
    z-index: ${(props) =>
        props.zindex ? props.zindex + 1 : defaultZindex + 1};
`;

/**
 * (Optional) Icon inside the marker
 *
 */
const Icon = styled.div`
    position: absolute;
    verticalalign: "middle";
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 2 : defaultZindex + 2};
`;

/**
 * Index value for marker
 *
 */
const Index = styled.div`
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 3 : defaultZindex + 3};
    white-space: nowrap;
`;

const Location = (props) => {
    const { state } = useContext(GoogleMapContext);

    // fit the marker inside the map
    if (state.map && props.fitBounds) {
        const map = state.map;

        let bounds = map.getBounds();
        if (bounds && !bounds.contains(props.location)) {
            bounds.extend(props.location);
            map.fitBounds(bounds);
        }
    }

    return (
        <OverlayView position={props.location}>
            {props.icon && <Icon zindex={props.zindex}>{props.icon}</Icon>}
            {props.index && <Index zindex={props.zindex}>{props.index}</Index>}
            {props.text && <Text zindex={props.zindex}>{props.text}</Text>}
        </OverlayView>
    );
};

Location.defaultProps = {
    onClick: null,
};

Location.propTypes = {
    location: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    color: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.object,
    index: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    fitBounds: PropTypes.bool,
    zindex: PropTypes.number,
};

export default Location;
