import React from "react";

import PropTypes from "prop-types";

import { moneyFormatter, compactMoneyFormatter } from "../Helpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

PriceRange.propTypes = {
    median: PropTypes.number,
    lowerQuantile: PropTypes.number,
    upperQuantile: PropTypes.number,
    count: PropTypes.number,
    type: PropTypes.string,
};

export default function PriceRange(props) {
    // const popover = (
    //     <Popover id="popover-basic">
    //         <Popover.Title as="h3">Sales Price Range</Popover.Title>
    //         <Popover.Content>
    //             This is the median price of similar nearby properties recently
    //             sold, and not an estimate.
    //         </Popover.Content>
    //     </Popover>
    // );

    const valueRangeText =
        props.type === "rent"
            ? moneyFormatter.format(props.lowerQuantile) +
              " - " +
              moneyFormatter.format(props.upperQuantile)
            : compactMoneyFormatter.format(props.lowerQuantile) +
              " - " +
              compactMoneyFormatter.format(props.upperQuantile);

    return (
        <section id="sales-price-range" className="py-1">
            <Row className="mt-2 align-items-center justify-content-center">
                <Col className="text-center">
                    Median {props.type === "rent" ? "Rent" : "Price"}
                </Col>
                <Col
                    className="text-center"
                    style={{
                        textAlign: "center",
                        fontWeight: "bolder",
                        fontSize: "1.4em",
                        color: "#315990",
                    }}
                >
                    {props.type === "rent"
                        ? moneyFormatter.format(props.median)
                        : compactMoneyFormatter.format(props.median)}
                </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
                <Col className="text-center">
                    {props.type === "rent" ? "Rental " : "Sales "} Price Range
                </Col>
                <Col className="text-center">{valueRangeText}</Col>
            </Row>
        </section>
    );
}
