import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Star = styled.span`
    color: orange;
`;
const EmptyStar = styled.span`
    color: lightgray;
`;

const Score = styled.span`
    color: orange;
`;

const Rating = (props) => (
    <>
        <Score>
            {props.score.toFixed(1)}{" "}
            {props.score >= 1 ? <Star>★</Star> : <EmptyStar>★</EmptyStar>}
            {props.score >= 2 ? <Star>★</Star> : <EmptyStar>★</EmptyStar>}
            {props.score >= 3 ? <Star>★</Star> : <EmptyStar>★</EmptyStar>}
            {props.score >= 4 ? <Star>★</Star> : <EmptyStar>★</EmptyStar>}
            {props.score >= 4.9 ? <Star>★</Star> : <EmptyStar>★</EmptyStar>}
        </Score>
    </>
);

Rating.defaultProps = {};

Rating.propTypes = {
    score: PropTypes.number.isRequired,
};

export default Rating;
