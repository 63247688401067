import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "./config";
import { getUA } from "react-device-detect";
import axios from "axios";

import "./App.css";
import Routes from "./Routes";
import Header from "./components/navbar/Header";
import Footer from "./components/navbar/Footer";

// Tracking tools
import Heap from "react-heap";

export const UserContext = React.createContext();

const initialState = {
    isAuthenticating: true,
    isAuthenticated: false,
    user: null,
    userPlan: "",
    chargebeeUser: false,
    chargebeeIsAuthenticating: true,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                ...state,
                isAuthenticating: true,
            };
        case "LOGIN":
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                user: action.payload,
            };
        case "LOGIN_CHARGEBEE":
            return {
                ...state,
                chargebeeUser: true,
                chargebeeIsAuthenticating: false,
            };
        case "SET_USER_PLAN":
            return {
                ...state,
                userPlan: action.payload,
            };
        case "NO_USER":
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
            };
        case "NO_USER_CHARGEBEE":
            return {
                ...state,
                chargebeeUser: false,
                chargebeeIsAuthenticating: false,
                userPlan: "",
            };
        case "LOGOUT":
            Auth.signOut();
            window.Chargebee.getInstance().logout();

            return {
                ...state,
                isAuthenticated: false,
                user: null,
                userPlan: "",
            };
        default:
            return state;
    }
};

function App() {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    // const [chargebeeUser, setChargeebeeUser] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

    const handleWindowResize = () => {
        setIsDesktop(window.innerWidth >= 768);
    };

    useEffect(() => {
        loadFacebookSDK();
        loadChargebee();

        dispatch({ type: "LOGIN_REQUEST" });
        Auth.currentAuthenticatedUser()
            .then(function(user) {
                dispatch({
                    type: "LOGIN",
                    payload: user,
                });
                checkSubscriptionPlan(user);
            })
            .catch((e) => {
                dispatch({
                    type: "NO_USER",
                });
                if (e !== "not authenticated" && e !== "No current user") {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            });

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    function loadFacebookSDK() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: config.social.FB,
                autoLogAppEvents: false,
                xfbml: true,
                version: "v3.1",
            });
        };

        (function(d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }

    function loadChargebee() {
        window.Chargebee.init({
            site: process.env.REACT_APP_CHARGEBEE_SITE,
            publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
            enableGATracking: true,
            enableFBQTracking: true,
        });

        window.Chargebee.getInstance();
    }

    function loadChargebeePortal(user) {
        if (!state.chargebeeUser) {
            axios
                .post(
                    process.env.REACT_APP_CHARGEBEE_API_URL +
                        "/create_portal_session",
                    { userId: user.username }
                )
                .then(function(response) {
                    window.Chargebee.getInstance().setPortalSession(
                        response.data
                    );
                    dispatch({
                        type: "LOGIN_CHARGEBEE",
                    });
                })
                .then(checkSubscriptionPlan(user))
                .catch(() => {
                    dispatch({
                        type: "NO_USER_CHARGEBEE",
                    });
                });
        }
    }

    function checkSubscriptionPlan(user) {
        axios
            .post(
                process.env.REACT_APP_CHARGEBEE_API_URL +
                    "/retrieve_subscription",
                { userId: user.username }
            )
            .then((response) => response.data)
            .then(function(data) {
                if (data.status !== "inactive") {
                    dispatch({
                        type: "SET_USER_PLAN",
                        payload: "Pro",
                    });
                } else {
                    dispatch({
                        type: "SET_USER_PLAN",
                        payload: "Free",
                    });
                }
            });
    }

    return (
        <UserContext.Provider value={[state, dispatch]}>
            {!state.isAuthenticating && (
                <>
                    {window.Chargebee.getInstance() &&
                        state.user &&
                        state.chargebeeIsAuthenticating &&
                        loadChargebeePortal(state.user)}
                    {process.env.NODE_ENV === "production" &&
                        !getUA.includes("Prerender") && (
                            <Heap appId="3283038452" />
                        )}
                    <Header search_bar="true" />
                    <Routes />
                    <Footer isDesktop={isDesktop} />
                </>
            )}
        </UserContext.Provider>
    );
}

export default withRouter(App);
