/* eslint-disable no-console */
import React, { useState, useEffect, Fragment, useContext } from "react";

import { GoogleMapContext } from "@googlemap-react/core";
import Places from "./Places";

import PropTypes from "prop-types";

const radius = 1200;

/**
 * https://developers.google.com/places/web-service/search
 *
 */
AmenitiesPanel.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    showDistance: PropTypes.bool,
};
export default function AmenitiesPanel(props) {
    const { state } = useContext(GoogleMapContext);

    const [latitude, setLatitude] = useState(props.latitude);
    const [longitude, setLongitude] = useState(props.longitude);

    useEffect(() => {
        setLatitude(props.latitude);
        setLongitude(props.longitude);
    }, [props.latitude, props.longitude]);

    if (state.map) {
        return (
            <Fragment>
                <Places
                    header={"Cafes"}
                    type={"cafe"}
                    longitude={longitude}
                    latitude={latitude}
                    radius={radius}
                    map={state.map}
                    showDistance={props.showDistance}
                />
                <Places
                    header={"Restaurants"}
                    type={"restaurant"}
                    longitude={longitude}
                    latitude={latitude}
                    radius={radius}
                    map={state.map}
                    showDistance={props.showDistance}
                />
                <Places
                    header={"Bars"}
                    type={"bar"}
                    longitude={longitude}
                    latitude={latitude}
                    radius={radius}
                    map={state.map}
                    showDistance={props.showDistance}
                />
                <Places
                    header={"Supermarkets"}
                    type={"supermarket"}
                    keyword={"supermarket|grocery"}
                    longitude={longitude}
                    latitude={latitude}
                    radius={radius}
                    map={state.map}
                    showDistance={props.showDistance}
                />
            </Fragment>
        );
    } else {
        return null;
    }
    // }
}
