/* global google */
//https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples/place-search-pagination
import { distance } from "../../Helpers";

export const nearbySearch = (location, radius, type, keyword, map) => {
    const service = new google.maps.places.PlacesService(map);

    return new Promise((resolve, reject) => {
        let places = [];

        service.nearbySearch(
            {
                location: location,
                radius: radius,
                type: keyword ? null : [type], // keyword overrides type.
                keyword: keyword,
                rankby: google.maps.places.RankBy.DISTANCE,
            },
            // eslint-disable-next-line no-unused-vars
            (results, status, _pagination) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    // pagination.hasNextPage && pagination.nextPage();

                    results.forEach((i) => {
                        i.liveable_type = type;
                        i.distance = distance(
                            location.lat,
                            location.lng,
                            i.geometry.location.lat(),
                            i.geometry.location.lng()
                        );

                        places.push(i);
                    });

                    resolve(places);

                    // if no more results then return the promise
                    // if (!pagination.hasNextPage) {
                    //     resolve(places);
                    // }
                } else {
                    reject(status);
                }
            }
        );
    });
};
