import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { JSONLD, Generic } from "react-structured-data";
import Helmet from "react-helmet";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";

import { convertUnderscoreToDash } from "../Helpers";
import AddressTextBox from "../components/AddressTextBox";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { FaUsers, FaCity, FaMapMarkedAlt } from "react-icons/fa";

import { UserContext } from "../App";

import "./Home.css";

Home.propTypes = {
    history: PropTypes.object,
};

export default function Home(props) {
    const [state, dispatch] = React.useContext(UserContext);

    const DEFAULT_LIMIT = 16; // cards to show per pagination

    const [blogPosts, setBlogPosts] = useState([]);
    const [la2List, setLa2List] = useState([]);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [maxLimit, setMaxLimit] = useState(500);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: "/" } },
        };
        props.history.push(location);
    }

    async function getBlogPosts() {
        const numberOfPosts = 8;
        const blogJson =
            "https://blog.liveable.co/wp-json/wp/v2/posts?per_page=" +
            numberOfPosts;

        fetch(blogJson)
            .then((response) => response.json())
            .then((response) => {
                setBlogPosts(response);
            });
    }

    async function getNeighbourhoods() {
        const la2Json =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/sydney" +
            "/la2-home.json";

        fetch(la2Json)
            .then((response) => response.json())
            .then((response) => {
                setLa2List(orderBy(response, "popn", "desc"));
                setMaxLimit(response.length);
            });
    }

    useEffect(() => {
        getNeighbourhoods();
        getBlogPosts();
    }, []);

    function handleClickButtonShowMore() {
        setLimit(limit + DEFAULT_LIMIT);
    }

    return (
        <Fragment>
            <Helmet>
                <meta
                    property="og:title"
                    content={"Property Research Simplified - Liveable"}
                />
                <meta
                    name="description"
                    content="Property Research Simplified. Find out all about a property and its surroundings. Recent Comparable Sales. Demographics. Nearby Amenities. Local Schools. Public Transport options and lots more"
                />
                <meta
                    property="og:description"
                    content="Property Research Simplified. Find out all about a property and its surroundings. Recent Comparable Sales. Demographics. Nearby Amenities. Local Schools. Public Transport options and lots more"
                />
                <meta property="og:url" content="https://liveable.co" />
                <link rel="canonical" href="https://liveable.co" />
                <style type="text/css">{`
                        .navbar.header {
                            display: none;
                        }

                    `}</style>
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co",
                        name: "Liveable - Property Research Simplified",
                        description:
                            "Get the full picture of a property and its surroundings. Liveable helps take the guesswork out of finding an ideal place to live",
                    }}
                />
            </JSONLD>
            <Container fluid className="header-bg-splash">
                <Container>
                    <Navbar
                        className="justify-content-between pt-3"
                        variant="dark"
                        expand="sm"
                    >
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src="https://liveable.co/images/liveable_logo_top.png"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Fragment>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Nav className="px-0">
                                    {state.isAuthenticated ? (
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                dispatch({
                                                    type: "LOGOUT",
                                                })
                                            }
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="warning"
                                                onClick={goToLogin}
                                            >
                                                Login
                                            </Button>
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    </Navbar>
                    <Row
                        id="cta"
                        style={{ paddingTop: "11vh", paddingBottom: "10vh" }}
                    >
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <h1 className="header-text">
                                Property Research Simplified
                            </h1>
                            <h2 className="header-text header-subtitle">
                                Get the full picture of a property and its
                                surroundings
                            </h2>
                            <div className="mt-4">
                                <AddressTextBox />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                    </Row>
                </Container>
            </Container>
            <Container
                fluid
                className="py-5 background-grey liveable-blue text-center"
            >
                <Container>
                    <Row>
                        <Col>
                            <h2 className="liveable-blue section-title">
                                Insights to help find your ideal place to live
                            </h2>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <FaCity className="icon-large liveable-blue" />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        Comparable Properties
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Knowing Comparable or Similar Properties
                                        that recently Sold, currently listed for
                                        Sale and for Rent will help give you an
                                        idea of what the price of the property
                                        should be.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <FaMapMarkedAlt className="icon-large liveable-blue" />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>Surroundings</Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Knowing what the nearby Amenities like
                                        Cafes, Restaurants, Schools, etc. are
                                        and the Transport options in the area
                                        can help determine how desireable the
                                        location is.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <FaUsers className="icon-large liveable-blue" />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        Neighbourhood Demographics
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Some neighbourhoods skew young, some
                                        skew old, some have higher incomes, some
                                        speak more languages. Know what makes
                                        the neighbourhood tick.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 background-grey liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="liveable-blue text-center section-title">
                                Get Started
                            </h2>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col
                            xs={{ span: 12, order: 2 }}
                            sm={{ span: 12, order: 2 }}
                            md={{ span: 7, order: 1 }}
                            lg={{ span: 7, order: 1 }}
                            xl={{ span: 7, order: 1 }}
                            className="pt-3"
                        >
                            <Card
                                bg="light"
                                className="h-100 py-3 align-middle"
                            >
                                <Card.Body>
                                    <h3>
                                        <a href="https://explore.liveable.co">
                                            Explore Neighbourhoods
                                        </a>
                                    </h3>
                                    <Card.Text className="mt-4 text-muted">
                                        Everyone has a different lifestyle.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Get personalised recommendations on your
                                        ideal neighbourhoods in a city based on
                                        your lifestyle choices.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Filter in and out what&#39;s important
                                        to you when looking for your ideal place
                                        to live.
                                    </Card.Text>
                                    <Button
                                        variant="outline-primary"
                                        className="mt-2 py-2"
                                        href="https://explore.liveable.co"
                                    >
                                        Explore Neighbourhoods
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 12, order: 1 }}
                            md={{ span: 5, order: 2 }}
                            lg={{ span: 5, order: 2 }}
                            xl={{ span: 5, order: 2 }}
                            className="pt-3 text-center"
                        >
                            <Image
                                src="/images/home_explore.jpg"
                                fluid
                                className="h-100"
                                thumbnail
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={5}
                            lg={5}
                            xl={5}
                            className="pt-3 text-center"
                        >
                            <Image
                                src="/images/home_schools.jpg"
                                fluid
                                className="h-100"
                                thumbnail
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            xl={7}
                            className="pt-3"
                        >
                            <Card
                                bg="light"
                                className="h-100 py-3 align-middle"
                            >
                                <Card.Body>
                                    <h3>
                                        <a href="https://liveable.co/schools">
                                            School Catchment Areas
                                        </a>
                                    </h3>
                                    <Card.Text className="mt-4 text-muted">
                                        Public Schools usually have specific
                                        catchment areas in which they will
                                        accept students only if their address is
                                        within that catchment area.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        School Catchment Areas is an interactive
                                        resource to view which schools cover
                                        what neighbourhoods, streets and
                                        addresses.
                                    </Card.Text>
                                    <Button
                                        variant="outline-primary"
                                        className="mt-2 py-2"
                                        href="https://liveable.co/schools"
                                    >
                                        View School Catchment Areas
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4 mb-3">
                    <Row>
                        <Col
                            xs={{ span: 12, order: 2 }}
                            sm={{ span: 12, order: 2 }}
                            md={{ span: 7, order: 1 }}
                            lg={{ span: 7, order: 1 }}
                            xl={{ span: 7, order: 1 }}
                            className="pt-3"
                        >
                            <Card
                                bg="light"
                                className="h-100 py-3 align-middle"
                            >
                                <Card.Body>
                                    <Card.Title>
                                        <h3>
                                            <a href="#cta">Property Reports</a>
                                        </h3>
                                    </Card.Title>
                                    <Card.Text className="mt-3 text-muted">
                                        Buying a property is never easy. For
                                        most, it&#39;s probably the biggest
                                        purchase they&#39;d make in their
                                        lifetime.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Weeks, if not months, of research would
                                        go into researching the ideal property.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Our Property Reports simplifies and
                                        supercharges the property research
                                        process; providing in-depth insights on
                                        Comparable Properties that sold recently
                                        and currently on the market, Amenities,
                                        Transport Options, Demographics.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Be fully informed and more importantly,
                                        get peace-of-mind before making the
                                        largest investment of your lifetime.
                                    </Card.Text>
                                    <Button
                                        variant="outline-primary"
                                        className="mt-2 py-2"
                                        href="#cta"
                                    >
                                        Get a Property Report
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 12, order: 1 }}
                            md={{ span: 5, order: 2 }}
                            lg={{ span: 5, order: 2 }}
                            xl={{ span: 5, order: 2 }}
                            className="pt-3 text-center"
                        >
                            <Image
                                src="/images/home_reports.jpg"
                                fluid
                                className="h-100"
                                thumbnail
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="liveable-blue text-center section-title">
                                The Pulse
                            </h2>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row className="carousel-wrapper">
                        {blogPosts.map((post) => (
                            <Col
                                xs={10}
                                sm={10}
                                md={4}
                                lg={4}
                                xl={4}
                                className="pt-3 text-center carousel-card"
                                key={"BlogPost_" + post.id}
                            >
                                <Card
                                    bg="light"
                                    key={"BlogPost_Card_" + post.id}
                                >
                                    {post.jetpack_featured_media_url && (
                                        <a href={post.link}>
                                            <Card.Img
                                                variant="top"
                                                src={
                                                    post.jetpack_featured_media_url
                                                }
                                            />
                                        </a>
                                    )}
                                    <Card.Body>
                                        <a href={post.link}>
                                            <Card.Title
                                                dangerouslySetInnerHTML={{
                                                    __html: post.title.rendered,
                                                }}
                                            ></Card.Title>
                                        </a>
                                        <Card.Text
                                            className="mt-3 mb-2 text-muted"
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered.includes(
                                                    "&hellip;"
                                                )
                                                    ? post.excerpt.rendered.substring(
                                                          0,
                                                          post.excerpt.rendered.indexOf(
                                                              "&hellip;"
                                                          ) + "&hellip;".length
                                                      )
                                                    : post.excerpt.rendered,
                                            }}
                                        ></Card.Text>
                                        <a href={post.link}>
                                            <Button
                                                id="blog-read-more-link"
                                                variant="link"
                                                className="mt-2"
                                            >
                                                Read more
                                            </Button>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 background-grey liveable-blue">
                <Container className="py-5">
                    <Row>
                        <Col>
                            <h2 className="liveable-blue text-center section-title">
                                Find a Property
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center">
                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="mt-3">
                                <AddressTextBox />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="liveable-blue text-center section-title">
                                Neighbourhoods
                            </h2>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        {la2List.slice(50, 50 + limit).map((la2) => (
                            <Col
                                xs={6}
                                sm={6}
                                md={3}
                                lg={3}
                                xl={3}
                                className="pt-3 text-center carousel-card"
                                key={"La2_" + la2.id}
                            >
                                <Card bg="light" key={"La2_Card_" + la2.id}>
                                    <Card.Body>
                                        {/* TODO: Sydney as LA4 hardcoded. Need to fix */}
                                        {la2.name_id && (
                                            <a
                                                href={
                                                    "/area/sydney/" +
                                                    convertUnderscoreToDash(
                                                        la2.parent_name_id
                                                    ) +
                                                    "/" +
                                                    convertUnderscoreToDash(
                                                        la2.name_id
                                                    )
                                                }
                                            >
                                                <Card.Title>
                                                    {la2.name}
                                                </Card.Title>
                                            </a>
                                        )}
                                        <Card.Subtitle className="mt-3 mb-2 text-muted">
                                            {la2.postal.join(", ")}
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {la2List.length > 0 && limit < maxLimit && (
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button
                                    id="home-neighbourhoods-show-more"
                                    variant="outline-primary"
                                    onClick={() => handleClickButtonShowMore()}
                                >
                                    Show More
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Container>
        </Fragment>
    );
}
