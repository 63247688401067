import React from "react";
import PropTypes from "prop-types";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";

Footer.propTypes = {
    isDesktop: PropTypes.bool,
};

function Footer() {
    return (
        <>
            <Navbar
                className="align-top footer secondary-footer pb-3"
                style={{
                    backgroundColor: "#315990",
                    color: "#ffffff",
                }}
                variant="dark"
                expand="md"
            >
                <Container className="pb-3">
                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="pt-4 pb-4 align-self-stretch"
                    >
                        <h5 className="widget-title">Resources</h5>
                        <a href="/pricing">Pricing</a>
                        <br />
                        <a href="https://blog.liveable.co">Blog</a>
                        <br />
                        {/* <a href="https://liveable.co/">Help Centre</a>
                        <br /> */}
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="pt-4 pb-4 align-self-stretch"
                    >
                        <h5 className="widget-title">Products</h5>
                        <a href="https://liveable.co">Property Reports</a>
                        <br />
                        <a href="https://explore.liveable.co">
                            Explore Neighbourhoods
                        </a>
                        <br />
                        <a href="https://liveable.co/schools">
                            Schools Catchment Areas
                        </a>
                        <br />
                        <a href="https://liveable.co/rent">
                            Rental Market Insights
                        </a>
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="pt-4 pb-4 align-self-stretch"
                    >
                        <h5 className="widget-title">Liveable</h5>
                        <a href="https://liveable.co/about">About</a>
                        <br />
                        <a href="https://liveable.co/contact">Contact</a>
                        <br />
                        <a href="https://liveable.co/terms">
                            Terms &amp; Conditions
                        </a>
                        <br />
                        <a href="https://liveable.co/privacy">Privacy Policy</a>
                    </Col>
                    <Col
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="pt-4 pb-4 align-self-stretch"
                    >
                        <h5 className="widget-title">Data</h5>
                        <div>
                            <img
                                src="https://liveable.co/images/powered_by_google_on_non_white.png"
                                alt="Powered by Google"
                            />
                        </div>
                        <div className="mt-1">
                            <img
                                src="https://liveable.co/images/powered-by-domain-white.png"
                                alt="Powered by Domain"
                            />
                        </div>
                        <div className="mt-2">
                            Australian Bureau of Statistics
                            <br />
                            NSW Department of Education
                        </div>
                    </Col>
                </Container>
            </Navbar>
            <Navbar
                className="flex-column align-items-stretch pt-2 footer"
                style={{
                    backgroundColor: "#1a2a4c",
                    color: "#ffffff",
                }}
                variant="dark"
            >
                <Container>
                    <Col className="text-center">
                        © {new Date().getFullYear()} Liveable Insights Pty Ltd
                        <br />
                        Made with ♥ in Sydney, Australia
                    </Col>
                </Container>
            </Navbar>
        </>
    );
}

export default Footer;
