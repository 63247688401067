/* eslint-disable no-console */
import React, { useRef, useEffect } from "react";

import PropTypes from "prop-types";

import * as d3 from "d3";

Legend.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    title: PropTypes.string,
    opacity: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

export default function Legend(props) {
    const ref = useRef(null);

    useEffect(() => {
        function drawLegend() {
            const { width, height, data, fill } = props;

            //avoid re-rendering over a previous svg
            d3.select(ref.current)
                .select("g")
                .remove();

            const svg = d3
                .select(ref.current)
                .attr("width", width)
                .attr("height", height)
                .append("g");

            let itemWidth = 80;
            let itemHeight = 18;

            let legend = svg
                .selectAll(".legend")
                .data(data)
                .enter()
                .append("g")
                .attr("transform", (_d, i) => {
                    return (
                        "translate(" + i * itemWidth + "," + itemHeight + ")"
                    );
                })
                .attr("class", "legend");

            // add boxes
            legend
                .append("rect")
                .attr("width", 15)
                .attr("height", 15)
                .attr(
                    "fill",
                    Array.isArray(fill)
                        ? (_d, i) => {
                              return fill[i];
                          }
                        : fill
                );

            // add labels
            legend
                .append("text")
                .attr("x", 15)
                .attr("y", 12)
                .attr("dx", 4)
                .style("text-anchor", "start")
                .style("font-size", 12)
                .text((d) => {
                    return d;
                });

            // Now space the groups out after they have been appended:
            const padding = 10;
            legend.attr("transform", (_d, i) => {
                return (
                    "translate(" +
                    (d3.sum(data, (_e, j) => {
                        if (j < i) {
                            return legend.nodes()[j].getBBox().width;
                        } else {
                            return 0;
                        }
                    }) +
                        padding * i) +
                    ",0)"
                );
            });
        }

        drawLegend();
    }, [props]);

    return <svg ref={ref} />;
}
