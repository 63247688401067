import React, { useState, useEffect, Fragment } from "react";
import { GoogleMapProvider } from "@googlemap-react/core";
import wkx from "wkx";
import { Link } from "react-router-dom";

import { titleCase, slugify, trackView } from "../Helpers";

import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
// import { getUA } from "react-device-detect";
import ScrollUp from "react-scroll-up";
import orderBy from "lodash/orderBy";

import { FaRegArrowAltCircleUp, FaSchool } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

import MapPanel from "../components/map/MapPanel";
import Geometry from "../components/map/Geometry";
import Place from "../components/map/Place";
import Loading from "../components/Loading";
import HeaderNav from "../components/navbar/HeaderNav";
import CardTableItemMobile from "../components/CardTableItemMobile";
import ComparableProperties from "../components/ComparableProperties";
import MortgageRepaymentCalculator from "../components/MortgageRepaymentCalculator";
// import DisplayAd from "../components/ad/DisplayAd";

import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";

import { UserContext } from "../App";

import "./SchoolProfile.css";

import AdSense from "react-adsense";

SchoolProfile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            schoolId: PropTypes.string,
            schoolSlug: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function SchoolProfile(props) {
    const [state] = React.useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [schoolId] = useState(props.match.params.schoolId);
    const [schoolSlug, setSchoolSlug] = useState(
        props.match.params.schoolSlug
            ? props.match.params.schoolSlug
            : undefined
    );
    const [schoolName, setSchoolName] = useState(undefined);
    const [school, setSchool] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);

    const [la2_id, setLa2_id] = useState(undefined);
    const [la3_id, setLa3_id] = useState(undefined);
    const [la4_id, setLa4_id] = useState(undefined);
    const [la2_name, setLa2_name] = useState(undefined);
    const [la3_name, setLa3_name] = useState(undefined);

    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [description, setDescription] = useState(undefined);
    const [hasNaplan, setHasNaplan] = useState(undefined);
    const [naplan, setNaplan] = useState([]);

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    function compareAndColourCode(input, compareOne, compareTwo) {
        const green = "#228B22";
        const orange = "#ffa800";
        const red = "#dc143c";
        const grey = "#696969";

        if (input >= compareOne && input >= compareTwo) {
            return green;
        } else if (input >= compareOne && input <= compareTwo) {
            return orange;
        } else if (input <= compareOne && input >= compareTwo) {
            return orange;
        } else if (input <= compareOne && input <= compareTwo) {
            return red;
        } else {
            return grey;
        }
    }

    const catchment_colours = {
        PRIMARY: "#315990",
        SECONDARY: "#ffa800",
        FUTURE: "#ffa800",
    };

    useEffect(() => {
        async function getLiveableArea(lat, lng) {
            let liveable_url =
                process.env.REACT_APP_LIVEABLE_DATA_API_URL +
                "/v1/la/sydney/?lat=" +
                lat +
                "&lng=" +
                lng;

            return fetch(liveable_url)
                .then((response) => response.json())
                .then((data) => {
                    setLa2_id(data.la2);
                    setLa3_id(data.la3);
                    setLa4_id(data.la4);
                    setLa2_name(data.la2_name);
                    setLa3_name(data.la3_name);
                });
        }

        let school_url =
            "https://data.liveable.co/schools/sydney/school/" +
            schoolId +
            ".json";

        fetch(school_url)
            .then((response) => response.json())
            .then((data) => {
                setSchool(data);
                // setSchoolId(data[0].ageid);
                setSchoolSlug(slugify(data[0].school_name));
                setSchoolName(data[0].school_name);
                setLatitude(data[0].lat);
                setLongitude(data[0].lng);
                setLoading(false);
                getLiveableArea(data[0].lat, data[0].lng);
            })
            .catch((e) => {
                setLoading(false);
                setErrorMessage("Unable to find requested School");
                console.log(e);
            });

        let naplan_url =
            "https://data.liveable.co/schools/sydney/naplan/" +
            schoolId +
            ".json";

        fetch(naplan_url)
            .then((response) => response.json())
            .then((data) => {
                setNaplan(orderBy(data, ["Year", "Grade"], ["desc", "asc"]));
                setHasNaplan(true);
            })
            .catch(() => {
                setHasNaplan(false);
            });
    }, [schoolId]);

    useEffect(() => {
        if (school && school[0]) {
            setDescription(
                school[0].school_name +
                    (school[0].town_suburb &&
                        school[0].town_suburb !== null &&
                        " is located in " +
                            titleCase(school[0].town_suburb, " ")) +
                    "." +
                    (school[0].level_of_schooling &&
                        " It is a " +
                            school[0].level_of_schooling +
                            " school") +
                    (school[0].school_subtype &&
                        " that caters for " + school[0].school_subtype) +
                    (school[0].school_specialty_type &&
                        school[0].school_gender &&
                        ", and a " +
                            school[0].school_specialty_type +
                            " " +
                            school[0].school_gender +
                            " school") +
                    "." +
                    (school[0].latest_year_enrolment_fte &&
                        " The number of full-time enrolled students in " +
                            school[0].school_name +
                            " is " +
                            parseInt(school[0].latest_year_enrolment_fte) +
                            ".")
            );
        }
    }, [schoolId, school]);

    // useEffect(() => {
    //     let haveLaDetails = true;
    //     if (listingState.properties[0]) {
    //         if (listingState.properties[0].la2) {
    //             setLa2_id(listingState.properties[0].la2);
    //             haveLaDetails = true;
    //         } else {
    //             haveLaDetails = false;
    //         }
    //         if (listingState.properties[0].la3) {
    //             setLa3_id(listingState.properties[0].la3);
    //             haveLaDetails = true;
    //         } else {
    //             haveLaDetails = false;
    //         }
    //         if (listingState.properties[0].la4) {
    //             setLa4_id(listingState.properties[0].la4);
    //             haveLaDetails = true;
    //         } else {
    //             haveLaDetails = false;
    //         }
    //     }

    //     if (listingState && !haveLaDetails) {
    //         if (latitude && longitude) {
    //             getLiveableArea(latitude, longitude);
    //         }
    //     }
    // }, [listingState, la2_id, la3_id, la4_id, latitude, longitude]);

    // track user view
    useEffect(() => {
        trackView(
            state.user,
            props.history.location.pathname,
            "school-profile"
        );
    }, [state.user, props.history.location]);

    return (
        <Fragment>
            <Helmet>
                {schoolName && (
                    <title>
                        {"School Profile: " + schoolName + " - Liveable"}
                    </title>
                )}
                {schoolName && (
                    <meta
                        property="og:title"
                        content={
                            "School Profile: " + schoolName + " - Liveable"
                        }
                    />
                )}
                {description && (
                    <meta name="description" content={description} />
                )}
                {description && (
                    <meta property="og:description" content={description} />
                )}
                {schoolId && schoolSlug && (
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/school/" +
                            schoolId +
                            "/" +
                            schoolSlug
                        }
                    />
                )}
                {schoolId && schoolSlug && (
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/school/" +
                            schoolId +
                            "/" +
                            schoolSlug
                        }
                    />
                )}
            </Helmet>
            {schoolId && schoolSlug && schoolName && (
                <>
                    <JSONLD>
                        <Generic
                            type="webPage"
                            jsonldtype="WebPage"
                            schema={{
                                name: "School Profile: " + schoolName,
                                description: description,
                                url:
                                    "https://liveable.co/school/" +
                                    schoolId +
                                    "/" +
                                    schoolSlug,
                            }}
                        />
                    </JSONLD>
                    <JSONLD>
                        <Generic
                            type="breadcrumbList"
                            jsonldtype="BreadcrumbList"
                        >
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Liveable",
                                        item: "https://liveable.co",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: "Schools",
                                        item: "https://liveable.co/schools",
                                    }}
                                />
                                {la4_id && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 3,
                                            name: titleCase(la4_id, "-"),
                                            item:
                                                "https://liveable.co/area/" +
                                                la4_id,
                                        }}
                                    />
                                )}
                                {la4_id && la3_id && la2_id && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 4,
                                            name: la3_name,
                                            item:
                                                "https://liveable.co/area/" +
                                                la4_id +
                                                "/" +
                                                la3_id,
                                        }}
                                    />
                                )}
                                {la4_id && la3_id && la2_id && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 5,
                                            name: la2_name,
                                            item:
                                                "https://liveable.co/schools/" +
                                                la4_id +
                                                "/" +
                                                la3_id +
                                                "/" +
                                                la2_id,
                                        }}
                                    />
                                )}
                                {schoolId && schoolSlug && schoolName && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 6,
                                            name: schoolName,
                                            item:
                                                "https://liveable.co/school/" +
                                                schoolId +
                                                "/" +
                                                schoolSlug,
                                        }}
                                    />
                                )}
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                </>
            )}
            <HeaderNav
                headings={[
                    {
                        label: "School Location & Catchment",
                        link: "#school",
                    },
                    {
                        label: "School Details",
                        link: "#details",
                    },
                    {
                        label: "Students",
                        link: "#students",
                    },
                    {
                        label: "NAPLAN",
                        link: "#naplan",
                    },
                    {
                        label: "Sold Properties",
                        link: "#sold",
                    },
                    {
                        label: "Listings for Sale",
                        link: "#sale",
                    },
                    {
                        label: "Listings for Rent",
                        link: "#rent",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pt-4 pb-4">
                {/* Breadcrumb */}
                <section id="breadcrumb" className="text-muted">
                    <Row>
                        <Col>
                            {school && la4_id && la2_id && la3_id && (
                                <>
                                    <Link to={"/schools/" + la4_id}>
                                        {titleCase(la4_id, "-")}
                                    </Link>
                                    <span className="px-2">&gt;</span>
                                    <Link
                                        to={"/schools/" + la4_id + "/" + la3_id}
                                    >
                                        {la3_name}
                                    </Link>
                                    <span className="px-2">&gt;</span>
                                    <Link
                                        to={
                                            "/schools/" +
                                            la4_id +
                                            "/" +
                                            la3_id +
                                            "/" +
                                            la2_id
                                        }
                                    >
                                        {la2_name}
                                    </Link>
                                </>
                            )}
                        </Col>
                    </Row>
                </section>
                {/* School Info */}
                <section id="school" className="pt-2 pb-3">
                    <Row>
                        <Col>
                            <h1 className="mb-3">{schoolName}</h1>
                        </Col>
                    </Row>
                    {school && school[0] && (
                        <>
                            <Row>
                                <Col>
                                    Address:{" "}
                                    {school[0].street +
                                        ", " +
                                        (school[0].town_suburb &&
                                            school[0].town_suburb !== null &&
                                            titleCase(
                                                school[0].town_suburb,
                                                " "
                                            ))}
                                </Col>
                            </Row>
                            {school[0].phone && (
                                <Row>
                                    <Col>Phone: {school[0].phone}</Col>
                                </Row>
                            )}
                            {school[0].school_email && (
                                <Row>
                                    <Col>
                                        Email:{" "}
                                        <a
                                            id="school-email-out"
                                            href={
                                                "mailto:" +
                                                school[0].school_email
                                            }
                                        >
                                            {school[0].school_email}
                                        </a>
                                    </Col>
                                </Row>
                            )}
                            {school[0].website && (
                                <Row>
                                    <Col>
                                        Web:{" "}
                                        <a
                                            id="school-link-out"
                                            href={school[0].website}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow"
                                        >
                                            {school[0].website}
                                        </a>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <Row className="mt-3">
                        <Col>
                            <small>
                                School data provided by: NSW Department of
                                Education
                            </small>
                        </Col>
                    </Row>
                    <Loading
                        loading={loading}
                        dataset={school}
                        datasetName="School"
                        errorMessage={errorMessage}
                    />
                </section>
                {school && latitude && longitude && (
                    <section id="catchment">
                        <Row className="mt-1">
                            <Col>
                                <h2>
                                    {school && school[0] && school[0].catchment
                                        ? "School Catchment Area"
                                        : "School Location"}
                                </h2>
                                {/* <Nav
                                className="justify-content-center flex-column flex-sm-row"
                                fill
                                variant="pills"
                                defaultActiveKey="all"
                                activeKey="current"
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={(e) =>
                                            this.handleClickNavSchoolTypes(
                                                "all",
                                                e
                                            )
                                        }
                                        eventKey="current"
                                    >
                                        Current Catchment
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={(e) =>
                                            this.handleClickNavSchoolTypes(
                                                "primary",
                                                e
                                            )
                                        }
                                        eventKey="future"
                                    >
                                        Future Catchment
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={(e) =>
                                            this.handleClickNavSchoolTypes(
                                                "primary",
                                                e
                                            )
                                        }
                                        eventKey="all"
                                    >
                                        Current + Future Catchment
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav> */}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <GoogleMapProvider>
                                    <MapPanel
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        height={"40vh"}
                                        zoom={13}
                                        enableZoom={true}
                                    />
                                    <Place
                                        key={school[0].school_code + "_place"}
                                        location={{
                                            lat: parseFloat(latitude),
                                            lng: parseFloat(longitude),
                                        }}
                                        text={schoolName}
                                        color={
                                            catchment_colours[school.file_type]
                                        }
                                        icon={<FaSchool />}
                                    />
                                    {school
                                        .filter(
                                            (catch_type) =>
                                                catch_type.file_type !==
                                                "FUTURE"
                                        )
                                        .map((catchment) => (
                                            <Geometry
                                                id={
                                                    schoolId.toString() +
                                                    "_" +
                                                    catchment.catch_type
                                                }
                                                key={
                                                    schoolId +
                                                    "_" +
                                                    catchment.catch_type +
                                                    "_geometry"
                                                }
                                                name={schoolName}
                                                geometry={
                                                    catchment.catchment &&
                                                    wkx.Geometry.parse(
                                                        catchment.catchment
                                                    ).toGeoJSON()
                                                }
                                                fill={
                                                    catchment_colours[
                                                        catchment.file_type
                                                    ]
                                                }
                                                index={catchment.file_type}
                                                location={{
                                                    lat: parseFloat(latitude),
                                                    lng: parseFloat(longitude),
                                                }}
                                            />
                                        ))}
                                </GoogleMapProvider>
                            </Col>
                        </Row>
                    </section>
                )}
                {school && <hr />}
                {school && (
                    <section id="details" className="pt-4 pb-2">
                        <Row>
                            <Col>
                                <h2>School Details</h2>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                <CardTableItemMobile
                                    label="Level"
                                    data={school[0].level_of_schooling}
                                />
                                <CardTableItemMobile
                                    label="Subtype"
                                    data={school[0].school_subtype}
                                />
                                <CardTableItemMobile
                                    label="Specialty"
                                    data={school[0].school_specialty_type}
                                />
                                <CardTableItemMobile
                                    label="Gender"
                                    data={school[0].school_gender}
                                />
                                <CardTableItemMobile
                                    label="Selective School"
                                    label_size={1.5}
                                    data={school[0].selective_school}
                                />
                                <CardTableItemMobile
                                    label="Opp. Class"
                                    label_size={1.5}
                                    superscript="1"
                                    data={
                                        school[0].oppotunity_class === "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                            </Col>
                            <Col md={1} lg={1} xl={1}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                <CardTableItemMobile
                                    label="Support Classes"
                                    label_size={1.5}
                                    data={
                                        school[0].support_classes === "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                                <CardTableItemMobile
                                    label="Distance Education"
                                    label_size={1.5}
                                    data={
                                        school[0].distance_education === "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                                <CardTableItemMobile
                                    label="Intensive English Centre"
                                    label_size={1.5}
                                    data={
                                        school[0].intensive_english_centre ===
                                        "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                                <CardTableItemMobile
                                    label="Late Opening School"
                                    label_size={1.5}
                                    data={
                                        school[0].late_opening_school === "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                                <CardTableItemMobile
                                    label="Healthy Canteen"
                                    label_size={1.5}
                                    data={
                                        school[0].healthy_canteen === "Y"
                                            ? "Yes"
                                            : "No"
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <Col>
                                <small>
                                    1. Opp. Class = Opportunity Classes, located
                                    in government primary schools cater for
                                    highly achieving Year 5 and Year 6
                                    academically gifted students.
                                </small>
                            </Col>
                        </Row>
                    </section>
                )}
                {school && <hr />}
                {school && (
                    <section id="students" className="pt-4 pb-2">
                        <Row>
                            <Col>
                                <h2>Students &#38; Community</h2>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                <CardTableItemMobile
                                    label="Enrolled Students"
                                    label_size={1.5}
                                    data={
                                        school[0].latest_year_enrolment_fte &&
                                        parseFloat(
                                            school[0].latest_year_enrolment_fte
                                        ).toFixed(1)
                                    }
                                />
                                <CardTableItemMobile
                                    label="ICSEA Value"
                                    label_size={1.5}
                                    superscript="2"
                                    data={
                                        school[0].icsea_value &&
                                        parseFloat(
                                            school[0].icsea_value
                                        ).toFixed(1)
                                    }
                                />
                                <CardTableItemMobile
                                    label="LBOTE %"
                                    label_size={1.5}
                                    superscript="3"
                                    data={
                                        school[0].lbote_pct &&
                                        school[0].lbote_pct + "%"
                                    }
                                />
                                <CardTableItemMobile
                                    label="Indigenous %"
                                    label_size={1.5}
                                    data={
                                        school[0].indigenous_pct &&
                                        school[0].indigenous_pct + "%"
                                    }
                                />
                            </Col>
                            <Col md={1} lg={1} xl={1}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                        </Row>
                        <Row className="pt-4">
                            <Col>
                                <small>
                                    2. ICSEA Value = Index of Socio-Educational
                                    Advantage. ICSEA provides an indication of
                                    the socio-educational backgrounds of
                                    students; it has nothing to do with the
                                    staff, school facilities or teaching
                                    programs at the school. More details:{" "}
                                    <a
                                        href="http://docs.acara.edu.au/resources/About_icsea_2014.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                    >
                                        ACARA
                                    </a>
                                    <br />
                                    3. LBOTE % = Language Background Other Than
                                    English. LBOTE is defined as one in whose
                                    home a language other than English is
                                    spoken.
                                </small>
                            </Col>
                        </Row>
                    </section>
                )}
                <hr />
                {/* Google ads */}
                <section>
                    <Row>
                        <Col>
                            <AdSense.Google
                                client="ca-pub-4439000183008596"
                                slot="8551552139"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                                layout="in-article"
                                format="fluid"
                            />
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Ads */}
                {/* <section className="pt-2 pb-2">
                    <DisplayAd limit={6} user={state.user} />
                </section>
                <hr /> */}
                {hasNaplan && (
                    <section id="naplan" className="pt-4">
                        <Row>
                            <Col>
                                <h2>NAPLAN</h2>
                                <p>
                                    NAPLAN is an annual assessment for all
                                    students in Years 3, 5, 7 and 9. The tests
                                    cover skills in reading, writing, spelling,
                                    grammar and punctuation, and numeracy. More
                                    details:{" "}
                                    <a
                                        href="https://www.nap.edu.au/about"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        https://www.nap.edu.au/about
                                    </a>
                                </p>
                            </Col>
                        </Row>
                        <Table responsive className="mt-3 naplan-table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Year</th>
                                    <th>Grade</th>
                                    <th className="text-center">Reading</th>
                                    <th className="text-center">Writing</th>
                                    <th className="text-center">Spelling</th>
                                    <th className="text-center">Grammar</th>
                                    <th className="text-center">Numeracy</th>
                                </tr>
                            </thead>
                            <tbody>
                                {naplan.map(
                                    (item) =>
                                        // ((!state.isAuthenticated &&
                                        //     item.Year ===
                                        //         new Date().getFullYear() - 1) ||
                                        state.isAuthenticated && (
                                            <Fragment
                                                key={
                                                    item.Year + "_" + item.Grade
                                                }
                                            >
                                                <tr
                                                    key={
                                                        item.Year +
                                                        "_" +
                                                        item.Grade +
                                                        "_stat"
                                                    }
                                                >
                                                    <td className="align-middle">
                                                        <strong>
                                                            {item.Year}
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle">
                                                        <strong>
                                                            {item.Grade}
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    color: compareAndColourCode(
                                                                        item.Reading,
                                                                        item.Reading_similar,
                                                                        item.Reading_all
                                                                    ),
                                                                }}
                                                                className="naplan-score"
                                                            >
                                                                {item.Reading}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    color: compareAndColourCode(
                                                                        item.Writing,
                                                                        item.Writing_similar,
                                                                        item.Writing_all
                                                                    ),
                                                                }}
                                                                className="naplan-score"
                                                            >
                                                                {item.Writing}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    color: compareAndColourCode(
                                                                        item.Spelling,
                                                                        item.Spelling_similar,
                                                                        item.Spelling_all
                                                                    ),
                                                                }}
                                                                className="naplan-score"
                                                            >
                                                                {item.Spelling}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    color: compareAndColourCode(
                                                                        item.Grammar,
                                                                        item.Grammar_similar,
                                                                        item.Grammar_all
                                                                    ),
                                                                }}
                                                                className="naplan-score"
                                                            >
                                                                {item.Grammar}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    color: compareAndColourCode(
                                                                        item.Numeracy,
                                                                        item.Numeracy_similar,
                                                                        item.Numeracy_all
                                                                    ),
                                                                }}
                                                                className="naplan-score"
                                                            >
                                                                {item.Numeracy}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr
                                                    key={
                                                        item.Year +
                                                        "_" +
                                                        item.Grade +
                                                        "_similar"
                                                    }
                                                    className="text-muted"
                                                >
                                                    <td></td>
                                                    <td>
                                                        <i>Similar Students</i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {
                                                                item.Reading_similar
                                                            }
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {
                                                                item.Writing_similar
                                                            }
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {
                                                                item.Spelling_similar
                                                            }
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {
                                                                item.Grammar_similar
                                                            }
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {
                                                                item.Numeracy_similar
                                                            }
                                                        </i>
                                                    </td>
                                                </tr>
                                                <tr
                                                    key={
                                                        item.Year +
                                                        "_" +
                                                        item.Grade +
                                                        "_all"
                                                    }
                                                    className="text-muted"
                                                >
                                                    <td></td>
                                                    <td>
                                                        <i>
                                                            All Australian
                                                            Students
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {item.Reading_all}
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {item.Writing_all}
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {item.Spelling_all}
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {item.Grammar_all}
                                                        </i>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <i>
                                                            {item.Numeracy_all}
                                                        </i>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )
                                )}
                            </tbody>
                        </Table>
                        {!state.isAuthenticated && (
                            <Alert
                                variant="primary"
                                className="mt-3 py-5 text-center"
                            >
                                <Alert.Heading>
                                    Sign up for free to view more NAPLAN scores
                                </Alert.Heading>
                                <Button
                                    className="mt-3 px-4 py-2"
                                    id="login-naplan"
                                    variant="warning"
                                    onClick={() => goToPage("signup")}
                                >
                                    Sign up
                                </Button>
                            </Alert>
                        )}
                    </section>
                )}
                {hasNaplan && <hr />}
                {/* Sold Properties */}
                {school && school[0] && latitude && longitude && (
                    <section id="sold" className="pt-4">
                        <Row>
                            <Col>
                                <h2>Sold Properties</h2>
                            </Col>
                        </Row>
                        {schoolName &&
                            listingState.count &&
                            listingState.median && (
                                <Row className="mt-2 mb-4">
                                    <Col>
                                        Recently Sold Properties{" "}
                                        {school[0].catchment
                                            ? "within the catchment area of "
                                            : "that are close to "}
                                        {schoolName}
                                    </Col>
                                </Row>
                            )}
                        <Row className="mt-3">
                            <Col>
                                <PropertiesContext.Provider
                                    value={{ listingState, dispatch }}
                                >
                                    <ComparableProperties
                                        cardType="sold"
                                        latitude={latitude}
                                        longitude={longitude}
                                        limitOverride={6}
                                        withinBoundary={
                                            school &&
                                            school[0] &&
                                            school[0].catchment
                                        }
                                    ></ComparableProperties>
                                </PropertiesContext.Provider>
                            </Col>
                        </Row>
                    </section>
                )}
                {latitude && longitude && <hr />}
                {/* Sales Listings */}
                {latitude && longitude && (
                    <section id="sale" className="pt-4">
                        <Row>
                            <Col>
                                <h2>Sales Listings</h2>
                                {schoolName && (
                                    <p>
                                        Current properties listed for Sale{" "}
                                        {school[0].catchment
                                            ? "within the catchment area of "
                                            : "that are close to "}
                                        {schoolName}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                {state.isAuthenticated &&
                                state.userPlan === "Pro" ? (
                                    <ComparableProperties
                                        cardType="sale"
                                        latitude={latitude}
                                        longitude={longitude}
                                        withinBoundary={
                                            school &&
                                            school[0] &&
                                            school[0].catchment
                                        }
                                    ></ComparableProperties>
                                ) : (
                                    <Alert
                                        variant="primary"
                                        className="mt-3 py-5 text-center"
                                    >
                                        <Alert.Heading>
                                            {!state.isAuthenticated
                                                ? "Login"
                                                : "Subscription"}{" "}
                                            required to view Sales Listings
                                        </Alert.Heading>
                                        <p className="mt-5">
                                            Similar to the Sold Properties
                                            section above, this section provides
                                            Sales Listings for properties
                                            currently on the market.
                                        </p>
                                        <Button
                                            className="mt-3 px-4 py-2"
                                            variant="warning"
                                            id="login-sales"
                                            onClick={() =>
                                                state.isAuthenticated
                                                    ? goToPage("pricing")
                                                    : goToPage("login")
                                            }
                                        >
                                            {state.isAuthenticated
                                                ? "Subscribe"
                                                : "Login"}
                                        </Button>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    </section>
                )}
                {latitude && longitude && <hr />}
                <section id="compare-home-loans" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Mortgage Repayments Calculator</h2>
                        </Col>
                    </Row>
                    <MortgageRepaymentCalculator
                        propertyPrice={listingState.median}
                    />
                </section>
                <hr />
                {/* Rental Listings */}
                {latitude && longitude && (
                    <section id="rent" className="pt-4 pb-5">
                        <Row>
                            <Col>
                                <h2>Rental Listings</h2>
                                {schoolName && (
                                    <p>
                                        Current properties listed for Rent{" "}
                                        {school[0].catchment
                                            ? "within the catchment area of "
                                            : "that are close to "}
                                        {schoolName}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                {state.isAuthenticated ? (
                                    <ComparableProperties
                                        cardType="rent"
                                        latitude={latitude}
                                        longitude={longitude}
                                        withinBoundary={
                                            school &&
                                            school[0] &&
                                            school[0].catchment
                                        }
                                    ></ComparableProperties>
                                ) : (
                                    <Alert
                                        variant="primary"
                                        className="mt-3 py-5 text-center"
                                    >
                                        <Alert.Heading>
                                            {!state.isAuthenticated
                                                ? "Login"
                                                : "Subscription"}{" "}
                                            required to view Rental Listings
                                        </Alert.Heading>
                                        <p className="mt-5">
                                            Similar to the Sold Properties
                                            section above, this section provides
                                            Rental Listings for properties
                                            currently on the market.
                                        </p>
                                        <Button
                                            className="mt-3 px-4 py-2"
                                            variant="warning"
                                            id="login-rental"
                                            onClick={() =>
                                                state.isAuthenticated
                                                    ? goToPage("pricing")
                                                    : goToPage("login")
                                            }
                                        >
                                            {state.isAuthenticated
                                                ? "Subscribe"
                                                : "Login"}
                                        </Button>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    </section>
                )}
            </Container>
        </Fragment>
    );
}
