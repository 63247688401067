/* eslint-disable no-console */
import React, {
    useState,
    useEffect,
    Fragment,
    useContext,
    useCallback,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getUA } from "react-device-detect";
import wkx from "wkx";
import { point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import bbox from "@turf/bbox";
import distance from "@turf/distance";

import Place from "./map/Place";
import MultiPlace, { clusterMarkers } from "./map/MultiPlace";
import PropertyCard from "./PropertyCard";
import {
    getPrices,
    // filterPropertiesByType,
    // groupPropertiesByType,
    // groupPropertiesByQuarterlyDate,
} from "../Helpers";
import Loading from "./Loading";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FaComponent from "./forms/FaComponent";
import DropdownForm from "./forms/DropdownForm";
import PropertyTypeDropdownForm from "./forms/PropertyTypeDropdownForm";

import { PropertiesContext } from "../state/PropertiesContext";

// import ListingSummary from "./ListingSummary";
// import ListingMedianTrend from "./ListingMedianTrend";

import {
    addAndSortByDistance,
    addressStripCountry,
    quantile,
    LOWER_QUARTILE,
    MEDIAN,
    UPPER_QUARTILE,
    moneyFormatter,
} from "../Helpers";
import orderBy from "lodash/orderBy";

let domain_listings_url =
    process.env.REACT_APP_DOMAIN_API_URL +
    "/search?page_size=100&raw_price=true";

let properties_url =
    process.env.REACT_APP_LIVEABLE_PROPERTIES_API_URL + "/get_properties/";

let properties_by_street_url =
    process.env.REACT_APP_LIVEABLE_PROPERTIES_API_URL +
    "/get_properties_by_street/";

let properties_listings_url =
    process.env.REACT_APP_LIVEABLE_PROPERTIES_API_URL + "/listings/";

const PRERENDER_LIMIT = 120; // cards to show per pagination for prerender
// const MAX_LIMIT = 48; // max cards to show, todo: update to the actual response filters?
// const minZoomLevel = 16; // minimum zoom level to filter out overlapping markers
const geoHashLevel = 8; // geohash length, can 1-12. 12 is most accurate

const type_colours = {
    sold: "#ffa800",
    sale: "#315990",
    rent: "green",
};

const dwelling_type_mapping = {
    AcreageSemiRural: "land",
    ApartmentUnitFlat: "apartment",
    BlockOfUnits: "apartment",
    Commercial: "commercial",
    DevelopmentSite: "land",
    Duplex: "semidetached",
    Farm: "farm",
    House: "house",
    Industrial: "industrial",
    MixedFarming: "farm",
    Land: "land",
    NewApartments: "apartment",
    NewHomeDesigns: "house",
    NewHouseLand: "house",
    NewLand: "land",
    Penthouse: "apartment",
    Rural: "land",
    RuralLifestyle: "land",
    SemiDetached: "semidetached",
    SpecialistFarm: "farm",
    Studio: "apartment",
    Terrace: "terrace",
    Townhouse: "townhouse",
    Villa: "house",
    VacantLand: "land",
};

const dwelling_types = {
    acreagesemirural: "other",
    apartment: "apartment",
    apartmentunitflat: "apartment",
    blockofunits: "apartment",
    commercial: "other",
    developmentsite: "other",
    duplex: "house",
    farm: "other",
    house: "house",
    industrial: "other",
    mixedfarming: "other",
    land: "other",
    newapartments: "apartment",
    newhomedesigns: "house",
    newhouseland: "house",
    newland: "other",
    penthouse: "apartment",
    rural: "other",
    rurallifestyle: "other",
    semidetached: "house",
    specialistfarm: "farm",
    studio: "apartment",
    terrace: "house",
    townhouse: "townhouse",
    villa: "house",
    vacantland: "other",
};

const MIN_HANDLE_IDX = 0;
const MAX_HANDLE_IDX = 1;

const MAX_BED = 5;
const MAX_BATH = 4;
const MAX_CAR = 4;
const RADIUS = process.env.REACT_APP_SOLD_RADIUS;
const RADIUS_PRERENDER = process.env.REACT_APP_PRERENDER_RADIUS;
const SOLD_AGE_MONTHS = process.env.REACT_APP_SOLD_AGE_MONTHS;
const PAGE_SIZE = 200;

const domain = {
    bed: [0, MAX_BED],
    bath: [0, MAX_BATH],
    car: [0, MAX_CAR],
};

const descriptions = {
    bed: "Bedrooms",
    bath: "Bathrooms",
    car: "Cars",
};

// const property_types = {
//     House: "house",
//     Apartment: "apartment",
//     Townhouse: "townhouse",
// };

const KeyStatsHeader = styled.div`
    text-align: center;
}`;

const KeyStats = styled.div`
    text-align: center;
    font-weight: bolder;
    font-size: 1.2em;
}`;

ListingPanel.propTypes = {
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    cardType: PropTypes.string.isRequired,
    numBed: PropTypes.number,
    numBath: PropTypes.number,
    numCar: PropTypes.number,
    dwellingType: PropTypes.string,
    months: PropTypes.number,
    addressSlug: PropTypes.string,
    filterStreet: PropTypes.bool,
    limitOverride: PropTypes.number,
    withinBoundary: PropTypes.string,
    hideShowMore: PropTypes.bool,
    agencyOrAgent: PropTypes.string,
    agencyOrAgentId: PropTypes.number,
};

export default function ListingPanel(props) {
    let defaultLimit = props.limitOverride || 12; // cards to show per pagination

    const [properties, setProperties] = useState([]);
    const [groupedProperties, setGroupedProperties] = useState([]);
    const [count, setCount] = useState(0);
    const [location, setLocation] = useState({
        latitude: props.latitude,
        longitude: props.longitude,
    });
    const [types, setTypes] = useState(
        props.dwellingType ? [props.dwellingType] : []
    );
    const [prices, setPrices] = useState([]);
    const [median, setMedian] = useState(undefined);
    const [lowerQuantile, setLowerQuantile] = useState(undefined);
    const [upperQuantile, setUpperQuantile] = useState(undefined);

    const [filters, setFilters] = useState({
        bed: {
            min: props.numBed ? Math.round(props.numBed / 2 + 0.5) : null,
            max: props.numBed ? props.numBed : null,
        },
        bath: {
            min: props.numBath ? Math.round(props.numBath / 2) : null,
            max: props.numBath ? props.numBath : null,
        },
        car: {
            min: props.numCar ? Math.round(props.numCar / 2) : null,
            max: props.numCar ? props.numCar : null,
        },
    });
    const [radius, setRadius] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const [limit, setLimit] = useState(
        props.limitOverride
            ? props.limitOverride
            : getUA.includes("Prerender")
            ? PRERENDER_LIMIT
            : defaultLimit
    );

    const [months] = useState(props.months ? props.months : SOLD_AGE_MONTHS);
    const [defaultLoad, setDefaultLoad] = useState(true);

    const context = useContext(PropertiesContext);

    /*
        dispatch comes from a custom hook so it doesn't have an stable signature therefore will 
        change every render. Add an additional layer of dependencies by wrapping the handler inside an useCallback hook 
    */
    const stableDispatch = useCallback(
        context && context.dispatch ? context.dispatch : () => {},
        []
    );

    /**
     * Optional dispatch to update the properties in parent component (StreeProfile)
     */
    useEffect(() => {
        if (
            properties &&
            typeof median !== "undefined" &&
            !isNaN(median) &&
            typeof lowerQuantile !== "undefined" &&
            !isNaN(lowerQuantile) &&
            typeof upperQuantile !== "undefined" &&
            !isNaN(upperQuantile)
        ) {
            stableDispatch({
                properties: properties,
                median: median,
                lower_quantile: lowerQuantile,
                upper_quantile: upperQuantile,
                count: prices.length,
            });
        }
    }, [
        prices,
        properties,
        median,
        lowerQuantile,
        upperQuantile,
        stableDispatch,
    ]);

    useEffect(() => {
        setProperties([]);

        if (props.withinBoundary) {
            let boundingBox = bbox(
                wkx.Geometry.parse(props.withinBoundary).toGeoJSON()
            );
            let bboxRadius =
                (distance(
                    point([boundingBox[0], boundingBox[1]]),
                    point([boundingBox[2], boundingBox[3]]),
                    { units: "kilometers" }
                ) /
                    2) *
                1000;

            if (bboxRadius > 2500) {
                setRadius(2500);
            } else {
                setRadius(parseInt(bboxRadius));
            }
        } else {
            setRadius(
                getUA.includes("Prerender")
                    ? RADIUS_PRERENDER
                    : props.agencyOrAgent
                    ? 1000
                    : RADIUS
            );
        }

        if (radius) {
            fetchProperties();
        }

        function fetchProperties() {
            const location_str =
                "&longitude=" +
                location.longitude.toFixed(6) +
                "&latitude=" +
                location.latitude.toFixed(6);

            let places_url = undefined;
            setLoading(true);
            setError("");

            if (
                props.agencyOrAgent &&
                props.agencyOrAgentId &&
                props.cardType
            ) {
                // Handle agency/agent listings
                places_url =
                    properties_listings_url +
                    props.cardType +
                    "/" +
                    props.agencyOrAgent +
                    "/" +
                    props.agencyOrAgentId;
            } else if (props.cardType === "sold") {
                places_url =
                    properties_url +
                    "?radius=" +
                    radius +
                    "&age_in_months=" +
                    months +
                    location_str;

                if (props.filterStreet) {
                    // override places_url for street
                    places_url = properties_by_street_url + props.addressSlug;
                }
            } else {
                const listing_status = "&listing_status=" + props.cardType;
                const page_size = "&page_size=" + PAGE_SIZE;

                places_url =
                    domain_listings_url +
                    "&radius=" +
                    radius +
                    location_str +
                    listing_status +
                    page_size;
            }
            // console.log(places_url);

            return fetch(places_url)
                .then((response) => response.json())
                .then((data) => {
                    let propertyList = [];

                    if (
                        !props.agencyOrAgent &&
                        (props.cardType === "sale" || props.cardType === "rent")
                    ) {
                        propertyList = data.listing;
                    } else {
                        propertyList = data.result;
                    }

                    // if boundary provided, filter out properties outside boundary
                    if (props.withinBoundary) {
                        propertyList = propertyList.filter((prop) => {
                            return booleanPointInPolygon(
                                point([
                                    props.cardType === "sold"
                                        ? prop.lng
                                        : prop.longitude,
                                    props.cardType === "sold"
                                        ? prop.lat
                                        : prop.latitude,
                                ]),
                                wkx.Geometry.parse(
                                    props.withinBoundary
                                ).toGeoJSON()
                            );
                        });
                    }

                    if (props.cardType === "sold") {
                        if (props.filterStreet) {
                            setProperties(
                                orderBy(propertyList, ["addressSlug"], ["asc"])
                            );
                        } else {
                            setProperties(
                                orderBy(
                                    addAndSortByDistance(
                                        propertyList.filter((object) => {
                                            return (
                                                object.addressSlug !==
                                                props.addressSlug
                                            );
                                        }),
                                        location.latitude,
                                        location.longitude
                                    ),
                                    ["distance", "lastContractDate"],
                                    ["asc", "desc"]
                                )
                            );
                        }
                    } else {
                        // Handle agency/agent listings
                        if (props.agencyOrAgent && props.agencyOrAgentId) {
                            setProperties(propertyList);
                        } else {
                            setProperties(
                                addAndSortByDistance(
                                    propertyList,
                                    location.latitude,
                                    location.longitude
                                )
                            );
                        }
                    }
                    setCount(propertyList ? propertyList.length : 0);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [
        location,
        radius,
        months,
        props.withinBoundary,
        props.addressSlug,
        props.filterStreet,
        props.cardType,
        props.agencyOrAgent,
        props.agencyOrAgentId,
    ]);

    useEffect(() => {
        if (
            props.latitude !== location.latitude &&
            props.longitude !== location.longitude
        ) {
            setLocation({
                latitude: props.latitude,
                longitude: props.longitude,
            });
        }
    }, [props.latitude, props.longitude, location]);

    useEffect(() => {
        filterProperties();

        function filterProperties() {
            // console.log("filterProperties", properties, filters, groupedProperties);
            let properties_count = properties.length;
            if (properties) {
                let filtered_properties = [...properties];
                // console.log(defaultLoad);

                // Filter sold properties client side
                if (
                    filters.bed.min !== null ||
                    filters.bath.min !== null ||
                    filters.car.min !== null ||
                    (typeof types !== "undefined" && types.length > 0)
                ) {
                    filtered_properties = filtered_properties.filter(
                        (listing) => showListing(listing)
                    );

                    // Show all the properties if the filters return 0 by default
                    if (
                        defaultLoad &&
                        filtered_properties.length === 0 &&
                        properties_count !== 0
                    ) {
                        filtered_properties = [...properties];
                        setError(
                            "Showing All Properties given no Comparable Property matches"
                        );
                    }

                    properties_count = filtered_properties.length;
                }

                // Group markers
                setGroupedProperties(
                    clusterMarkers(filtered_properties, limit, geoHashLevel)
                );

                // get the prices to calculate median
                const filtered_prices = getPrices(
                    filtered_properties,
                    props.cardType
                );
                setPrices(filtered_prices);
                setMedian(quantile(filtered_prices, MEDIAN));
                setLowerQuantile(quantile(filtered_prices, LOWER_QUARTILE));
                setUpperQuantile(quantile(filtered_prices, UPPER_QUARTILE));
                setCount(properties_count);
            }
        }

        /**
         * Filter out property listing based on search params
         * @param {*} listing
         */
        function showListing(listing) {
            let show = true;

            const numBed =
                props.cardType === "sold" ||
                (props.agencyOrAgent && props.agencyOrAgentId)
                    ? listing.numBed
                    : listing.num_bedrooms;
            const numBath =
                props.cardType === "sold" ||
                (props.agencyOrAgent && props.agencyOrAgentId)
                    ? listing.numBath
                    : listing.num_bathrooms;
            const numCar =
                props.cardType === "sold" ||
                (props.agencyOrAgent && props.agencyOrAgentId)
                    ? listing.numCar
                    : listing.num_parking_spaces;

            const dwellingType =
                props.cardType === "sold" ||
                (props.agencyOrAgent && props.agencyOrAgentId)
                    ? listing.dwellingType
                        ? listing.dwellingType
                        : undefined
                    : listing.property_type
                    ? listing.property_type
                    : undefined;

            // bed, but no bath/car =>filter beds but ignore bath/car
            if (
                filters.bed.min !== null &&
                filters.bath.min === null &&
                filters.car.min === null
            ) {
                show =
                    (numBed >= filters.bed.min && numBed <= filters.bed.max) ||
                    (numBed >= MAX_BED && filters.bed.max === MAX_BED) ||
                    (filters.bed.min === 0 && numBed === 0);
            }
            // bed, bath no/car => filter beds, bath but ignore car
            else if (
                filters.bed.min !== null &&
                filters.bath.min !== null &&
                filters.car.min === null
            ) {
                show =
                    ((numBed >= filters.bed.min && numBed <= filters.bed.max) ||
                        (numBed >= MAX_BED && filters.bed.max === MAX_BED) ||
                        (filters.bed.min === 0 && numBed === 0)) &&
                    ((numBath >= filters.bath.min &&
                        numBath <= filters.bath.max) ||
                        (numBath >= MAX_BATH &&
                            filters.bath.max === MAX_BATH) ||
                        (filters.bath.min === 0 && numBath === 0));
            }
            //  filter car
            else if (
                filters.bed.min === null &&
                filters.bath.min === null &&
                filters.car.min !== null
            ) {
                show =
                    (numCar >= filters.car.min && numCar <= filters.car.max) ||
                    (numCar >= MAX_CAR && filters.car.max === MAX_CAR) ||
                    (filters.car.min === 0 && numCar === 0);
            } else if (
                filters.bed.min === null &&
                filters.bath.min !== null &&
                filters.car.min === null
            ) {
                show =
                    (numBath >= filters.bath.min &&
                        numBath <= filters.bath.max) ||
                    (numBath >= MAX_BATH && filters.bath.max === MAX_BATH) ||
                    (filters.bath.min === 0 && numBath === 0);
            } else if (
                filters.bed.min === null &&
                filters.bath.min !== null &&
                filters.car.min !== null
            ) {
                show =
                    ((numBath >= filters.bath.min &&
                        numBath <= filters.bath.max) ||
                        (numBath >= MAX_BATH &&
                            filters.bath.max === MAX_BATH) ||
                        (filters.bath.min === 0 && numBath === 0)) &&
                    ((numCar >= filters.car.min && numCar <= filters.car.max) ||
                        (numCar >= MAX_CAR && filters.car.max === MAX_CAR) ||
                        (filters.car.min === 0 && numCar === 0));
            } else if (
                filters.bed.min !== null &&
                filters.bath.min === null &&
                filters.car.min !== null
            ) {
                show =
                    ((numBed >= filters.bed.min && numBed <= filters.bed.max) ||
                        (numBed >= MAX_BED && filters.bed.max === MAX_BED) ||
                        (filters.bed.min === 0 && numBed === 0)) &&
                    ((numCar >= filters.car.min && numCar <= filters.car.max) ||
                        (numCar >= MAX_CAR && filters.car.max === MAX_CAR) ||
                        (filters.car.min === 0 && numCar === 0));
            }
            //  filter beds, bath and car
            else if (
                filters.bed.min !== null &&
                filters.bath.min !== null &&
                filters.car.min !== null
            ) {
                show =
                    ((numBed >= filters.bed.min && numBed <= filters.bed.max) ||
                        (numBed >= MAX_BED && filters.bed.max === MAX_BED) ||
                        (filters.bed.min === 0 && numBed === 0)) &&
                    ((numBath >= filters.bath.min &&
                        numBath <= filters.bath.max) ||
                        (numBath >= MAX_BATH &&
                            filters.bath.max === MAX_BATH) ||
                        (filters.bath.min === 0 && numBath === 0)) &&
                    ((numCar >= filters.car.min && numCar <= filters.car.max) ||
                        (numCar >= MAX_CAR && filters.car.max === MAX_CAR) ||
                        (filters.car.min === 0 && numCar === 0));
            }

            if (typeof types !== "undefined" && types.length > 0 && show) {
                show =
                    (dwellingType &&
                        types.includes(
                            dwelling_types[dwellingType.toLowerCase()]
                        )) ||
                    (types.includes("other") &&
                        dwellingType &&
                        !Object.keys(dwelling_types).includes(
                            dwellingType.toLowerCase()
                        ) &&
                        !Object.keys(dwelling_type_mapping).includes(
                            dwellingType
                        )) ||
                    (types.includes("other") &&
                        (!dwellingType || dwellingType === ""));
            }

            return show;
        }
    }, [
        properties,
        filters,
        types,
        limit,
        props.cardType,
        props.agencyOrAgent,
        props.agencyOrAgentId,
        defaultLoad,
    ]);

    useEffect(() => {
        if (props.dwellingType) {
            setTypes([props.dwellingType]);
        }

        if (props.numBed || props.numBath || props.numCar) {
            setFilters({
                bed: {
                    min: props.numBed ? props.numBed : null,
                    max: props.numBed ? props.numBed : null,
                },
                bath: {
                    min: props.numBath ? props.numBath : null,
                    max: props.numBath ? props.numBath : null,
                },
                car: {
                    min: props.numCar ? props.numCar : null,
                    max: props.numCar ? props.numCar : null,
                },
            });
        }
    }, [props.numBed, props.numBath, props.numCar, props.dwellingType]);

    /**
     * callback function to update the handle values of the bed/bath/car dropdown filters
     */
    function handleSliderValues(values, handle_id) {
        let temp_filters = { ...filters };
        temp_filters[handle_id] = {
            min: values[MIN_HANDLE_IDX],
            max: values[MAX_HANDLE_IDX],
        };

        setFilters(temp_filters);
        setDefaultLoad(false);
        setError("");
    }

    function handleTypes(types) {
        setTypes(types);
        setDefaultLoad(false);
        setError("");
    }

    function clearFilter() {
        setTypes([]);
        setFilters({
            bed: {
                min: null,
                max: null,
            },
            bath: {
                min: null,
                max: null,
            },
            car: {
                min: null,
                max: null,
            },
        });
        setDefaultLoad(false);
        setError("");
    }

    function handleClickButtonShowMore() {
        setLimit(limit + defaultLimit);
    }

    // function showSummaries(groupedProperties) {
    //     let summaries = [];

    //     Object.keys(property_types).forEach((t) => {
    //         if (
    //             Object.prototype.hasOwnProperty.call(
    //                 groupedProperties,
    //                 property_types[t]
    //             )
    //         ) {
    //             summaries.push(
    //                 <Col key={property_types[t]}>
    //                     <ListingSummary
    //                         properties={groupedProperties[property_types[t]]}
    //                         dwellingType={t}
    //                         handleSliderValues={() => handleSliderValues}
    //                         handleTypes={() => handleTypes}
    //                     />
    //                 </Col>
    //             );
    //         }
    //     });

    //     return <Row className="mt-2">{summaries}</Row>;
    // }

    // function getMedians(properties) {
    //     // let groupedProperties = {};
    //     let groupedMedians = {};
    //     let groupedPropertiesQuarterly = {};

    //     if (props.cardType === "sold") {
    //         //todo group by dwelling type

    //         let dwellingTypes = ["house", "apartment"];

    //         dwellingTypes.forEach((dwellingType) => {
    //             let medians = {};
    //             // let dwellingType = "house";

    //             groupedPropertiesQuarterly[
    //                 dwellingType
    //             ] = groupPropertiesByQuarterlyDate(
    //                 filterPropertiesByType(properties, dwellingType),
    //                 SOLD_AGE_MONTHS
    //             );

    //             // console.log(groupedPropertiesQuarterly);

    //             // Calculate grouped median prices
    //             let quarterlyMedian = 0;
    //             Object.keys(groupedPropertiesQuarterly[dwellingType]).forEach(
    //                 (quarterKey) => {
    //                     if (
    //                         groupedPropertiesQuarterly[dwellingType][quarterKey]
    //                             .length > 0
    //                     ) {
    //                         const quarterlyPrices = getPrices(
    //                             groupedPropertiesQuarterly[dwellingType][
    //                                 quarterKey
    //                             ],
    //                             props.cardType
    //                         );
    //                         let thisQuarterlyMedian = median(quarterlyPrices);
    //                         const medianChange =
    //                             quarterlyMedian !== 0
    //                                 ? (
    //                                       (100 *
    //                                           (thisQuarterlyMedian -
    //                                               quarterlyMedian)) /
    //                                       quarterlyMedian
    //                                   ).toFixed(1)
    //                                 : 0;

    //                         if (medianChange) {
    //                             medians[quarterKey] = {
    //                                 median: thisQuarterlyMedian,
    //                                 change: medianChange,
    //                                 count: quarterlyPrices.length,
    //                             };
    //                         }
    //                         // console.log(
    //                         //     medians,
    //                         //     quarterKey,
    //                         //     quarterlyMedian,
    //                         //     thisQuarterlyMedian,
    //                         //     medianChange,
    //                         //     quarterlyPrices.length
    //                         // );

    //                         quarterlyMedian = thisQuarterlyMedian;
    //                     }
    //                 }
    //             );
    //             // console.log(medians);

    //             groupedMedians[dwellingType] = medians;
    //         });
    //     }

    //     return groupedMedians;
    // }

    return (
        <Fragment>
            <Row className="justify-content-center mt-2">
                {Object.keys(domain).map((l) => (
                    <DropdownForm
                        key={l}
                        id={l}
                        values={filters[l]}
                        handleSliderValues={handleSliderValues}
                        domain={domain[l]}
                    >
                        {<FaComponent tag={l} />} {" " && descriptions[l]}
                    </DropdownForm>
                ))}

                <PropertyTypeDropdownForm
                    key={"property"}
                    types={types}
                    handleTypes={handleTypes}
                >
                    {<FaComponent tag="building" />} {" Property Types"}
                </PropertyTypeDropdownForm>

                <Button variant="light" onClick={clearFilter}>
                    Clear
                </Button>
            </Row>

            <Loading
                loading={loading}
                errorMessage={error}
                dataset={groupedProperties}
                datasetName="Properties"
            />

            {groupedProperties.length > 0 && (
                <>
                    <Row className="justify-content-center mt-3">
                        <Col xs={0} sm={2} md={3} lg={4} xl={4} />
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <KeyStatsHeader>{"Median Price"}</KeyStatsHeader>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <KeyStatsHeader>
                                {"Total "}
                                {props.cardType === "sold"
                                    ? "Sales"
                                    : "Listings"}
                            </KeyStatsHeader>
                        </Col>
                        {/* <Col xs={2}>
             <KeyStatsHeader>
                 {"Median Quarterly Growth"}
             </KeyStatsHeader>
         </Col> */}
                        <Col xs={0} sm={2} md={3} lg={4} xl={4} />
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={0} sm={2} md={3} lg={4} xl={4} />
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <KeyStats>
                                {median ? moneyFormatter.format(median) : "-"}
                            </KeyStats>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <KeyStats>{count}</KeyStats>
                        </Col>

                        {/* <Col xs={2}>
             <KeyStats>
                 {Object.keys(medians).length > 0
                     ? moneyFormatter.format(
                           medians[
                               Object.keys(medians)[
                                   Object.keys(medians)
                                       .length - 1
                               ]
                           ].median
                       ) +
                       " (" +
                       medians[
                           Object.keys(medians)[
                               Object.keys(medians).length -
                                   1
                           ]
                       ].change +
                       ")"
                     : "-"}
             </KeyStats>
         </Col> */}
                        <Col xs={0} sm={2} md={3} lg={4} xl={4} />
                    </Row>

                    <Row className="justify-content-center mt-2">
                        <small>
                            {groupedProperties.length} out of{" "}
                            <strong>{count}</strong> properties shown
                        </small>
                    </Row>
                    <Row className="mt-2">
                        {groupedProperties
                            .slice(0, limit)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={4}
                                        xl={4}
                                        className="mt-2 mb-2"
                                        key={"card_" + index}
                                    >
                                        {props.cardType === "sold" ||
                                        (props.agencyOrAgent &&
                                            props.agencyOrAgentId) ? (
                                            <PropertyCard
                                                key={
                                                    "PropertyCard_" +
                                                    item.index +
                                                    "_" +
                                                    index
                                                }
                                                index={item.cardText}
                                                type={item.numBed}
                                                cardType={props.cardType}
                                                colour={
                                                    type_colours[props.cardType]
                                                }
                                                addressSlug={item.addressSlug}
                                                address={
                                                    item.formattedAddress &&
                                                    addressStripCountry(
                                                        item.formattedAddress
                                                    )
                                                }
                                                dwellingType={
                                                    item.dwellingType
                                                        ? item.dwellingType
                                                        : ""
                                                }
                                                numBed={item.numBed}
                                                numBath={item.numBath}
                                                numCar={item.numCar}
                                                area={item.area}
                                                areaType={item.areaType}
                                                price={
                                                    props.agencyOrAgent &&
                                                    props.agencyOrAgentId
                                                        ? item.listingDetails[0]
                                                              .rawPrice
                                                        : item.sold
                                                        ? item.sold[0]
                                                              .purchasePrice
                                                        : ""
                                                }
                                                distance={
                                                    props.filterStreet
                                                        ? ""
                                                        : item.distance
                                                }
                                                lastContractDate={
                                                    item.sold
                                                        ? item.sold[0]
                                                              .contractDate
                                                        : ""
                                                }
                                                images={item.images}
                                                lotNumber={item.lotNumber}
                                                latitude={item.lat}
                                                longitude={item.lng}
                                                agencyOrAgent={
                                                    props.agencyOrAgent
                                                }
                                            />
                                        ) : (
                                            <PropertyCard
                                                key={
                                                    "PropertyCard_" +
                                                    item.index +
                                                    "_" +
                                                    index
                                                }
                                                index={item.cardText}
                                                type={item.num_bedrooms}
                                                cardType={props.cardType}
                                                colour={
                                                    type_colours[props.cardType]
                                                }
                                                address={
                                                    item.displayable_address
                                                }
                                                dwellingType={
                                                    dwelling_type_mapping[
                                                        item.property_type
                                                    ]
                                                }
                                                numBed={item.num_bedrooms}
                                                numBath={item.num_bathrooms}
                                                numCar={item.num_parking_spaces}
                                                price={item.price}
                                                distance={
                                                    props.filterStreet
                                                        ? ""
                                                        : item.distance
                                                }
                                                images={item.images}
                                                latitude={
                                                    item.latitude
                                                        ? item.latitude
                                                        : item.lat
                                                }
                                                longitude={
                                                    item.longitude
                                                        ? item.longitude
                                                        : item.lng
                                                }
                                                details_url={item.details_url}
                                                agencyOrAgent={
                                                    props.agencyOrAgent
                                                }
                                            />
                                        )}
                                    </Col>
                                    {item.showMarker &&
                                        (item.multi ? (
                                            <MultiPlace
                                                location={{
                                                    lat: item.latitude
                                                        ? item.latitude
                                                        : item.lat,
                                                    lng: item.longitude
                                                        ? item.longitude
                                                        : item.lng,
                                                }}
                                                color={
                                                    type_colours[props.cardType]
                                                }
                                                index={item.markerText}
                                                fitBounds={true}
                                                width={"40px"}
                                                radius={"2px"}
                                                zindex={100 + index}
                                                multiPlace={item.multiPlace}
                                                key={
                                                    "multiPlace" +
                                                    item.listing_id +
                                                    "_" +
                                                    item.index +
                                                    "_" +
                                                    index
                                                }
                                            />
                                        ) : (
                                            <Place
                                                key={
                                                    "Place_" +
                                                    item.listing_id +
                                                    "_" +
                                                    item.index +
                                                    "_" +
                                                    index
                                                }
                                                location={{
                                                    lat: item.latitude
                                                        ? item.latitude
                                                        : item.lat,
                                                    lng: item.longitude
                                                        ? item.longitude
                                                        : item.lng,
                                                }}
                                                color={
                                                    type_colours[props.cardType]
                                                }
                                                index={item.markerText}
                                                fitBounds={true}
                                                zindex={(index + 1) * 10}
                                            />
                                        ))}
                                </Fragment>
                            ))}
                    </Row>
                </>
            )}
            {props.cardType !== "sold" && !props.agencyOrAgent && (
                <Row className="mt-4">
                    <Col className="text-center">
                        <div>
                            <img
                                style={{
                                    display: "block",
                                    margin: "auto auto",
                                }}
                                src="https://s3-ap-southeast-2.amazonaws.com/liveable.co/images/powered-by-domain-rgb.png"
                                alt="Powered by Domain"
                                loading="lazy"
                            />
                        </div>
                    </Col>
                </Row>
            )}

            {!props.hideShowMore &&
                groupedProperties.length > 0 &&
                groupedProperties.length >= limit &&
                limit < count && (
                    <Row className="mt-4">
                        <Col className="text-center">
                            <Button
                                id={props.cardType + "-show-more"}
                                variant="outline-primary"
                                onClick={() => handleClickButtonShowMore()}
                            >
                                Show More
                            </Button>
                        </Col>
                    </Row>
                )}
        </Fragment>
    );
}
