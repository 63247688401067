import React from "react";
import PropTypes from "prop-types";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Login from "../../components/userManagement/Login";

LoginPage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.shape({
        path: PropTypes.string,
    }),
};

export default function LoginPage(props) {
    return (
        <Container>
            <Row className="mt-4">
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Login
                        formType={
                            props.match.path
                                ? props.match.path.replace("/", "")
                                : "login"
                        }
                        location={props.location}
                    />
                </Col>
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
            </Row>
        </Container>
    );
}
