/*global google*/
import React, { useState } from "react";
import {
    classnames,
    slugify,
    addressStripCountry,
    convertDashToPlus,
} from "../Helpers";
import { withRouter } from "react-router-dom";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useGoogleAPI } from "@googlemap-react/core";
import { getUA } from "react-device-detect";

import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";

import PropTypes from "prop-types";

AddressTextBox.propTypes = {
    history: PropTypes.object,
    type: PropTypes.string,
    search: PropTypes.string,
    errorMessage: PropTypes.string,
};

function AddressTextBox(props) {
    const [address, setAddress] = useState("");

    function handleChange(address) {
        setAddress(address);
    }

    function handleSelect(address) {
        geocodeByAddress(convertDashToPlus(slugify(address))).then(
            (results) => {
                setAddress(addressStripCountry(results[0].formatted_address));

                handleCloseClick();

                // props.history.push({
                //     pathname:
                //         "/property/" + slugify(results[0].formatted_address),
                //     state: {
                //         address: addressStripCountry(
                //             results[0].formatted_address
                //         ),
                //         placeId: placeId,
                //     },
                // });

                if (props.search === "address" && props.type === "rent") {
                    window.location.href =
                        "/rent/" + slugify(results[0].formatted_address);
                } else {
                    const types = results[0].types;

                    if (
                        types &&
                        (types.includes("locality") ||
                            types.includes("postal_code"))
                    ) {
                        const location = getLatLng(results[0]);
                        props.history.push({
                            pathname:
                                (props.type === "schools"
                                    ? "/redirect-schools/"
                                    : "/redirect-area/") +
                                slugify(results[0].formatted_address),
                            state: {
                                lat: location.lat,
                                lng: location.lng,
                                address: results[0].formatted_address,
                            },
                        });
                    } else if (types && types.includes("route")) {
                        getLatLng(results[0]).then(({ lat, lng }) => {
                            props.history.push({
                                pathname:
                                    "/street/" +
                                    slugify(results[0].formatted_address),
                                state: {
                                    lat: lat,
                                    lng: lng,
                                    address: results[0].formatted_address,
                                    street:
                                        results[0].address_components[0]
                                            .short_name,
                                },
                            });
                        });
                    } else {
                        window.location.href =
                            (window.location.pathname.split("/")[1] === "rent"
                                ? "/rent/"
                                : "/property/") +
                            slugify(results[0].formatted_address);
                    }
                }
            }
        );
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    function handleCloseClick() {
        setAddress("");
    }

    function handleError(status, clearSuggestions) {
        console.log("Error from Google Maps API", status); // eslint-disable-line no-console
        clearSuggestions();
    }

    const googleApi = useGoogleAPI({
        apiKey: getUA.includes("Prerender")
            ? process.env.REACT_APP_GOOGLE_MAPS_INVALID_API_KEY
            : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraryParam: `&libraries=places`,
        languageParam: "",
        regionParam: "",
    });

    return (
        <>
            {googleApi && (
                <PlacesAutocomplete
                    onChange={handleChange}
                    value={address}
                    onSelect={handleSelect}
                    onError={handleError}
                    shouldFetchSuggestions={address.length > 2}
                    searchOptions={{
                        location: new google.maps.LatLng(-34, 151),
                        radius: 20000,
                        bounds: new google.maps.LatLngBounds(
                            new google.maps.LatLng(
                                -34.0136120075924,
                                151.0305270538681
                            ),
                            new google.maps.LatLng(
                                -33.72109120784339,
                                151.38282456928306
                            )
                        ),
                        types:
                            props.search === "address"
                                ? ["address"]
                                : ["geocode"],
                        componentRestrictions: { country: "au" },
                    }}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                    }) => {
                        return (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <InputGroup>
                                        <FormControl
                                            {...getInputProps({
                                                placeholder:
                                                    props.search === "address"
                                                        ? "Enter Address"
                                                        : "Enter Address / Street / Neighbourhood / Postcode",
                                            })}
                                            className="address-search-box"
                                        />
                                        <InputGroup.Append>
                                            {address.length > 0 && (
                                                <button
                                                    className="clear-button"
                                                    onClick={handleCloseClick}
                                                >
                                                    x
                                                </button>
                                            )}
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form>
                                {suggestions.length > 0 && (
                                    <Dropdown show>
                                        <Dropdown.Menu id="places-autosuggest">
                                            {suggestions.map((suggestion) => {
                                                const className = classnames(
                                                    "suggestion-item",
                                                    {
                                                        "suggestion-item--active":
                                                            suggestion.active,
                                                    }
                                                );

                                                return (
                                                    /* eslint-disable react/jsx-key */
                                                    <Dropdown.Item
                                                        {...getSuggestionItemProps(
                                                            suggestion,
                                                            {
                                                                className,
                                                            }
                                                        )}
                                                    >
                                                        <strong>
                                                            {
                                                                suggestion
                                                                    .formattedSuggestion
                                                                    .mainText
                                                            }
                                                        </strong>{" "}
                                                        <small>
                                                            {
                                                                suggestion
                                                                    .formattedSuggestion
                                                                    .secondaryText
                                                            }
                                                        </small>
                                                    </Dropdown.Item>
                                                );
                                                /* eslint-enable react/jsx-key */
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </>
                        );
                    }}
                </PlacesAutocomplete>
            )}
        </>
    );
}

export default withRouter(AddressTextBox);
