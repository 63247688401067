import styled from "styled-components";

const NumberedMarker = styled.span`
    background-color: ${(props) => (props.color ? props.color : "#DC143C")};
    display: inline-block;
    padding: 0.1em 0.6em;
    border: 2px solid #fff;
    border-radius: 100%;
`;

export default NumberedMarker;
