import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import { GoogleMapProvider } from "@googlemap-react/core";
import MapPanel from "../map/MapPanel";
import ListingPanel from "../ListingPanel";

AgentProperties.propTypes = {
    agencyOrAgent: PropTypes.string.isRequired,
    agencyOrAgentId: PropTypes.number.isRequired,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    cardType: PropTypes.string.isRequired,
    numBed: PropTypes.number,
    numBath: PropTypes.number,
    numCar: PropTypes.number,
    dwellingType: PropTypes.string,
    showHome: PropTypes.bool,
    months: PropTypes.number,
    limitOverride: PropTypes.number,
};

export default function AgentProperties(props) {
    const [agencyOrAgent, setAgencyOrAgent] = useState(props.agencyOrAgent);
    const [agencyOrAgentId, setAgencyOrAgentId] = useState(
        props.agencyOrAgentId
    );

    useEffect(() => {
        if (
            props.agencyOrAgent !== agencyOrAgent ||
            props.agencyOrAgentId !== agencyOrAgentId
        ) {
            setAgencyOrAgent(props.agencyOrAgent);
            setAgencyOrAgentId(props.agencyOrAgentId);
        }
    }, [
        props.agencyOrAgent,
        props.agencyOrAgentId,
        agencyOrAgent,
        agencyOrAgentId,
    ]);

    return (
        <GoogleMapProvider>
            {agencyOrAgent && agencyOrAgentId && (
                <Fragment>
                    <MapPanel
                        location={{
                            lat: props.latitude,
                            lng: props.longitude,
                        }}
                        height={"40vh"}
                        zoom={15}
                    />
                    <ListingPanel
                        agencyOrAgent={agencyOrAgent}
                        agencyOrAgentId={agencyOrAgentId}
                        latitude={props.latitude}
                        longitude={props.longitude}
                        cardType={props.cardType}
                        numBed={props.numBed}
                        numBath={props.numBath}
                        numCar={props.numCar}
                        dwellingType={props.dwellingType}
                        months={props.months}
                        limitOverride={props.limitOverride}
                    />
                </Fragment>
            )}
        </GoogleMapProvider>
    );
    // }
}
