import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import isEmpty from "lodash.isempty";
import orderBy from "lodash/orderBy";
import { slugify } from "../Helpers";
import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AgencyDetails from "../components/agent/AgencyDetails";
import AgentCard from "../components/agent/AgentCard";
// import AreaCard from "../components/AreaCard";
import HeaderNav from "../components/navbar/HeaderNav";
import AgentProperties from "../components/agent/AgentProperties";

import ScrollUp from "react-scroll-up";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

const agency_url = process.env.REACT_APP_LIVEABLE_AGENT_API_URL + "/agency/";

Agency.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            agencyId: PropTypes.string,
            agencySlug: PropTypes.string,
        }),
    }),
};
export default function Agency(props) {
    const [agency, setAgency] = useState(undefined);
    const [agencyId] = useState(props.match.params.agencyId);
    const [agencySlug] = useState(
        props.match.params.agencySlug
            ? props.match.params.agencySlug
            : undefined
    );
    const [agents, setAgents] = useState(undefined);
    const [listingState, dispatch] = React.useReducer(reducer, initialState);

    useEffect(() => {
        try {
            const url = agency_url + agencyId;
            // console.log("useEffect", url, agencyName);
            fetch(url)
                .then((response) => response.json())
                .then((d) => {
                    setAgency(d.result);
                    setAgents(
                        orderBy(
                            d.result.agents,
                            function(agent) {
                                return agent.numberOfSales;
                            },
                            "desc"
                        )
                    );
                });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }, [agencyId]);

    function renderAgents() {
        let columns = [];
        agents.forEach((agent) => {
            if (typeof agent.redirectId === "undefined") {
                columns.push(
                    <Col
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        key={agent.agentId}
                        className="py-2"
                    >
                        <AgentCard
                            agentId={agent.agentId}
                            agentSlug={slugify(agent.agentName)}
                            name={agent.agentName}
                            title={agent.title}
                            photo={agent.agentImage}
                        />
                    </Col>
                );
            }
        });

        // console.log(columns);

        return columns;
    }

    return (
        <>
            <Helmet>
                {agency && <title>{agency.agencyName + " - Liveable"}</title>}
                {agency && (
                    <meta
                        property="og:title"
                        content={agency.agencyName + " - Liveable"}
                    />
                )}
                {agency && agents && (
                    <meta
                        name="description"
                        content={
                            agency.agencyName +
                            ". Agents:" +
                            agents.map(
                                (agent) =>
                                    agent.agentName && " " + agent.agentName
                            )
                        }
                    />
                )}
                {agency && agents && (
                    <meta
                        name="og:description"
                        content={
                            agency.agencyName +
                            ". Agents: " +
                            agents.map(
                                (agent) =>
                                    agent.agentName && " " + agent.agentName
                            )
                        }
                    />
                )}
                {agencySlug && (
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/agency/" +
                            agencyId +
                            "/" +
                            agencySlug
                        }
                    />
                )}
                {agencySlug && (
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/agency/" +
                            agencyId +
                            "/" +
                            agencySlug
                        }
                    />
                )}
                {agency && agency.agencyLogo && (
                    <meta property="og:image" content={agency.agencyLogo} />
                )}
            </Helmet>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    {agency && (
                        <GenericCollection type="itemListElement">
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 1,
                                    name: "Liveable",
                                    item: "https://liveable.co",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 2,
                                    name: "Agency",
                                    item: "https://liveable.co/agency",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 3,
                                    name: agency.agencyName,

                                    item:
                                        "https://liveable.co/agency/" +
                                        agencyId +
                                        "/" +
                                        agencySlug,
                                }}
                            />
                        </GenericCollection>
                    )}
                </Generic>
            </JSONLD>
            <HeaderNav
                headings={[
                    {
                        label: "Agency",
                        link: "#agency",
                    },
                    {
                        label: "Agents",
                        link: "#agents",
                    },
                    // {
                    //     label: "Areas Covered",
                    //     link: "#areas-covered",
                    // },
                    {
                        label: "Sale Listings",
                        link: "#listing-sale",
                    },
                    {
                        label: "Sale Listings",
                        link: "#listing-sale",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pt-4 pb-5">
                <section id="agency">
                    {agency && (
                        <AgencyDetails
                            name={agency.agencyName}
                            address={agency.agencyAddress}
                            agencyLogo={agency.agencyLogo}
                            phone={agency.phone}
                            website={agency.website}
                            facebook={agency.facebook}
                            twitter={agency.twitter}
                        />
                    )}
                </section>
                <section id="agents">
                    {agency && agents && (
                        <Row className="mt-3">{renderAgents()}</Row>
                    )}
                </section>
                {agency && !isEmpty(agency.marketsCovered) && <hr />}
                {/* Areas Covered */}
                {/* <section
                    id="areas-covered"
                    className="pt-2 pb-2 text-center text-sm-left"
                >
                    {agency && !isEmpty(agency.marketsCovered) && (
                        <>
                            <Row>
                                <Col>
                                    <h2>Areas Covered</h2>
                                    Areas that{" "}
                                    {agency.agencyName
                                        ? agency.agencyName
                                        : "this agency"}{" "}
                                    covers based on past sales and rentals.
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                {agency.marketsCovered.map((area) => (
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        key={area.la2}
                                        style={{ marginTop: "1em" }}
                                    >
                                        <AreaCard
                                            base_url={
                                                "/area/sydney/" +
                                                convertUnderscoreToDash(
                                                    area.la3
                                                ) +
                                                "/"
                                            }
                                            name_id={convertUnderscoreToDash(
                                                area.la2
                                            )}
                                            name={area.name}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                </section> */}
                {agency && <hr />}
                {/* Listings */}
                <section
                    id="listing-sale"
                    className="pt-2 pb-2 text-center text-sm-left"
                >
                    {agency && (
                        <>
                            <Row>
                                <Col>
                                    <h2>Sales Listings</h2>
                                    Properties listed for sale by{" "}
                                    {agency.agencyName
                                        ? agency.agencyName
                                        : "this agency"}
                                    .
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <PropertiesContext.Provider
                                        value={{ listingState, dispatch }}
                                    >
                                        <AgentProperties
                                            agencyOrAgent="agency"
                                            agencyOrAgentId={parseInt(agencyId)}
                                            cardType="sale"
                                            latitude={-33.8688}
                                            longitude={151.2093}
                                        ></AgentProperties>
                                    </PropertiesContext.Provider>
                                </Col>
                            </Row>
                        </>
                    )}
                </section>
            </Container>
        </>
    );
}
