import React, { Component } from "react";
import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FaArrowDown } from "react-icons/fa";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container className="pb-5 liveable-blue">
                <>
                    <Helmet>
                        <title>{"About - Liveable"}</title>
                        <meta
                            property="description"
                            content={
                                "Liveable simplifies the Property Research process. We provide detailed insights on an area, neighbourhood, street, property to help you find your ideal place to live."
                            }
                        />
                        <meta
                            property="og:title"
                            content={"About - Liveable"}
                        />
                        <link
                            rel="canonical"
                            href="https://liveable.co/about"
                        />
                    </Helmet>
                    <Row className="mt-5 pb-2 text-center">
                        <Col>
                            <h1>About Us</h1>
                            <h2 style={{ fontSize: "1.6rem" }} className="mt-4">
                                Our mission is to help property seekers find
                                their ideal place to live
                            </h2>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="pt-3 "
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <Card.Title className="text-center">
                                        The Early Days
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text className="text-muted">
                                        Liveable started out when two mates,
                                        Evan and Hung, were separately trying to
                                        find an ideal neighbourhood to live in
                                        Sydney, Australia.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        With their analytical backgrounds, they
                                        got together and came up with
                                        spreasheets that crunched numbers on the
                                        various neighbourhoods in Sydney.
                                    </Card.Text>
                                    <Card.Text className="mb-2 text-muted">
                                        They found that what they built on
                                        spreadsheets worked so well that it
                                        became a party trick with their friends
                                        finding areas to live in Sydney.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="py-3 text-center"
                        >
                            <FaArrowDown className="icon-large" />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="pt-3 "
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <Card.Title className="text-center">
                                        <a href="https://explore.liveable.co">
                                            Explore
                                        </a>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text className="text-muted">
                                        After being tired of crunching numbers
                                        manually on a spreadsheet whenever
                                        friends asked them where the best place
                                        for them to live was, they decided to
                                        make that self serve and built a website
                                        front-end for it.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        This website became what Explore is
                                        today - a questionnaire that allows
                                        users to fill up what their lifestyle
                                        choices are, and serving up what their
                                        ideal neighbourhood was.
                                    </Card.Text>
                                    <Card.Text className="mb-2 text-muted">
                                        Explore expanded to major cities in
                                        Australia - Sydney, Melbourne, Brisbane,
                                        Perth and Adelaide, as well as London,
                                        UK and Boston, USA.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="py-3 text-center"
                        >
                            <FaArrowDown className="icon-large" />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="pt-3"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <Card.Title className=" text-center">
                                        <a href="https://liveable.co/schools">
                                            Schools
                                        </a>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text className="text-muted">
                                        With Explore gaining momentum, users
                                        started asking about Schools and adding
                                        that as a criteria in the questionnaire
                                        for Explore.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        Once we started pulling school data into
                                        Explore, we found that schools in
                                        Australia tend to have catchment zones,
                                        and whether your child is able to attend
                                        a specific school is dependent on
                                        whether where you lived fell into that
                                        school catchment zone.
                                    </Card.Text>
                                    <Card.Text className="mb-2 text-muted">
                                        That led to creating a separate resource
                                        that showed what a school catchment zone
                                        was, and whether neighbourhoods and
                                        individual properties fell within that
                                        catchment zone.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="py-3 text-center"
                        >
                            <FaArrowDown className="icon-large" />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                    <Row className="mt-2 mb-4">
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="pt-3"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <Card.Title className=" text-center">
                                        <a href="https://liveable.co">
                                            Property Reports
                                        </a>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text className="text-muted">
                                        As part of launching Explore and
                                        Schools, we started to realise how rich
                                        some of the datasets were.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        At the same time, there was a property
                                        boom in Australia. People were
                                        interested in knowing more about an
                                        individual property and its
                                        surroundings, spending weeks and even
                                        months researching, before making what
                                        would probably be the largest purchase
                                        in their lifetime.
                                    </Card.Text>
                                    <Card.Text className="text-muted">
                                        We asked property seekers what was it
                                        they were after and the data they were
                                        interested in when buying a property and
                                        compiled all of that into a single
                                        property report.
                                    </Card.Text>
                                    <Card.Text className="mb-2 text-muted">
                                        What started off as a pdf based report
                                        eventually morphed into an interactive
                                        mobile-friendly website that you see
                                        today.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} xl={3}></Col>
                    </Row>
                </>
            </Container>
        );
    }
}

export default About;
