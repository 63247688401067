import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import AddressTextBox from "../components/AddressTextBox";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { UserContext } from "../App";

import { FaChartBar, FaCity, FaMapMarkedAlt } from "react-icons/fa";

import "./Home.css";

StreetHome.propTypes = {
    history: PropTypes.object,
};

export default function StreetHome(props) {
    const [state, dispatch] = React.useContext(UserContext);

    const [blogPosts, setBlogPosts] = useState([]);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: "/" } },
        };
        props.history.push(location);
    }

    async function getBlogPosts() {
        const numberOfPosts = 8;
        const blogJson =
            "https://blog.liveable.co/wp-json/wp/v2/posts?per_page=" +
            numberOfPosts;

        fetch(blogJson)
            .then((response) => response.json())
            .then((response) => {
                setBlogPosts(response);
            });
    }

    useEffect(() => {
        getBlogPosts();
    }, []);

    return (
        <Fragment>
            <Helmet>
                <style type="text/css">{`
                        .navbar.header {
                            display: none;
                        }

                    `}</style>
                <link rel="canonical" href="https://liveable.co/area" />
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co",
                        name: "Liveable - Property Research Simplified",
                        description:
                            "Get the full picture of a street in a particular neighbourhood. Liveable helps take the guesswork out of finding an ideal place to live",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic
                    type="webPage"
                    jsonldtype="WebPage"
                    schema={{
                        name: "Street Research Simplified - Liveable",
                        description:
                            "Get the full picture of a street in a particular neighbourhood. Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics.",
                        url: "https://liveable.co/area",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    <GenericCollection type="itemListElement">
                        <Generic
                            jsonldtype="ListItem"
                            schema={{
                                position: 1,
                                name: "Street",
                                item: "https://liveable.co/street",
                            }}
                        />
                    </GenericCollection>
                </Generic>
            </JSONLD>
            <Container fluid className="header-bg-street-splash">
                <Container>
                    <Navbar
                        className="justify-content-between pt-3"
                        variant="dark"
                        expand="sm"
                    >
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src="https://liveable.co/images/liveable_logo_top.png"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Fragment>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>

                                <Nav className="px-0">
                                    {state.isAuthenticated ? (
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                dispatch({
                                                    type: "LOGOUT",
                                                })
                                            }
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="warning"
                                                onClick={goToLogin}
                                            >
                                                Login
                                            </Button>
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    </Navbar>
                    <Row
                        id="header-cta"
                        style={{ paddingTop: "11vh", paddingBottom: "10vh" }}
                    >
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <h1 className="header-text">
                                Street Research Simplified
                            </h1>
                            <h4 className="header-text">
                                Get the full picture of a street in a
                                neighbourhood
                            </h4>
                            <div className="mt-4">
                                <AddressTextBox />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                    </Row>
                </Container>
            </Container>
            <Container
                fluid
                className="py-5 background-grey liveable-blue text-center"
            >
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue">
                                Insights to help find your ideal place to live
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaCity />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        What&#39;s on a Street
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        What do the street look like? What do
                                        houses on the street look like? What are
                                        the demographics of people that live on
                                        the street and its surroundings? We help
                                        answer these questions which will give
                                        you a better picture of the street.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaMapMarkedAlt />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>The Best Street</Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        The old adage of &#34;
                                        <em>
                                            buying the worst house in the best
                                            street instead of the best house in
                                            the worst street
                                        </em>
                                        &#34; definitely rings true. Knowing
                                        which are the best streets in the
                                        neighbourhood will help hone your
                                        search.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaChartBar />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>In-depth Insights</Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Knowing what the median price is for
                                        homes on the street, what the last sale
                                        price was for an equivalent home you are
                                        looking for, will help you work out
                                        match properties to your budget.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                Word on the Street
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row className="carousel-wrapper">
                        {blogPosts.map((post) => (
                            <Col
                                xs={10}
                                sm={10}
                                md={4}
                                lg={4}
                                xl={4}
                                className="pt-3 text-center carousel-card"
                                key={"BlogPost_" + post.id}
                            >
                                <Card
                                    bg="light"
                                    key={"BlogPost_Card_" + post.id}
                                >
                                    {post.jetpack_featured_media_url && (
                                        <a href={post.link}>
                                            <Card.Img
                                                variant="top"
                                                src={
                                                    post.jetpack_featured_media_url
                                                }
                                            />
                                        </a>
                                    )}
                                    <Card.Body>
                                        <a href={post.link}>
                                            <Card.Title
                                                dangerouslySetInnerHTML={{
                                                    __html: post.title.rendered,
                                                }}
                                            ></Card.Title>
                                        </a>
                                        <Card.Text
                                            className="mt-3 mb-2 text-muted"
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered.includes(
                                                    "&hellip;"
                                                )
                                                    ? post.excerpt.rendered.substring(
                                                          0,
                                                          post.excerpt.rendered.indexOf(
                                                              "&hellip;"
                                                          ) + "&hellip;".length
                                                      )
                                                    : post.excerpt.rendered,
                                            }}
                                        ></Card.Text>
                                        <a href={post.link}>
                                            <Button
                                                id="blog-read-more-link"
                                                variant="link"
                                                className="mt-2"
                                            >
                                                Read more
                                            </Button>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        </Fragment>
    );
}
