import React, { useEffect, useState } from "react";
import { JSONLD, Generic } from "react-structured-data";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import orderBy from "lodash/orderBy";

import { convertUnderscoreToDash } from "../Helpers";

import AddressTextBox from "../components/AddressTextBox";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { FaExclamationTriangle } from "react-icons/fa";

ErrorPage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export function ErrorPage(props) {
    const DEFAULT_LIMIT = 16; // cards to show per pagination

    const [la2List, setLa2List] = useState([]);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [maxLimit, setMaxLimit] = useState(500);

    async function getNeighbourhoods() {
        const la2Json =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/sydney" +
            "/la2-home.json";

        fetch(la2Json)
            .then((response) => response.json())
            .then((response) => {
                setLa2List(orderBy(response, "popn", "desc"));
                setMaxLimit(response.length);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getNeighbourhoods();
    }, []);

    function handleClickButtonShowMore() {
        setLimit(limit + DEFAULT_LIMIT);
    }

    return (
        <>
            <Container>
                <Helmet>
                    <title>{"Page does not exist - Liveable"}</title>
                    <meta
                        property="description"
                        content={"You have reached a page that does not exist"}
                    />
                    <meta
                        property="og:title"
                        content={"Page does not exist - Liveable"}
                    />
                    <link rel="canonical" href="https://liveable.co/404" />
                </Helmet>
                <JSONLD>
                    <Generic
                        type="website"
                        jsonldtype="WebSite"
                        schema={{
                            url: "https://liveable.co/404",
                            name: "Page does not exist - Liveable",
                            description:
                                "You have reached a page that does not exist",
                        }}
                    />
                </JSONLD>
            </Container>
            <Container fluid className="py-5">
                <Container className="py-5">
                    <Row className="text-center">
                        <Col>
                            <h1>
                                <FaExclamationTriangle />
                            </h1>
                            <h1 className="mt-3"> Whoops!</h1>
                            <h5>
                                {props.location.state &&
                                props.location.state.message
                                    ? props.location.state.message
                                    : "The page you were looking for does not exist"}
                            </h5>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 background-grey liveable-blue">
                <Container className="py-5">
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                Find Another Address
                            </h3>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center">
                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="mt-3">
                                <AddressTextBox />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={3} xl={3}></Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="py-5 liveable-blue">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue text-center">
                                Neighbourhoods
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        {la2List.slice(50, 50 + limit).map((la2) => (
                            <Col
                                xs={6}
                                sm={6}
                                md={3}
                                lg={3}
                                xl={3}
                                className="pt-3 text-center carousel-card"
                                key={"La2_" + la2.id}
                            >
                                <Card bg="light" key={"La2_Card_" + la2.id}>
                                    <Card.Body>
                                        {/* TODO: Sydney as LA4 hardcoded. Need to fix */}
                                        {la2.name_id && (
                                            <a
                                                href={
                                                    "/area/sydney/" +
                                                    convertUnderscoreToDash(
                                                        la2.parent_name_id
                                                    ) +
                                                    "/" +
                                                    convertUnderscoreToDash(
                                                        la2.name_id
                                                    )
                                                }
                                            >
                                                <Card.Title>
                                                    {la2.name}
                                                </Card.Title>
                                            </a>
                                        )}
                                        <Card.Subtitle className="mt-3 mb-2 text-muted">
                                            {la2.postal.join(", ")}
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {la2List.length > 0 && limit < maxLimit && (
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button
                                    id="home-neighbourhoods-show-more"
                                    variant="outline-primary"
                                    onClick={() => handleClickButtonShowMore()}
                                >
                                    Show More
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Container>
        </>
    );
}

export default withRouter(ErrorPage);
