import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { moneyFormatter, titleCase } from "../Helpers";
import { FaBed, FaBath, FaCar, FaRulerCombined } from "react-icons/fa";
import moment from "moment";

import NumberedMarker from "../components/NumberedMarker";
import PropertyLink from "../components/PropertyLink";
import { convertDistance, slugify } from "../Helpers";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Carousel from "react-bootstrap/Carousel";

const property_type_border_colour = {
    sold: "warning",
    sale: "info",
    rent: "success",
};

class PropertyCard extends Component {
    static propTypes = {
        index: PropTypes.string,
        cardType: PropTypes.string,
        colour: PropTypes.string,
        addressSlug: PropTypes.string,
        lotNumber: PropTypes.number,
        address: PropTypes.string,
        dwellingType: PropTypes.string,
        numBed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numBath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numCar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        area: PropTypes.number,
        areaType: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        date: PropTypes.string,
        lastContractDate: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        history: PropTypes.object,
        images: PropTypes.array,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        details_url: PropTypes.string,
        agencyOrAgent: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = { selectedIndex: 0 };
    }

    propertyCard() {
        return (
            <Card.Body onClick={() => this.onClick}>
                <Row>
                    <Col xs={2} className="d-inline">
                        <NumberedMarker
                            style={{
                                color: "#ffffff",
                                backgroundColor: this.props.colour,
                            }}
                            className="align-top"
                        >
                            {this.props.index}
                        </NumberedMarker>
                    </Col>
                    <Col xs={6} className="d-inline">
                        <span className="align-top">
                            {/* {moneyFormatter.format(this.props.price)} */}
                            {typeof this.props.price === "number" ? (
                                <h5>
                                    <strong>
                                        {moneyFormatter.format(
                                            this.props.price
                                        )}
                                    </strong>
                                </h5>
                            ) : (
                                <h5>
                                    <strong>{this.props.price}</strong>
                                </h5>
                            )}
                        </span>

                        {this.props.lastContractDate && (
                            <span>
                                {moment(
                                    this.props.lastContractDate,
                                    "YYYYMMDD"
                                ).format("DD MMM YYYY")}
                            </span>
                        )}
                    </Col>
                    <Col xs={4} className="d-inline text-right">
                        <span className="align-top">
                            <Badge variant="light">
                                {this.props.dwellingType &&
                                    titleCase(this.props.dwellingType, " ")}
                            </Badge>
                        </span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={2} />
                    <Col xs={8}>
                        <Card.Subtitle>
                            {this.props.addressSlug ? (
                                <h6>
                                    <PropertyLink
                                        address={this.props.address}
                                        addressSlug={this.props.addressSlug}
                                        {...this.props}
                                    />
                                </h6>
                            ) : (
                                <h6>
                                    <PropertyLink
                                        address={this.props.address}
                                    />
                                </h6>
                            )}
                        </Card.Subtitle>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={2} />
                    <Col xs={10}>
                        <Card.Text>
                            <>
                                <FaBed
                                    className="fa-xs"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                />{" "}
                                {this.props.numBed || this.props.numBed === 0
                                    ? this.props.numBed
                                    : "-"}{" "}
                            </>
                            <>
                                <FaBath
                                    className="fa-xs ml-1"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                />{" "}
                                {this.props.numBath || this.props.numBath === 0
                                    ? this.props.numBath
                                    : "-"}{" "}
                            </>
                            <>
                                <FaCar
                                    className="fa-xs ml-1"
                                    style={{
                                        verticalAlign: "text-bottom",
                                    }}
                                />{" "}
                                {this.props.numCar || this.props.numCar === 0
                                    ? this.props.numCar
                                    : "-"}{" "}
                            </>
                            {this.props.area &&
                                this.props.areaType &&
                                this.props.areaType === "M" && (
                                    <>
                                        <FaRulerCombined
                                            className="fa-xs ml-1"
                                            style={{
                                                verticalAlign: "text-bottom",
                                            }}
                                        />{" "}
                                        <small>
                                            {this.props.area
                                                ? this.props.area
                                                : "-"}
                                            {"m²"}
                                        </small>
                                    </>
                                )}
                        </Card.Text>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} />
                    <Col xs={10} className="text-muted text-nowrap">
                        {this.props.distance !== undefined && (
                            <small>
                                {convertDistance(this.props.distance, "km")}{" "}
                                away
                            </small>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        );
    }

    imgOnError(image) {
        const image_source_large =
            process.env.REACT_APP_IMAGE_BUCKET_URL +
            "/" +
            image.substr(0, image.lastIndexOf(".")) +
            "-800.jpg";
        return image_source_large;
    }

    onSelect(selectedIndex) {
        this.setState({ selectedIndex: selectedIndex });
    }

    showImageCarousel(images) {
        let items = [];

        images.forEach((image, index) => {
            let image_source =
                process.env.REACT_APP_IMAGE_BUCKET_URL +
                "/" +
                image.substr(0, image.lastIndexOf(".")) +
                "-320.jpg";

            let image_source_large =
                process.env.REACT_APP_IMAGE_BUCKET_URL +
                "/" +
                image.substr(0, image.lastIndexOf(".")) +
                "-800.jpg";

            let alt = "Image #" + index + " for " + this.props.address;

            if (image.includes("-plan")) {
                image_source = image_source_large;
            }

            if (this.props.cardType !== "sold" && !this.props.agencyOrAgent) {
                image_source = image;
                image_source_large = image;
                alt = this.props.address
                    ? "Image #" + index + " for " + this.props.address
                    : "";
            }

            if (
                index <= this.state.selectedIndex + 1 &&
                index >= this.state.selectedIndex - 1
            ) {
                items.push(
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={image_source}
                            onError={(e) => {
                                if (e.target.src !== image_source_large) {
                                    e.target.onerror = null;
                                    e.target.src = image_source_large;
                                }
                            }}
                            alt={alt}
                            loading="lazy"
                        />
                    </Carousel.Item>
                );
            } else {
                // lazy load images
                items.push(
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            data-src={image_source}
                            onError={(e) => {
                                if (e.target.src !== image_source_large) {
                                    e.target.onerror = null;
                                    e.target.src = image_source_large;
                                }
                            }}
                            alt={alt}
                            loading="lazy"
                        />
                    </Carousel.Item>
                );
            }
        });

        return (
            <Carousel interval={null} onSelect={(i) => this.onSelect(i)}>
                {items}
            </Carousel>
        );
    }

    showDefaultImage() {
        return (
            <Card.Img
                height={232}
                src={
                    this.props.dwellingType &&
                    this.props.dwellingType.toLowerCase() === "house"
                        ? process.env.REACT_APP_IMAGE_BUCKET_URL + "/house.jpg"
                        : process.env.REACT_APP_IMAGE_BUCKET_URL + "/unit.jpg"
                }
            />
        );
    }

    redirect = () => {
        if (this.props.addressSlug) {
            this.props.history.push({
                pathname:
                    process.env.REACT_APP_PROFILE_URL +
                    "/" +
                    this.props.addressSlug,

                state: {
                    address: this.props.address,
                    addressSlug: this.props.addressSlug,
                    lotNumber: this.props.lotNumber,
                    images: this.props.images,
                    dwellingType: this.props.dwellingType,
                    numBed: this.props.numBed,
                    numBath: this.props.numBath,
                    numCar: this.props.numCar,
                    area: this.props.area,
                    areaType: this.props.areaType,
                    price: this.props.price,
                    lastContractDate: this.props.lastContractDate,
                    latitude: this.props.latitude,
                    longitude: this.props.longitude,
                },
            });
        } else {
            // window.location.href =
            //     process.env.REACT_APP_PROFILE_URL +
            //     "/" +
            //     slugify(this.props.address.replace("/", "-"));
            this.props.history.push({
                pathname:
                    process.env.REACT_APP_PROFILE_URL +
                    "/" +
                    slugify(this.props.address.replace("/", "-")),

                state: {
                    address: this.props.address,
                    images: this.props.images,
                    dwellingType: this.props.dwellingType,
                    numBed: this.props.numBed,
                    numBath: this.props.numBath,
                    numCar: this.props.numCar,
                    price: this.props.price,
                    lastContractDate: this.props.lastContractDate,
                    latitude: this.props.latitude,
                    longitude: this.props.longitude,
                },
            });
        }
    };

    render() {
        return (
            <Card
                border={property_type_border_colour[this.props.cardType]}
                className="h-100"
            >
                {this.props.images
                    ? this.showImageCarousel(this.props.images)
                    : this.showDefaultImage()}
                {this.propertyCard()}
                <Card.Footer>
                    <Row className="mt-2">
                        <Col>
                            <Button
                                block
                                id="view-details"
                                variant="primary"
                                onClick={() => this.redirect()}
                            >
                                View Property Details
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {this.props.details_url && (
                                <a
                                    id="domain_link_out"
                                    href={
                                        this.props.details_url +
                                        "?utm_source=liveable.co&utm_medium=referral"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button block variant="success">
                                        View Listing
                                    </Button>
                                </a>
                            )}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        );
    }
}

// export default PropertyCard;

export default withRouter(PropertyCard);
