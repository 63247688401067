import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import { TransitLayer, GoogleMapContext } from "@googlemap-react/core";
import MapPanel from "./map/MapPanel";
import TransitPlace, {
    transitModes,
    transitRouteModeColours,
    transitRouteModes,
    ignoreTransitDirections,
    ignoreTransitNames,
} from "./map/TransitPlace";
import TransitTable from "./TransitTable";
import Place from "./map/Place";
import { FaHome } from "react-icons/fa";
import Geohash from "latlon-geohash";
import PropTypes from "prop-types";
import Loading from "./Loading";

let Set = require("es6-set");

const radius = 1600; //here API search radius, max is 50 per response
let here_stations_url =
    "https://transit.api.here.com/v3/stations/by_geocoord.json?app_id=" +
    process.env.REACT_APP_ID_HERE +
    "&app_code=" +
    process.env.REACT_APP_CODE_HERE +
    "&radius=" +
    radius +
    "&max=50&center=";

const minZoomLevel = 16; // minimum zoom level to filter out overlapping markers
const geoHashLevel = 8; // geohash length, can 1-12. 12 is most accurate

/**
 * Use Here API https://developer.here.com/documentation/transit/topics/resource-search-geocoord.html
 *  to search for transit stops within x radius of the location
 *
 */

class Transit extends Component {
    static propTypes = {
        longitude: PropTypes.number.isRequired,
        latitude: PropTypes.number.isRequired,
        showHome: PropTypes.bool,
        showDistance: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            latitude: this.props.latitude,
            longitude: this.props.longitude,
            transitStops: [],
            loading: true,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        let transit_url =
            here_stations_url +
            this.state.latitude +
            "," +
            this.state.longitude;

        this.getData(transit_url);
    }

    getData(transit_url) {
        fetch(transit_url)
            .then((response) => response.json())
            .then((data) =>
                this.setState({
                    transitStops: this.getTransitStops(data),
                    loading: false,
                })
            );
    }

    getTransitStops(data) {
        let transitStops = [];

        if (data.Res.Stations) {
            const stations = data.Res.Stations.Stn;

            stations.forEach((station) => {
                let mainTransit = {};
                mainTransit.id = station.id;
                mainTransit.name = station.name;
                mainTransit.lat = station.y;
                mainTransit.lng = station.x;
                mainTransit.distance = station.distance;
                mainTransit.duration = convertHereDuration(station.duration);
                mainTransit.show = true;
                mainTransit.routes = [];

                const transports = station.Transports.Transport;
                let added = {};

                let transit = { ...mainTransit };
                transports.forEach((transport) => {
                    transit.mode = transitModes[transport.mode];
                    transit.color = transitRouteModeColours[transit.mode];

                    // filter out mainTransit stops that we want to ignore
                    if (
                        !(transport.name in added) &&
                        !ignoreTransitNames.includes(transport.name) &&
                        !ignoreTransitDirections.includes(transport.dir)
                    ) {
                        added[transport.name] = transport;

                        //dont add if no At present??
                        if (transport.At) {
                            const route = {
                                color: transport.At.color,
                                name: transport.name,
                            };
                            transit.routes.push(route);
                        }
                    }
                });
                transitStops.push(transit);
            });
        }
        return transitStops;
    }

    onZoomChanged = () => {
        this.setState({
            zoomedChanged: true,
        });
        this.filterOutTransit();
    };

    /**
     * Dont show train stations that are too close to each when zoomed out
     *
     */
    filterOutTransit() {
        const transitSet = new Set();

        if (this.context.state.map) {
            this.state.transitStops.forEach((transit) => {
                const geohash = Geohash.encode(
                    transit.lat,
                    transit.lng,
                    geoHashLevel
                );
                let transitStop = geohash + transit.mode;
                if (transit.mode === "train") {
                    if (
                        transitSet.has(transitStop) &&
                        this.context.state.map.zoom <= minZoomLevel
                    ) {
                        transit.show = false;
                    } else {
                        transit.show = true;
                        transitSet.add(transitStop);
                    }
                }
            });
        }
    }

    render() {
        this.context.state.map && this.filterOutTransit();

        return (
            <>
                <MapPanel
                    location={{
                        lat: this.state.latitude,
                        lng: this.state.longitude,
                    }}
                    height={"65vh"}
                    onZoomChanged={this.onZoomChanged}
                    zoom={minZoomLevel}
                />
                {this.props.showHome && (
                    <Place
                        key={this.state.address}
                        location={{
                            lat: this.state.latitude,
                            lng: this.state.longitude,
                        }}
                        icon={<FaHome />}
                        height="30px"
                        width="30px"
                    />
                )}
                <Loading
                    loading={this.state.loading}
                    errorMessage={this.state.errorMessage}
                    dataset={this.state.transitStops}
                    datasetName="Transit Stops"
                />
                {!isEmpty(this.state.transitStops) &&
                    this.state.transitStops.map(
                        (transit) =>
                            transit.show && (
                                <TransitPlace
                                    key={transit.id}
                                    location={{
                                        lat: transit.lat,
                                        lng: transit.lng,
                                    }}
                                    color={transit.color}
                                    index={transitRouteModes[transit.mode]}
                                    zoom={
                                        this.context.state.map
                                            ? this.context.state.map.zoom
                                            : minZoomLevel
                                    }
                                    routes={transit.routes}
                                    duration={transit.duration}
                                />
                            )
                    )}

                <TransitTable
                    data={this.state.transitStops}
                    showDistance={this.props.showDistance}
                />
                <TransitLayer />
            </>
        );
    }
}

//eg. PT0H11M8S
const convertHereDuration = (hereDuration) => {
    hereDuration = hereDuration.replace("PT0H", "");
    hereDuration = hereDuration.toLowerCase();
    return hereDuration;
};

//https://reactjs.org/docs/context.html#classcontexttype
Transit.contextType = GoogleMapContext;
export default Transit;
