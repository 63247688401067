import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import LoaderButton from "../../components/forms/LoaderButton";

import "./ResetPassword.css";

export default class ResetPassword extends Component {
    static propTypes = {
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            code: "",
            email: "",
            password: "",
            codeSent: false,
            confirmed: false,
            confirmPassword: "",
            isConfirming: false,
            isSendingCode: false,
        };
    }

    handleNavClickLogin(e) {
        e.preventDefault();
        this.props.history.push("/login");
    }

    handleNavClickSignUp(e) {
        e.preventDefault();
        this.props.history.push("/login");
    }

    validateCodeForm() {
        return this.state.email.length > 0;
    }

    validateResetForm() {
        return (
            this.state.code.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleSendCodeClick = async (event) => {
        event.preventDefault();

        this.setState({ isSendingCode: true });

        try {
            await Auth.forgotPassword(this.state.email);
            this.setState({ codeSent: true });
        } catch (e) {
            alert(e.message);
            this.setState({ isSendingCode: false });
        }
    };

    handleConfirmClick = async (event) => {
        event.preventDefault();

        this.setState({ isConfirming: true });

        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.code,
                this.state.password
            );
            this.setState({ confirmed: true });
        } catch (e) {
            alert(e.message);
            this.setState({ isConfirming: false });
        }
    };

    renderRequestCodeForm() {
        return (
            <Row className="mt-4">
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <form onSubmit={this.handleSendCodeClick}>
                        <FormGroup controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <FormControl
                                autoFocus
                                type="email"
                                placeholder="eg. alexa@gmail.com"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={this.state.isSendingCode}
                            disabled={!this.validateCodeForm()}
                        >
                            Send Confirmation
                        </LoaderButton>
                    </form>
                </Col>
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
            </Row>
        );
    }

    renderConfirmationForm() {
        return (
            <Row className="mt-4">
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <form onSubmit={this.handleConfirmClick}>
                        <FormGroup controlId="code">
                            <Form.Label>Confirmation Code</Form.Label>
                            <FormControl
                                autoFocus
                                type="tel"
                                value={this.state.code}
                                onChange={this.handleChange}
                            />
                            <div className="mt-3 mb-0">
                                Please check your email ({this.state.email}) for
                                the confirmation code.
                            </div>
                        </FormGroup>
                        <hr />
                        <FormGroup controlId="password">
                            <Form.Label>New Password</Form.Label>
                            <FormControl
                                type="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <FormControl
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.confirmPassword}
                            />
                        </FormGroup>
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={this.state.isConfirming}
                            disabled={!this.validateResetForm()}
                        >
                            Confirm
                        </LoaderButton>
                    </form>
                </Col>
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
            </Row>
        );
    }

    renderSuccessMessage() {
        setTimeout(() => {
            this.props.history.push("/login");
        }, 3000);

        return (
            <Row className="mt-4">
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    className="text-center"
                >
                    <p>Your password has been reset.</p>
                    <p>
                        You will be redirected in 3s or{" "}
                        <Link to="/login">
                            click here to login with your new credentials.
                        </Link>
                    </p>
                </Col>
                <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
            </Row>
        );
    }

    render() {
        return (
            <Container>
                <Row className="mt-5">
                    <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Nav
                            className="justify-content-center flex-sm-row"
                            fill
                            variant="pills"
                            defaultActiveKey="all"
                            activeKey="login"
                        >
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) => this.handleNavClickLogin(e)}
                                    eventKey="login"
                                >
                                    Login
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleNavClickSignUp(e)
                                    }
                                    eventKey="signup"
                                >
                                    Sign Up
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col xs={0} sm={0} md={3} lg={4} xl={4}></Col>
                </Row>
                {!this.state.codeSent
                    ? this.renderRequestCodeForm()
                    : !this.state.confirmed
                    ? this.renderConfirmationForm()
                    : this.renderSuccessMessage()}
            </Container>
        );
    }
}
