import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import "./MortgageRepaymentCalculator.css";

const MIN_LOAN_TERM = 1;
const MAX_LOAN_TERM = 30;
const MIN_INTEREST_RATE = 0.01;
const MAX_INTEREST_RATE = 99.99;
const MIN_AMOUNT = 10000;
const MAX_AMOUNT = 100000000;

MortgageRepaymentCalculator.propTypes = {
    propertyPrice: PropTypes.number,
};

function MortgageRepaymentCalculator(props) {
    const [estimatedPropertyPrice, setEstimatedPropertyPrice] = useState(
        props.propertyPrice || "500,000"
    );
    const [loanAmount, setLoanAmount] = useState(
        props.propertyPrice * 0.8 || "400,000"
    );
    const [loanTerm, setLoanTerm] = useState(30);
    const [interestRate, setInterestRate] = useState(2.99);
    const [repaymentType, setRepaymentType] = useState("pi");

    const [
        estimatedPropertyPriceError,
        setEstimatedPropertyPriceError,
    ] = useState(undefined);
    const [loanAmountError, setLoanAmountError] = useState(undefined);
    const [loanTermError, setLoanTermError] = useState(undefined);
    const [interestRateError, setInterestRateError] = useState(undefined);
    const [unoTerms, setUnoTerms] = useState(true);

    useEffect(() => {
        if (props.propertyPrice) {
            setEstimatedPropertyPrice(props.propertyPrice.toLocaleString());
            setLoanAmount((props.propertyPrice * 0.8).toLocaleString());
        }
    }, [props.propertyPrice]);

    function validateForm() {
        return (
            !estimatedPropertyPriceError &&
            !loanAmountError &&
            !loanTermError &&
            !interestRateError &&
            unoTerms
        );
    }

    function handleLoanTermFormChange(e) {
        const {
            target: { value },
        } = e;
        const formatNumber = value;
        setLoanTerm(formatNumber);

        if (formatNumber < MIN_LOAN_TERM || formatNumber > MAX_LOAN_TERM) {
            setLoanTermError(
                "Please enter a value between " +
                    MIN_LOAN_TERM +
                    " and " +
                    MAX_LOAN_TERM
            );
        } else {
            setLoanTermError(undefined);
        }
    }

    function handleInterestRateFormChange(e) {
        const {
            target: { value },
        } = e;
        const formatNumber = value;
        setInterestRate(formatNumber);

        if (
            formatNumber < MIN_INTEREST_RATE ||
            formatNumber > MAX_INTEREST_RATE
        ) {
            setInterestRateError(
                "Please enter a value between " +
                    MIN_INTEREST_RATE +
                    " and " +
                    MAX_INTEREST_RATE
            );
        } else {
            setInterestRateError(undefined);
        }
    }

    function handleCheckChange(e) {
        const {
            target: { checked },
        } = e;
        setUnoTerms(checked);
    }

    function handleCommaForNumbers(e) {
        const {
            target: { name, value },
        } = e;
        const formatNumber = parseInt(value.replace(/,/g, ""));

        if (name === "estimatedPropertyPrice") {
            setEstimatedPropertyPrice(formatNumber.toLocaleString());
        } else if (name === "loanAmount") {
            setLoanAmount(formatNumber.toLocaleString());
        }

        if (formatNumber < MIN_AMOUNT || formatNumber > MAX_AMOUNT) {
            const errorMessage =
                "Please enter a value between " +
                MIN_AMOUNT +
                " and " +
                MAX_AMOUNT;

            if (name === "estimatedPropertyPrice") {
                setEstimatedPropertyPriceError(errorMessage);
            } else if (name === "loanAmount") {
                setLoanAmountError(errorMessage);
            }
        } else {
            if (name === "estimatedPropertyPrice") {
                setEstimatedPropertyPriceError(undefined);
            } else if (name === "loanAmount") {
                setLoanAmountError(undefined);
            }
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (validateForm() === false) {
            e.stopPropagation();
        }
    }

    function calculateRepaymentAmount(
        loanAmount,
        interestRate,
        loanTerm,
        repaymentType
    ) {
        return repaymentType === "pi"
            ? (loanAmount *
                  (interestRate / 100 / 12) *
                  Math.pow(1 + interestRate / 100 / 12, loanTerm * 12)) /
                  (Math.pow(1 + interestRate / 100 / 12, loanTerm * 12) - 1)
            : loanAmount * (interestRate / 100 / 12);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="py-2">
                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="py-2">
                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-2"> */}
                    <Form.Group controlId="formGroupPropertyPrice">
                        <Form.Label>Estimated Property Price</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrependPropertyPrice">
                                    $
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                onChange={handleCommaForNumbers}
                                value={estimatedPropertyPrice}
                                name="estimatedPropertyPrice"
                                aria-label="Estimated Property Price"
                                aria-describedby="inputGroupPrependPropertyPrice"
                            />
                        </InputGroup>
                        <small className="validation-error">
                            {estimatedPropertyPriceError}
                        </small>
                    </Form.Group>
                    <Form.Group controlId="formGroupLoanAmount">
                        <Form.Label>Loan Amount</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrependLoanAmount">
                                    $
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                onChange={handleCommaForNumbers}
                                value={loanAmount}
                                name="loanAmount"
                                aria-label="Loan Amount"
                                aria-describedby="inputGroupPrependLoanAmount"
                            />
                        </InputGroup>
                        <small className="validation-error">
                            {loanAmountError}
                        </small>
                    </Form.Group>
                    <Form.Group controlId="formGroupLoanTerm">
                        <Form.Label>Loan Term</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                name="loanTerm"
                                value={loanTerm}
                                onChange={handleLoanTermFormChange}
                                min={MIN_LOAN_TERM}
                                max={MAX_LOAN_TERM}
                                step="1"
                                disabled={repaymentType === "pi" ? false : true}
                                aria-label="Loan Term"
                                aria-describedby="inputGroupAppendLoanTerm"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="inputGroupAppendLoanTerm">
                                    years
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <small className="validation-error">
                            {loanTermError}
                        </small>
                    </Form.Group>
                    <Form.Group controlId="formGroupInterestRate">
                        <Form.Label>Interest Rate</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                name="interestRate"
                                onChange={handleInterestRateFormChange}
                                value={interestRate}
                                min={MIN_INTEREST_RATE}
                                max={MAX_INTEREST_RATE}
                                step="0.01"
                                aria-label="Interest Rate"
                                aria-describedby="inputGroupAppendInterestRate"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="inputGroupAppendInterestRate">
                                    %
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <small className="validation-error">
                            {interestRateError}
                        </small>
                    </Form.Group>
                    <Form.Group controlId="formGroupRepaymentType">
                        <Form.Label>Repayment Type</Form.Label>
                        <Nav
                            variant="pills"
                            defaultActiveKey="pi"
                            onSelect={(selectedKey) =>
                                setRepaymentType(selectedKey)
                            }
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="pi">
                                    Principal and Interest
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="interest-only">
                                    Interest Only
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Form.Group>
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="text-center pt-5 pb-3"
                >
                    {/* <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="text-center py-2"
                > */}
                    <Row>
                        <Col style={{ fontSize: "1.2rem" }}>
                            Estimated Repayments
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="mortgage-repayment-amount">
                                {estimatedPropertyPrice &&
                                interestRate &&
                                loanAmount &&
                                loanTerm &&
                                repaymentType &&
                                validateForm()
                                    ? "$" +
                                      Math.round(
                                          calculateRepaymentAmount(
                                              parseInt(
                                                  loanAmount.replace(/,/g, "")
                                              ),
                                              interestRate,
                                              loanTerm,
                                              repaymentType
                                          )
                                      ).toLocaleString()
                                    : "$0"}
                            </span>
                            <span> / month</span>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col>
                            <Button
                                variant="warning"
                                size="lg"
                                disabled={!validateForm()}
                                type="submit"
                                href="https://app.unohomeloans.com.au/new-loan?uno-referrer=liveable&loanType=Purchase&utm_source=liveable&utm_campaign=April20&utm_medium=website&utm_content=web1_S1C1_purchase"
                                target="_blank"
                            >
                                Compare Home Loans
                            </Button>
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Col>
                            <Image
                                src="/images/powered_by_uno.png"
                                alt="Home loans powered by uno"
                                className="uno-logo"
                            ></Image>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="uno-terms text-muted">
                            <input
                                type="checkbox"
                                name="unoTerms"
                                defaultChecked
                                onChange={handleCheckChange}
                            />{" "}
                            By continuing, I agree to uno&#39;s Website{" "}
                            <a
                                href="https://unohomeloans.com.au/terms-and-conditions/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms &amp; Conditions
                            </a>{" "}
                            and to us using your information as per our
                            <a
                                href="https://unohomeloans.com.au/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Privacy Policy
                            </a>{" "}
                            (incl. collection statement) and giving you
                            documents electronically under our{" "}
                            <a
                                href="https://unohomeloans.com.au/privacy-policy/#econsent"
                                rel="noopener noreferrer"
                            >
                                e-consent.{" "}
                            </a>
                            Liveable may receive a referral fee for introducing
                            you to uno Home Loans.
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default MortgageRepaymentCalculator;
