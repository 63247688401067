import React, { Component, Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EditableTextInput from "./EditableTextInput";
import { FaBed, FaBath, FaCar, FaVectorSquare } from "react-icons/fa";
import PropTypes from "prop-types";
import { titleCase } from "../Helpers";

import PriceRange from "./PriceRange";
import Loading from "./Loading";

class PropertyInfo extends Component {
    static propTypes = {
        addressSlug: PropTypes.string,
        numBed: PropTypes.number,
        numBath: PropTypes.number,
        numCar: PropTypes.number,
        address: PropTypes.string,
        area: PropTypes.number,
        areaType: PropTypes.string,
        dwellingType: PropTypes.string,
        onChangeNumBed: PropTypes.func.isRequired,
        onChangeNumBath: PropTypes.func.isRequired,
        onChangeNumCar: PropTypes.func.isRequired,
        median: PropTypes.number,
        lowerQuantile: PropTypes.number,
        upperQuantile: PropTypes.number,
        count: PropTypes.number,
        type: PropTypes.string,
        loading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            numBed: this.props.numBed,
            numBath: this.props.numBath,
            numCar: this.props.numCar,
            area: this.props.area,
            areaType: this.props.areaType,
            dwellingType: this.props.dwellingType,
        };
    }

    getAreaType = (areaType) => {
        if (areaType === "M") {
            return "sqm";
        } else if (areaType === "H") {
            return "hectare";
        }
    };

    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col>
                                <h1>{this.props.address}</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-between">
                            <Col
                                xs={12}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={8}
                                // className="text-center"
                            >
                                {/* TODO: Allow user to select from a list of dwelling types when unavailable */}
                                {this.props.dwellingType && (
                                    <span className="mt-3 mb-1 mr-3">
                                        {titleCase(this.props.dwellingType)}
                                    </span>
                                )}
                                <span>
                                    <FaBed
                                        style={{ verticalAlign: "text-bottom" }}
                                    />{" "}
                                    <EditableTextInput
                                        inputType="number"
                                        min="0"
                                        value={this.props.numBed}
                                        onChangeSave={this.props.onChangeNumBed}
                                        width="3em"
                                    />
                                </span>
                                <span className="ml-3">
                                    <FaBath
                                        style={{ verticalAlign: "text-bottom" }}
                                    />{" "}
                                </span>
                                <EditableTextInput
                                    inputType="number"
                                    min="0"
                                    value={this.props.numBath}
                                    onChangeSave={this.props.onChangeNumBath}
                                    width="3em"
                                />
                                <span className="ml-3">
                                    <FaCar
                                        style={{ verticalAlign: "text-bottom" }}
                                    />{" "}
                                    <EditableTextInput
                                        inputType="number"
                                        min="0"
                                        value={this.props.numCar}
                                        onChangeSave={this.props.onChangeNumCar}
                                        width="3em"
                                    />
                                </span>
                                {this.props.area && this.props.areaType && (
                                    <span className="ml-3">
                                        <FaVectorSquare
                                            style={{
                                                verticalAlign: "text-bottom",
                                            }}
                                        ></FaVectorSquare>{" "}
                                        {this.props.area}{" "}
                                        {this.getAreaType(this.props.areaType)}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    {!this.props.loading &&
                        this.props.median &&
                        this.props.lowerQuantile &&
                        this.props.upperQuantile && (
                            <>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <PriceRange
                                        median={this.props.median}
                                        lowerQuantile={this.props.lowerQuantile}
                                        upperQuantile={this.props.upperQuantile}
                                        count={this.props.count}
                                        type={this.props.type}
                                    />
                                </Col>
                            </>
                        )}
                </Row>
                <Loading loading={this.props.loading} />
            </Fragment>
        );
    }
}

export default PropertyInfo;
