import React, { Component } from "react";
import Helmet from "react-helmet";
import { getUA } from "react-device-detect";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            submitSuccessful: false,
            errorMessage: "",
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        axios({
            method: "POST",
            url:
                "https://m55zrx3rx2.execute-api.ap-southeast-2.amazonaws.com/dev/static-site-mailer",
            data: this.state,
        }).then((response) => {
            if (response.status === 200) {
                this.setState({
                    alertMessage: "Your message has been sent. Thank you.",
                    submitSuccessful: true,
                });
                this.resetForm();
            } else {
                this.setState({
                    alertMessage:
                        "There was an error sending your message. Please try again.",
                    submitSuccessful: false,
                });
            }
        });
    }

    resetForm() {
        this.setState({
            name: "",
            email: "",
            message: "",
        });
    }

    onNameChange(event) {
        this.setState({ name: event.target.value });
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value });
    }

    render() {
        return (
            <Container>
                <Helmet>
                    <title>{"Contact - Liveable"}</title>
                    <meta
                        property="description"
                        content={
                            "Liveable simplifies the Property Research process. We provide detailed insights on an area, neighbourhood, street, property to help you find your ideal place to live."
                        }
                    />
                    <meta property="og:title" content={"Contact - Liveable"} />
                    <link rel="canonical" href="https://liveable.co/contact" />
                    {/* Zendesk widget */}
                    {!getUA.includes("Prerender") &&
                        process.env.NODE_ENV === "production" && (
                            <script
                                id="ze-snippet"
                                src="https://static.zdassets.com/ekr/snippet.js?key=09814143-b86a-422d-a3bb-f9c58904826d"
                            ></script>
                        )}
                </Helmet>
                <Row className="mt-5 pb-2 text-center">
                    <Col>
                        <h1>Contact Us</h1>
                    </Col>
                </Row>
                <Row className="mt-2 pb-4 text-center">
                    <Col>
                        To get in touch with us, you can either use our{" "}
                        <strong>Live Chat</strong> function on this page, or use
                        the contact form below.
                    </Col>
                </Row>
                {this.state.alertMessage && (
                    <Row className="mt-3 text-center">
                        <Col>
                            <Alert
                                variant={
                                    this.state.submitSuccessful &&
                                    this.state.alertMessage
                                        ? "success"
                                        : "warning"
                                }
                            >
                                <Alert.Heading as="h5">
                                    {this.state.alertMessage}
                                </Alert.Heading>
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row className="mt-3 pb-5 text-center">
                    <Col xs={0} sm={2} md={3} lg={3} xl={3}></Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    // size="lg"
                                    placeholder="Name"
                                    value={this.state.name}
                                    onChange={this.onNameChange.bind(this)}
                                />
                                <Form.Control
                                    type="email"
                                    // size="lg"
                                    placeholder="Email address"
                                    className="mt-3"
                                    value={this.state.email}
                                    onChange={this.onEmailChange.bind(this)}
                                />
                                <Form.Control
                                    as="textarea"
                                    rows="6"
                                    type="text"
                                    // size="lg"
                                    placeholder="How can we help?"
                                    className="mt-3"
                                    value={this.state.message}
                                    onChange={this.onMessageChange.bind(this)}
                                />
                                <br />
                                <Button
                                    type="submit"
                                    // size="lg"
                                    variant="outline-primary"
                                    block
                                    onClick={this.handleSubmit.bind(this)}
                                    disabled={
                                        !this.state.message ||
                                        !this.state.name ||
                                        !this.state.email
                                    }
                                >
                                    Send Message
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={0} sm={2} md={3} lg={3} xl={3}></Col>
                </Row>
            </Container>
        );
    }
}

export default Contact;
