import React, { Component } from "react";
import { GoogleMapProvider } from "@googlemap-react/core";

import MapPanel from "../components/map/MapPanel";
import Geometry from "../components/map/Geometry";
import Location from "../components/map/Location";

import PropTypes from "prop-types";

/*
 */

export default class CensusArea extends Component {
    static propTypes = {
        la1_id: PropTypes.string.isRequired,
        la2_id: PropTypes.string.isRequired,
        la3_id: PropTypes.string.isRequired,
        la4_id: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            features: [],
        };
    }

    componentDidMount() {
        const url =
            process.env.REACT_APP_DATA_URL +
            "/metadata/" +
            this.props.la4_id +
            "/" +
            this.props.la3_id +
            "/" +
            this.props.la2_id +
            "_features.geojson";

        fetch(url)
            .then((response) => response.json())
            .then((data) => this.setState({ features: data.features }))
            .then(() => {
                //set the location of this census area based on the first feature,
                // or the one with matching LA1 ID
                let feature = this.state.features[0];
                this.setState({
                    latitude: feature.properties.la1_location.lat,
                    longitude: feature.properties.la1_location.lng,
                });
                for (feature of this.state.features) {
                    if (feature.properties.la1 === this.props.la1_id) {
                        this.setState({
                            latitude: feature.properties.la1_location.lat,
                            longitude: feature.properties.la1_location.lng,
                            area: feature,
                        });

                        break;
                    }
                }
            });
    }

    render() {
        // const { features } = this.state;
        return (
            <GoogleMapProvider>
                {this.state.latitude && this.state.longitude && (
                    <MapPanel
                        location={{
                            lat: this.state.latitude,
                            lng: this.state.longitude,
                        }}
                        height={"40vh"}
                        zoom={16}
                    />
                )}

                {this.state.area && (
                    <>
                        <Geometry
                            id={this.state.area.properties.la1}
                            geometry={this.state.area.geometry}
                            fill={"#165582"}
                            location={this.state.area.properties.la1_location}
                            opacity={0.8}
                        />
                        <Location
                            location={this.state.area.properties.la1_location}
                            text={this.props.la1_id}
                        />
                    </>
                )}
            </GoogleMapProvider>
        );
    }
}
