import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import AddressTextBox from "../components/AddressTextBox";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { UserContext } from "../App";

import { FaUserFriends, FaChartLine, FaMapMarkedAlt } from "react-icons/fa";

import "./Home.css";

RentHome.propTypes = {
    history: PropTypes.object,
};

export default function RentHome(props) {
    const [state, dispatch] = React.useContext(UserContext);

    function goToLogin() {
        const location = {
            pathname: "/login",
            state: { from: { pathname: "/" } },
        };
        props.history.push(location);
    }

    return (
        <Fragment>
            <Helmet>
                <style type="text/css">{`
                        .navbar.header {
                            display: none;
                        }

                    `}</style>
                <title>{"Rental Market Research Simplified - Liveable"}</title>
                <meta
                    property="og:title"
                    content={"Rental Market Research Simplified - Liveable"}
                />
                <meta
                    name="description"
                    content="Get the full picture of what the current rental market is in like around a specific property"
                />
                <meta
                    property="og:description"
                    content="Get the full picture of what the current rental market is in like around a specific property"
                />
                <link rel="canonical" href="https://liveable.co/rent" />
                <meta property="og:url" content="https://liveable.co/rent" />
                <meta
                    property="og:image"
                    content="https://liveable.co/images/area_splash_bg.jpeg"
                />
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co",
                        name: "Liveable - Property Research Simplified",
                        description:
                            "Get the full picture of a property and its surroundings. Liveable helps take the guesswork out of finding an ideal place to live",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic
                    type="webPage"
                    jsonldtype="WebPage"
                    schema={{
                        name: "Rental Market Research Simplified - Liveable",
                        description:
                            "Get the full picture of what the current rental market is in like around a specific property",
                        url: "https://liveable.co/rent",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    <GenericCollection type="itemListElement">
                        <Generic
                            jsonldtype="ListItem"
                            schema={{
                                position: 1,
                                name: "Rent",
                                item: "https://liveable.co/rent",
                            }}
                        />
                    </GenericCollection>
                </Generic>
            </JSONLD>
            <Container fluid className="header-bg-area-splash">
                <Container>
                    <Navbar
                        className="justify-content-between pt-3"
                        variant="dark"
                        expand="sm"
                    >
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src="https://liveable.co/images/liveable_logo_top.png"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Fragment>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>
                                <Col className="px-0">
                                    <Navbar.Text className="m-0"></Navbar.Text>
                                </Col>

                                <Nav className="px-0">
                                    {state.isAuthenticated ? (
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                dispatch({
                                                    type: "LOGOUT",
                                                })
                                            }
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                variant="warning"
                                                onClick={goToLogin}
                                            >
                                                Login
                                            </Button>
                                        </>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    </Navbar>
                    <Row
                        id="header-cta"
                        style={{ paddingTop: "11vh", paddingBottom: "10vh" }}
                    >
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <h1 className="header-text">
                                Rental Market Research Simplified
                            </h1>
                            <h4 className="header-text">
                                Get the full picture of what the rental market
                                is like around your property
                            </h4>
                            <div className="mt-4">
                                <AddressTextBox type="rent" search="address" />
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                    </Row>
                </Container>
            </Container>
            <Container
                fluid
                className="py-5 background-grey liveable-blue text-center"
            >
                <Container>
                    <Row>
                        <Col>
                            <h3 className="liveable-blue">
                                Insights to help you understand the current
                                rental market
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaChartLine />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>Price Insights</Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        What are the current prices of
                                        comparable properties going for around
                                        your property? We provide statistics
                                        based on live data from our listings
                                        partner, Domain, to help get a better
                                        understanding of current prices.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaMapMarkedAlt />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        How Your Property Compares
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Knowing prices of what&#39;s on the
                                        market helps, but it&#39;s also about
                                        knowing what actually is on the market
                                        and the proximity of the properties
                                        around you. Something closer to your
                                        property will have more of an impact
                                        than something a couple of blocks away.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            className="pt-3 text-center"
                        >
                            <Card bg="light">
                                <Card.Header>
                                    <h1 className="liveable-blue">
                                        <FaUserFriends />
                                    </h1>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        Tenant / Landlord Negotiations
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-2 text-muted">
                                        Understanding what the current rental
                                        market is around a property or an area
                                        can help with rental negotiations
                                        between tenants and landlords especially
                                        during times of uncertainty.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    );
}
