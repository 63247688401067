import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { API } from "aws-amplify";
import {
    slugify,
    convertDashToPlus,
    addressStripCountry,
    whatsMyLimit,
    titleCase,
    convertUnderscoreToDash,
    moneyFormatter,
    getSlugCount,
    storeSlug,
    convertNameToId,
} from "../Helpers";
import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import { getUA } from "react-device-detect";
import { Link } from "react-router-dom";
import ScrollUp from "react-scroll-up";

import { FaRegArrowAltCircleUp } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import HeaderNav from "../components/navbar/HeaderNav";
import PropertyInfo from "../components/PropertyInfo";
import ComparableProperties from "../components/ComparableProperties";
import PropertyImagePanel from "../components/PropertyImagePanel";
import CallToActionFooter from "../components/navbar/CallToActionFooter";

import { UserContext } from "../App";

import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";

Profile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            addressSlug: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function Profile(props) {
    const RENTAL_SLUGS_STORAGE_VARIABLE = "rentSlugs";

    const [state] = React.useContext(UserContext);

    const [addressSlug, setAddressSlug] = useState(undefined);
    const [lotNumber, setLotNumber] = useState(undefined);
    const [address, setAddress] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [numBed, setNumBed] = useState(undefined);
    const [numBath, setNumBath] = useState(undefined);
    const [numCar, setNumCar] = useState(undefined);
    const [area, setArea] = useState(undefined);
    const [areaType, setAreaType] = useState(undefined);
    const [dwellingType, setDwellingType] = useState(undefined);
    const [images, setImages] = useState([]);
    // const [types, setTypes] = useState(undefined);
    const [la2_id, setLa2_id] = useState(undefined);
    const [la2_name, setLa2_name] = useState(undefined);
    const [la3_id, setLa3_id] = useState(undefined);
    const [la3_name, setLa3_name] = useState(undefined);
    const [la4_id, setLa4_id] = useState(undefined);

    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [description, setDescription] = useState(undefined);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        /**
         * Get la1, la2 from lat/lng and update the state
         *
         */
        async function getLiveableArea(lat, lng) {
            let liveable_url =
                process.env.REACT_APP_LIVEABLE_DATA_API_URL +
                "/v1/la/sydney/?lat=" +
                lat +
                "&lng=" +
                lng;

            return fetch(liveable_url)
                .then((response) => response.json())
                .then((data) => {
                    setLa2_id(data.la2);
                    setLa3_id(data.la3);
                    setLa4_id(data.la4);
                    setLa2_name(data.la2_name);
                    setLa3_name(data.la3_name);
                });
            // }
        }

        try {
            API.get(
                "properties",
                `/address/${props.match.params.addressSlug}`
            ).then((property) => {
                if (property.count > 0 && property.result[0].addressSlug) {
                    // if there are multiple properties with the same address slug, we take the first one
                    storeSlug(
                        property.result[0].addressSlug,
                        RENTAL_SLUGS_STORAGE_VARIABLE
                    );

                    setAddressSlug(property.result[0].addressSlug);
                    setLotNumber(property.result[0].lotNumber);
                    setAddress(
                        addressStripCountry(property.result[0].formattedAddress)
                    );
                    setLatitude(property.result[0].lat);
                    setLongitude(property.result[0].lng);
                    setNumBed(property.result[0].numBed);
                    setNumBath(property.result[0].numBath);
                    setNumCar(property.result[0].numCar);
                    setArea(property.result[0].area);
                    setAreaType(property.result[0].areaType);
                    setDwellingType(property.result[0].dwellingType);
                    setImages(property.result[0].images);
                    setLa2_id(property.result[0].la2);
                    setLa3_id(property.result[0].la3);
                    setLa4_id(property.result[0].la4);
                    if (property.result[0].la2) {
                        setLa2_name(titleCase(property.result[0].la2, "_"));
                    }

                    if (property.result[0].la3) {
                        setLa3_name(titleCase(property.result[0].la3, "_"));
                    }

                    if (
                        !property.result[0].la2 ||
                        !property.result[0].la3 ||
                        !property.result[0].la4
                    ) {
                        getLiveableArea(
                            property.result[0].lat,
                            property.result[0].lng
                        );
                    }
                    setLoading(false);
                    // TODO: Work out what to do with multiple properties having the same addressSlug
                    //} else if (property.count > 1) {
                } else {
                    // retrieve the lat/lng from the address slug
                    if (getUA.includes("Prerender")) {
                        let here_geocode_url =
                            "https://geocoder.api.here.com/6.2/geocode.json?app_id=" +
                            process.env.REACT_APP_ID_HERE +
                            "&app_code=" +
                            process.env.REACT_APP_CODE_HERE +
                            "&searchtext=" +
                            convertDashToPlus(props.match.params.addressSlug);

                        fetch(here_geocode_url)
                            .then((response) => response.json())
                            .then((data) => {
                                const view = data.Response.View;
                                if (
                                    view.length > 0 &&
                                    view[0].Result.length > 0
                                ) {
                                    const location = view[0].Result[0].Location;

                                    setLatitude(
                                        location.DisplayPosition.Latitude
                                    );
                                    setLongitude(
                                        location.DisplayPosition.Longitude
                                    );
                                    setAddressSlug(
                                        slugify(location.Address.Label)
                                    );
                                    setLotNumber(0);
                                    setAddress(
                                        addressStripCountry(
                                            location.Address.Label
                                        )
                                    );

                                    getLiveableArea(
                                        location.DisplayPosition.Latitude,
                                        location.DisplayPosition.Longitude
                                    );
                                }
                            });
                    } else {
                        geocodeByAddress(
                            convertDashToPlus(props.match.params.addressSlug)
                        ).then((results) => {
                            storeSlug(
                                results[0].addressSlug,
                                RENTAL_SLUGS_STORAGE_VARIABLE
                            );

                            setAddressSlug(
                                slugify(results[0].formatted_address)
                            );
                            setLotNumber(0);
                            setAddress(
                                addressStripCountry(
                                    results[0].formatted_address
                                )
                            );

                            getLatLng(results[0]).then(({ lat, lng }) => {
                                setLatitude(lat);
                                setLongitude(lng);
                                getLiveableArea(lat, lng);
                            });
                        });
                    }

                    setLoading(false);
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }, [props.history, props.match.params.addressSlug]);

    useEffect(() => {
        const description =
            address +
            " has " +
            listingState.count +
            " similar properties (" +
            (numBed === undefined || isNaN(numBed) ? "-" : numBed) +
            " bed, " +
            (numBath === undefined || isNaN(numBath) ? "-" : numBath) +
            " bath, " +
            (numCar === undefined || isNaN(numCar) ? "-" : numCar) +
            " car) " +
            "with a median rental price of " +
            moneyFormatter.format(listingState.median) +
            " per week. " +
            "The rental price range for the comparable " +
            "nearby properties is " +
            moneyFormatter.format(listingState.lower_quantile) +
            " to " +
            moneyFormatter.format(listingState.upper_quantile) +
            " per week";

        setDescription(description);
    }, [listingState, address, numBed, numBath, numCar]);

    function handleChangeNumBed(bed) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numBed: bed,
        });
    }

    function handleChangeNumBath(bath) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numBath: bath,
        });
    }

    function handleChangeNumCar(car) {
        savePropertyDetails({
            addressSlug: addressSlug,
            lotNumber: lotNumber,
            formattedAddress: address,
            lat: latitude,
            lng: longitude,
            numCar: car,
        });
    }

    function savePropertyDetails(propertyDetails) {
        return API.put("properties", `/address/${addressSlug}`, {
            body: propertyDetails,
        });
    }

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    return (
        <Fragment>
            {address && (
                <Helmet>
                    <title>
                        {"Rental Market Report: " + address + " - Liveable"}
                    </title>
                    <meta
                        property="og:title"
                        content={
                            "Rental Market Report: " + address + " - Liveable"
                        }
                    />
                    {description && (
                        <meta name="description" content={description} />
                    )}
                    {description && (
                        <meta property="og:description" content={description} />
                    )}
                    {images && images[0] && (
                        <meta
                            property="og:image"
                            content={
                                process.env.REACT_APP_IMAGE_BUCKET_URL +
                                "/" +
                                images[0].substr(
                                    0,
                                    images[0].lastIndexOf(".")
                                ) +
                                "-800.jpg"
                            }
                        />
                    )}
                    {addressSlug && (
                        <meta
                            property="og:url"
                            content={"https://liveable.co/rent/" + addressSlug}
                        />
                    )}
                    {addressSlug && (
                        <link
                            rel="canonical"
                            href={"https://liveable.co/rent/" + addressSlug}
                        />
                    )}
                    {/* Since paywalled, tell Google not to have a Cached view for the page on searches */}
                    <meta name="robots" content="noarchive" />

                    {/* Zendesk widget */}
                    {!getUA.includes("Prerender") &&
                        process.env.NODE_ENV === "production" && (
                            <script
                                id="ze-snippet"
                                src="https://static.zdassets.com/ekr/snippet.js?key=09814143-b86a-422d-a3bb-f9c58904826d"
                            ></script>
                        )}
                </Helmet>
            )}

            {address && description && (
                <JSONLD>
                    <Generic
                        type="webPage"
                        jsonldtype="WebPage"
                        schema={{
                            name: "Rental Market Report Report: " + address,
                            content: description,
                            url:
                                "https://liveable.co" + props.location.pathname,
                            isAccessibleForFree: "False",
                        }}
                    >
                        <Generic
                            type="hasPart"
                            jsonldtype="WebPageElement"
                            schema={{
                                isAccessibleForFree: "False",
                                cssSelector: ".paywall",
                            }}
                        />
                    </Generic>
                </JSONLD>
            )}
            {address && la4_id && la3_name && la2_name && (
                <JSONLD>
                    <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                        <GenericCollection type="itemListElement">
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 1,
                                    name: "Rental Market Report",
                                    item: "https://liveable.co/rent/",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 2,
                                    name: address,
                                    item:
                                        "https://liveable.co/rent/" +
                                        props.match.params.addressSlug,
                                }}
                            />
                        </GenericCollection>
                    </Generic>
                </JSONLD>
            )}

            <HeaderNav
                headings={[
                    {
                        label: "Property",
                        link: "#property",
                    },
                    {
                        label: "Comparable Rental Listings",
                        link: "#rent",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pb-5 pt-4">
                <Alert variant="warning" className="pb-2">
                    <Alert.Heading>
                        Rental information relating to COVID-19
                    </Alert.Heading>
                    <p>
                        Given the current circumstances along with Federal and
                        State Government directives, we encourage you to check
                        official information provided by your state body.
                    </p>
                    <p>
                        <strong>New South Wales</strong>:
                        <a href="https://www.fairtrading.nsw.gov.au/resource-library/publications/coronavirus-covid-19/property/moratorium">
                            {" "}
                            https://www.fairtrading.nsw.gov.au/resource-library/publications/coronavirus-covid-19/property/moratorium
                        </a>
                        <br />
                        <strong>Victoria</strong>:
                        <a href="https://www.consumer.vic.gov.au/resources-and-tools/advice-in-a-disaster/coronavirus-covid19-and-your-rights">
                            {" "}
                            https://www.consumer.vic.gov.au/resources-and-tools/advice-in-a-disaster/coronavirus-covid19-and-your-rights
                        </a>
                        <br />
                        <strong>Queensland</strong>:
                        <a href="https://tenantsqld.org.au/coronavirus-covid-19-information-2/">
                            {" "}
                            https://tenantsqld.org.au/coronavirus-covid-19-information-2/
                        </a>
                        <br />
                        <strong>South Australia</strong>:
                        <a href="https://www.cbs.sa.gov.au/rental-advice-due-covid-19">
                            {" "}
                            https://www.cbs.sa.gov.au/rental-advice-due-covid-19
                        </a>
                        <br />
                        <strong>Western Australia</strong>:
                        <a href="https://www.commerce.wa.gov.au/consumer-protection/residential-tenancies-covid-19-response">
                            {" "}
                            https://www.commerce.wa.gov.au/consumer-protection/residential-tenancies-covid-19-response
                        </a>
                        <br />
                        <strong>Tasmania</strong>:
                        <a href="https://www.cbos.tas.gov.au/topics/housing/residential-tenancies-covid-19-emergency-provisions">
                            {" "}
                            https://www.cbos.tas.gov.au/topics/housing/residential-tenancies-covid-19-emergency-provisions
                        </a>
                        <br />
                        <strong>ACT</strong>:
                        <a href="https://www.covid19.act.gov.au/news-articles/fact-sheets-for-landlords-and-tenants">
                            {" "}
                            https://www.covid19.act.gov.au/news-articles/fact-sheets-for-landlords-and-tenants
                        </a>
                        <br />
                        <strong>Northern Territory</strong>:
                        <a href="https://nt.gov.au/property/renters/renters-your-rights-and-responsibilities/common-tenancy-disputes">
                            {" "}
                            https://nt.gov.au/property/renters/renters-your-rights-and-responsibilities/common-tenancy-disputes
                        </a>
                    </p>
                </Alert>
                {/* Breadcrumb */}
                {la2_id && la2_name && la3_id && la3_name && la4_id && (
                    <section id="breadcrumb" className="text-muted">
                        <Row>
                            <Col>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id)
                                    }
                                >
                                    {titleCase(la4_id, "_")}
                                </Link>
                                <span className="px-2">&gt;</span>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name)
                                    }
                                >
                                    {la3_name}
                                </Link>
                                <span className="px-2">&gt;</span>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertNameToId(la3_name) +
                                        "/" +
                                        convertNameToId(la2_name)
                                    }
                                >
                                    {titleCase(la2_id, "_")}
                                </Link>
                            </Col>
                        </Row>
                    </section>
                )}
                {/* Property Info */}
                <section id="property" className="pt-2">
                    <Row>
                        <Col>
                            <PropertyInfo
                                addressSlug={addressSlug}
                                address={address}
                                numBed={numBed}
                                numBath={numBath}
                                numCar={numCar}
                                area={area}
                                areaType={areaType}
                                dwellingType={dwellingType}
                                lat={latitude}
                                lng={longitude}
                                onChangeNumBed={handleChangeNumBed}
                                onChangeNumBath={handleChangeNumBath}
                                onChangeNumCar={handleChangeNumCar}
                                median={listingState.median}
                                lowerQuantile={listingState.lower_quantile}
                                upperQuantile={listingState.upper_quantile}
                                count={listingState.count}
                                type={"rent"}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                    {/* Property Images */}
                    {!getUA.includes("Prerender") &&
                        !isEmpty(images) &&
                        images.length > 1 && (
                            <PropertyImagePanel
                                imageBucketUrl={
                                    process.env.REACT_APP_IMAGE_BUCKET_URL
                                }
                                images={images}
                                address={address}
                            />
                        )}
                </section>
                <hr />
                {/* Rental Listings */}
                <section id="rent" className="pt-4 paywall">
                    <Row>
                        <Col>
                            <h2>Comparable Rental Listings</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {address && listingState && listingState.count
                                ? description
                                : "Listings for similar properties for rent: " +
                                  (numBed === undefined ? "-" : numBed) +
                                  "bed, " +
                                  (numBath === undefined ? "-" : numBath) +
                                  "bath and " +
                                  (numCar === undefined ? "-" : numCar) +
                                  "car."}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {getSlugCount(RENTAL_SLUGS_STORAGE_VARIABLE) <=
                                whatsMyLimit(state.isAuthenticated) ||
                            state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <PropertiesContext.Provider
                                        value={{ listingState, dispatch }}
                                    >
                                        <ComparableProperties
                                            addressSlug={addressSlug}
                                            cardType="rent"
                                            latitude={latitude}
                                            longitude={longitude}
                                            address={address}
                                            numBed={numBed}
                                            numBath={numBath}
                                            numCar={numCar}
                                            dwellingType={dwellingType}
                                            showHome={true}
                                            limitOverride={120}
                                        ></ComparableProperties>
                                    </PropertiesContext.Provider>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <Alert.Heading>
                                        You have reached your limit
                                    </Alert.Heading>
                                    <Alert.Heading className="mt-4" as="h5">
                                        {!state.isAuthenticated
                                            ? "Login"
                                            : "Subscribe"}{" "}
                                        to view Comparable Rental Listings
                                    </Alert.Heading>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        onClick={() =>
                                            state.isAuthenticated
                                                ? goToPage("pricing")
                                                : goToPage("login")
                                        }
                                    >
                                        {state.isAuthenticated
                                            ? "Subscribe"
                                            : "Login"}
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>

            {/* If user is authenticated but is on the Free plan and hits limit, redirect to Pricing page */}
            {!getUA.includes("Prerender") &&
                getSlugCount(RENTAL_SLUGS_STORAGE_VARIABLE) >
                    whatsMyLimit(state.isAuthenticated) &&
                state.isAuthenticated &&
                state.userPlan === "Free" && (
                    <Redirect
                        to={{
                            pathname: "/pricing",
                            state: {
                                from: { pathname: props.location.pathname },
                                message:
                                    "You have reached your Free plan limit",
                            },
                        }}
                    />
                )}

            {!getUA.includes("Prerender") && state.userPlan !== "Pro" && (
                <CallToActionFooter
                    slugStoreVar={RENTAL_SLUGS_STORAGE_VARIABLE}
                />
            )}
        </Fragment>
    );
}
