import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import HeaderNav from "../components/navbar/HeaderNav";

import ScrollUp from "react-scroll-up";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
/*
Agency:
https://wwzcwh15el.execute-api.ap-southeast-2.amazonaws.com/dev/agent/get_agency/<string:agency_slug>

Agent:
https://wwzcwh15el.execute-api.ap-southeast-2.amazonaws.com/dev/agent/get_agent/<string:agency_slug>/<string:agent_slug>
PRODUCTION

Agency:
https://api.liveable.co/agent/get_agency/<string:agency_slug>

Agent:
https://api.liveable.co/agent/get_agent/<string:agency_slug>/<string:agent_slug>

*/

const agent_url = process.env.REACT_APP_LIVEABLE_AGENT_API_URL + "/get_agent";

Agent.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            agencySlug: PropTypes.string,
            agentSlug: PropTypes.string,
        }),
    }),
};
export default function Agent(props) {
    const [agencySlug] = useState(
        props.match.params.agencySlug
            ? props.match.params.agencySlug
            : undefined
    );
    const [agentSlug] = useState(
        props.match.params.agentSlug ? props.match.params.agentSlug : undefined
    );

    const [agent, setAgent] = useState(undefined);

    useEffect(() => {
        try {
            const url = agent_url + "/" + agencySlug + "/" + agentSlug;
            // console.log("useEffect", url, agencySlug);
            fetch(url)
                .then((response) => response.json())
                .then((d) => {
                    setAgent(d.result);
                });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }, [agencySlug, agentSlug]);

    return (
        <>
            <Helmet>
                {agent && (
                    <title>
                        {agent.agencyName +
                            ": " +
                            agent.agentName +
                            " - Liveable"}
                    </title>
                )}
                {agent && (
                    <meta
                        property="og:title"
                        content={
                            agent.agencyName +
                            ": " +
                            agent.agentName +
                            " - Liveable"
                        }
                    />
                )}
                {agent && (
                    <meta
                        name="description"
                        content={
                            agent.agentName +
                            " of " +
                            agent.agencyName +
                            ". View in-depth statistics on their properties and areas covered."
                        }
                    />
                )}
                {agent && (
                    <meta
                        name="og:description"
                        content={
                            agent.agentName +
                            " of " +
                            agent.agencyName +
                            ". View in-depth statistics on their properties and areas covered."
                        }
                    />
                )}
                {agencySlug && agentSlug && (
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/agent/" +
                            agencySlug +
                            "/" +
                            agentSlug
                        }
                    />
                )}
                {agencySlug && agentSlug && (
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/agent/" +
                            agencySlug +
                            "/" +
                            agentSlug
                        }
                    />
                )}
                {agent && agent.agentPhoto && (
                    <meta property="og:image" content={agent.agentPhoto} />
                )}
            </Helmet>
            <JSONLD>
                <Generic type="breadcrumbList" jsonldtype="BreadcrumbList">
                    {agent && (
                        <GenericCollection type="itemListElement">
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 1,
                                    name: "Liveable",
                                    item: "https://liveable.co",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 2,
                                    name: "Agent",
                                    item: "https://liveable.co/agent",
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 3,
                                    name: agent.agencyName,

                                    item:
                                        "https://liveable.co/agent/" +
                                        agencySlug,
                                }}
                            />
                            <Generic
                                jsonldtype="ListItem"
                                schema={{
                                    position: 4,
                                    name: agent.agentName,

                                    item:
                                        "https://liveable.co/agent/" +
                                        agencySlug +
                                        "/" +
                                        agentSlug,
                                }}
                            />
                        </GenericCollection>
                    )}
                </Generic>
            </JSONLD>
            <HeaderNav
                headings={[
                    {
                        label: "Agent",
                        link: "#agent",
                    },
                    {
                        label: "Areas Covered",
                        link: "#areas-covered",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pt-4 pb-5">
                <section id="agent">
                    {agent && <Row className="mt-3"></Row>}
                </section>
                <hr />
            </Container>
        </>
    );
}
