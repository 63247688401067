import React from "react";
import PropTypes from "prop-types";

import Card from "react-bootstrap/Card";

AgentCard.propTypes = {
    agentId: PropTypes.number,
    agentSlug: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string,
    title: PropTypes.string,
    agencyLogo: PropTypes.string,
};

export default function AgentCard(props) {
    function addDefaultSrc(ev) {
        // console.log(props.name);
        ev.target.src =
            process.env.REACT_APP_IMAGE_BUCKET_URL +
            "/agents/profile-placeholder.png";
    }

    return (
        <Card className="h-100">
            <Card.Img
                variant="top"
                src={process.env.REACT_APP_IMAGE_BUCKET_URL + "/" + props.photo}
                max-width={"110px"}
                onError={addDefaultSrc}
                className="p-1"
            />
            <Card.Body className="text-center px-3 py-2">
                <Card.Title>
                    {props.agentId && props.agentSlug ? (
                        <a
                            href={
                                "/agent/" +
                                props.agentId +
                                "/" +
                                props.agentSlug
                            }
                            className="stretched-link"
                        >
                            {props.name}
                        </a>
                    ) : (
                        <h4>{props.name}</h4>
                    )}
                </Card.Title>
                <Card.Text>{props.title}</Card.Text>
            </Card.Body>
            {props.agencyLogo && (
                <Card.Footer>
                    <Card.Img
                        variant="bottom"
                        src={
                            process.env.REACT_APP_IMAGE_BUCKET_URL +
                            "/" +
                            props.agencyLogo
                        }
                        max-width={"110px"}
                        className="p-1"
                    />
                </Card.Footer>
            )}
        </Card>
    );
}
