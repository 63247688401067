import React, { Component } from "react";
import PropTypes from "prop-types";

class EditableTextInput extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        inputType: PropTypes.string.isRequired,
        min: PropTypes.string.isRequired,
        onChangeSave: PropTypes.func.isRequired,
        width: PropTypes.string.isRequired,
    };

    state = {
        value: this.props.value,
        isInEditMode: false,
    };

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.setState({ isInEditMode: false });
    };

    changeEditMode = () => {
        this.setState({ isInEditMode: !this.state.isInEditMode });
    };

    updateComponentValue = () => {
        // eslint-disable-next-line react/no-string-refs
        if (this.refs.textInputField.value !== "") {
            this.setState({
                isInEditMode: false,
                // eslint-disable-next-line react/no-string-refs
                value: this.refs.textInputField.value,
            });
            // eslint-disable-next-line react/no-string-refs
            this.props.onChangeSave(this.refs.textInputField.value);
        } else {
            this.setState({
                isInEditMode: false,
                value: null,
            });
        }
    };

    renderEditView = () => {
        return (
            <span>
                <input
                    type={this.props.inputType}
                    min={this.props.min}
                    defaultValue={this.state.value}
                    ref="textInputField" // eslint-disable-line react/no-string-refs
                    autoFocus
                    style={{ width: this.props.width }}
                    // onBlur={this.updateComponentValue}
                />
                <button
                    type="button"
                    aria-label="Close"
                    onClick={this.changeEditMode}
                >
                    X
                </button>
                <button onClick={this.updateComponentValue}>OK</button>
            </span>
        );
    };

    renderDefaultView = () => {
        return this.props.value === undefined &&
            this.state.value === undefined ? (
            <span onClick={this.changeEditMode}>-</span>
        ) : this.props.value === undefined && this.state.value !== undefined ? (
            <span onClick={this.changeEditMode}>{this.state.value}</span>
        ) : (
            <span>{this.state.value}</span>
        );
    };

    render() {
        return (
            <span ref={(node) => (this.node = node)}>
                {this.state.isInEditMode
                    ? this.renderEditView()
                    : this.renderDefaultView()}
            </span>
        );
        // return this.state.isInEditMode
        //   ? this.renderEditView()
        //   : this.renderDefaultView();
    }
}

export default EditableTextInput;
