import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";

import CardTableItemMobile from "./CardTableItemMobile";

class LASchoolCard extends Component {
    static propTypes = {
        base_url: PropTypes.string,
        la3_id: PropTypes.string,
        la2_id: PropTypes.string,
        name: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };

    constructor(props) {
        super(props);
        this.state = {
            base_url: this.props.base_url,
            la3_id: this.props.la3_id,
            la2_id: this.props.la2_id,
            name: this.props.name,
            data: this.props.data,
        };
    }
    render() {
        return (
            <Card bg="light">
                <Card.Link
                    href={
                        this.state.base_url +
                        "/" +
                        this.state.la3_id +
                        "/" +
                        this.state.la2_id
                    }
                >
                    <Card.Body>
                        <Card.Title className="text-center pb-3">
                            {this.state.name}
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            {this.state.data.map((item, index) => (
                                <CardTableItemMobile
                                    label={item.label}
                                    data={item.data}
                                    label_size={1.4}
                                    key={index + "_" + item.label}
                                />
                            ))}
                        </Card.Subtitle>
                    </Card.Body>
                </Card.Link>
            </Card>
        );
    }
}

export default LASchoolCard;
