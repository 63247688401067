import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Scrollspy from "react-scrollspy";
import { Link } from "react-scroll";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

class HeaderNav extends Component {
    static propTypes = { headings: PropTypes.array };

    constructor(props) {
        super(props);
        this.state = { links: [] };
    }

    componentDidMount() {
        let linkArray = [];
        this.props.headings.map((heading) =>
            linkArray.push(heading.link.replace("#", ""))
        );
        this.setState({ links: linkArray });
    }

    render() {
        return (
            <Navbar
                id="nav-header"
                style={{ backgroundColor: "#eeeeee" }}
                sticky="top"
                className="py-0"
            >
                <Container>
                    <Nav className="mr-auto carousel-wrapper">
                        <div className="carousel-card">
                            <Scrollspy
                                items={this.state.links}
                                currentClassName="active"
                                offset={-30}
                            >
                                {!isEmpty(this.props.headings) &&
                                    this.props.headings.map(
                                        (heading, index) => (
                                            <li
                                                key={"NavLink_heading_" + index}
                                            >
                                                <Link
                                                    to={heading.link.replace(
                                                        "#",
                                                        ""
                                                    )}
                                                    key={
                                                        "NavLink_heading_" +
                                                        heading.label
                                                    }
                                                    id={
                                                        "NavLink_heading_" +
                                                        heading.label
                                                    }
                                                    offset={-40}
                                                    smooth={true}
                                                    className="pointer"
                                                >
                                                    {heading.label}
                                                </Link>
                                                {index !==
                                                this.props.headings.length -
                                                    1 ? (
                                                    <span className="mx-3 disabled">
                                                        &#8226;
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </li>
                                        )
                                    )}
                            </Scrollspy>
                        </div>
                    </Nav>
                </Container>
            </Navbar>
        );
    }
}

export default HeaderNav;
