import React, { Component } from "react";
import PropTypes from "prop-types";

import Card from "react-bootstrap/Card";

class AreaCard extends Component {
    static propTypes = {
        base_url: PropTypes.string,
        name_id: PropTypes.string,
        name: PropTypes.string,
        postal: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    constructor(props) {
        super(props);
        this.state = {
            base_url: this.props.base_url,
            name_id: this.props.name_id,
            name: this.props.name,
            postal: this.props.postal,
        };
    }
    render() {
        return (
            <Card bg="light" className="text-center">
                <Card.Link
                    href={
                        this.state.base_url
                            ? this.state.base_url + this.state.name_id
                            : this.state.name_id
                    }
                    rel="nofollow"
                >
                    <Card.Body>
                        <Card.Title>{this.state.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            {this.state.postal && this.state.postal.join(", ")}
                        </Card.Subtitle>
                    </Card.Body>
                </Card.Link>
            </Card>
        );
    }
}

export default AreaCard;
