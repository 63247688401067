import React, { Component, Fragment } from "react";
import isEmpty from "lodash.isempty";
import orderBy from "lodash/orderBy";
import styled from "styled-components";
import PropTypes from "prop-types";

import { GoogleMapProvider } from "@googlemap-react/core";
import Geohash from "latlon-geohash";
import { point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import wkx from "wkx";

import {
    titleCase,
    computeCentreLocationBetweenPoints,
    addAndSortByDistance,
    convertDistance,
    slugify,
    convertDashToUnderscore,
} from "../Helpers";

import MapPanel from "./map/MapPanel";
import Geometry from "./map/Geometry";
import Place from "./map/Place";
import CardTableItemMobile from "./CardTableItemMobile";
import Loading from "./Loading";

// Boostrap Imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { FaHome } from "react-icons/fa";

let Set = require("es6-set");

const catchment_colours = {
    PRIMARY: "#ffa800",
    HIGH_BOYS: "#315990",
    HIGH_GIRLS: "#e83e8c",
    HIGH_COED: "#28a745",
    CENTRAL_PRIMARY: "#6f42c1",
    CENTRAL_HIGH: "#dc3545",
};

const school_level = {
    Primary: "primary",
    Secondary: "secondary",
    "Specific Purposes": "other",
    "Central/Community": "other",
    "Environmental Education ": "other",
    Infants: "other",
    Other: "other",
    null: "other",
};

const yes_and_no = {
    Y: "Yes",
    N: "No",
    null: "No",
};

const NumberedMarker = styled.span`
    background-color: ${(props) => (props.color ? props.color : "#DC143C")};
    display: inline-block;
    padding: 0.1em 0.6em;
    border: 2px solid #fff;
    border-radius: 100%;
`;

class Schools extends Component {
    static propTypes = {
        addressSlug: PropTypes.string,
        address: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        la2_id: PropTypes.string.isRequired,
        areaOnly: PropTypes.bool,
        showHome: PropTypes.bool,
        schoolsCallback: PropTypes.func,
        hideMiniMap: PropTypes.bool,
        enableSorting: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            addressSlug: undefined,
            isDesktop: false,
            schools: [],
            school_level: "all",
            sort_by: [
                "level_of_schooling",
                "distance",
                "school_name",
                "school_subtype",
            ],
            loading: true,
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    computeCentreLocation(schools) {
        let lat = 0;
        let lng = 0;

        if (schools.length > 0) {
            const geohash_set = new Set();

            for (let i = 0; i < schools.length; i++) {
                lat += schools[i].lat;
                lng += schools[i].lng;
                const geohash = Geohash.encode(
                    schools[i].lat,
                    schools[i].lng,
                    9
                );

                if (geohash_set.has(geohash)) {
                    // Geohash.adjacent(geohash, direction): return adjacent cell to given geohash in specified direction (N/S/E/W).
                    const neighbour = Geohash.adjacent(geohash, "N"); //move the duplicate marker slightly west
                    const latlon = Geohash.decode(neighbour);
                    schools[i].lat = latlon.lat;
                    schools[i].lng = latlon.lon;

                    geohash_set.add(neighbour);
                } else {
                    geohash_set.add(geohash);
                }
            }

            lat = lat / schools.length;
            lng = lng / schools.length;

            return [lat, lng];
        }
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
        this.setState({ loading: true });

        this.setState({ school_level: "all" });

        let schools_url =
            "https://data.liveable.co/schools/sydney/" +
            convertDashToUnderscore(this.props.la2_id) +
            ".json";

        fetch(schools_url)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ schools: data, loading: false });
                this.props.schoolsCallback && this.sendSchoolsData(data);
            });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth >= 768 });
    }

    handleClickNavSchoolTypes = (param) => {
        this.setState({ school_level: param });
    };

    handleSort = (sort_key) => {
        this.setState({
            sort_by: sort_key.split("-")[0],
            order_by: sort_key.split("-")[1],
        });
    };

    sendSchoolsData = (data) => {
        this.props.schoolsCallback(data);
    };

    render() {
        const { isDesktop } = this.state;
        let { schools } = this.state;

        if (
            !isEmpty(schools) &&
            !this.props.areaOnly &&
            this.props.latitude &&
            this.props.longitude
        ) {
            let schools_within = schools.filter(
                (school) =>
                    school.catchment &&
                    booleanPointInPolygon(
                        point([this.props.longitude, this.props.latitude]),
                        wkx.Geometry.parse(school.catchment).toGeoJSON()
                    )
            );

            schools = schools_within;
        }

        let schools_filtered = schools.filter(
            (school) =>
                (school_level[school.level_of_schooling] ===
                    this.state.school_level ||
                    this.state.school_level === "all") &&
                school.file_type !== "FUTURE"
        );

        schools_filtered = addAndSortByDistance(
            schools_filtered,
            this.props.latitude,
            this.props.longitude
        );

        schools_filtered = orderBy(
            schools_filtered,
            this.state.sort_by,
            this.state.order_by
        );

        if (schools_filtered) {
            this.computeCentreLocation(schools_filtered);
        }

        const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        return (
            <Fragment>
                {isDesktop && (
                    <Row className="mb-1">
                        <Col>
                            <GoogleMapProvider>
                                <MapPanel
                                    location={{
                                        lat: this.props.latitude,
                                        lng: this.props.longitude,
                                    }}
                                    height={"50vh"}
                                    zoom={13}
                                    enableZoom={true}
                                />
                                {this.props.latitude &&
                                    this.props.longitude &&
                                    this.props.showHome && (
                                        <Place
                                            key={this.state.addressSlug}
                                            location={{
                                                lat: this.props.latitude,
                                                lng: this.props.longitude,
                                            }}
                                            icon={<FaHome />}
                                            height="30px"
                                            width="30px"
                                            zindex={110}
                                        />
                                    )}
                                {!isEmpty(schools) &&
                                    schools_filtered.map((school, index) => (
                                        <Fragment key={index}>
                                            <Place
                                                key={
                                                    school.school_code +
                                                    "_place"
                                                }
                                                location={{
                                                    lat: parseFloat(school.lat),
                                                    lng: parseFloat(school.lng),
                                                }}
                                                text={school.school_name}
                                                color={
                                                    catchment_colours[
                                                        school.catch_type
                                                    ]
                                                }
                                                index={alpha.charAt(index)}
                                            />
                                            <Geometry
                                                key={
                                                    school.school_code +
                                                    "_geometry"
                                                }
                                                id={
                                                    school.school_code +
                                                    "_" +
                                                    index
                                                }
                                                name={school.school_name}
                                                geometry={
                                                    school.catchment &&
                                                    wkx.Geometry.parse(
                                                        school.catchment
                                                    ).toGeoJSON()
                                                }
                                                fill={
                                                    catchment_colours[
                                                        school.catch_type
                                                    ]
                                                }
                                                index={alpha.charAt(index)}
                                                location={{
                                                    lat: parseFloat(school.lat),
                                                    lng: parseFloat(school.lng),
                                                }}
                                            />
                                        </Fragment>
                                    ))}
                            </GoogleMapProvider>
                        </Col>
                    </Row>
                )}
                <Row className="pt-2">
                    <Col>
                        <Nav
                            className="justify-content-center flex-column flex-sm-row"
                            fill
                            variant="pills"
                            defaultActiveKey="all"
                            activeKey={this.state.school_level}
                        >
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavSchoolTypes("all", e)
                                    }
                                    eventKey="all"
                                >
                                    All Schools
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavSchoolTypes(
                                            "primary",
                                            e
                                        )
                                    }
                                    eventKey="primary"
                                >
                                    Primary
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavSchoolTypes(
                                            "secondary",
                                            e
                                        )
                                    }
                                    eventKey="secondary"
                                >
                                    Secondary
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavSchoolTypes(
                                            "other",
                                            e
                                        )
                                    }
                                    eventKey="other"
                                >
                                    Other
                                </Nav.Link>
                            </Nav.Item>
                            {this.props.enableSorting && (
                                <NavDropdown
                                    title="Sort By"
                                    id="sort-nav-dropdown"
                                    onSelect={this.handleSort}
                                    flip="false"
                                >
                                    <NavDropdown.Item eventKey="school_subtype-asc">
                                        Subtype: A-Z
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="school_subtype-desc">
                                        Subtype: Z-A
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="school_specialty_type-asc">
                                        Specialty: A-Z
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="school_specialty_type-desc">
                                        Specialty: Z-A
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="selective_school-asc">
                                        Selective: A-Z
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="selective_school-desc">
                                        Selective: Z-A
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="school_gender-asc">
                                        School Gender: A-Z
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="school_gender-desc">
                                        School Gender: Z-A
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="latest_year_enrolment_fte-asc">
                                        Enrolled Students: Low-High
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="latest_year_enrolment_fte-desc">
                                        Enrolled Students: High-Low
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="icsea_value-asc">
                                        ICSEA Value: Low-High
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="icsea_value-desc">
                                        ICSEA Value: High-Low
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="lbote_pct-asc">
                                        LBOTE: Low-High
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="lbote_pct-desc">
                                        LBOTE: High-Low
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                        </Nav>
                    </Col>
                </Row>
                {isDesktop ? (
                    <Table hover responsive className="mt-3">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-center">#</th>
                                <th>Name</th>
                                {!this.props.areaOnly && (
                                    <th className="text-center">Distance</th>
                                )}
                                <th className="text-center">Level</th>
                                <th className="text-center">Subtype</th>
                                <th className="text-center">
                                    Selective School
                                </th>
                                <th className="text-center">
                                    Opp. Class<sup>1</sup>
                                </th>
                                <th className="text-center">School Gender</th>
                                <th className="text-center">
                                    Enrolled Students
                                </th>
                                <th className="text-center">
                                    ICSEA Value<sup>2</sup>
                                </th>
                                <th className="text-center">
                                    LBOTE<sup>3</sup>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {schools_filtered.map((item, index) => (
                                <tr key={item.school_code + "_desc"}>
                                    <td className="align-middle text-center">
                                        <NumberedMarker
                                            style={{
                                                color: "#ffffff",
                                                backgroundColor:
                                                    catchment_colours[
                                                        item.catch_type
                                                    ],
                                            }}
                                        >
                                            {alpha.charAt(index)}
                                        </NumberedMarker>
                                    </td>
                                    <td className="align-middle">
                                        <a
                                            href={
                                                "/school/" +
                                                item.ageid +
                                                "/" +
                                                slugify(item.school_name)
                                            }
                                        >
                                            {item.school_name}
                                        </a>
                                        <div className="pt-3">
                                            <small>
                                                {item.street +
                                                    ", " +
                                                    (item.town_suburb &&
                                                        item.town_suburb !==
                                                            null &&
                                                        titleCase(
                                                            item.town_suburb,
                                                            " "
                                                        ))}
                                                <br />
                                                {item.school_email}
                                            </small>
                                        </div>
                                    </td>
                                    {!this.props.areaOnly && (
                                        <td className="align-middle text-center">
                                            {convertDistance(
                                                item.distance,
                                                "km"
                                            )}
                                        </td>
                                    )}
                                    <td className="align-middle text-center">
                                        {item.level_of_schooling}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.school_subtype}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.selective_school}
                                    </td>
                                    <td className="align-middle text-center">
                                        {yes_and_no[item.opportunity_class]}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.school_gender}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.latest_year_enrolment_fte &&
                                            parseFloat(
                                                item.latest_year_enrolment_fte
                                            ).toFixed(1)}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.icsea_value &&
                                            parseFloat(
                                                item.icsea_value
                                            ).toFixed(1)}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item.lbote_pct && item.lbote_pct + "%"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    schools_filtered.map((item, index) => (
                        <Row className="mt-3" key={index + "_row"}>
                            <Col>
                                <Card key={index + "_card"}>
                                    <Card.Header>
                                        <strong>
                                            <NumberedMarker
                                                style={{
                                                    color: "#ffffff",
                                                    backgroundColor:
                                                        catchment_colours[
                                                            item.catch_type
                                                        ],
                                                }}
                                            >
                                                {alpha.charAt(index)}
                                            </NumberedMarker>{" "}
                                            <a
                                                href={
                                                    "/school/" +
                                                    item.ageid +
                                                    "/" +
                                                    slugify(item.school_name)
                                                }
                                            >
                                                {item.school_name}
                                            </a>
                                        </strong>
                                    </Card.Header>
                                    <Card.Body>
                                        <CardTableItemMobile
                                            label="Level"
                                            data={item.level_of_schooling}
                                        />
                                        <CardTableItemMobile
                                            label="Subtype"
                                            data={item.school_subtype}
                                        />
                                        <CardTableItemMobile
                                            label="Specialty"
                                            data={item.school_specialty_type}
                                        />
                                        <CardTableItemMobile
                                            label="Selective"
                                            data={item.selective_school}
                                        />
                                        {item.level_of_schooling ===
                                            "Primary" && (
                                            <CardTableItemMobile
                                                label="Opp. Class"
                                                superscript="1"
                                                data={
                                                    yes_and_no[
                                                        item.opportunity_class
                                                    ]
                                                }
                                            />
                                        )}
                                        <CardTableItemMobile
                                            label="Gender"
                                            data={item.school_gender}
                                        />
                                        <CardTableItemMobile
                                            label="Enrolled Students"
                                            data={
                                                item.latest_year_enrolment_fte &&
                                                parseFloat(
                                                    item.latest_year_enrolment_fte
                                                ).toFixed(1)
                                            }
                                        />
                                        <CardTableItemMobile
                                            label="ICSEA Value"
                                            superscript="2"
                                            data={
                                                item.icsea_value &&
                                                parseFloat(
                                                    item.icsea_value
                                                ).toFixed(1)
                                            }
                                        />
                                        <CardTableItemMobile
                                            label="LBOTE"
                                            superscript="3"
                                            data={
                                                item.lbote_pct &&
                                                item.lbote_pct + "%"
                                            }
                                        />
                                        <Row className="mt-3" />
                                        <small>
                                            <Row>
                                                <Col className="text-center">
                                                    {item.street +
                                                        ", " +
                                                        (item.town_suburb &&
                                                            item.town_suburb !==
                                                                null &&
                                                            titleCase(
                                                                item.town_suburb,
                                                                " "
                                                            ))}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-center">
                                                    {item.school_email}
                                                </Col>
                                            </Row>
                                        </small>
                                        {!this.props.areaOnly && (
                                            <Row className="mt-2">
                                                <Col className="text-center">
                                                    {item.distance + "km away"}
                                                </Col>
                                            </Row>
                                        )}
                                        {!this.props.hideMiniMap && (
                                            <Row className="mt-4">
                                                <Col>
                                                    <GoogleMapProvider>
                                                        <MapPanel
                                                            location={computeCentreLocationBetweenPoints(
                                                                parseFloat(
                                                                    item.lat
                                                                ),
                                                                parseFloat(
                                                                    item.lng
                                                                ),
                                                                this.props
                                                                    .latitude,
                                                                this.props
                                                                    .longitude
                                                            )}
                                                            height={"30vh"}
                                                            zoom={13}
                                                        />
                                                        {this.props.latitude &&
                                                            this.props
                                                                .longitude &&
                                                            this.props
                                                                .address && (
                                                                <Place
                                                                    key={
                                                                        this
                                                                            .state
                                                                            .addressSlug
                                                                    }
                                                                    location={{
                                                                        lat: this
                                                                            .props
                                                                            .latitude,
                                                                        lng: this
                                                                            .props
                                                                            .longitude,
                                                                    }}
                                                                    icon={
                                                                        <FaHome />
                                                                    }
                                                                    size="25px"
                                                                    zindex={110}
                                                                />
                                                            )}
                                                        <Fragment key={index}>
                                                            <Place
                                                                key={
                                                                    item.school_code +
                                                                    "_place"
                                                                }
                                                                location={{
                                                                    lat: parseFloat(
                                                                        item.lat
                                                                    ),
                                                                    lng: parseFloat(
                                                                        item.lng
                                                                    ),
                                                                }}
                                                                text={
                                                                    item.school_name
                                                                }
                                                                color={
                                                                    catchment_colours[
                                                                        item
                                                                            .catch_type
                                                                    ]
                                                                }
                                                                index={alpha.charAt(
                                                                    index
                                                                )}
                                                            />
                                                            <Geometry
                                                                key={
                                                                    item.school_code +
                                                                    "_geometry"
                                                                }
                                                                id={
                                                                    item.school_code +
                                                                    "_" +
                                                                    index
                                                                }
                                                                name={
                                                                    item.school_name
                                                                }
                                                                geometry={
                                                                    item.catchment &&
                                                                    wkx.Geometry.parse(
                                                                        item.catchment
                                                                    ).toGeoJSON()
                                                                }
                                                                fill={
                                                                    catchment_colours[
                                                                        item
                                                                            .catch_type
                                                                    ]
                                                                }
                                                                index={alpha.charAt(
                                                                    index
                                                                )}
                                                                location={{
                                                                    lat: parseFloat(
                                                                        item.lat
                                                                    ),
                                                                    lng: parseFloat(
                                                                        item.lng
                                                                    ),
                                                                }}
                                                            />
                                                        </Fragment>
                                                    </GoogleMapProvider>
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.hideMiniMap && (
                                            <Row className="mt-4">
                                                <Col>
                                                    <a
                                                        href={
                                                            "/school/" +
                                                            item.ageid +
                                                            "/" +
                                                            slugify(
                                                                item.school_name
                                                            )
                                                        }
                                                    >
                                                        <Button block>
                                                            View School
                                                            Catchment &#38;
                                                            Details
                                                        </Button>{" "}
                                                    </a>
                                                </Col>
                                            </Row>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))
                )}
                <Loading
                    loading={this.state.loading}
                    dataset={schools_filtered}
                    datasetName="Schools"
                />
                <Row className="pt-2">
                    <Col>
                        <small>
                            1. Opp. Class = Opportunity Classes, located in
                            government primary schools cater for highly
                            achieving Year 5 and Year 6 academically gifted
                            students.
                            <br />
                            2. ICSEA Value = Index of Socio-Educational
                            Advantage. ICSEA provides an indication of the
                            socio-educational backgrounds of students; it has
                            nothing to do with the staff, school facilities or
                            teaching programs at the school. More details:{" "}
                            <a
                                href="http://docs.acara.edu.au/resources/About_icsea_2014.pdf"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                            >
                                ACARA
                            </a>
                            <br />
                            3. LBOTE = Language Background Other Than English.
                            LBOTE is defined as one in whose home a language
                            other than English is spoken.
                        </small>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <small>
                            School data provided by: NSW Department of Education
                        </small>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Schools;
