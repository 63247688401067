import React from "react";
import PropTypes from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";
import isEmpty from "lodash.isempty";
import { css } from "@emotion/core";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

const override = css`
    display: absolute;
    margin: 0 auto;
    border-color: red;
`;

Loading.propTypes = {
    loading: PropTypes.bool.isRequired,
    dataset: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    datasetName: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default function Loading(props) {
    return (
        <Row className="mt-3">
            <Col className="text-center">
                <BeatLoader
                    css={override}
                    sizeUnit={"px"}
                    size={15}
                    color={"#315990"}
                    loading={props.loading}
                />
                {!props.loading && props.errorMessage && (
                    <Alert variant="danger">{props.errorMessage}</Alert>
                )}
                {!props.loading &&
                    !props.errorMessage &&
                    props.dataset &&
                    isEmpty(props.dataset) && (
                        <Alert variant="danger">
                            No {props.datasetName} found
                        </Alert>
                    )}
            </Col>
        </Row>
    );
}
