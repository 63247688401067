import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AppliedRoute from "./components/routes/AppliedRoute";

import Home from "./containers/Home";
import PropertyProfile from "./containers/PropertyProfile";

import StreetProfile from "./containers/StreetProfile";
import StreetHome from "./containers/StreetHome";

import AreaProfile from "./containers/AreaProfile";
import AreaLA3 from "./containers/AreaLA3";
import AreaLA4 from "./containers/AreaLA4";
import AreaHome from "./containers/AreaHome";
import RedirectAreaPage from "./containers/RedirectAreaPage";
import RedirectSchoolsAreaPage from "./containers/RedirectSchoolsAreaPage";

import RentHome from "./containers/RentHome";
import RentProfile from "./containers/RentProfile";

import SchoolProfile from "./containers/SchoolProfile";
import SchoolsLA2 from "./containers/SchoolsLA2";
import SchoolsHome from "./containers/SchoolsHome";

import LoginPage from "./containers/userManagement/LoginPage";
import ResetPassword from "./containers/userManagement/ResetPassword";

import Terms from "./containers/legal/Terms";
import Privacy from "./containers/legal/Privacy";
import Pricing from "./containers/Pricing";
import Contact from "./containers/Contact";
import About from "./containers/About";
import ErrorPage from "./containers/ErrorPage";

import Agency from "./containers/Agency";
import Agent from "./containers/Agent";

// import RedirectAreaPage from "./containers/RedirectAreaPage";
// import Features from "./containers/Features";
// import CensusChartContainer from "./containers/CensusChartContainer";
// import GroupedCensusChartContainer from "./containers/GroupedCensusChartContainer";
// import TransitContainer from "./containers/TransitContainer";
// import Amenities from "./containers/Amenities";
// import Listings from "./containers/Listings";
// import RadarContainer from "./containers/RadarContainer";
// import MortgageRepaymentCalculator from "./components/MortgageRepaymentCalculator";

import PropTypes from "prop-types";

Routes.propTypes = {
    appProps: PropTypes.object,
};

export default function Routes({ appProps }) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps} />
            <AppliedRoute
                path="/login"
                exact
                component={LoginPage}
                appProps={appProps}
            />
            <AppliedRoute
                path="/login/reset"
                exact
                component={ResetPassword}
                appProps={appProps}
            />
            <AppliedRoute
                path="/signup"
                exact
                component={LoginPage}
                appProps={appProps}
            />
            <AppliedRoute
                path="/property/:addressSlug"
                exact
                component={PropertyProfile}
                appProps={appProps}
            />
            <AppliedRoute
                path="/street/:addressSlug"
                exact
                component={StreetProfile}
                appProps={appProps}
            />
            <AppliedRoute
                path="/street/"
                exact
                component={StreetHome}
                appProps={appProps}
            />
            <AppliedRoute
                path="/area/:la4_id/:la3_id/:la2_id"
                exact
                component={AreaProfile}
                appProps={appProps}
            />
            {/* Temporary Redirect to area/la4/la3/la2 from old URL area/la3/la2 */}
            {/* <AppliedRoute
                path="/area/:la3_id/:la2_id"
                exact
                component={RedirectAreaPage}
                appProps={appProps}
            /> */}
            {/*  Redirect to area/la4/la3/la2 from URL area/addressSlug */}
            <AppliedRoute
                path="/redirect-area/:addressSlug"
                exact
                component={RedirectAreaPage}
                appProps={appProps}
            />
            <AppliedRoute
                path="/area/:la4_id/:la3_id"
                exact
                component={AreaLA3}
                appProps={appProps}
            />
            <AppliedRoute
                path="/area/:la4_id"
                exact
                component={AreaLA4}
                appProps={appProps}
            />
            <AppliedRoute
                path="/area/"
                exact
                component={AreaHome}
                appProps={appProps}
            />
            <AppliedRoute
                path="/terms"
                exact
                component={Terms}
                appProps={appProps}
            />
            <AppliedRoute
                path="/privacy"
                exact
                component={Privacy}
                appProps={appProps}
            />
            <AppliedRoute
                path="/pricing"
                exact
                component={Pricing}
                appProps={appProps}
            />
            <AppliedRoute
                path="/contact"
                exact
                component={Contact}
                appProps={appProps}
            />
            <AppliedRoute
                path="/about"
                exact
                component={About}
                appProps={appProps}
            />
            <AppliedRoute
                path="/agency/:agencyId/:agencySlug"
                exact
                component={Agency}
            />
            <AppliedRoute path="/agency/:agencyId" exact component={Agency} />
            <AppliedRoute
                path="/agent/:agentId/:agentSlug"
                exact
                component={Agent}
            />
            <AppliedRoute path="/agent/:agentId" exact component={Agent} />
            <AppliedRoute
                path="/rent/"
                exact
                component={RentHome}
                appProps={appProps}
            />
            <AppliedRoute
                path="/rent/:addressSlug"
                exact
                component={RentProfile}
                appProps={appProps}
            />
            <AppliedRoute
                path="/schools/"
                exact
                component={SchoolsHome}
                appProps={appProps}
            />
            <AppliedRoute
                path="/schools/:la4_id"
                exact
                component={AreaLA4}
                appProps={appProps}
            />
            <AppliedRoute
                path="/schools/:la4_id/:la3_id"
                exact
                component={AreaLA3}
                appProps={appProps}
            />
            <AppliedRoute
                path="/schools/:la4_id/:la3_id/:la2_id"
                exact
                component={SchoolsLA2}
                appProps={appProps}
            />
            <AppliedRoute
                path="/school/:schoolId"
                exact
                component={SchoolProfile}
                appProps={appProps}
            />
            <AppliedRoute
                path="/school/:schoolId/:schoolSlug"
                exact
                component={SchoolProfile}
                appProps={appProps}
            />
            <AppliedRoute
                path="/redirect-schools/:addressSlug"
                exact
                component={RedirectSchoolsAreaPage}
                appProps={appProps}
            />

            {/* <AppliedRoute path="/explore/:city" exact component={Features} />
            <AppliedRoute
                path="/censuschart/:addressSlug"
                exact
                component={CensusChartContainer}
            />
            <AppliedRoute
                path="/groupedcensuschart/:addressSlug"
                exact
                component={GroupedCensusChartContainer}
            />
            <AppliedRoute
                path="/transit/:addressSlug"
                exact
                component={TransitContainer}
            />
            <AppliedRoute
                path="/amenities/:addressSlug"
                exact
                component={Amenities}
            />
            <AppliedRoute
                path="/listings/:propertyType/:addressSlug"
                exact
                component={Listings}
            />
            <AppliedRoute
                path="/radar/:scores"
                exact
                component={RadarContainer}
            />
            <AppliedRoute
                path="/linechart"
                exact
                component={LineChartContainer}
            /> */}
            {/* <AppliedRoute
                path="/mortgage"
                exact
                component={MortgageRepaymentCalculator}
            /> */}
            {/* Finally, catch all unmatched routes */}
            <Route path="/404" component={ErrorPage} />
            <Redirect to="/404" />
        </Switch>
    );
}
