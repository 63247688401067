import React, { useState, useEffect, useContext } from "react";
import { MapBox, GoogleMapContext } from "@googlemap-react/core";
import PropTypes from "prop-types";
import { getUA } from "react-device-detect";

MapPanel.propTypes = {
    location: PropTypes.object.isRequired,
    zoom: PropTypes.number,
    height: PropTypes.string,
    width: PropTypes.string,
    enableZoom: PropTypes.bool,
};

export default function MapPanel(props) {
    const { state } = useContext(GoogleMapContext);
    // const location = props.location; //TODO: update to default to users location

    const height = props.height ? props.height : "50vh";
    const width = props.width ? props.width : "100%";

    // You can now access the map object and other objects via `state`.
    // Remember to check existence, since they might be undefined.

    const [location, setLocation] = useState(props.location);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : 16);

    useEffect(() => {
        if (
            location.latitude !== props.location.latitude ||
            location.longitude !== props.location.longitude
        ) {
            setLocation(props.location);

            if (state.map) {
                state.map.setCenter(props.location);

                //reset the zoom when the location changes
                state.map.setZoom(props.zoom);
            }
        }
    }, [props.location, props.zoom, state.map, location]);

    useEffect(() => {
        if (zoom !== props.zoom) {
            setZoom(props.zoom);

            if (state.map) {
                state.map.setZoom(props.zoom);
            }
        }
    }, [props.zoom, zoom, state.map]);

    const mapStyle = [
        {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
                {
                    saturation: 36,
                },
                {
                    color: "#333333",
                },
                {
                    lightness: 40,
                },
            ],
        },
        {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    visibility: "on",
                },
                {
                    color: "#ffffff",
                },
                {
                    lightness: 16,
                },
            ],
        },
        {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#fefefe",
                },
                {
                    lightness: 20,
                },
            ],
        },
        {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
                {
                    color: "#fefefe",
                },
                {
                    lightness: 17,
                },
                {
                    weight: 1.2,
                },
            ],
        },
        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
                {
                    color: "#f5f5f5",
                },
                {
                    lightness: 20,
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#f5f5f5",
                },
                {
                    lightness: 21,
                },
            ],
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
                {
                    color: "#dedede",
                },
                {
                    lightness: 21,
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#ffffff",
                },
                {
                    lightness: 17,
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
                {
                    color: "#ffffff",
                },
                {
                    lightness: 29,
                },
                {
                    weight: 0.2,
                },
            ],
        },
        {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
                {
                    color: "#ffffff",
                },
                {
                    lightness: 18,
                },
            ],
        },
        {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
                {
                    color: "#ffffff",
                },
                {
                    lightness: 16,
                },
            ],
        },
        // {
        //     featureType: "transit",
        //     elementType: "geometry",
        //     stylers: [
        //         {
        //             color: "#f2f2f2",
        //         },
        //         {
        //             lightness: 19,
        //         },
        //     ],
        // },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    color: "#e9e9e9",
                },
                {
                    lightness: 17,
                },
            ],
        },
    ];

    return (
        <MapBox
            style={{
                height: height,
                width: width,
            }}
            apiKey={
                getUA.includes("Prerender")
                    ? process.env.REACT_APP_GOOGLE_MAPS_INVALID_API_KEY
                    : process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }
            opts={{
                center: location,
                zoom: zoom,
                styles: mapStyle,
                disableDefaultUI: true,
                zoomControl: props.enableZoom,
            }}
            useGeometry
            usePlaces
        />
    );
}
