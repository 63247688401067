/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PlaceTable from "./PlaceTable";
import sortBy from "lodash/sortBy";

import PropTypes from "prop-types";

import { nearbySearch } from "./map/PlaceSearch";
import { liveableNearbySearch } from "./map/LiveablePlaceSearch";

import { getUA } from "react-device-detect";

import Loading from "./Loading";

const limit = 10;
// const radius = 1500;

/**
 * https://developers.google.com/places/web-service/search
 *  https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=1500&type=restaurant&key=YOUR_API_KEY
 *
 *
 */

Places.propTypes = {
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    radius: PropTypes.number.isRequired,
    type: PropTypes.string,
    keyword: PropTypes.string,
    header: PropTypes.string.isRequired,
    map: PropTypes.object.isRequired,
    showDistance: PropTypes.bool,
};
export default function Places(props) {
    const [places, setPlaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        function fetchAmenities(lat, lng) {
            setLoading(true);

            getUA.includes("Prerender")
                ? liveableNearbySearch(
                      {
                          lat: lat,
                          lng: lng,
                      },
                      props.radius,
                      props.type
                  )
                      .then((data) => {
                          setPlaces(cleanUpData(data));
                          setLoading(false);
                      })
                      .catch(() => {
                          setLoading(false);
                          setErrorMessage("No results found");
                      })
                : nearbySearch(
                      {
                          lat: lat,
                          lng: lng,
                      },
                      props.radius,
                      props.type,
                      props.keyword,
                      props.map
                  )
                      .then((data) => {
                          setPlaces(cleanUpData(data));
                          setLoading(false);
                      })
                      .catch(() => {
                          setLoading(false);
                          setErrorMessage("No results found");
                      });
        }

        function cleanUpData(data) {
            data = uniq(data);

            data = data.filter((item) => {
                return item.rating > 0;
            });

            data = sortBy(data, "distance");

            return data;
        }
        function uniq(a) {
            let seen = {};
            return a.filter((item) => {
                return Object.prototype.hasOwnProperty.call(
                    seen,
                    item.place_id + item.liveable_type
                )
                    ? false
                    : (seen[item.place_id + item.liveable_type] = true);
            });
        }

        fetchAmenities(props.latitude, props.longitude);
    }, [
        props.latitude,
        props.longitude,
        props.keyword,
        props.map,
        props.radius,
        props.type,
    ]);

    return (
        <div className="info-panel mt-3">
            <h4>{props.header}</h4>
            <Loading
                loading={loading}
                dataset={places ? places : []}
                datasetName={props.header}
                errorMessage={errorMessage}
            />
            {places && (
                <>
                    {places.length > limit ? (
                        <div className="mb-2">
                            Showing the 10 closest places
                        </div>
                    ) : (
                        ""
                    )}
                    <div id="example-collapse-text">
                        <PlaceTable
                            data={places}
                            limit={limit}
                            key={"amenities_" + props.type}
                            showDistance={props.showDistance}
                        />
                    </div>
                </>
            )}
        </div>
    );
    // }
}
