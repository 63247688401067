import React, { useState, useEffect, Fragment } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useGoogleAPI } from "@googlemap-react/core";

import {
    slugify,
    convertDashToPlus,
    addressStripCountry,
    titleCase,
    convertUnderscoreToDash,
    compactMoneyFormatter,
    trackView,
} from "../Helpers";

import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import { getUA } from "react-device-detect";
import { Link } from "react-router-dom";
import ScrollUp from "react-scroll-up";

// import { GoogleMapProvider } from "@googlemap-react/core";

import { FaRegArrowAltCircleUp } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import HeaderNav from "../components/navbar/HeaderNav";
import ComparableProperties from "../components/ComparableProperties";
// import Amenities from "../components/Amenities";
// import Transit from "../components/Transit";
import Schools from "../components/Schools";
import Census from "../components/Census";
import MortgageRepaymentCalculator from "../components/MortgageRepaymentCalculator";

import {
    PropertiesContext,
    reducer,
    initialState,
} from "../state/PropertiesContext";
import Swiper from "react-id-swiper"; // https://www.npmjs.com/package/react-id-swiper
import "swiper/css/swiper.min.css";

import moment from "moment";

import { UserContext } from "../App";

StreetProfile.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            addressSlug: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

const swiper_params = {
    // pagination: {
    //     el: ".swiper-pagination",
    //     type: "bullets",
    //     clickable: true,
    // },

    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    // lazy: true,
    lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 6,
        loadOnTransitionStart: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },

    // pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    // },

    scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 2,
        },
        // when window width is >= 768px
        1024: {
            slidesPerView: "auto",
        },
    },
    spaceBetween: 10,
    // slidesPerView: "auto", //not compatible with lazy loader
    // centeredSlides: true, //not compatible with lazy loader
    slideClass: "swiper-slide-liveable",
};

export default function StreetProfile(props) {
    const [state] = React.useContext(UserContext);

    const [addressSlug, setAddressSlug] = useState(
        props.match.params.addressSlug
            ? props.match.params.addressSlug
            : undefined
    );
    const [address, setAddress] = useState(
        props.location.state ? props.location.state.address : undefined
    );
    const [street, setStreet] = useState(
        props.location.state ? props.location.state.street : undefined
    );
    const [images, setImages] = useState([]);
    const [latitude, setLatitude] = useState(
        props.location.state ? props.location.state.lat : undefined
    );
    const [longitude, setLongitude] = useState(
        props.location.state ? props.location.state.lng : undefined
    );
    const [la2_id, setLa2_id] = useState(undefined);
    const [la3_id, setLa3_id] = useState(undefined);
    const [la4_id, setLa4_id] = useState(undefined);

    const [listingState, dispatch] = React.useReducer(reducer, initialState);
    const [description, setDescription] = useState(undefined);

    // call the google API so window.google gets loaded
    let google = useGoogleAPI({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraryParam: `&libraries=places`,
        languageParam: "",
        regionParam: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        // retrieve the lat/lng from the address slug
        if (
            getUA.includes("Prerender") &&
            addressSlug &&
            !latitude &&
            !longitude
        ) {
            let here_geocode_url =
                "https://geocoder.api.here.com/6.2/geocode.json?app_id=" +
                process.env.REACT_APP_ID_HERE +
                "&app_code=" +
                process.env.REACT_APP_CODE_HERE +
                "&searchtext=" +
                convertDashToPlus(props.match.params.addressSlug);

            fetch(here_geocode_url)
                .then((response) => response.json())
                .then((data) => {
                    const view = data.Response.View;

                    if (view.length > 0 && view[0].Result.length > 0) {
                        const location = view[0].Result[0].Location;

                        setLatitude(location.DisplayPosition.Latitude);
                        setLongitude(location.DisplayPosition.Longitude);
                        setStreet(location.Address.Street);

                        setAddressSlug(slugify(location.Address.Label));

                        setAddress(addressStripCountry(location.Address.Label));

                        // getLiveableArea(
                        //     location.DisplayPosition.Latitude,
                        //     location.DisplayPosition.Longitude
                        // );
                    }
                });
        } else {
            if (google && addressSlug && !latitude && !longitude) {
                geocodeByAddress(convertDashToPlus(addressSlug)).then(
                    (results) => {
                        setAddressSlug(slugify(results[0].formatted_address));

                        setAddress(
                            addressStripCountry(results[0].formatted_address)
                        );

                        if (
                            results[0].address_components[0].types.includes(
                                "route"
                            )
                        ) {
                            setStreet(
                                results[0].address_components[0].short_name
                            );
                        }

                        getLatLng(results[0]).then(({ lat, lng }) => {
                            setLatitude(lat);
                            setLongitude(lng);
                            // getLiveableArea(lat, lng);
                        });
                    }
                );
            }
        }
    }, [
        props.match.params.addressSlug,
        addressSlug,
        latitude,
        longitude,
        google,
    ]);

    useEffect(() => {
        trackView(
            state.user,
            props.history.location.pathname,
            "street-profile"
        );
    }, [state.user, props.history.location]);

    async function getLiveableArea(lat, lng) {
        let liveable_url =
            process.env.REACT_APP_LIVEABLE_DATA_API_URL +
            "/v1/la/sydney/?lat=" +
            lat +
            "&lng=" +
            lng;

        return fetch(liveable_url)
            .then((response) => response.json())
            .then((data) => {
                setLa2_id(data.la2);
                setLa3_id(data.la3);
                setLa4_id(data.la4);
            });
    }

    /** Use lat lng from history push */
    useEffect(() => {
        // console.log(
        //     "update from history",
        //     props.match.params.addressSlug,
        //     addressSlug,
        //     props.location.state,
        //     latitude,
        //     latitude !== props.location.state.lat,
        //     longitude,
        //     longitude !== props.location.state.lng
        // );

        window.scrollTo(0, 0);
        if (props.location.state) {
            if (
                props.location.state.lat &&
                props.location.state.lng
                // &&(latitude !== props.location.state.lat ||
                //     longitude !== props.location.state.lng)
            ) {
                setLatitude(props.location.state.lat);
                setLongitude(props.location.state.lng);
                setAddressSlug(props.match.params.addressSlug);
                setAddress(addressStripCountry(props.location.state.address));
                setStreet(props.location.state.street);

                // getLiveableArea(
                //     props.location.state.lat,
                //     props.location.state.lng
                // );
            }

            // if (props.location.state.address) {
            // setAddress(addressStripCountry(props.location.state.address));
            // setAddressSlug(props.location.state.pathname);
            // }
        }
    }, [
        latitude,
        longitude,
        props.match.params.addressSlug,
        addressSlug,
        props.location.state,
    ]);

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    useEffect(() => {
        let highest_property = undefined;

        function getImages(properties) {
            let max_price = 0;
            let street_images = [];

            properties.forEach((p) => {
                if (p.images) {
                    street_images.push(
                        p.images[0].substr(0, p.images[0].lastIndexOf(".")) +
                            "-800.jpg"
                    );
                }

                if (p.lastPrice > max_price) {
                    highest_property = p;
                    max_price = p.lastPrice;
                }
            });

            setImages(galleryItems(street_images));
        }

        function galleryItems(street_images) {
            let gallery = [];
            //TODO: https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
            // Swiper supports srcset
            street_images.forEach((image, index) => {
                gallery.push(
                    <img
                        key={index}
                        data-src={
                            process.env.REACT_APP_IMAGE_BUCKET_URL + "/" + image
                        }
                        width={320}
                        height={240}
                        className="swiper-lazy"
                        alt={image}
                        loading="lazy"
                    />
                );
            });

            return gallery;
        }

        getImages(listingState.properties);
        // setMedian(listingState.median);
        // setLowerQuantile(listingState.lower_quantile);
        // setUpperQuantile(listingState.upper_quantile);

        const sold_description =
            address +
            " has " +
            listingState.count +
            " properties with a median property price of " +
            compactMoneyFormatter.format(listingState.median) +
            ". The price range on the street is " +
            compactMoneyFormatter.format(listingState.lower_quantile) +
            " to " +
            compactMoneyFormatter.format(listingState.upper_quantile);

        // Highest price paid
        const highest_price = highest_property
            ? ". The highest price paid for a property on this street was " +
              addressStripCountry(highest_property.formattedAddress) +
              " which sold for " +
              compactMoneyFormatter.format(highest_property.lastPrice) +
              " on " +
              moment(highest_property.lastContractDate, "YYYYMMDD").format(
                  "MMM YYYY"
              )
            : "";

        setDescription(sold_description + highest_price);
    }, [listingState, address]);

    useEffect(() => {
        let haveLaDetails = true;
        if (listingState.properties[0]) {
            if (listingState.properties[0].la2) {
                setLa2_id(listingState.properties[0].la2);
                haveLaDetails = true;
            } else {
                haveLaDetails = false;
            }
            if (listingState.properties[0].la3) {
                setLa3_id(listingState.properties[0].la3);
                haveLaDetails = true;
            } else {
                haveLaDetails = false;
            }
        }

        if (listingState && !haveLaDetails) {
            if (latitude && longitude) {
                getLiveableArea(latitude, longitude);
            }
        }
    }, [listingState, la2_id, la3_id, la4_id, latitude, longitude]);

    return (
        <Fragment>
            <Helmet>
                {address && (
                    <title>
                        {"Street Profile: " + address + " - Liveable"}
                    </title>
                )}
                {address && (
                    <meta
                        property="og:title"
                        content={"Street Profile: " + address + " - Liveable"}
                    />
                )}
                {description && (
                    <meta name="description" content={description} />
                )}
                {description && (
                    <meta property="og:description" content={description} />
                )}
                {addressSlug && (
                    <meta
                        property="og:url"
                        content={"https://liveable.co/street/" + addressSlug}
                    />
                )}
                {addressSlug && (
                    <link
                        rel="canonical"
                        href={"https://liveable.co/street/" + addressSlug}
                    />
                )}
                {/* Zendesk widget */}
                {!getUA.includes("Prerender") &&
                    process.env.NODE_ENV === "production" && (
                        <script
                            id="ze-snippet"
                            src="https://static.zdassets.com/ekr/snippet.js?key=09814143-b86a-422d-a3bb-f9c58904826d"
                        ></script>
                    )}

                <script src="https://unohomeloans.com.au/loan-score-widget/uno-loanScore-widget.min.js"></script>
            </Helmet>
            {address && (
                <>
                    <JSONLD>
                        <Generic
                            type="webPage"
                            jsonldtype="WebPage"
                            schema={{
                                name: "Street Profile: " + address,
                                description: description,
                                url:
                                    "https://liveable.co" +
                                    props.location.pathname,
                            }}
                        />
                    </JSONLD>
                    <JSONLD>
                        <Generic
                            type="breadcrumbList"
                            jsonldtype="BreadcrumbList"
                        >
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Liveable",
                                        item: "https://liveable.co",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: "Street",
                                        item: "https://liveable.co/street",
                                    }}
                                />
                                {address && (
                                    <Generic
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: 3,
                                            name: address,
                                        }}
                                    />
                                )}
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                </>
            )}
            <HeaderNav
                headings={[
                    {
                        label: "Street",
                        link: "#street",
                    },
                    {
                        label: "Sold Properties",
                        link: "#sold",
                    },
                    {
                        label: "Listings for Sale",
                        link: "#sale",
                    },
                    {
                        label: "Listings for Rent",
                        link: "#rent",
                    },
                    {
                        label: "Amenities",
                        link: "#amenities",
                    },
                    {
                        label: "Schools",
                        link: "#schools",
                    },
                    {
                        label: "Transit Options",
                        link: "#transit",
                    },
                    {
                        label: "Insights",
                        link: "#insights",
                    },
                ]}
            />
            <ScrollUp
                showUnder={800}
                style={{ bottom: "2vh", left: "2vh", zIndex: 9999 }}
            >
                <span>
                    <FaRegArrowAltCircleUp
                        style={{
                            width: "2.7em",
                            height: "2.7em",
                            fill: "#eeeeee",
                        }}
                    />
                </span>
            </ScrollUp>
            <Container className="pt-4 pb-4">
                {/* Breadcrumb */}
                {la2_id && la3_id && la4_id && (
                    <section id="breadcrumb" className="text-muted">
                        <Row>
                            <Col>
                                {titleCase(la4_id, "_")}
                                <span className="px-2">&gt;</span>
                                {titleCase(la3_id, "_")}
                                <span className="px-2">&gt;</span>
                                <Link
                                    to={
                                        "/area/" +
                                        convertUnderscoreToDash(la4_id) +
                                        "/" +
                                        convertUnderscoreToDash(la3_id) +
                                        "/" +
                                        convertUnderscoreToDash(la2_id)
                                    }
                                >
                                    {titleCase(la2_id, "_")}
                                </Link>
                            </Col>
                        </Row>
                    </section>
                )}

                {/* Street Info */}
                <section id="street" className="pt-2 pb-3">
                    <Row>
                        <Col>
                            <h1>{address}</h1>
                        </Col>
                    </Row>

                    {/* {median && listingState.properties && (
                        <Row>
                            <Col>
                                <h4>
                         
                                    {street} has {listingState.count} properties
                                    with a Median Property price of{" "}
                                    {moneyFormatter.format(median)}. The price
                                    range on the street is{" "}
                                    {moneyFormatter.format(lowerQuantile)} -{" "}
                                    {moneyFormatter.format(upperQuantile)}
                                </h4>
                            </Col>
                        </Row>
                    )} */}
                </section>

                {/* Property Images */}
                {!isEmpty(images) && images.length > 1 && (
                    <Swiper {...swiper_params}>
                        {images.map((image, index) => (
                            <div key={index}>{image}</div>
                        ))}
                    </Swiper>
                )}

                {/* Sold Properties */}
                <section id="sold" className="pt-4">
                    <Row className="mt-4">
                        <Col>
                            <h2>Sold Properties on {street}</h2>
                        </Col>
                    </Row>
                    {description && listingState.count && listingState.median && (
                        <Row className="mt-2 mb-4">
                            <Col>{description}</Col>
                        </Row>
                    )}

                    <Row className="mt-3">
                        <Col>
                            {latitude &&
                                longitude &&
                                address &&
                                street &&
                                images && (
                                    <PropertiesContext.Provider
                                        value={{ listingState, dispatch }}
                                    >
                                        <ComparableProperties
                                            addressSlug={addressSlug}
                                            cardType="sold"
                                            latitude={latitude}
                                            longitude={longitude}
                                            address={address}
                                            filterStreet={true}
                                        ></ComparableProperties>
                                    </PropertiesContext.Provider>
                                )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Sales Listings */}
                <section id="sale" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Sales Listings near {street}</h2>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {state.isAuthenticated &&
                            state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        addressSlug={addressSlug}
                                        cardType="sale"
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                        filterStreet={true}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <Alert.Heading>
                                        {!state.isAuthenticated
                                            ? "Login"
                                            : "Subscription"}{" "}
                                        required to view Comparable Sales
                                        Listings
                                    </Alert.Heading>
                                    <p className="mt-5">
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Sales Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Sales Listings allows
                                        you to benchmark what Sellers of similar
                                        properties are asking for.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        onClick={() =>
                                            state.isAuthenticated
                                                ? goToPage("pricing")
                                                : goToPage("login")
                                        }
                                    >
                                        {state.isAuthenticated
                                            ? "Subscribe"
                                            : "Login"}
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                <section id="compare-home-loans" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Mortgage Repayments Calculator</h2>
                        </Col>
                    </Row>
                    <MortgageRepaymentCalculator
                        propertyPrice={listingState.median}
                    />
                </section>
                <hr />
                <section id="home-loans-score" className="pt-4">
                    <Row className="mb-4">
                        <Col>
                            <h2>Check your Existing Home Loan Score</h2>
                            <p>
                                uno is Australia&#39;s first Active Home Loan
                                Manager. If you already have a home loan, uno
                                can help you find a great deal and stay on a
                                great deal the whole time you have a loan.
                            </p>
                        </Col>
                    </Row>
                    {/* Uno Loan Score widget */}
                    <div
                        id="uno-loanScore-widget"
                        apikey="RF4V^#x$yf2v3U"
                        referrer="liveable"
                        referrerlabel="Liveable"
                    />
                </section>
                <hr />
                {/* Rental Listings */}
                <section id="rent" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Rental Listings near {street}</h2>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            Listings for similar properties for rent:{" "}
                            {numBed === undefined ? "-" : numBed} bed,{" "}
                            {numBath === undefined ? "-" : numBath} bath and{" "}
                            {numCar === undefined ? "-" : numCar} car.
                        </Col>
                    </Row> */}
                    <Row className="mt-3">
                        <Col>
                            {state.isAuthenticated &&
                            state.userPlan === "Pro" ? (
                                latitude &&
                                longitude && (
                                    <ComparableProperties
                                        addressSlug={addressSlug}
                                        cardType="rent"
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                        // numBed={numBed}
                                        // numBath={numBath}
                                        // numCar={numCar}
                                        // dwellingType={dwellingType}
                                        filterStreet={true}
                                    ></ComparableProperties>
                                )
                            ) : (
                                <Alert
                                    variant="primary"
                                    className="mt-3 py-5 text-center"
                                >
                                    <Alert.Heading>
                                        {!state.isAuthenticated
                                            ? "Login"
                                            : "Subscription"}{" "}
                                        required to view Comparable Rental
                                        Listings
                                    </Alert.Heading>
                                    <p className="mt-5">
                                        Similar to the Comparable Sold
                                        Properties section above, this section
                                        provides Rental Listings for comparable
                                        properties currently on the market.
                                    </p>
                                    <p>
                                        Knowing Comparable Rental Listings is
                                        handy for Investors. It allows an
                                        Investor to benchmark what the potential
                                        yield is for the property, and how much
                                        supply there is for similar properties
                                        on the market.
                                    </p>
                                    <Button
                                        className="mt-3 px-4 py-2"
                                        variant="warning"
                                        onClick={() =>
                                            state.isAuthenticated
                                                ? goToPage("pricing")
                                                : goToPage("login")
                                        }
                                    >
                                        {state.isAuthenticated
                                            ? "Subscribe"
                                            : "Login"}
                                    </Button>
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr />
                {/* Amenities  - Remove for Streets */}
                {/* <section id="amenities" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Amenities near {street}</h2>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {longitude && latitude && (
                                <Amenities
                                    address={address}
                                    latitude={latitude}
                                    longitude={longitude}
                                    showHome={false}
                                    showDistance={false}
                                />
                            )}
                        </Col>
                    </Row>
                </section>
                <hr /> */}
                {/* Schools  */}
                {longitude && latitude && la2_id && (
                    <>
                        <section id="schools" className="pt-4">
                            <Row>
                                <Col>
                                    <h2>Schools near {street}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    List of Schools and their Catchment Areas
                                    for {address}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Schools
                                        latitude={latitude}
                                        longitude={longitude}
                                        la2_id={la2_id}
                                        showHome={true}
                                        hideMiniMap={true}
                                    />
                                </Col>
                            </Row>
                        </section>
                        <hr />
                    </>
                )}
                {/* Transit Options*/}
                {/* <section id="transit" className="pt-4">
                    <Row>
                        <Col>
                            <h2>Transit Options near {street}</h2>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {latitude && longitude && (
                                <GoogleMapProvider>
                                    <Transit
                                        addressSlug={addressSlug}
                                        latitude={latitude}
                                        longitude={longitude}
                                        showHome={false}
                                        showDistance={false}
                                    />
                                </GoogleMapProvider>
                            )}
                        </Col>
                    </Row>
                </section>
                <hr /> */}
                {/* Neighbourhood Insights */}
                {la2_id && la3_id && la4_id && (
                    <section id="insights" className="pt-4">
                        <Row>
                            <Col>
                                <h2>
                                    Neighbourhood Insights for{" "}
                                    {titleCase(la2_id, "_")}
                                </h2>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Census
                                    la2_id={la2_id}
                                    la3_id={la3_id}
                                    la4_id={la4_id}
                                />
                            </Col>
                        </Row>
                    </section>
                )}
            </Container>
        </Fragment>
    );
}
