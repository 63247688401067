import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getSlugCount, whatsMyLimit } from "../../Helpers";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";

import { UserContext } from "../../App";

CallToActionFooter.propTypes = {
    visitCount: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    slugStoreVar: PropTypes.string,
};

function CallToActionFooter(props) {
    const [state] = React.useContext(UserContext);

    const [slugStoreVar] = useState(props.slugStoreVar || "addressSlugs");

    function goToPage(pageRoute) {
        const location = {
            pathname: "/" + pageRoute,
            state: { from: { pathname: props.location.pathname } },
        };
        props.history.push(location);
    }

    return (
        <Navbar
            className="align-top cta-footer"
            style={{
                backgroundColor: "#ffa800",
                color: "#ffffff",
            }}
            variant="dark"
            expand="md"
            fixed="bottom"
        >
            <Container className="py-1">
                <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                {slugStoreVar === "rentSlugs" ? (
                    <>
                        <Col
                            className="text-center"
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <span
                                className="mr-2 font-weight-bolder"
                                style={{ fontSize: "1.3em" }}
                            >
                                {getSlugCount(slugStoreVar) >
                                whatsMyLimit(state.isAuthenticated)
                                    ? whatsMyLimit(state.isAuthenticated)
                                    : getSlugCount(slugStoreVar)}{" "}
                                /{" "}
                                {state.isAuthenticated
                                    ? process.env
                                          .REACT_APP_PAYWALL_SIGNEDUP_VIEW_LIMIT
                                    : process.env
                                          .REACT_APP_PAYWALL_GUEST_VIEW_LIMIT}
                            </span>
                            Property views. <br />
                            {!state.isAuthenticated ? (
                                <span>Sign up to view more Properties.</span>
                            ) : (
                                state.userPlan !== "Pro" && (
                                    <span>
                                        Subscribe to view more Properties
                                    </span>
                                )
                            )}
                        </Col>
                        <Col
                            className="text-center py-2"
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            {!state.isAuthenticated ? (
                                <Button
                                    id="button-login-cta"
                                    variant="outline-light"
                                    className="font-weight-bold"
                                    onClick={() => goToPage("login")}
                                >
                                    Login / Signup
                                </Button>
                            ) : (
                                state.userPlan !== "Pro" && (
                                    <Button
                                        id="button-subscribe-cta"
                                        variant="outline-light"
                                        className="font-weight-bold"
                                        onClick={() => goToPage("pricing")}
                                    >
                                        Subscribe
                                    </Button>
                                )
                            )}
                        </Col>
                    </>
                ) : (
                    <>
                        <Col
                            className="text-center py-1"
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <span>
                                <strong>Supercharge your research today</strong>
                                <br />
                                Get full access to our comprehensive data
                            </span>
                        </Col>
                        <Col
                            className="text-center py-2"
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <Button
                                id="button-subscribe-cta"
                                variant="outline-light"
                                className="font-weight-bold"
                                onClick={() => goToPage("pricing")}
                            >
                                Upgrade
                            </Button>
                        </Col>
                    </>
                )}
                <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
            </Container>
        </Navbar>
    );
}

export default withRouter(CallToActionFooter);
