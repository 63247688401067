import React, { Component, Fragment } from "react";
import isEmpty from "lodash.isempty";

import {
    transitRouteModeColours,
    transitRouteModes,
} from "../components/map/TransitPlace";

import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import styled from "styled-components";
import { FaWalking } from "react-icons/fa";
import { convertDistance } from "../Helpers";

import PropTypes from "prop-types";

const distance = {
    color: "gray",
    fontSize: "0.8em",
};

const TransitDetails = styled.span`
    fontweight: "bold";
    color: "black";
`;

const Routes = styled.div`
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; /* row row-reverse column column-reverse  */
    padding: 1px;
`;

const Route = styled.div`
    flex-basis: 25%;
    flex-grow: 0;
    border: 2px solid ${(props) => (props.color ? props.color : "white")};
    background-color: white;
    text-align: center;
    /*padding: 0 1px; */
    margin: 2px;
`;

const TransitMarker = styled.div`
    background-color: ${(props) => (props.color ? props.color : "#DC143C")};
    padding: 0.1em 0.5em;
    border-radius: 100%;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const DEFAULT_LIMIT = 5; // transit stops to show by default
const SHOW_MORE = 12; // transit stops to show per pagination

export default class TransitTable extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        showDistance: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            transit_mode_filter: "all",
            limit: DEFAULT_LIMIT,
        };
    }

    handleClickNavTransitModes = (param) => {
        this.setState({ transit_mode_filter: param });
    };

    handleClickButtonShowMore = () => {
        this.setState({ limit: this.state.limit + SHOW_MORE });
    };

    getTransitStops() {
        if (this.state.transit_mode_filter !== "all") {
            let filtered = this.props.data.filter((transit) => {
                return transit.mode === this.state.transit_mode_filter;
            });

            return filtered;
        } else {
            return this.props.data;
        }
    }

    render() {
        return (
            <>
                <Row className="pt-2">
                    <Col>
                        <Nav
                            className="justify-content-center flex-column flex-sm-row"
                            fill
                            variant="pills"
                            defaultActiveKey="all"
                        >
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavTransitModes(
                                            "all",
                                            e
                                        )
                                    }
                                    eventKey="all"
                                >
                                    All Transit
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavTransitModes(
                                            "train",
                                            e
                                        )
                                    }
                                    eventKey="train"
                                >
                                    Train
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavTransitModes(
                                            "bus",
                                            e
                                        )
                                    }
                                    eventKey="bus"
                                >
                                    Bus
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={(e) =>
                                        this.handleClickNavTransitModes(
                                            "light_rail",
                                            e
                                        )
                                    }
                                    eventKey="light_rail"
                                >
                                    Light Rail
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Table responsive className="mt-2">
                    <thead className="thead-light">
                        <tr className="d-flex">
                            <th className="text-center col-1">Type</th>
                            <th className="col-5">Transit Stop</th>
                            <th className="col-6">Transit Line/s</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getTransitStops()
                            .slice(0, this.state.limit)
                            .map((transit, i) => (
                                <Fragment key={i}>
                                    <tr className="d-flex">
                                        <td className="col-1">
                                            <TransitMarker
                                                color={
                                                    transitRouteModeColours[
                                                        transit.mode
                                                    ]
                                                }
                                            >
                                                {
                                                    transitRouteModes[
                                                        transit.mode
                                                    ]
                                                }
                                            </TransitMarker>
                                        </td>

                                        <td className="col-5 d-flex flex-column justify-content-center">
                                            <>
                                                <TransitDetails>
                                                    {transit.name}
                                                </TransitDetails>

                                                {this.props.showDistance && (
                                                    <div style={distance}>
                                                        {convertDistance(
                                                            transit.distance,
                                                            "m"
                                                        )}{" "}
                                                        <span>
                                                            <FaWalking
                                                                style={{
                                                                    verticalAlign:
                                                                        "text-top",
                                                                }}
                                                            />
                                                            {transit.duration}
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        </td>
                                        <td className="col-6">
                                            <Routes>
                                                {transit.routes.map((route) => (
                                                    <Route
                                                        key={route.name}
                                                        color={route.color}
                                                    >
                                                        {route.name}
                                                    </Route>
                                                ))}
                                            </Routes>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                    </tbody>
                </Table>
                <Row>
                    <Col className="text-center">
                        {this.props.data && isEmpty(this.getTransitStops()) && (
                            <Alert variant="danger">
                                No Transit Stops found
                            </Alert>
                        )}
                    </Col>
                </Row>
                {this.props.data &&
                    this.props.data.length > 0 &&
                    this.state.limit < this.props.data.length && (
                        <Row className="mt-3">
                            <Col className="text-center">
                                <Button
                                    id="transit-show-more"
                                    variant="outline-primary"
                                    onClick={() =>
                                        this.handleClickButtonShowMore()
                                    }
                                >
                                    Show More
                                </Button>
                            </Col>
                        </Row>
                    )}
            </>
        );
    }
}
