import React, { Component } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { OverlayView } from "@googlemap-react/core";
import { FaWalking } from "react-icons/fa";

import _ from "lodash";

const zoomLevelToShowDetails = 17;
const maxRoutes = 3; // max number of routes to display when zooming in

export const transitModes = {
    0: "train",
    1: "train",
    2: "train",
    3: "train",
    4: "train",
    5: "bus",
    6: "ferry",
    7: "subway",
    8: "light_rail",
    9: "bus",
    10: "cable_car",
    11: "cable_car",
    12: "bus",
    13: "monorail",
    14: "flight",
};

export const ignoreTransitNames = ["Non Revenue", "Out Of Service"];
export const ignoreTransitDirections = ["Empty Train"];

export const transitRouteModes = {
    train: "T",
    bus: "B",
    light_rail: "L",
    ferry: "F",
    subway: "S",
    flight: "A",
    cable_car: "C",
    monorail: "C",
    metro: "M",
};

export const transitRouteModeColours = {
    train: "#F5B602",
    bus: "#60D3FF",
    light_rail: "#FC4E4E",
    ferry: "#2E9C06",
    metro: "#06969f",
};

/**
 * Circular marker
 *
 */
const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => (props.size ? props.size : "20px")};
    height: ${(props) => (props.size ? props.size : "20px")};
    background-color: ${(props) => (props.color ? props.color : "#2c7fb8")};
    border: 2px solid #fff;
    border-radius: 100%;
    z-index: ${(props) => (props.zindex ? props.zindex : 100)};
`;
/*

*/
//cursor: ${(props) => (props.onClick ? "pointer" : "default")};

/**
 * Routes accompanying the Transit Stop
 *
 */
const Routes = styled.div`
    position: absolute;
    top: 10px;
    transform: translateX(-50%);
    text-align: center;
    width: 50px;
    z-index: ${(props) => (props.zindex ? props.zindex : 103)};
    display: flex;
    flex-wrap: wrap;
    /*flex-direction: column; row row-reverse column column-reverse  */
    justify-content: space-around; /* center space-around space-between */
`;

const Route = styled.div`
    /*flex: 0 1 50%;  flex-grow, flex-shrink, flex-basis 
    width: 24x;*/
    border: 2px solid ${(props) => (props.color ? props.color : "black")};
    background-color: white;
    text-align: center;
    padding: 0 1px;
`;

const MoreRoutes = styled.div`
    background-color: Gainsboro;
    text-align: center;
    padding: 0 1px;
    height: 14px;
`;

/**
 * Time it takes to walk to the Transit Stop
 *
 */
const Duration = styled.div`
    position: absolute;
    top: -6px;
    left: 10px;
    text-align: left;
    font-weight: bold;
    width: 60px;
    /* opacity: 0.9; */
    z-index: ${(props) => (props.zindex ? props.zindex : 102)};
`;
//width: 60px;

/**
 * (Optional) Icon inside the marker
 *
 */
const Icon = styled.div`
    position: absolute;
    verticalalign: "middle";
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) => (props.zindex ? props.zindex + 20 : 120)};
`;

/**
 * Index value for marker
 *
 */
const Index = styled.div`
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) => (props.zindex ? props.zindex + 10 : 110)};
`;

export default class TransitPlace extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        onHover: PropTypes.func,
        location: PropTypes.object.isRequired,
        color: PropTypes.string,
        text: PropTypes.string,
        routes: PropTypes.array,
        icon: PropTypes.object,
        index: PropTypes.string,
        size: PropTypes.string,
        duration: PropTypes.string,
        zindex: PropTypes.number,
        zoom: PropTypes.number,
        showDetails: PropTypes.bool,
        showDuration: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showDetails: false,
            zindex: props.zindex ? props.zindex : 100,
            maxRoutes: maxRoutes,
        };

        this.onChangeMouseOutDelayed = _.debounce(this.hideRoutes, 500);
    }

    onChangeMouseOver = () => {
        this.showRoutes();
    };

    showRoutes = () => {
        this.setState({
            showDetails: true,
            zindex: this.state.zindex + 500,
            maxRoutes: this.props.routes.length,
        });
    };

    hideRoutes = () => {
        this.setState({
            showDetails: false,
            zindex: this.state.zindex - 500,
            maxRoutes: maxRoutes,
        });
    };

    render() {
        return (
            <OverlayView position={this.props.location}>
                <Marker
                    onMouseOver={() => this.onChangeMouseOver()}
                    onMouseOut={() => this.onChangeMouseOutDelayed()}
                    color={this.props.color}
                    zindex={this.props.zindex}
                    size={this.props.size}
                />
                {this.props.icon && <Icon>{this.props.icon}</Icon>}
                {this.props.index && <Index>{this.props.index}</Index>}
                {this.props.zoom &&
                    (this.props.zoom >= zoomLevelToShowDetails ||
                        this.state.showDetails) &&
                    this.props.routes && (
                        <>
                            <Routes zindex={this.state.zindex}>
                                {this.props.routes
                                    .slice(0, this.state.maxRoutes)
                                    .map((route) => (
                                        <Route
                                            key={route.name}
                                            color={route.color}
                                        >
                                            {route.name}
                                        </Route>
                                    ))}

                                {!this.state.showDetails &&
                                    this.props.routes.length >
                                        this.state.maxRoutes && (
                                        <MoreRoutes key={"more"}>
                                            {"more"}
                                        </MoreRoutes>
                                    )}
                            </Routes>
                            {this.props.showDuration && (
                                <Duration zindex={this.state.zindex}>
                                    <FaWalking />
                                    {this.props.duration}
                                </Duration>
                            )}
                        </>
                    )}
            </OverlayView>
        );
    }
}
