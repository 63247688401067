import React from "react";
import Button from "react-bootstrap/Button";
import { FaSpinner } from "react-icons/fa";
import PropTypes from "prop-types";

import "./LoaderButton.css";

LoaderButton.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading && <FaSpinner className="spinning" />}
            {props.children}
        </Button>
    );
}
