import React, { useState, useEffect, Fragment } from "react";
import { GoogleMapProvider } from "@googlemap-react/core";

import MapPanel from "./map/MapPanel";
import Place from "./map/Place";
import AmenitiesPanel from "./AmenitiesPanel";

import { FaHome } from "react-icons/fa";
import PropTypes from "prop-types";

Amenities.propTypes = {
    address: PropTypes.string,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    showDistance: PropTypes.bool,
};

export default function Amenities(props) {
    const [latitude, setLatitude] = useState(props.latitude);
    const [longitude, setLongitude] = useState(props.longitude);

    useEffect(() => {
        setLatitude(props.latitude);
        setLongitude(props.longitude);
    }, [props.latitude, props.longitude]);

    return (
        <>
            {latitude && longitude && (
                <GoogleMapProvider>
                    <Fragment>
                        <MapPanel
                            location={{
                                lat: latitude,
                                lng: longitude,
                            }}
                            height={"50vh"}
                            zoom={20}
                        />

                        <AmenitiesPanel
                            latitude={latitude}
                            longitude={longitude}
                            showDistance={props.showDistance}
                        />
                        {props.showDistance && (
                            <Place
                                key={props.address}
                                location={{
                                    lat: latitude,
                                    lng: longitude,
                                }}
                                icon={<FaHome />}
                                height="30px"
                                width="30px"
                                zindex={110}
                            />
                        )}
                    </Fragment>
                </GoogleMapProvider>
            )}
        </>
    );
}
