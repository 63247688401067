import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

export default class OverlappingColumnChart extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        data: PropTypes.array.isRequired,
        title: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.ref = createRef();

        //http://colorbrewer2.org/#type=qualitative&scheme=Paired&n=10
        this.colors = d3.scaleOrdinal([
            "#a6cee3",
            "#1f78b4",
            "#b2df8a",
            "#33a02c",
            "#fb9a99",
            "#e31a1c",
            "#fdbf6f",
            "#ff7f00",
            "#cab2d6",
            "#6a3d9a",
        ]);
        // this.colors = d3.scaleOrdinal(d3.schemeCategory10);
        // this.colors = d3.scaleOrdinal(d3.schemeBlues[9]); //max is 9
    }

    componentDidMount() {
        this.drawChart();
    }

    drawChart() {
        let { width, height, data } = this.props;

        // Make left margin dynamic based on label widths
        const longest_type = data.reduce(function(a, b) {
            return a.type.length > b.type.length ? a : b;
        });

        width = this.ref.current
            ? this.ref.current.parentNode.offsetWidth * 0.9
            : width;

        // set the dimensions and margins of the graph
        const margin = {
            top: 10,
            right: 20,
            bottom: longest_type.type.length * 5 + 10, //bottom margin based on label text lengths
            left: 30,
        };
        const new_width = width - margin.left - margin.right;
        const new_height = height - margin.top - margin.bottom;

        const maxValue = d3.max(data, (d) => {
            return d.value > d.secondary ? d.value : d.secondary;
        });

        //avoid re-rendering over a previous svg
        d3.select(this.ref.current)
            .select("g")
            .remove();

        const svg = d3
            .select(this.ref.current)
            .attr("width", new_width + margin.left + margin.right) // fixed size
            .attr("height", new_height + margin.top + margin.bottom) // fixed size
            // .attr("width", "100%") // make responsive
            // .attr("height", "100%") // make responsive
            // .attr("preserveAspectRatio", "xMinYMin") // make responsive
            // .attr(
            //     "viewBox",
            //     "0 0 " +
            //         (new_width + margin.left + margin.right) +
            //         " " +
            //         (new_height + margin.top + margin.bottom)
            // ) // make responsive
            .append("g")
            .attr(
                "transform",
                "translate(" + margin.left + "," + margin.top + ")"
            );

        // set the ranges
        const x = d3
            .scaleBand()
            .range([0, new_width])
            // .padding(0.1);
            .padding(0.2);

        // const y = d3.scaleLinear().range([new_height, 0]);
        const y = d3.scaleLinear().range([new_height, 0]); //range is the pixel span of your data (from min to max),

        x.domain(
            data.map((d) => {
                return d.type;
            })
        );

        y.domain([0, maxValue * 1.2]); //domain is the user-space span of your data (the min and max of your data values)

        // const yTextPadding = 4;

        // create a group for your overlapped bars
        const g = svg
            .selectAll(".bar")
            .data(data)
            .enter()
            .append("g");

        g.append("rect")
            .attr("class", "malebar")
            .attr("x", (d) => {
                return x(d.type);
            })
            // .attr("width", x.bandwidth()) // use if overlapping
            .attr("width", x.bandwidth() / 2)
            .attr("y", (d) => {
                // console.log(d);
                return y(d.value);
            })
            .attr("height", (d) => {
                return new_height - y(d.value);
            })
            .attr("style", "fill-opacity: 0.6; fill: #e377c2;");

        g.append("rect")
            .attr("class", "femalebar")
            .attr("x", (d) => {
                // return x(d.type);  // use if overlapping
                return x(d.type) + x.bandwidth() / 2;
            })
            // .attr("width", x.bandwidth())  // use if overlapping
            .attr("width", x.bandwidth() / 2)
            .attr("y", (d) => {
                // console.log(d);
                return y(d.secondary);
            })
            .attr("height", (d) => {
                return new_height - y(d.secondary);
            })
            .attr("style", "fill: #1f77b4;");

        //append text to the bar
        // svg
        //   .selectAll(".bartext")
        //   .data(this.props.data)
        //   .enter()
        //   .append("text")
        //   // .attr("class", "bartext")
        //   .attr("text-anchor", "middle")
        //   .attr("font-family", "sans-serif")
        //   .attr("font-size", "8px")
        //   .attr("fill", "black")
        //   .attr("x", (d, i) => {
        //     return (
        //       i * (new_width / this.props.data.length) +
        //       new_width / this.props.data.length / 2
        //     );
        //   })
        //   .attr("y", (d, i) => {
        //     const y_val = new_height - y(d.value) + yTextPadding;
        //     return new_height - y_val;
        //   })
        //   .text(d => {
        //     return d.value;
        //   });

        // add the x Axis
        svg.append("g")
            .attr("transform", "translate(0," + new_height + ")")
            .call(d3.axisBottom(x).ticks(null, "s"))
            .call((g) => g.select(".domain").remove()) // remove the axis line
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("font-weight", "bold")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-70)");

        // add the y Axis
        svg.append("g")
            .call(
                d3
                    .axisLeft(y)
                    // .ticks(null, "s")
                    .tickFormat((d) => {
                        //return (100 * (d / total)).toFixed(1) + "%";
                        return d;
                    })
            )
            .call((g) => g.select(".domain").remove()); // remove the axis line
        // .call((g) =>
        //     g
        //         .select(".tick:last-of-type text")
        //         // .clone()
        //         .attr("x", 5)
        //         // .attr("y", 5)
        //         .attr("text-anchor", "start")
        //         .attr("font-weight", "bold")
        //         .text(title)
        // );
    }

    UNSAFE_componentWillUpdate() {
        this.drawChart();
    }

    render() {
        return <svg ref={this.ref} />;
    }
}
