import React, { Component } from "react";
import { Polygon, GoogleMapContext } from "@googlemap-react/core";

import PropTypes from "prop-types";

let defaultState = {
    // strokeColor: "white",
    // strokeOpacity: 0.1,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillOpacity: 0.3,
    // fillOpacity: 0,
    zIndex: 10,
};

const hoverState = {
    // strokeOpacity: 1,
    fillOpacity: 0.8,
    // strokeWeight: 3,
    zIndex: 11, //default ZIndex is required for mouseover/out otherwise nerighbouring Polygons strokeColor can override this ones
};

const clickZoomLevel = 17;

const getPolygonPoints = (coordinates) => {
    let points = [];

    coordinates.forEach((entry) => {
        points.push({ lat: entry[1], lng: entry[0] });
    });

    return points;
};

//Retrieve the polygon/multipolygon from the feature as paths array
const getPaths = (feature) => {
    let geometry = feature;

    const type = geometry.type;
    let paths = [];
    if (type === "Polygon") {
        geometry.coordinates.forEach((coords) => {
            paths.push(getPolygonPoints(coords));
        });
    } else if (type === "MultiPolygon") {
        geometry.coordinates.forEach((coords) => {
            coords.forEach((innercoords) =>
                paths.push(getPolygonPoints(innercoords))
            );
        });
    }
    return paths;
};

class Geometry extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        geometry: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        fill: PropTypes.string,
        zIndex: PropTypes.number,
        opacity: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = defaultState;
    }

    componentDidMount() {
        this.setState({ fillColor: this.props.fill });
        this.setState({ strokeColor: this.props.fill });
    }

    handleClick() {
        if (this.context.state.map) {
            const map = this.context.state.map;

            map.setZoom(clickZoomLevel);
            map.panTo(this.props.location);
        }
        // this.props.history.push({
        //   pathname: "/censuschart/" + addressSlug,
        //   state: {
        //     la1_id: la1_id,
        //     // la2: la2,
        //     addressSlug: addressSlug,
        //     latitude: location.lat,
        //     longitude: location.lng
        //   }
        // });
        // return (
        //   <Redirect
        //     to={{
        //       pathname: "/censuschart/" + addressSlug,
        //       state: {
        //         id: id,
        //         addressSlug: addressSlug,
        //         latitude: location.lat,
        //         longitude: location.lng
        //       }
        //     }}
        //   />
        // );
        //re-direct to profile
        // console.log(this);
        //TODO update popup with profile?
    }

    handleMouseOver() {
        // console.log(this.props.name, this.props.id);
        this.setState(hoverState);
    }

    handleMouseOut() {
        this.setState(defaultState);
    }

    render() {
        return this.props.geometry ? (
            <Polygon
                key={this.props.id + "_polygon"}
                id={this.props.id}
                opts={{
                    paths: getPaths(this.props.geometry),
                    strokeColor: this.state.strokeColor
                        ? this.state.strokeColor
                        : "#ffa800",
                    strokeOpacity: this.state.strokeOpacity,
                    strokeWeight: this.state.strokeWeight,
                    fillColor: this.state.fillColor
                        ? this.state.fillColor
                        : "#ffa800",
                    fillOpacity: this.props.opacity
                        ? this.props.opacity
                        : this.state.fillOpacity,
                    zIndex: this.state.zIndex + this.props.zIndex,
                }}
                onClick={() => this.handleClick()}
                onMouseOver={() => this.handleMouseOver()}
                onMouseOut={() => this.handleMouseOut()}
            />
        ) : null;
    }
}

//https://reactjs.org/docs/context.html#classcontexttype
Geometry.contextType = GoogleMapContext;

export default Geometry;
