import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

/**
 * https://observablehq.com/@d3/pie-chart?collection=@d3/d3-shape
 *
 */
export default class PieChart extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        innerRadius: PropTypes.number,
        outerRadius: PropTypes.number.isRequired,
        data: PropTypes.array.isRequired,
        topElems: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.ref = createRef();

        this.createPie = d3
            .pie()
            .value((d) => d.value)
            .sort(null);

        this.createArc = d3
            .arc()
            .innerRadius(props.innerRadius)
            .outerRadius(props.outerRadius);

        //https://github.com/d3/d3-scale-chromatic/tree/v1.3.3
        this.colors = d3.scaleOrdinal(d3.schemeCategory10);
        // this.colors = d3.scaleOrdinal(d3.schemeBlues);

        this.format = d3.format(".0f");

        if (props.topElems && props.topElems < this.props.data.length) {
            this.data = this.props.data.slice(
                this.props.data.length - props.topElems,
                this.props.data.length
            );
        } else {
            this.data = this.props.data;
        }
    }

    getTotal() {
        const total = this.data
            .map((item) => item.value)
            .reduce((prev, next) => prev + next);

        return total;
    }

    componentDidMount() {
        const svg = d3.select(this.ref.current);

        const pieData = this.createPie(this.data);
        const total = this.getTotal();

        let { width } = this.props;

        const topMargin = 20;
        const legendHeight = 50;
        const legendTopMargin = 10;
        const bottomMargin = 40;
        const height =
            this.props.outerRadius * 2 +
            topMargin * 2 +
            legendHeight +
            legendTopMargin +
            bottomMargin;

        svg.attr("class", "chart")
            .attr("width", width) // fixed size
            .attr("height", height); // fixed size

        const x = width / 2;
        const y = height / 2 - topMargin - bottomMargin;
        const group = svg.append("g").attr("transform", `translate(${x} ${y})`);

        const groupWithEnter = group
            .selectAll("g.arc")
            .data(pieData)
            .enter();

        const path = groupWithEnter.append("g").attr("class", "arc");

        path.append("path")
            .attr("class", "arc")
            .attr("d", this.createArc)
            .attr("fill", (d) => this.colors(d.index));

        //https://stackoverflow.com/questions/19792552/d3-put-arc-labels-in-a-pie-chart-if-there-is-enough-space/19801529#19801529
        path.append("text")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .attr(
                "transform",
                (d) => `translate(${this.createArc.centroid(d)})`
            )
            .style("fill", "white")
            .style("font-size", 10)
            .text((d) => (100 * (d.value / total)).toFixed(1) + "%");

        //add legend
        // legend dimensions
        const legendRectSize = 16; // defines the size of the colored squares in legend
        const legendSpacing = 4; // defines spacing between squares
        // const legendItemSize = 8;
        // const legendSpacing = 2;

        const legendOffset =
            height - legendHeight - legendTopMargin - bottomMargin;

        const legend = svg
            .selectAll(".legend")
            .data(this.data)
            .enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", (d, i) => {
                const legendItemHeight = legendRectSize + legendSpacing; // height of element is the height of the colored square plus the spacing
                const y =
                    // legendOffset + legendRectSize * 2 + i * legendItemHeight;
                    legendOffset + i * legendItemHeight;
                const x = 0;
                return `translate(${x}, ${y})`;
            });

        legend
            .append("rect")
            .attr("width", legendRectSize)
            .attr("height", legendRectSize)
            .style("fill", (_d, i) => this.colors(i));

        legend
            .append("text")
            .attr("x", legendRectSize + legendSpacing)
            .attr("y", legendRectSize - legendSpacing)
            .text((d) => {
                //return this.formatType(d.type);
                return d.type;
            });
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const total = this.getTotal();
        const svg = d3.select(this.ref.current);
        const data = this.createPie(nextProps.data);

        const group = svg
            .select("g")
            .selectAll("g.arc")
            .data(data);

        group.exit().remove();

        const groupWithUpdate = group
            .enter()
            .append("g")
            .attr("class", "arc");

        const path = groupWithUpdate
            .append("path")
            .merge(group.select("path.arc"));

        path.attr("class", "arc")
            .attr("d", this.createArc)
            .attr("fill", (d, i) => this.colors(i));

        const text = groupWithUpdate.append("text").merge(group.select("text"));

        text.attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .attr(
                "transform",
                (d) => `translate(${this.createArc.centroid(d)})`
            )
            .text((d) => (100 * (d.value / total)).toFixed(1) + "%");
    }

    render() {
        return <svg ref={this.ref} />;
    }
}
