import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import { GoogleMapProvider } from "@googlemap-react/core";
import orderBy from "lodash/orderBy";
import { Link } from "react-router-dom";

import {
    convertUnderscoreToDash,
    convertDashToUnderscore,
    titleCase,
} from "../Helpers";
import MapPanel from "../components/map/MapPanel";
import Geometry from "../components/map/Geometry";
// import NeighbouringArea from "../components/NeighbouringArea";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

AreaLA3.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            la4_id: PropTypes.string,
            la3_id: PropTypes.string,
        }),
    }),
    location: PropTypes.object,
    history: PropTypes.object,
};

export default function AreaLA3(props) {
    const [la2List, setLa2List] = useState([]);

    const [id, setId] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [area, setArea] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);

    // const [population, setPopulation] = useState(undefined);
    const [geometry, setGeometry] = useState(undefined);

    async function getNeighbourhoods() {
        const la2Json =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/sydney" +
            "/la2-home.json";

        fetch(la2Json)
            .then((response) => response.json())
            .then((response) => {
                setLa2List(orderBy(response, "name", "asc"));
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        const la3GeoJson =
            process.env.REACT_APP_DATA_URL +
            "/metadata" +
            "/" +
            props.match.params.la4_id +
            "/" +
            convertDashToUnderscore(props.match.params.la3_id) +
            "/" +
            "_features.json";

        fetch(la3GeoJson)
            .then((response) => response.json())
            .then((response) => {
                setId(response.properties.name_id);
                setName(response.properties.name);
                setArea(response.properties.area);
                setLatitude(response.properties.location.lat);
                setLongitude(response.properties.location.lng);
                // setPopulation(response.properties.popn);
                setGeometry(response.geometry);
            })
            .catch(() => {
                props.history.push({
                    pathname: "/404",
                });
            });

        getNeighbourhoods();
    }, [props.match.params.la3_id, props.match.params.la4_id, props.history]);

    return (
        <Fragment>
            {name && (
                <Helmet>
                    <title>{name + " Property Research - Liveable"}</title>
                    <meta
                        property="og:title"
                        content={"Area Profile: " + name + " - Liveable"}
                    />
                    <meta
                        name="description"
                        content={
                            "Area Profile for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            "Area Profile for " +
                            name +
                            ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics."
                        }
                    />
                    <link
                        rel="canonical"
                        href={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id +
                            "/" +
                            props.match.params.la3_id
                        }
                    />
                    <meta
                        property="og:url"
                        content={
                            "https://liveable.co/area/" +
                            props.match.params.la4_id +
                            "/" +
                            props.match.params.la3_id
                        }
                    />
                </Helmet>
            )}
            {name && (
                <>
                    <JSONLD>
                        <Generic
                            type="webPage"
                            jsonldtype="WebPage"
                            schema={{
                                name: "Area Profile: " + name,
                                description:
                                    "Area Profile for " +
                                    name +
                                    ". Recently Sold Properties. Current Sales and Rental Listings on the market. Amenities around the area. Schools in the area. Neighbourhood demographics.",
                                url:
                                    "https://liveable.co" +
                                    props.location.pathname,
                            }}
                        />
                    </JSONLD>
                    <JSONLD>
                        <Generic
                            type="breadcrumbList"
                            jsonldtype="BreadcrumbList"
                        >
                            <GenericCollection type="itemListElement">
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 1,
                                        name: "Area",
                                        item: "https://liveable.co/area",
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 2,
                                        name: titleCase(
                                            props.match.params.la4_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id,
                                    }}
                                />
                                <Generic
                                    jsonldtype="ListItem"
                                    schema={{
                                        position: 3,
                                        name: titleCase(
                                            props.match.params.la3_id,
                                            "-"
                                        ),
                                        item:
                                            "https://liveable.co/area/" +
                                            props.match.params.la4_id +
                                            "/" +
                                            props.match.params.la3_id,
                                    }}
                                />
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                </>
            )}
            <Container className="pb-5 pt-4">
                {/* Breadcrumb */}
                <section id="breadcrumb" className="text-muted">
                    <Row>
                        <Col>
                            <Link to={"/area/" + props.match.params.la4_id}>
                                {titleCase(props.match.params.la4_id, "-")}
                            </Link>
                        </Col>
                    </Row>
                </section>
                <section id="area">
                    <Row>
                        <Col>
                            <h1>{name}</h1>
                        </Col>
                    </Row>

                    {/* Google Map */}
                    <Row className="mt-4 mb-4">
                        <Col>
                            <GoogleMapProvider>
                                {latitude && longitude && (
                                    <MapPanel
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                        height={"40vh"}
                                        zoom={area < 100000000 ? 12 : 10}
                                        enableZoom={true}
                                    />
                                )}
                                {geometry && (
                                    <Geometry
                                        id={id}
                                        name={name}
                                        geometry={geometry}
                                        fill={"#315990"}
                                        location={{
                                            lat: latitude,
                                            lng: longitude,
                                        }}
                                    />
                                )}
                            </GoogleMapProvider>
                        </Col>
                    </Row>
                </section>
                <section id="neighbourhoods">
                    <Row className="mt-3">
                        <Col>
                            <h2>Neighbourhoods</h2>
                        </Col>
                    </Row>
                    <Row>
                        {la2List
                            .filter(function(la2Id) {
                                return la2Id.parent_name_id === id;
                            })
                            .map((la2) => (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    className="pt-3 text-center carousel-card"
                                    key={"La2_" + la2.id}
                                >
                                    <Card bg="light" key={"La2_Card_" + la2.id}>
                                        <Card.Body>
                                            {la2.name_id && (
                                                <a
                                                    href={
                                                        "/area/" +
                                                        props.match.params
                                                            .la4_id +
                                                        "/" +
                                                        convertUnderscoreToDash(
                                                            la2.parent_name_id
                                                        ) +
                                                        "/" +
                                                        convertUnderscoreToDash(
                                                            la2.name_id
                                                        )
                                                    }
                                                >
                                                    <Card.Title>
                                                        {la2.name}
                                                    </Card.Title>
                                                </a>
                                            )}
                                            <Card.Subtitle className="mt-3 mb-2 text-muted">
                                                Postcode:{" "}
                                                {la2.postal.join(", ")}
                                            </Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </section>
            </Container>
        </Fragment>
    );
}
