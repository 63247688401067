import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import styled from "styled-components";

import { slugify } from "../Helpers";

const Link = styled.span`
    &:hover {
        color: #ffa800;
        cursor: pointer;
    }
`;

class PropertyLink extends Component {
    static propTypes = {
        addressSlug: PropTypes.string,
        lotNumber: PropTypes.number,
        address: PropTypes.string.isRequired,
        dwellingType: PropTypes.string,
        numBed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numBath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numCar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        area: PropTypes.number,
        areaType: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        date: PropTypes.string,
        images: PropTypes.array,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        history: PropTypes.object,
    };

    redirect = () => {
        if (this.props.addressSlug) {
            this.props.history.push({
                pathname:
                    process.env.REACT_APP_PROFILE_URL +
                    "/" +
                    this.props.addressSlug,

                state: {
                    address: this.props.address,
                    addressSlug: this.props.addressSlug,
                    lotNumber: this.props.lotNumber,
                    images: this.props.images,
                    dwellingType: this.props.dwellingType,
                    numBed: this.props.numBed,
                    numBath: this.props.numBath,
                    numCar: this.props.numCar,
                    area: this.props.area,
                    areaType: this.props.areaType,
                    price: this.props.price,
                    date: this.props.date,
                    latitude: this.props.latitude,
                    longitude: this.props.longitude,
                },
            });
        } else {
            // window.location.href =
            //     process.env.REACT_APP_PROFILE_URL +
            //     "/" +
            //     slugify(this.props.address.replace("/", "-"));
            this.props.history.push({
                pathname:
                    process.env.REACT_APP_PROFILE_URL +
                    "/" +
                    slugify(this.props.address.replace("/", "-")),

                state: {
                    address: this.props.address,
                    images: this.props.images,
                    dwellingType: this.props.dwellingType,
                    numBed: this.props.numBed,
                    numBath: this.props.numBath,
                    numCar: this.props.numCar,
                    price: this.props.price,
                    latitude: this.props.latitude,
                    longitude: this.props.longitude,
                },
            });
        }
    };

    render() {
        return (
            <Link
                id="property-card-address"
                onClick={() => {
                    this.redirect();
                }}
            >
                {this.props.address}
            </Link>
        );
    }
}

export default withRouter(PropertyLink);
