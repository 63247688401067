/* eslint-disable no-console */
import React, { Component, Fragment } from "react";
import { GoogleMapContext } from "@googlemap-react/core";
import { FaBeer, FaCoffee, FaUtensils, FaShoppingBasket } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import PropTypes from "prop-types";

import { convertDistance } from "../Helpers";
import Place from "./map/Place";
import Rating from "./map/Rating";

const type_colours = {
    train: "#F5B602",
    bus: "#60D3FF",
    light_rail: "#FC4E4E",
    ferry: "#2E9C06",
    bar: "red",
    cafe: "green",
    restaurant: "purple",
    supermarket: "blue",
};
const type_icons = {
    bar: <FaBeer />,
    cafe: <FaCoffee />,
    restaurant: <FaUtensils />,
    supermarket: <FaShoppingBasket />,
};

const name = {
    color: "#1a2a4c",
    fontWeight: "bold",
    textAlign: "left",
};

const address = {
    color: "#666666",
    fontSize: "0.8em",
    textAlign: "left",
};

const rating = {
    textAlign: "right",
};

const distance = {
    color: "#666666",
    fontSize: "0.8em",
    textAlign: "right",
};

export default class PlaceTable extends Component {
    static contextType = GoogleMapContext;

    static propTypes = {
        data: PropTypes.array.isRequired,
        limit: PropTypes.number.isRequired,
        showDistance: PropTypes.bool,
    };

    render() {
        if (this.context.state.map) {
            return (
                <Table size="sm" responsive>
                    <tbody>
                        {this.props.data
                            .slice(0, this.props.limit)
                            .map((item) => (
                                <Fragment key={"Fragment_" + item.place_id}>
                                    <tr
                                        className="d-flex"
                                        key={"tr_" + item.place_id}
                                    >
                                        <td className="col-6">
                                            <>
                                                <div style={name}>
                                                    {item.name}
                                                </div>
                                                <div style={address}>
                                                    {item.vicinity}
                                                </div>
                                            </>
                                        </td>
                                        <td className="col-4">
                                            <div style={rating}>
                                                <Rating score={item.rating} />
                                            </div>
                                        </td>
                                        {this.props.showDistance && (
                                            <td className="col-2">
                                                <div style={distance}>
                                                    {convertDistance(
                                                        item.distance,
                                                        "km"
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                    <Place
                                        key={item.place_id}
                                        location={{
                                            lat: item.lat
                                                ? item.lat
                                                : item.geometry.location.lat(),
                                            lng: item.lng
                                                ? item.lng
                                                : item.geometry.location.lng(),
                                        }}
                                        color={type_colours[item.liveable_type]}
                                        icon={type_icons[item.liveable_type]}
                                        name={item.name}
                                        fitBounds={true}
                                    />
                                </Fragment>
                            ))}
                    </tbody>
                </Table>
            );
        } else {
            return <div />;
        }
    }
}
