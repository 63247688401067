import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PropTypes from "prop-types";

class CardTableItemMobile extends Component {
    static propTypes = {
        label: PropTypes.string,
        superscript: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        link: PropTypes.string,
        label_size: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            label: this.props.label,
            superscript: this.props.superscript,
            data: this.props.data,
            link: this.props.link,
            label_size: 1,
        };
    }

    componentDidMount() {
        this.props.label_size &&
            this.setState({ label_size: this.props.label_size });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label,
            superscript: nextProps.superscript,
            data: nextProps.data,
            link: nextProps.link,
        });
    }

    render() {
        return (
            <Row>
                <Col
                    xs={Math.round(5 * this.state.label_size)}
                    sm={Math.round(4 * this.state.label_size)}
                    md={Math.round(6 * this.state.label_size)}
                >
                    {this.state.label}
                    {this.state.superscript && (
                        <sup>{this.state.superscript}</sup>
                    )}
                    :
                </Col>
                <Col className="text-right">
                    {this.state.link ? (
                        <a href={this.state.link}>{this.state.data}</a>
                    ) : (
                        this.state.data
                    )}
                </Col>
            </Row>
        );
    }
}

export default CardTableItemMobile;
