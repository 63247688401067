/* eslint-disable react/prop-types */
import React, { Fragment, useContext } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { OverlayView, GoogleMapContext } from "@googlemap-react/core";

import Place from "./Place";
import Geohash from "latlon-geohash";

// import _ from "lodash";
import sortBy from "lodash/sortBy";

let Map = require("es6-map");
const defaultZindex = 100;
const limit = 100;
const groupedZoomlevel = 18; // 1 iz highest level of zoom, 16 is average
const geoHashLevel = 11; // geohash length, values from 1-12. 12 is most accurate

/**
 * Rectangular marker
 *
 */
const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(props) => (props.width ? props.width : "40px")};
    height: ${(props) => (props.height ? props.height : "20px")};
    background-color: ${(props) => (props.color ? props.color : "#2c7fb8")};
    border: 2px solid #fff;
    border-radius: 8px;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
    z-index: ${(props) => (props.zindex ? props.zindex : defaultZindex)};
`;

/**
 * Text accompanying the marker
 *
 */
const Text = styled.div`
    position: absolute;
    top: 16px;
    left: -50px;
    border: 2px solid #fff;
    background-color: white;
    text-align: center;
    width: 100px;
    z-index: ${(props) => (props.zindex ? props.zindex + 1 : defaultZindex)};
`;

/**
 * (Optional) Icon inside the marker
 *
 */
const Icon = styled.div`
    position: absolute;
    verticalalign: "middle";
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 2 : defaultZindex + 2};
`;

/**
 * Index value for marker
 *
 */
const Index = styled.div`
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: white;
    transform: translate(-50%, -50%);
    z-index: ${(props) =>
        props.zindex ? props.zindex + 3 : defaultZindex + 3};
    white-space: nowrap;
`;

MultiPlace.propTypes = {
    location: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    color: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.object,
    index: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    fitBounds: PropTypes.bool,
    zindex: PropTypes.number,
    multiPlace: PropTypes.array.isRequired,
};

/**
 * Contains a list of markers to be shown when zoom level is reached, otherwise show the grouped marker
 */
export default function MultiPlace(props) {
    const { state } = useContext(GoogleMapContext);
    const map = state.map;

    if (map && props.fitBounds) {
        let bounds = map.getBounds();
        if (bounds && !bounds.contains(props.location)) {
            // fit the marker inside the map
            bounds.extend(props.location);
            map.fitBounds(bounds);
        }
    }

    const renderGroupedMarker = () => {
        return (
            <OverlayView position={props.location}>
                <Marker
                    {...(props.onClick ? { onClick: props.onClick } : {})}
                    {...(props.onHover ? { onHover: props.onHover } : {})}
                    color={props.color}
                    zindex={props.zindex}
                    height={props.height}
                    width={props.width}
                />
                {props.icon && <Icon zindex={props.zindex}>{props.icon}</Icon>}
                {props.index && (
                    <Index zindex={props.zindex}>{props.index}</Index>
                )}
                {props.text && <Text zindex={props.zindex}>{props.text}</Text>}
            </OverlayView>
        );
    };

    function renderAllMarkers(listings) {
        return (
            <>
                {listings.map((item, index) => (
                    <Fragment key={"Fragment_" + index}>
                        {item.showMarker &&
                            (item.multi ? (
                                renderGroupedMarker()
                            ) : (
                                <Place
                                    key={
                                        "Place_" +
                                        item.listing_id +
                                        "_" +
                                        item.index +
                                        "_" +
                                        index
                                    }
                                    location={{
                                        lat: item.latitude
                                            ? item.latitude
                                            : item.lat,
                                        lng: item.longitude
                                            ? item.longitude
                                            : item.lng,
                                    }}
                                    color={props.color}
                                    index={item.markerText}
                                    fitBounds={props.fitBounds}
                                    zindex={(index + 1) * 10}
                                />
                            ))}
                    </Fragment>
                ))}
            </>
        );
    }

    if (state.map.getZoom() >= groupedZoomlevel) {
        let listings = [];

        listings = clusterMarkers(props.multiPlace, limit, geoHashLevel);

        return renderAllMarkers(listings);
    } else {
        return renderGroupedMarker(props);
    }
}

const getAlphaIndex = (index) => {
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // handle index values that are greater than 29
    if (index >= alpha.length) {
        return (
            alpha.charAt(Math.floor(index / alpha.length) - 1) +
            alpha.charAt(index % alpha.length)
        );
    }

    return alpha.charAt(index);
};

/**
 *
 * @param {*} properties - list of properties
 * @param {*} limit - number of properties to try and cluster
 * @param {*} geoHashLevel - granularity of the groupings. smaller is more granular, ie properties need to be very close in order to cluster
 */
export const clusterMarkers = (properties, limit, geoHashLevel) => {
    let propertyMap = new Map(); // group properties at same location together

    // console.log("listings", properties.slice(0, limit));

    properties.slice(0, limit).forEach((property, index) => {
        property.multiPlace = undefined; // reset the clusters (if any)
        property.multi = undefined;
        // console.log(property);
        const geohashKey = Geohash.encode(
            property.latitude ? property.latitude : property.lat,
            property.longitude ? property.longitude : property.lng,
            geoHashLevel
        );

        // already found a property at this location
        if (propertyMap.has(geohashKey)) {
            let existingProperties = propertyMap.get(geohashKey);
            if (Array.isArray(existingProperties)) {
                // multiple properties at this location
                index = existingProperties[0].index;
                // console.log(property, property.index, index);
                property.index = index;

                existingProperties.push(property);

                existingProperties.forEach((prop, i) => {
                    prop.multi = true;
                    prop.markerText =
                        getAlphaIndex(index) + "1-" + existingProperties.length;
                    prop.cardText = getAlphaIndex(index) + "" + (i + 1);
                    prop.multiPlace = [...existingProperties];
                    prop.geohashKey = geohashKey;
                });

                propertyMap.set(geohashKey, [...existingProperties]);
            } else {
                // two properties at this location
                index = existingProperties.index; //re-use index from clustered marker
                property.index = index;

                existingProperties.cardText = getAlphaIndex(index) + "1";
                property.cardText = getAlphaIndex(index) + "2";
                existingProperties.markerText = getAlphaIndex(index) + "1-2";
                property.markerText = existingProperties.markerText;
                existingProperties.multi = true;
                property.multi = true;

                propertyMap.set(geohashKey, [existingProperties, property]);

                // create a new list of  properties at this Place and update the currect property with the list
                existingProperties.multiPlace = [existingProperties, property];
                property.multiPlace = [existingProperties, property];
            }
        } else {
            // default single property at this location
            if (property.multiPlace && property.multiPlace[0]) {
                property.index = property.multiPlace[0].index;
            } else {
                property.index = propertyMap.size;
                // property.index = index; // first property seen for this place
            }

            // property.cardText = property.cardText
            //     ? property.cardText
            //     : getAlphaIndex(property.index);

            // property.markerText = property.markerText
            //     ? property.cardText
            //     : getAlphaIndex(property.index);

            property.cardText = getAlphaIndex(property.index);
            property.markerText = getAlphaIndex(property.index);

            property.showMarker = true;
            property.multi = false;
            propertyMap = propertyMap.set(geohashKey, property);
        }
    });

    return sortBy(properties.slice(0, limit), "index");
};
