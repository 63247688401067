import { useState } from "react";
import sortBy from "lodash/sortBy";
import moment from "moment";
import { getUA } from "react-device-detect";

const ID_LOOKUP_TABLE = {
    ku_ring_gai: "ku-ring-gai",
    ku_ring_gai_chase: "ku-ring-gai_chase",
    canterbury_bankstown: "canterbury-bankstown",
};

const isObject = (val) => {
    return typeof val === "object" && val !== null;
};

export const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
        if (typeof arg === "string") {
            classes.push(arg);
        } else if (isObject(arg)) {
            Object.keys(arg).forEach((key) => {
                if (arg[key]) {
                    classes.push(key);
                }
            });
        } else {
            throw new Error(
                "`classnames` only accepts string or object as arguments"
            );
        }
    });

    return classes.join(" ");
};

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
};

export const moneyFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 0,
});

export const compactMoneyFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    maximumSignificantDigits: 3,
    notation: "compact",
    compactDisplay: "short",
});

export const titleCase = (str, splitChar) => {
    return str
        .toLowerCase()
        .split(splitChar)
        .map(function(word) {
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
};

export const convertDashToPlus = (inputString) => {
    return inputString.replace(/-/gi, "+");
};

export const convertUnderscoreToDash = (inputString) => {
    return inputString.replace(/_/g, "-");
};

export const convertDashToUnderscore = (inputString) => {
    return inputString.replace(/-/g, "_");
};

export const convertNameToId = (inputString) => {
    return inputString
        .replace(/ /g, "-")
        .replace(/,./g, "")
        .toLowerCase();
};

export const convertDashToSlug = (inputString) => {
    let slug = inputString.replace(/-/g, "_");

    if (Object.prototype.hasOwnProperty.call(ID_LOOKUP_TABLE, slug)) {
        return ID_LOOKUP_TABLE[slug];
    } else {
        return slug;
    }
};

export const distance = (lat1, lon1, lat2, lon2) => {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist.toFixed(2);
    }
};

// dateString = YYYYMMDD from DynamoDB
export const convertDateStringToDate = (dateString) => {
    return new Date(
        dateString.substring(0, 4),
        parseInt(dateString.substring(4, 6)) - 1,
        dateString.substring(6, 8)
    );
};

export const addressGeocodedSplit = (address) => {
    let addressLineOne = address.split(",");
    let addressLineTwo = addressLineOne.splice(addressLineOne.length - 2, 2);

    return {
        street: addressLineOne,
        area: addressLineTwo[0].trim(),
        country: addressLineTwo[1].trim(),
    };
};

export const addressStripCountry = (address) => {
    const address_split = address.split(",");

    if (
        address_split.length &&
        address_split[address_split.length - 1].trim() === "Australia"
    ) {
        return address.substr(0, address.lastIndexOf(","));
    } else {
        return address;
    }
};

export const computeCentreLocationBetweenPoints = (
    lat_a,
    lng_a,
    lat_b,
    lng_b
) => {
    let lat_center = lat_a;
    let lng_center = lng_a;

    if (lat_a && lng_a && lat_b && lng_b) {
        lat_center = (lat_a + lat_b) / 2;
        lng_center = (lng_a + lng_b) / 2;
    }
    return { lat: lat_center, lng: lng_center };
};

export const addAndSortByDistance = (data, point_latitude, point_longitude) => {
    if (data) {
        data.forEach((item) => {
            if ((item.latitude || item.lat) && (item.longitude || item.lng)) {
                item.distance = distance(
                    point_latitude,
                    point_longitude,
                    item.latitude ? item.latitude : item.lat,
                    item.longitude ? item.longitude : item.lng
                );
            }
        });

        data = sortBy(data, "distance");
    }
    return data;
};

export const convertDistance = (distance, unit) => {
    let convertedDistance = parseFloat(distance);
    if (unit === "m") {
        if (convertedDistance > 1000) {
            return (convertedDistance / 1000).toFixed(2) + "km";
        } else {
            return convertedDistance + "m";
        }
    } else if (unit === "km") {
        if (convertedDistance < 1) {
            return convertedDistance * 1000 + "m";
        } else {
            return convertedDistance.toFixed(2) + "km";
        }
    }
};

export const useFormFields = (initialState) => {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function(event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value,
            });
        },
    ];
};

export const storeSlug = (addressSlug, variable) => {
    let slugStoreVar = "addressSlugs";

    if (variable) {
        slugStoreVar = variable;
    }

    let myStorage = window.localStorage.getItem(slugStoreVar);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    myStorage = myStorage ? myStorage.split(",") : [];

    // Add new data to localStorage Array if it doesn't already exist
    !myStorage.includes(addressSlug) && myStorage.push(addressSlug);

    // Save back to localStorage
    window.localStorage.setItem(slugStoreVar, myStorage.toString());
};

export const getSlugCount = (variable) => {
    let slugStoreVar = "addressSlugs";

    if (variable) {
        slugStoreVar = variable;
    }

    let myStorage = window.localStorage.getItem(slugStoreVar);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    myStorage = myStorage ? myStorage.split(",") : [];

    return Number(myStorage.length);
};

// export const incrementVisitCount = () => {
//     let myStorage = window.localStorage,
//         pageCount;

//     if (!myStorage.getItem("pageCount")) {
//         myStorage.setItem("pageCount", 1);
//     } else {
//         pageCount = Number(myStorage.getItem("pageCount"));
//         pageCount++;
//         myStorage.setItem("pageCount", pageCount);
//     }

//     return pageCount;
// };

// export const getVisitCount = () => {
//     return Number(window.localStorage.getItem("pageCount"));
// };

export const whatsMyLimit = (isAuthenticated) => {
    if (isAuthenticated) {
        return Number(process.env.REACT_APP_PAYWALL_SIGNEDUP_VIEW_LIMIT);
    } else {
        return Number(process.env.REACT_APP_PAYWALL_GUEST_VIEW_LIMIT);
    }
};

export const calculateMedian = (arr) => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const LOWER_QUARTILE = 0.1;
export const MEDIAN = 0.5;
export const UPPER_QUARTILE = 0.9;

const asc = (arr) => arr.sort((a, b) => a - b);

/**
 * const q25 = arr => quantile(arr, LOWER_QUARTILE);
   const q50 = arr => quantile(arr, MEDIAN);
   const q75 = arr => quantile(arr, UPPER_QUARTILE);

 * @param {*} arr 
 * @param {*} q 
 */
export const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

/**
 * Str values eg.
 * Rents
 * LEASED - MORE WANTED !! => []
 * $750 Per Week => 759
 * $500 - $595 per week => return the lower amount or median?
 *
 * Sales
 * For sale $1mil to $1.05mil => 1000000, 1050000
 * $4,800,000 - $5,280,000 => 4800000, 5280000
 * $1,680,000 => 1680000
 *
 * @param {
 * } str
 */
export const getPrice = (str) => {
    let numbers = [];

    if (str && str !== null) {
        str = str.replace(/,/g, ""); // remove all commas

        if (str.indexOf("mil") > 0) {
            str = str.replace(/mil/g, ""); // remove mil from string
            numbers = str.match(/\$\w+(\.)?(\w+)?/g); // find all digits starting with $
            if (numbers) {
                numbers = numbers.map(
                    (d) => parseFloat(d.replace(/\$/g, "")) * 1000000
                ); // convert to float and convert to million
            }
        } else {
            numbers = str.match(/\d+/g); // find all digits
            if (numbers) {
                numbers = numbers.filter((n) => n.length > 2); // remove short digits eg 1 or 10
                numbers = numbers.map(Number);
            }
        }
    }

    return numbers;
};

/**
 * get the start and end for a quarter eg 1, 2, 3, 4
 * where 0, 2019 returns
 * 20190101, 20190331
 *
 * @param {*} quarter
 * @param {*} year
 */
const getQuarterRange = (quarter, year) => {
    const start = moment()
        .quarter(quarter)
        .startOf("quarter")
        .year(year);

    const end = moment()
        .quarter(quarter)
        .endOf("quarter")
        .year(year);

    return { start, end };
};

const getQuarterKey = (quarter, year) => {
    return year + "Q" + quarter;
};

export const groupPropertiesByQuarterlyDate = (properties, months = 18) => {
    let grouped = {};
    // const dateFormat = "YYYYMMDD";

    // initialise quarters going back x months from today
    const start = moment().subtract({ months: months });
    let quarterAdjustment = start.quarter();
    let year = start.year();
    // console.log("Math.floor(months/3)", Math.floor(months / 3), properties);
    for (let i = 0; i <= Math.floor(months / 3); i++) {
        const quarter = getQuarterRange(quarterAdjustment, year);
        // console.log(
        //     quarter.start.format(dateFormat),
        //     year + "Q" + quarterAdjustment
        // );

        // grouped[quarter.start.format(dateFormat)] = []; // set the key for the grouped properties
        grouped[getQuarterKey(quarterAdjustment, year)] = []; // set the key for the grouped properties

        quarterAdjustment = (quarterAdjustment++ % 4) + 1;
        year = quarter.end.add({ day: 1 }).year();
    }

    // group properties into quarters
    properties.forEach((p) => {
        if (Object.prototype.hasOwnProperty.call(p, "lastContractDate")) {
            let lastContractDate = moment(p.lastContractDate, "YYYYMMDD");

            // const quarterKey = getQuarterRange(
            //     settlementDate.quarter(),
            //     settlementDate.year()
            // ).start.format(dateFormat);

            const quarterKey = getQuarterKey(
                lastContractDate.quarter(),
                lastContractDate.year()
            );

            p.quarter = lastContractDate.quarter();
            p.year = lastContractDate.year();

            // if (quarterKey === "2019Q2") {
            // console.log(p.geohash, p);
            // }

            if (grouped[quarterKey]) {
                grouped[quarterKey].push(p);
            }
        }
    });

    return grouped;
};

export const filterPropertiesByType = (properties, propertyType) => {
    properties = properties.filter((p) =>
        Object.prototype.hasOwnProperty.call(p, "dwellingType")
            ? p.dwellingType === propertyType
            : p.property_type === propertyType
    );

    return properties;
};

export const groupPropertiesByType = (properties, type_map) => {
    let grouped = {};

    properties.forEach((p) => {
        let dwellingType = Object.prototype.hasOwnProperty.call(
            p,
            "dwellingType"
        )
            ? p.dwellingType
            : p.property_type;

        if (dwellingType && typeof dwellingType !== "undefined") {
            dwellingType = dwellingType.toLowerCase();
        }

        const type = type_map[dwellingType];
        if (typeof type !== "undefined") {
            if (grouped[type]) {
                // add to existing type
                grouped[type] = groupPropertiesByDetails(grouped[type], p);
            } else {
                // create new type
                grouped[type] = groupPropertiesByDetails({}, p);
            }
        } else {
            if (Object.values(type_map).indexOf("other") > 0) {
                if (grouped["other"]) {
                    grouped[type] = groupPropertiesByDetails(
                        grouped["other"],
                        p
                    );
                } else {
                    grouped["other"] = groupPropertiesByDetails({}, p);
                }
            }
        }
    });

    return grouped;
};

export const groupPropertiesByDetails = (type_dict, property) => {
    let detail = Object.prototype.hasOwnProperty.call(property, "numBed")
        ? property.numBed
        : undefined;

    if (typeof detail === "undefined") {
        detail = Object.prototype.hasOwnProperty.call(property, "num_bedrooms")
            ? property.num_bedrooms
            : undefined;
    }

    if (typeof detail !== "undefined") {
        if (type_dict[detail]) {
            type_dict[detail].push(property);
        } else {
            type_dict[detail] = [property];
        }
    }

    return type_dict;
};

/**
 *  From the listings get all the prices as an array
 * @param {
 * } properties
 */
export const getPrices = (properties, type = "sold") => {
    let prices = [];

    if (type !== "sold") {
        properties.forEach((v) => {
            if (typeof v.price === "string") {
                let listedPrices = getPrice(v.price);

                if (listedPrices && listedPrices.length === 2) {
                    prices.push((listedPrices[0] + listedPrices[1]) / 2); // take mid point
                } else if (listedPrices && listedPrices.length !== 0) {
                    prices.push(listedPrices[0]);
                }
            } else if (typeof v.price === "number") {
                prices.push(v.price);
            }
        });
    } else {
        properties
            .filter(
                (v) =>
                    //remove prices that are not numbers eg DEPOSIT TAKEN
                    v.sold && v.sold[0] && !isNaN(v.sold[0].purchasePrice)
            )
            .forEach((v) => {
                prices.push(v.sold[0].purchasePrice);
                // console.log(v.geohash);
            });
    }

    // remove outliers eg really small prices or very large prices 3 times the median
    if (prices && prices.length > 0) {
        const medianPrice = calculateMedian(prices);
        prices = prices.filter(
            (p) =>
                (type === "rent" ? p > 100 : p > 100000) || p < 3 * medianPrice
        );
    }

    return prices;
};

export async function getBlogPost(city, neighbourhood) {
    // // fetch the category ID for city
    // const categoryURL =
    //     "https://blog.liveable.co/wp-json/wp/v2/categories?slug=" + city;
    // const tagsURL =
    //     "https://blog.liveable.co/wp-json/wp/v2/tags?slug=" + neighbourhood;

    // // const neighbourhood_tagsURL = "https://blog.liveable.co/wp-json/wp/v2/tags?slug=" + "neighbourhood-guide";

    // const post_promise = await Promise.all([fetch(categoryURL), fetch(tagsURL)])
    //     .then(async ([aa, bb]) => {
    //         const a = await aa.json();
    //         const b = await bb.json();
    //         return [a, b];
    //     })
    //     .then(([category, tag]) => {
    //         if (tag.length) {
    //             const postURL =
    //                 "https://blog.liveable.co/wp-json/wp/v2/posts?tags=" +
    //                 tag[0].id +
    //                 // "," +
    //                 // 158 + // hardcoded neighbourhood-guide tag id
    //                 "&categories=" +
    //                 category[0].id;

    //             console.log(category, tag, postURL);

    //             return fetch(postURL).then((response) => response.json());
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });

    const categoryURL =
        "https://blog.liveable.co/wp-json/wp/v2/categories?slug=" + city;
    const slug = slugify(neighbourhood) + "-neighbourhood-guide";

    const post_promise = fetch(categoryURL)
        .then((response) => response.json())
        .then((category) => {
            if (category.length) {
                const postURL =
                    "https://blog.liveable.co/wp-json/wp/v2/posts?" +
                    "&categories=" +
                    category[0].id +
                    "&slug=" +
                    slug;

                return fetch(postURL).then((response) => response.json());
            }
        });

    return post_promise;
}

export async function trackView(user, pathname, page_type) {
    let trackedUser = undefined;
    if (user && Object.prototype.hasOwnProperty.call(user, "username")) {
        trackedUser = user.username;
    }

    // handle user tracking
    if (process.env.NODE_ENV === "production" && !getUA.includes("Prerender")) {
        setTimeout(() => {
            fetch(process.env.REACT_APP_LIVEABLE_AD_API_URL + "/view", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: trackedUser,
                    pathname: pathname,
                    page_type: page_type,
                }),
            });
        }, 3000);
    }
}
