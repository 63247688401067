import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

AppliedRoute.propTypes = {
    component: PropTypes.func,
    appProps: PropTypes.object,
};

export default function AppliedRoute({ component: C, appProps, ...rest }) {
    return (
        <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
    );
}
