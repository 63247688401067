import React, { Component } from "react";
import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container>
                <Helmet>
                    <title>{"Terms and Conditions - Liveable"}</title>
                    <meta
                        property="description"
                        content={
                            "By accessing and/or using this website and related services, you agree to these Terms and Conditions, which include our Privacy Policy (available at https://liveable.co/privacy) (Terms). You should review our Privacy Policy and these Terms carefully and immediately cease using our website if you do not agree to these Terms."
                        }
                    />
                    <meta
                        property="og:title"
                        content={"Terms and Conditions - Liveable"}
                    />
                    <link rel="canonical" href="https://liveable.co/terms" />
                </Helmet>
                <Row className="mt-5 pb-4 text-center">
                    <Col>
                        <h1>Terms and Conditions</h1>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <p>Thank you for visiting our website. </p>
                        <p>
                            This website is owned and operated by Liveable
                            Insights Pty Ltd (ABN 93 613 654 185).
                        </p>
                        <p>
                            By accessing and/or using this website and related
                            services, you agree to these Terms and Conditions,
                            which include our Privacy Policy (available at{" "}
                            <a href="https://liveable.co/privacy">
                                https://liveable.co/privacy
                            </a>
                            ) (Terms).
                        </p>
                        <p>
                            You should review our Privacy Policy and these Terms
                            carefully and immediately cease using our website if
                            you do not agree to these Terms.{" "}
                        </p>
                        <p>
                            In these Terms, &apos;us&apos;, &apos;we&apos; and
                            &apos;our&apos; means Liveable Insights Pty Ltd.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>1. Registration</h4>
                        <p>
                            You may need to be a registered member to access
                            certain features of our website.
                        </p>
                        <p>
                            When you register and activate your account, you
                            will provide us with personal information such as
                            your name and email address. You must ensure that
                            this information is accurate and current. We will
                            handle all personal information we collect in
                            accordance with our{" "}
                            <a href="https://liveable.co/privacy">
                                Privacy Policy
                            </a>
                            .
                        </p>
                        <p>
                            You are responsible for keeping your account secure
                            and are responsible for all use and activity carried
                            out under your account.
                        </p>
                        <p>
                            If you are under the age of 13 years, you may not
                            create an account or register as a member. If you
                            are 13 or older but under the age of 18, you
                            represent that you have reviewed these Terms with
                            your parent or legal guardian to make sure that you
                            and your parent or legal guardian understand these
                            Terms. If you are a parent or guardian permitting a
                            person under the age of 18 (a Minor) to create an
                            account, you agree to:
                        </p>
                        <ul>
                            <li>
                                exercise supervision over the Minor&apos;s use
                                of our website and account;
                            </li>
                            <li>
                                assume all risks associated with the
                                Minor&apos;s use of our website and their
                                account, including the transmission of content
                                or information to and from third parties via the
                                Internet;
                            </li>
                            <li>
                                ensure that the content and information that the
                                Minor may encounter on our website are suitable
                                for the Minor;
                            </li>
                            <li>
                                assume liabilities resulting from the
                                Minor&apos;s use of our website and their
                                account;
                            </li>
                            <li>
                                ensure the accuracy and truthfulness of all
                                information submitted by the Minor; and
                            </li>
                            <li>
                                provide the consents contained in these Terms on
                                behalf of the Minor.
                            </li>
                        </ul>
                        <p>
                            We may ask you to confirm that you have your
                            parent&apos;s or guardian&apos;s permission and that
                            they have agreed to these Terms on your behalf, and,
                            even if we do not do this, we will assume that this
                            is the case and will provide access to our website
                            and your account on this basis.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>2. Collection Notice</h4>
                        <p>
                            We collect personal information about you in order
                            to respond to your enquiry, process your
                            registration, provide you with access to certain
                            parts of the website and for purposes otherwise set
                            out in our Privacy Policy at{" "}
                            <a href="https://liveable.co/privacy">
                                https://liveable.co/privacy
                            </a>
                            .
                        </p>
                        <p>
                            We may disclose that information to third parties
                            that help us deliver our services (including
                            information technology suppliers, communication
                            suppliers and our business partners) or as required
                            by law. If you do not provide this information, we
                            may not be able to provide all of our services or
                            products to you.
                        </p>
                        <p>
                            Our Privacy Policy explains: (i) how we store and
                            use, and how you may access and correct your
                            personal information; (ii) how you can lodge a
                            complaint regarding the handling of your personal
                            information; and (iii) how we will handle any
                            complaint. If you would like any further information
                            about our privacy policies or practices, please
                            contact us at hello@liveable.co.
                        </p>
                        <p>
                            By providing your personal information to us, you
                            consent to the collection, use, storage and
                            disclosure of that information as described in the
                            Privacy Policy and these Terms.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>
                            3. Accuracy, completeness and timeliness of
                            information
                        </h4>
                        <p>
                            The information on our website is not comprehensive
                            and is intended to provide a summary of the subject
                            matter covered. While we use all reasonable attempts
                            to ensure the accuracy and completeness of the
                            information on our website, to the extent permitted
                            by law, including the Australian Consumer Law, we
                            make no warranty regarding the information on this
                            website. You should monitor any changes to the
                            information contained on this website.
                        </p>
                        <p>
                            We are not liable to you or anyone else if
                            interference with or damage to your computer systems
                            occurs in connection with the use of this website or
                            a linked website. You must take your own precautions
                            to ensure that whatever you select for your use from
                            our website is free of viruses or anything else
                            (such as worms or Trojan horses) that may interfere
                            with or damage the operations of your computer
                            systems.
                        </p>
                        <p>
                            We may, from time to time and without notice, change
                            or add to the website (including the Terms) or the
                            information, products or services described in it.
                            However, we do not undertake to keep the website
                            updated. We are not liable to you or anyone else if
                            errors occur in the information on the website or if
                            that information is not up-to-date.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>4. Promotions and competitions</h4>
                        <p>
                            For certain campaigns, promotions or contests,
                            additional terms and conditions may apply. If you
                            want to participate in such a campaign, promotion or
                            contest, you need to agree to the relevant terms and
                            conditions applicable to that campaign, promotion or
                            contest. In case of any inconsistency between such
                            terms and conditions and these Terms, those terms
                            and conditions will prevail.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>5. Purchases</h4>
                        <p>
                            You are able to purchase premium services from us.
                            These services can be delivered via subscription or
                            one-off payments. We reserve the right to cancel or
                            refuse access to our premium services for any user.
                        </p>
                        <p>
                            The following terms apply to those users that have
                            purchased said premium features.
                        </p>
                        <h5>Price Changes:</h5>
                        <p>
                            We reserve the right to change our pricing at any
                            time, and with reasonable notice to our subscribers.
                            At the end of the notice period the user’s next
                            billing period (i.e. monthly or annually) payment
                            shall be at the revised price.
                        </p>
                        <h5>Payment methods and security:</h5>
                        <p>
                            Payments are made via credit card and handled by
                            Stripe, a third-party payment service. Credit card
                            details are not stored on our systems.
                        </p>
                        <h5>Cancellation:</h5>
                        <p>
                            You may cancel their subscription at any time. You
                            shall not be entitled to a partial refund, however,
                            at our sole discretion, we may choose to give you a
                            partial refund.
                        </p>
                        <h5>Subscription fees:</h5>
                        <p>
                            If applicable, you authorise us to automatically
                            debit your credit card on your initial registration
                            and then on each subsequent anniversary of your
                            billing cycle (i.e. monthly or quarterly or
                            annually), until cancelled.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>6. Linked sites</h4>
                        <p>
                            Our website may contain links to websites operated
                            by third parties. Those links are provided for
                            convenience and may not remain current or be
                            maintained. Unless expressly stated otherwise, we do
                            not endorse and are not responsible for the content
                            on those linked websites and have no control over or
                            rights in those linked websites.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>7. Intellectual property rights</h4>
                        <p>
                            Unless otherwise indicated, we own or license from
                            third parties all rights, title and interest
                            (including copyright, designs, patents, trademarks
                            and other intellectual property rights) in this
                            website and in all of the material (including all
                            text, graphics, logos, audio and software) made
                            available on this website (Content).
                        </p>
                        <p>
                            Your use of this website and use of and access to
                            any Content does not grant or transfer any rights,
                            title or interest to you in relation to this website
                            or the Content. However we do grant you a licence to
                            access the website and view the Content on the terms
                            and conditions set out in this Agreement and, where
                            applicable, as expressly authorised by us and/or our
                            third party licensors.
                        </p>
                        <p>
                            Any reproduction or redistribution of this website
                            or the Content is prohibited and may result in civil
                            and criminal penalties. In addition, you must not
                            copy the Content to any other server, location or
                            support for publication, reproduction or
                            distribution is expressly prohibited.
                        </p>
                        <p>
                            All other use, copying or reproduction of this
                            website, the Content or any part of it is
                            prohibited, except to the extent permitted by law.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>8. No commercial use</h4>
                        <p>
                            This website is for your personal, non-commercial
                            use only. You may not modify, copy, distribute,
                            transmit, display, perform, reproduce, publish,
                            license, commercially exploit, create derivative
                            works from, transfer, or sell any Content, software,
                            products or services contained within this website.
                            You may not use this website, or any of its Content,
                            to further any commercial purpose, including any
                            advertising or advertising revenue generation
                            activity on your own website.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>9. Unacceptable activity</h4>
                        <p>
                            You must not do any act that we would deem to be
                            inappropriate, is unlawful or is prohibited by any
                            laws applicable to our website, including but not
                            limited to:
                        </p>
                        <ul>
                            <li>
                                any act that would constitute a breach of either
                                the privacy (including uploading private or
                                personal information without an
                                individual&apos;s consent) or any other of the
                                legal rights of individuals;
                            </li>
                            <li>
                                using this website to defame or libel us, our
                                employees or other individuals;
                            </li>
                            <li>
                                uploading files that contain viruses that may
                                cause damage to our property or the property of
                                other individuals;
                            </li>
                            <li>
                                posting or transmitting to this website any
                                non-authorised material including, but not
                                limited to, material that is, in our opinion,
                                likely to cause annoyance, or which is
                                defamatory, racist, obscene, threatening,
                                pornographic or otherwise or which is
                                detrimental to or in violation of our systems or
                                a third party&apos;s systems or network
                                security.
                            </li>
                        </ul>
                        <p>
                            If we allow you to post any information to our
                            website, we have the right to take down this
                            information at our sole discretion and without
                            notice.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>10. Warranties and disclaimers</h4>
                        <p>
                            To the maximum extent permitted by law, including
                            the Australian Consumer Law, we make no warranties
                            or representations about this website or the
                            Content, including but not limited to warranties or
                            representations that they will be complete, accurate
                            or up-to-date, that access will be uninterrupted or
                            error-free or free from viruses, or that this
                            website will be secure.
                        </p>
                        <p>
                            We reserve the right to restrict, suspend or
                            terminate without notice your access to this
                            website, any Content, or any feature of this website
                            at any time without notice and we will not be
                            responsible for any loss, cost, damage or liability
                            that may arise as a result.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h4>11. Liability</h4>
                        <p>
                            To the maximum extent permitted by law, including
                            the Australian Consumer Law, in no event shall we be
                            liable for any direct and indirect loss, damage or
                            expense – irrespective of the manner in which it
                            occurs – which may be suffered due to your use of
                            our website and/or the information or materials
                            contained on it, or as a result of the
                            inaccessibility of this website and/or the fact that
                            certain information or materials contained on it are
                            incorrect, incomplete or not up-to-date.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <h4>12. Jurisdiction and governing law</h4>
                        <p>
                            Your use of the website and these Terms are governed
                            by the law of New South Wales and you submit to the
                            non-exclusive jurisdiction of the courts exercising
                            jurisdiction in New South Wales.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h4>13. Supplier Notices</h4>
                        <p>
                            Our services may incorporate the Google Maps API
                            provided by Google Inc. Where the Google Maps API is
                            incorporated into our services, you agree to be
                            bound by Google’s Terms of Service available at{" "}
                            <a href="https://www.google.com/intl/en/policies/terms">
                                https://www.google.com/intl/en/policies/terms
                            </a>
                        </p>
                        <p>
                            Transit Data used on the website is provided by
                            HERE. © 1987 - 2019 HERE. All rights reserved.
                        </p>
                        <p>
                            Includes content licensed under Creative Commons
                            Attribution 4.0 International (CC BY 4.0) License at{" "}
                            <a href="http://creativecommons.org/licenses/by/4.0">
                                http://creativecommons.org/licenses/by/4.0
                            </a>{" "}
                            from the following sources:
                        </p>
                        <ul>
                            <li>
                                Transport for NSW,{" "}
                                <a href="https://opendata.transport.nsw.gov.au/data-licence">
                                    https://opendata.transport.nsw.gov.au/data-licence
                                </a>
                            </li>
                            <li>
                                Australian Bureau of Statistics,{" "}
                                <a href="https://www.abs.gov.au/websitedbs/D3310114.nsf/Home/Creative%20Commons%20licensing">
                                    https://www.abs.gov.au/websitedbs/D3310114.nsf/Home/Creative%20Commons%20licensing
                                </a>
                            </li>
                            <li>
                                NSW Department of Education,{" "}
                                <a href="https://data.cese.nsw.gov.au/data/dataset/school-intake-zones-catchment-areas-for-nsw-government-schools">
                                    https://data.cese.nsw.gov.au/data/dataset/school-intake-zones-catchment-areas-for-nsw-government-schools
                                </a>
                            </li>
                            <li>
                                FontAwesome,{" "}
                                <a href="https://github.com/FortAwesome/Font-Awesome">
                                    https://github.com/FortAwesome/Font-Awesome
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Terms;
