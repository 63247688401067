import React, { useEffect } from "react";
import { JSONLD, Generic, GenericCollection } from "react-structured-data";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { getUA } from "react-device-detect";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { UserContext } from "../App";

Pricing.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export function Pricing(props) {
    const [state, dispatch] = React.useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleSignup(subscribe, planType) {
        const location = {
            pathname: "/signup",
            state: {
                from: props.location.pathname,
                property:
                    props.location.state && props.location.state.from
                        ? props.location.state.from
                        : "https://liveable.co",
                subscribe: subscribe,
                planId: planType,
            },
        };
        props.history.push(location);
    }

    function handleSubscribe(planType) {
        window.Chargebee.getInstance().openCheckout({
            hostedPage: () => {
                let data = {
                    userId: state.user.username,
                    firstName: state.user.attributes.given_name,
                    email: state.user.attributes.email,
                    redirectUrl:
                        props.location.state && props.location.state.from
                            ? "https://liveable.co" + props.location.state.from
                            : "https://liveable.co",
                    planId: planType,
                };
                return axios
                    .post(
                        process.env.REACT_APP_CHARGEBEE_API_URL +
                            "/generate_checkout_new_url",
                        data
                    )
                    .then((response) => response.data);
            },
            success() {
                dispatch({
                    type: "SET_USER_PLAN",
                    payload: "Pro",
                });
                props.location.state.pathname
                    ? props.history.push(props.location.state.from)
                    : props.history.push("/");
            },
        });
    }

    // Used to add new user to Pro group in Cognito
    // async function addToGroup(groupName) {
    //     Promise.resolve(Auth.currentAuthenticatedUser()).then((user) =>
    //         Auth.currentCredentials()
    //             .then((credentials) =>
    //                 Promise.resolve(
    //                     new CognitoIdentityServiceProvider({
    //                         apiVersion: "2016-04-18",
    //                         credentials: Auth.essentialCredentials(credentials),
    //                         region: "ap-southeast-2",
    //                     })
    //                 )
    //             )
    //             .then((client) =>
    //                 client
    //                     .adminAddUserToGroup({
    //                         GroupName: "Pro",
    //                         UserPoolId: user.pool.userPoolId,
    //                         Username: user.username,
    //                     })
    //                     .promise()
    //             )
    //     );
    // }

    return (
        <Container>
            <Helmet>
                <title>{"Pricing - Liveable"}</title>
                <meta
                    property="description"
                    content={
                        "Liveable provides a service that enables you to access our full suite of data covering a Property and its surroundings without any restrictions."
                    }
                />
                <meta property="og:title" content={"Pricing - Liveable"} />
                <link rel="canonical" href="https://liveable.co/pricing" />
                {/* Zendesk widget */}
                {!getUA.includes("Prerender") &&
                    process.env.NODE_ENV === "production" && (
                        <script
                            id="ze-snippet"
                            src="https://static.zdassets.com/ekr/snippet.js?key=09814143-b86a-422d-a3bb-f9c58904826d"
                        ></script>
                    )}
            </Helmet>
            <JSONLD>
                <Generic
                    type="website"
                    jsonldtype="WebSite"
                    schema={{
                        url: "https://liveable.co/pricing",
                        name: "Pricing - Liveable",
                        description:
                            "Liveable provides a service that enables you to access our full suite of data covering a Property and its surroundings without any restrictions.",
                    }}
                />
            </JSONLD>
            <JSONLD>
                <Generic type="faq" jsonldtype="FAQPage">
                    <GenericCollection type="mainEntity">
                        <Generic
                            jsonldtype="Question"
                            schema={{
                                name: "Do you provide a trial?",
                            }}
                        >
                            <Generic
                                type="acceptedAnswer"
                                jsonldtype="Answer"
                                schema={{
                                    text:
                                        "Yes, all our plans come with a 7-day free trial period. You will only be charged after the trial period is over.",
                                }}
                            />
                        </Generic>
                        <Generic
                            jsonldtype="Question"
                            schema={{
                                name: "What is the difference between plans?",
                            }}
                        >
                            <Generic
                                type="acceptedAnswer"
                                jsonldtype="Answer"
                                schema={{
                                    text:
                                        "The key difference between the plans is the frequency of billing - weekly, monthly, bi-annual, annual. All our plans provide comprehensive data for an unlimited number of views across our database of over 500,000+ properties.",
                                }}
                            />
                        </Generic>
                        <Generic
                            jsonldtype="Question"
                            schema={{
                                name: "Does my subscription auto-renew?",
                            }}
                        >
                            <Generic
                                type="acceptedAnswer"
                                jsonldtype="Answer"
                                schema={{
                                    text:
                                        "Yes, your subscription will automatically renew based on the period you elected. You will get an email reminder a couple of days before it is due for renewal.",
                                }}
                            />
                        </Generic>
                        <Generic
                            jsonldtype="Question"
                            schema={{
                                name: "Can I cancel any time?",
                            }}
                        >
                            <Generic
                                type="acceptedAnswer"
                                jsonldtype="Answer"
                                schema={{
                                    text:
                                        "Yes, you can cancel at any time you wish. If you cancel during your 7-day trial period, you will not be charged. However, once you pass the 7-day trial period and have been charged, we will not provide any refunds for unused days. Your account will continue to be active for the life of the period you subscribed for.However, we will not provide any refunds for unused days and your account will continue to be active for the life of the period you subscribed for.",
                                }}
                            />
                        </Generic>
                        <Generic
                            jsonldtype="Question"
                            schema={{
                                name: "How are my payment details managed?",
                            }}
                        >
                            <Generic
                                type="acceptedAnswer"
                                jsonldtype="Answer"
                                schema={{
                                    text:
                                        "We do not store any of your payment details. All our transactions are handled by Stripe, a reputable payment provider that maintain high standards for security.",
                                }}
                            />
                        </Generic>
                    </GenericCollection>
                </Generic>
            </JSONLD>
            <Row className="mt-5 pb-4 text-center">
                <Col>
                    <h1>Upgrade to supercharge your property research</h1>
                </Col>
            </Row>
            {/* {console.log(props.location)} */}
            {props.location.state && props.location.state.message && (
                <Row className="mt-3 text-center">
                    <Col>
                        <Alert variant="warning">
                            <Alert.Heading as="h5">
                                {props.location.state.message}
                            </Alert.Heading>
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className="mt-3 text-center">
                <Col>
                    <Alert variant="success">
                        <Alert.Heading as="h5">
                            <p>
                                Get <strong>unlimited</strong> access to over
                                500,000+ properties
                                <br />
                                for less than $4 per week
                            </p>
                            <p>
                                <em>
                                    <small>
                                        (that&#39;s cheaper than the price of a
                                        coffee!)
                                    </small>
                                </em>
                            </p>
                        </Alert.Heading>
                    </Alert>
                </Col>
            </Row>
            <Row className="mt-5 text-center">
                <Col>
                    <h2>Select your billing cycle</h2>
                </Col>
            </Row>
            <Row className="mb-5 pb-3">
                {/* 1 MONTH PLAN */}
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Card className="mt-4 pb-2 text-center">
                        <Card.Body>
                            <Card.Title>
                                <strong>1 month</strong>
                            </Card.Title>
                            <p>with 7 day free trial</p>
                            <h2>$59 AUD</h2>
                            <em>($13.61 per week)</em>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                id="buy-monthly"
                                variant="warning"
                                size="lg"
                                block
                                onClick={() => {
                                    state.isAuthenticated
                                        ? handleSubscribe("monthly")
                                        : handleSignup(true, "monthly");
                                }}
                                disabled={
                                    state.isAuthenticated &&
                                    state.userPlan === "Pro"
                                        ? true
                                        : false
                                }
                            >
                                {state.isAuthenticated &&
                                state.userPlan === "Pro"
                                    ? "Subscribed"
                                    : "Start Free Trial"}
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
                {/* 6 MONTHS PLAN */}
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Card
                        className="mt-4 pb-2 text-center shadow"
                        style={{ borderWidth: "2px" }}
                    >
                        <Card.Body>
                            <Card.Title>
                                <strong>6 months</strong>
                            </Card.Title>
                            <p>with 7 day free trial</p>
                            <h2>$99 AUD</h2>
                            <em>($3.82 per week)</em>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                id="buy-sixmonths"
                                variant="warning"
                                size="lg"
                                block
                                onClick={() => {
                                    state.isAuthenticated
                                        ? handleSubscribe("sixmonths")
                                        : handleSignup(true, "sixmonths");
                                }}
                                disabled={
                                    state.isAuthenticated &&
                                    state.userPlan === "Pro"
                                        ? true
                                        : false
                                }
                            >
                                {state.isAuthenticated &&
                                state.userPlan === "Pro"
                                    ? "Subscribed"
                                    : "Start Free Trial"}
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
                {/* ANNUAL PLAN */}
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Card className="mt-4 pb-2 text-center">
                        <Card.Body>
                            <Card.Title>
                                <strong>12 months</strong>
                            </Card.Title>
                            <p>with 7 day free trial</p>
                            <h2>$189 AUD</h2>
                            <em>($3.63 per week)</em>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                id="buy-annual"
                                variant="warning"
                                size="lg"
                                block
                                onClick={() => {
                                    state.isAuthenticated
                                        ? handleSubscribe("annual")
                                        : handleSignup(true, "annual");
                                }}
                                disabled={
                                    state.isAuthenticated &&
                                    state.userPlan === "Pro"
                                        ? true
                                        : false
                                }
                            >
                                {state.isAuthenticated &&
                                state.userPlan === "Pro"
                                    ? "Subscribed"
                                    : "Start Free Trial"}
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <Card>
                        <Card.Body className="mt-2">
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                <strong>Unlimited</strong> Property Report Views
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Property Sale and Listing History
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Property Statistics
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Area Details
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Detailed Demographics
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Property Listings
                            </Card.Text>
                            <Card.Text>
                                <FaCheck className="mr-2" />
                                Cancel any time
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}
            <Row className="mt-5 text-center">
                <Col>
                    <h2>FAQ</h2>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mt-4">
                    <h3 style={{ fontSize: "1.4rem" }}>
                        Do you provide a trial?
                    </h3>
                    <p>
                        Yes, all our plans come with a 7-day free trial period.
                        You will only be charged after the trial period is over.
                    </p>
                    <h3 style={{ fontSize: "1.4rem" }}>
                        What is the difference between plans?
                    </h3>
                    <p>
                        The key difference between the plans is the frequency of
                        billing - weekly, monthly, bi-annual, annual.
                    </p>
                    <p>
                        All our plans provide comprehensive data for an{" "}
                        <strong>unlimited</strong> number of views across our
                        database of over 500,000+ properties.
                    </p>
                    <h3 style={{ fontSize: "1.4rem" }}>
                        Does my subscription auto-renew?
                    </h3>
                    <p>
                        Yes, your subscription will automatically renew based on
                        the period you elected. You will get an email reminder a
                        couple of days before it is due for renewal.
                    </p>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mt-4">
                    <h3 style={{ fontSize: "1.4rem" }}>
                        Can I cancel at any time?
                    </h3>
                    <p>Yes, you can cancel at any time you wish.</p>
                    <p>
                        If you cancel during your 7-day trial period, you will
                        not be charged.
                    </p>
                    <p>
                        However, once you pass the 7-day trial period and have
                        been charged, we will not provide any refunds for unused
                        days. Your account will continue to be active for the
                        life of the period you subscribed for.
                    </p>
                    <h3 style={{ fontSize: "1.4rem" }}>
                        How are my payment details managed?
                    </h3>
                    <p>
                        We do not store any of your payment details. All our
                        transactions are handled by Stripe, a reputable payment
                        provider that maintain high standards for security.
                    </p>
                </Col>
            </Row>
            <Row className="mt-4 pb-5">
                <Col className="text-center">
                    If you have any questions that are not covered in our FAQ,
                    you can reach out to us via email:{" "}
                    <a href="mailto:hello@liveable.co">hello@liveable.co</a>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(Pricing);
